import React from "react";
import { Button, Stack, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Typography, TextField } from "@mui/material";

const FileUpload = (porps) => {
  let {
    value,
    handleClick,
    handleFileChange,
    setFieldValue,
    values,
    touched,
    errors,
  } = porps;
  return (
    <>
      <Stack gap={3}>
        <Typography className="fw-400 dark-text">File Summary</Typography>
        <TextField
          className="input-field"
          fullWidth
          id="outlined-basic short summary"
          variant="outlined"
          multiline
          maxRows={2}
          name="fileDescription"
          placeholder="Enter file summary here..."
          value={values?.fileDescription}
          onChange={(event) => {
            setFieldValue("fileDescription", event.target.value);
          }}
          helperText={touched.fileDescription && errors.fileDescription}
          error={touched.fileDescription && Boolean(errors.fileDescription)}
        />

        {value && (
          <div className="upload-img-wrapper mt-2 text-break">
            {value && `File name: ${value.name || value}`}
          </div>
        )}

        <Button className="btn ternary-btn w-100 mt-3" onClick={handleClick}>
          <FontAwesomeIcon icon={faUpload} />
          {value ? "Change File" : "Upload File"}
        </Button>
      </Stack>
    </>
  );
};

export default FileUpload;
