import { lazy, Suspense } from "react";
import AddNews from "../pages/home/addNews";
import AuthGuard from "../utils/authGuard";
import AuthGuardForSuperAdmin from "../utils/authGuardForSuperAdmin";
import Types from "../pages/types";
import AddNewTypes from "../pages/types/addNewType";
import Dashboard from "../pages/dashboard";
import System from "../pages/system";
import People from "../pages/people";
import Forms from "../pages/forms";
import Categories from "../pages/categories";
import Settings from "../pages/settings";
import ProfileFields from "../pages/profile-fields";
import Users from "../pages/users";
import DummyPostType from "../pages/dummy-type-post";
import SearchResult from "../pages/search-result/searchResult";

const dashboardRoutes = [
  {
    path: "/",
    element: (
      <Suspense>
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/system",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <System />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/people",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <People />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/forms",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <Forms />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/settings",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <Settings />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/profile-fields",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <ProfileFields />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/dummy-post-type",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <DummyPostType />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/search-result",
    element: (
      <Suspense>
        <AuthGuard>
          <SearchResult />
        </AuthGuard>
      </Suspense>
    ),
  },
];

export default dashboardRoutes;
