import React from "react";

import {
  Stack,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Zoom,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TableHeaderForSorting = ({
  columns,
  handleChangeDataOrdr,
  dataOrder,
}) => {
  return (
    <TableHead className={`z-0`} style={{ zIndex: 0 }}>
      <TableRow>
        {columns?.map((headCell) =>
          headCell?.sorting ? (
            <TableCell
              key={headCell.id}
              align={headCell.isCenter ? "center" : "left"}
              padding={"normal"}
              sortDirection={true}
              sx={{ minWidth: headCell?.minWidth }}
              className={`tbl-cell-head ${headCell?.className} text-uppercase`}
            >
              <TableSortLabel
                onClick={() => { }}
                sx={{ color: "#0395FF !important" }}
                className="text-uppercase"
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align={headCell.isCenter ? "center" : "left"}
              padding={"normal"}
              sortDirection={false}
              sx={{ color: "#0395FF", minWidth: headCell?.minWidth }}
              className={`tbl-cell-head ${headCell?.className} text-uppercase`}
            >
              {headCell?.showInfoIcon ? (
                <>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <span>{headCell.label}</span>{" "}
                    <Tooltip
                      title={headCell?.tooltipText}
                      TransitionComponent={Zoom}
                      placement="top"
                      arrow
                      sx={{ cursor: "pointer" }}
                    >
                      {dataOrder === "asc" ? (
                        <ExpandLessIcon
                          fontSize="small"
                          onClick={handleChangeDataOrdr}
                          color="#660C60"
                        />
                      ) : (
                        <ExpandMoreIcon
                          fontSize="small"
                          onClick={handleChangeDataOrdr}
                          color="#660C60"
                        />
                      )}
                    </Tooltip>
                  </Stack>
                </>
              ) : (
                headCell.label
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaderForSorting;
