export const columns_types_ack_fields_header = [
  {
    key: 1,
    disablePadding: true,
    id: "attribute",
    label: "Attribute",
    minWidth: 350,
    className: "tbl-cell-head-4",
  },
  {
    key: 2,
    disablePadding: true,
    id: "mandatory",
    label: "Mandatory?",
    minWidth: 240,
    className: "tbl-cell-head-4",
  },
];
