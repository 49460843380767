import { lazy, Suspense } from "react";

const Authentication = lazy(() => import("../pages/authentication"));

const authRoutes = [
  {
    path: "/sign-in",
    element: (
      <Suspense>
        <Authentication />
      </Suspense>
    ),
  },
  {
    path: "/auth/google",
    element: (
      <Suspense>
        <Authentication />
      </Suspense>
    ),
  },
];

export default authRoutes;
