export const columns_global2_search_header = [
    {
        key: 1,
        disablePadding: true,
        id: "name",
        label: "Name",
        minWidth: 250,
        className: "tbl-head-class-2",
        showInfoIcon: true,
    },
    {
        key: 2,
        disablePadding: true,
        id: "modified",
        label: "Last Modified",
        minWidth: 200,
    },
    {
        key: 3,
        disablePadding: true,
        id: "type",
        label: "Type",
        minWidth: 200,
    },
    {
        key: 4,
        disablePadding: true,
        id: "created-by",
        label: "Created By",
        minWidth: 200,
    },
];
