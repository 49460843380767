import React, { useEffect, useState } from "react";
import Header from "../../components/layout/header";
import DrawerMenu from "../../components/layout/drawer";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Image } from "react-bootstrap";
import {
  Box,
  Stack,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  CircularProgress,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faGears, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { Toast } from "../../utils/toast";
import {
  faCheck,
  faList,
  faPlay,
  faPause,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../../components/common/loader";
import api from "../../components/common/commonFunctionForApi";
import { attributeTabAction } from "../../redux/slices/attribute/attribute";
import { Toaster } from "react-hot-toast";
import { columns_atttributes_headers } from "../../helpers/theaderColumns/attributes/attributes";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import StatusIcon from "../../components/common/StatusIcon";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box">{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  errorTab: PropTypes.number.isRequired,
};

const Attributes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedAttributeTab } = useSelector((state) => state?.attributeTab);

  const [value, setValue] = useState(0);
  const [dataOrder, setDataOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const [getAllAttributes, setGetAllAttributes] = useState([]);
  const [anchorElMenu, setAnchorElForMenu] = useState(null);
  const [attributeID, setAttributeID] = useState();
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const openMenuFoEdit = Boolean(anchorElMenu);

  //states for paginations
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [nextPage, setNextPage] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(false);

  const ITEM_HEIGHT = 48;

  const user = localStorage.getItem("user");
  const userToken = localStorage.getItem("accessToken");

  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    drawerVariant,
    usersCollapsable,
  } = useSelector((state) => state?.drawerMenu);

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setAttributeID(row);
  };
  const handleClose = () => {
    setAnchorElForMenu(null);
  };

  const handleChange = (event, newValue) => {
    dispatch(attributeTabAction.handleSelectedTab(newValue));

    setValue(newValue);
    let value =
      newValue == 0
        ? "enable"
        : newValue == 1
          ? "disable"
          : newValue == 3
            ? "deleted" : "system";
    setPage(1);
    setGetAllAttributes([]);
    fetchAllAttributes(value, dataOrder, 1);
  };

  const handleChangeDataOrdr = () => {
    let order = dataOrder === "asc" ? "desc" : "asc";
    setDataOrder(dataOrder === "asc" ? "desc" : "asc");

    setPage(1);
    setGetAllAttributes([]);

    fetchAllAttributes(
      value == 0
        ? "enable"
        : value == 1
          ? "disable"
          : value == 3
            ? "deleted"
            : "system",
      order, 1
    );
  };

  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const changeAttributeStatus = async (id, status) => {
    try {
      let data = {
        status: status,
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/attribute/status `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        if (value === 2) {
          setGetAllAttributes((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id ? { ...category, status: status } : category
            )
          );
        } else {
          setGetAllAttributes((prevAtt) =>
            prevAtt.filter((att) => att.id !== id)
          );
        }
        setLoading(false)
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const deleteAttributeAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/attribute/delete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        if (value === 2) {
          setGetAllAttributes((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id ? { ...category, status: 'deleted' } : category
            )
          );

        } else {
          setGetAllAttributes((prevAtt) =>
            prevAtt.filter((att) => att.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
        setDataOrder(dataOrder);
      } else {
        if (response?.message) {
          Toast.error(response?.message);
        }
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const RestoreAttribute = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/attribute/restore `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        if (value === 2) {
          setGetAllAttributes((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id ? { ...category, status: response?.response?.status } : category
            )
          );

        } else {
          setGetAllAttributes((prevAtt) =>
            prevAtt.filter((att) => att.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const permanantlyDeleteAttributeAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/attribute/forcedelete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);

        setGetAllAttributes([]);
        setPage(1);

        fetchAllAttributes(
          value == 0
            ? "enable"
            : value == 1
              ? "disable"
              : value == 3
                ? "deleted"
                : "system",
          dataOrder, 1
        );
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps2(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps3(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps4(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps5(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  const fetchAllAttributes = async (type, order, page) => {
    try {
      if (page == 1) {
        setLoading(true);
      }
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(
        `admin/attribute/list/${type}${order ? `?order=${order}` : ""}&page=${page ?? 1}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const newCategories = response?.response?.data;

      setGetAllAttributes((prevAtt) => {
        const uniqueAtt = newCategories.filter(
          (newAtt) =>
            !prevAtt.some((att) => att.id === newAtt.id)
        );
        return [...prevAtt, ...uniqueAtt];
      });

      setTotalPages(response?.response?.meta.last_page);
      if (page + 1 <= response?.response?.meta.last_page) {
        setNextPage(true);
      }
    } catch (err) {
      console.log("error in fetch posts api::", err);
      if (err?.response?.status === 401) {
        localStorage.setItem("last-redirect-page", `/categories`);
        logoutHelper(dispatch, navigate, userToken);
      }

      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    } finally {
      setIsDataFetching(false); // Reset fetching state after the API call
      setLoading(false);
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const handleScroll = async () => {
    const scrollableHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.innerHeight + window.scrollY;
    const remainingScrollThreshold = scrollableHeight * 0.3;

    if (
      scrollableHeight - scrollPosition < remainingScrollThreshold &&
      !isDataFetching &&
      page < totalPages
    ) {
      setIsDataFetching(true);
      const nextPageNum = page + 1;
      setPage(nextPageNum);
      await fetchAllAttributes(value == 0
        ? "enable"
        : value == 1
          ? "disable"
          : value == 3
            ? "deleted"
            : "system", dataOrder, nextPageNum);
    }
  };

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () =>
      window.removeEventListener("scroll", debouncedHandleScroll);
  }, [isDataFetching, page, totalPages]);


  useEffect(() => {
    fetchAllAttributes(
      selectedAttributeTab == 0
        ? "enable"
        : selectedAttributeTab == 1
          ? "disable"
          : selectedAttributeTab == 3
            ? "deleted"
            : "system",
      dataOrder
    );
    setValue(selectedAttributeTab);
  }, []);

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage == true && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage == true && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <div className="main-div-2-types">
            <Box className="">
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    component="h3"
                    className="cursor-pointer"
                  >
                    Admin
                  </Link>
                  <Typography key="3" component="h3" color="text.primary">
                    Attributes
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Box>
            <Button
              className="btn primary-btn"
              onClick={() => {
                localStorage.setItem(
                  "last-redirect-page",
                  `/attribute/add-new`
                );

                navigate("/attribute/add-new");
              }}
            >
              Add New
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="box-attribute"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                className={"tabs"}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#660C60",
                  },
                }}
              >
                {/* <Tab
                  icon={<FontAwesomeIcon icon={faList} />}
                  label="All Attributes"
                  iconPosition="start"
                  {...a11yProps1(0)}
                /> */}

                <Tab
                  label="Active Attributes"
                  icon={<FontAwesomeIcon icon={faPlay} />}
                  iconPosition="start"
                  {...a11yProps2(0)}
                />
                <Tab
                  label="Inactive Attributes"
                  {...a11yProps3(1)}
                  icon={<FontAwesomeIcon icon={faPause} />}
                  iconPosition="start"
                />

                <Tab
                  label="System Attributes"
                  {...a11yProps5(2)}
                  icon={<FontAwesomeIcon icon={faGears} />}
                  iconPosition="start"
                />
                <Tab
                  label="Deleted Attributes"
                  {...a11yProps4(3)}
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  iconPosition="start"
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={value}>
              <Paper sx={{ width: "100%" }} className="type-list-paper">
                <TableContainer>
                  {" "}
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    {getAllAttributes?.length > 0 && (
                      <>
                        <TableHeaderForSorting
                          columns={columns_atttributes_headers}
                          handleChangeDataOrdr={handleChangeDataOrdr}
                          dataOrder={dataOrder}
                        />
                        <TableBody>
                          {getAllAttributes?.map((row, index) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index + 1}
                              className="cursor-pointer"
                            >
                              <TableCell
                                align="left"
                                className={`tbl-body-cell tbl-border-left ${row?.status === "deleted" && "text-muted"
                                  }`}
                              >
                                <div
                                  className="d-flex flex-warp gap-3"
                                  style={{ fontSize: "14px" }}
                                >
                                  <p
                                    className={`${row?.status === "deleted" &&
                                      "text-muted fw-700"
                                      }`}
                                  >
                                    {row?.title}
                                  </p>
                                  {row?.is_system == 1 && (
                                    <Chip
                                      label="System"
                                      className="chip-label-div"
                                    />
                                  )}
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tbl-body-cell-3 "
                              >
                                {row?.section_name}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tbl-body-cell-3 "
                              >
                                {row?.element_name}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tbl-body-cell-4"
                              >
                                <FontAwesomeIcon
                                  icon={
                                    row?.is_repeatable != 1 ? faXmark : faCheck
                                  }
                                  className={`${row?.is_repeatable == 1
                                    ? "check-icon"
                                    : "cross-icon"
                                    }`}
                                />
                              </TableCell>
                              <TableCell align="left" className="tbl-body-cell">
                                <Box>
                                  <Stack
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                    spacing={1}
                                    className="z-n1"
                                  >
                                    <Avatar
                                      alt="Admin"
                                      src="/static/images/avatar/1.jpg"
                                      className="author-avtar-creator"
                                    >
                                      {row?.created_by?.charAt(0)}
                                    </Avatar>
                                    <Typography className="fw-400 fs-14 ">
                                      {row?.created_by}
                                    </Typography>
                                  </Stack>
                                </Box>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tbl-body-cell-2 tbl-border-right"
                              >
                                <div>
                                  <Stack
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                    spacing={1}
                                    className="gap-1"
                                  >
                                    <StatusIcon status={row?.is_archived === 1 ? 'archived' : row?.status} />

                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        openMenuFoEdit
                                          ? "long-menu"
                                          : undefined
                                      }
                                      aria-expanded={
                                        openMenuFoEdit ? "true" : undefined
                                      }
                                      aria-haspopup="true"
                                      onClick={(e) => handleClick(e, row)}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Stack>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
                {isDataFetching && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 2
                    }}
                  >
                    <CircularProgress sx={{ color: "#660C60" }} />
                  </Box>
                )}
              </Paper>
              {getAllAttributes?.length < 1 && (
                <div className="no-data mt-5">
                  <div>
                    <Image
                      src={NoDataFoundImg}
                      className="text-center mx-auto no-data-img mt-4"
                    />
                  </div>
                  <Typography component="h5" variant="h5">
                    No data found
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Box>
        </Container>
        <Menu
          anchorEl={anchorElMenu}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          open={openMenuFoEdit}
          anchorOrigin={"right"}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              zIndex: "1",
            },
          }}
        >
          {attributeID?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                localStorage.setItem(
                  "last-redirect-page",
                  `/attribute/edit/${attributeID?.id}`
                );
                navigate(`/attribute/edit/${attributeID?.id}`);
              }}
            >
              <Box className="d-flex flex-wrap justify-content-center align-items-center gap-3">
                <FontAwesomeIcon icon={faPen} className="tbl-menu-item-text-icon" />
                <Typography className="tbl-menu-item-text">Edit Attribute</Typography>
              </Box>

            </MenuItem>
          )}

          {attributeID?.status !== "enable" &&
            attributeID?.status !== "deleted" && (
              <MenuItem
                onClick={() => changeAttributeStatus(attributeID?.id, "enable")}
              >
                Active
              </MenuItem>
            )}
          {attributeID?.status !== "disable" &&
            attributeID?.status !== "deleted" && (
              <MenuItem
                onClick={() =>
                  changeAttributeStatus(attributeID?.id, "disable")
                }
              >
                Inactive
              </MenuItem>
            )}
          {attributeID?.status !== "deleted" && attributeID?.is_system !== 1 && (
            <MenuItem onClick={() => deleteAttributeAPI(attributeID?.id)}>
              Delete
            </MenuItem>
          )}
          {attributeID?.status === "deleted" && attributeID?.is_system !== 1 && (
            <>
              <MenuItem onClick={() => RestoreAttribute(attributeID?.id)}>
                Restore
              </MenuItem>
              <MenuItem
                onClick={() => permanantlyDeleteAttributeAPI(attributeID?.id)}
              >
                Permanantly Delete
              </MenuItem>
            </>
          )}
        </Menu>
        <Loader isLoading={loading} />
        <Toaster />
      </div>
    </>
  );
};

export default Attributes;
