import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import StarBorder from "@mui/icons-material/StarBorder";
import {
  faHouse,
  faUser,
  faTrophy,
  faCheck,
  faFile,
  faList,
  faEnvelopeOpen,
  faStar,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@mui/material/List";
import { faMessage } from "@fortawesome/free-regular-svg-icons";

export const UserMenu = (props) => {
  let {
    selectedItem,
    open,
    openOperation,
    handleClickForOperationsMenu,
    dispatch,
    navigate,
    location,
    adminAccessible
  } = props;
  return (
    <List className="list-item-menu">
      <ListItem
        key={"user-home"}
        disablePadding
        sx={{ display: "block" }}
        className={
          selectedItem == "user-home" || location.pathname === "/dashboard"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")

            dispatch(drawerMenuAction.clickDrawerItem("user-home"));
            if (adminAccessible == true) {
              localStorage.setItem("last-redirect-page-admin-user", `/dashboard`)
            } else {
              localStorage.setItem("last-redirect-page-user", `/dashboard`)
            }
            localStorage.removeItem("selected-chat-room-id")
            navigate("/dashboard");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faHouse} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Home"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>

      <ListItem
        key={"operations"}
        disablePadding
        sx={{ display: "block" }}
        className={
          selectedItem == "operations"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")

            dispatch(drawerMenuAction.clickDrawerItem("operations"))

          }
          }
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faUser} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Operations"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"sales"}
        disablePadding
        sx={{ display: "block" }}
        className={
          selectedItem == "sales"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")

            dispatch(drawerMenuAction.clickDrawerItem("sales"))
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faTrophy} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Sales"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"operations2"}
        disablePadding
        sx={{ display: "block" }}
        className={
          selectedItem == "operations2"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")

            dispatch(drawerMenuAction.clickDrawerItem("operations2"));
            handleClickForOperationsMenu();
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faCheck} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Operations"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
          {open == true && (
            <>
              {openOperation ? (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  sx={{ opacity: open ? 1 : 0 }}
                  className="menu-icon"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronUp}
                  sx={{ opacity: open ? 1 : 0 }}
                  className="menu-icon"
                />
              )}
            </>
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={openOperation} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            key={"star"}
            disablePadding
            sx={{ display: "block" }}
            className={
              selectedItem == "star"
                ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
                } `
                : "non-selected-menu-item w-48px"
            }
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                localStorage.removeItem("selected-folder-item")
                localStorage.removeItem("globalSearchTab")
                localStorage.removeItem("globalSearchText")

                dispatch(drawerMenuAction.clickDrawerItem("star"))
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  ml: 4,
                  justifyContent: "center",
                }}
                className={`${open == false && "sub-menu-icon-false"}`}
              >
                <FontAwesomeIcon icon={faStar} className="menu-icon" />
              </ListItemIcon>
              <ListItemText
                primary="Starred"
                className="menu-name"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
      <ListItem
        key={"infrastructure"}
        disablePadding
        sx={{ display: "block" }}
        className={
          selectedItem == "infrastructure"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")

            dispatch(drawerMenuAction.clickDrawerItem("infrastructure"))
          }
          }
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faFile} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Infrastructure"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"Eforms"}
        disablePadding
        sx={{ display: "block" }}
        className={
          selectedItem == "Eforms"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")

            dispatch(drawerMenuAction.clickDrawerItem("Eforms"))
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faList} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Eforms,Register&Req..."
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"Open Support Tickets"}
        disablePadding
        sx={{ display: "block" }}
        className={
          selectedItem == "Open"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")

            dispatch(drawerMenuAction.clickDrawerItem("Open"))
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faEnvelopeOpen} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Open Support Tickets"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"Local Files"}
        disablePadding
        sx={{ display: "block" }}
        className={
          selectedItem == "Local"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")

            dispatch(drawerMenuAction.clickDrawerItem("Local"))
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faFile} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Local Files"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"Documents"}
        disablePadding
        sx={{ display: "block" }}
        className={
          selectedItem == "Documents"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")

            dispatch(drawerMenuAction.clickDrawerItem("Documents"))
          }
          }
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faFile} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Documents"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"user-chat"}
        disablePadding
        sx={{ display: "block" }}
        className={
          selectedItem == "user-chat" || location.pathname === "/user-conversation"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            dispatch(drawerMenuAction.clickDrawerItem("user-chat"))
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")
            if (adminAccessible == true) {
              localStorage.setItem("last-redirect-page-admin-user", `/user-conversation`)
            } else {
              localStorage.setItem("last-redirect-page-user", `/user-conversation`)
            }
            navigate("/user-conversation");
          }
          }
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faMessage} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Conversations"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
