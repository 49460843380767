import { createSlice } from "@reduxjs/toolkit";
const initialData = {
  selectedUserTab: 0,
};

const userTab = createSlice({
  name: "userTabDetails",
  initialState: initialData,
  reducers: {
    handleSelectedTab(state, action) {
      state.selectedUserTab = action?.payload;
    },
  },
});

export const userTabAction = userTab.actions;
export default userTab.reducer;
