export const columns_category_headers_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 130,
    className: "tbl-cell-head-4",
  },
  {
    key: 2,
    disablePadding: true,
    id: "used",
    label: "Used",
    minWidth: 120,
    className: "tbl-cell-head-4",
  },
  {
    key: 3,
    disablePadding: true,
    id: "date",
    label: "Date",
    minWidth: 150,
    className: "tbl-cell-head-4",
  },
  {
    key: 4,
    id: "creator",
    disablePadding: true,
    label: "Creator",
    minWidth: 150,
    className: "other-color tbl-cell-head-4",
  },
  {
    key: 5,
    id: "status",
    disablePadding: true,
    label: "Status",
    minWidth: 50,
    className: "other-color tbl-cell-head-4",
  },
];
