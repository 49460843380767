import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DragDrop from "./fileUploadDragnDrop";
import { Image, Stack } from "react-bootstrap";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const FileUploadModalAttribue = (props) => {
  let {
    open,
    handleClose,
    title,
    handleFileChange,
    file,
    setFileValue,
    fileTypes,
    isImage,
    isImageEditable,
  } = props;
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title === "image" ? "Upload Image" : "Upload File"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="dialog-content">
          <Stack gap={4}>
            <DragDrop
              handleFileChange={handleFileChange}
              fileTypes={fileTypes}
            />
            {isImage == true && file ? (
              <div className="upload-img-wrapper-2">
                <Image
                  src={
                    isImageEditable == true ? file : URL?.createObjectURL(file)
                  }
                />
              </div>
            ) : isImage == false && file ? (
              <div>
                {file
                  ? `File name: ${file?.name || file}`
                  : "No files uploaded yet"}
              </div>
            ) : (
              ""
            )}
          </Stack>
        </DialogContent>
        {file && (
          <DialogActions>
            <Button autoFocus onClick={setFileValue}>
              {title === "image" ? "Upload Image" : "Upload File"}
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default FileUploadModalAttribue;
