import { Suspense } from "react";
// import NotFound from "../pages/NotFound";
import authRoutes from "../routes/authRoutes";
import dashboardRoutes from "../routes/dashboardRoutes";
import newsRoutes from "./newsRoute";
import typeRoutes from "./typeRoute";
import attributeRoutes from "./attribute";
import categoryRoutes from "./category";
import groupRoutes from "./groupRoute";
import userRoutes from "./users";
import loggedUserRoute from "./userDash";
import conversationRoutes from "./conversation";

const routes = [
  ...authRoutes,
  ...dashboardRoutes,
  ...newsRoutes,
  ...typeRoutes,
  ...attributeRoutes,
  ...categoryRoutes,
  ...groupRoutes,
  ...userRoutes,
  ...loggedUserRoute,
  ...conversationRoutes,
  {
    path: "*",
    element: <Suspense>{/* <NotFound /> */}</Suspense>,
  },
];

export default routes;
