import { lazy, Suspense } from "react";
import AuthGuard from "../utils/authGuard";
import AuthGuardForSuperAdmin from "../utils/authGuardForSuperAdmin";
import Types from "../pages/types";
import AddNewTypes from "../pages/types/addNewType";
import Home from "../pages/home";
import AddNews from "../pages/home/addNews";
import ViewNews from "../pages/news/viewNews";

const typeRoutes = [
  {
    path: "/types",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <Types />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/types/add-new",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddNewTypes />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/types/edit/:id",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddNewTypes />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/type/add/:type",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddNews />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/type/:type",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <Home />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/type/edit/:type/:id",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddNews />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/type/view/:type/:id",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <ViewNews />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
];

export default typeRoutes;
