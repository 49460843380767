import * as Yup from "yup";

export const addNewAttributesvalidationSchema = Yup.object().shape({
  attributeTitle: Yup.string("Please enter attribute title.").required(
    "Attribute title is required"
  ),
  tabShowUnder: Yup.string("Please select tab to show under option.").required(
    "Tab to show under is required"
  ),
  attributeRepeatable: Yup.string("Please select tab to show under option."),
  fieldType: Yup.string("Please select field type.").required(
    "Field type is required"
  ),
  rowsData: Yup.array().when("fieldType", (fieldType, schema) => {
    let minOptions // Default minimum options
    if (fieldType) {
      // Check fieldType to determine the minimum options
      if (fieldType.includes("radio_single_choice") ||
        fieldType.includes("checkbox_single_choice")) {
        minOptions = 1; // Minimum options for these types
      } else if (fieldType.includes("checkbox_multiple_choice") ||
        fieldType.includes("dropdodown_multiple_choice") ||
        fieldType.includes("dropdodown_single_choice")) {
        minOptions = 2; // Minimum options for these types
      } else {
        return schema
      }
    }

    // Apply validation schema with the dynamic minimum options
    return schema.of(
      Yup.object().shape({
        key: Yup.string().required("Key is required"),
        value: Yup.string().required("Value is required"),
        default: Yup.boolean(),
      })
    ).min(minOptions, `Please enter at least ${minOptions} option(s)`);
  }),
  attributeStatus: Yup.boolean().required("Please select attribute status."),
  fieldMandatory: Yup.boolean().required("Select mandatory is required"),
});
