import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";

import { useNavigate } from "react-router";
import { UserMenu } from "../common/userMenu";
import { AdminMenu } from "../common/adminMenu";
import { useLocation } from "react-router-dom";
import api from "../common/commonFunctionForApi";
import { useSelector } from "react-redux";

const drawerWidth = 320;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerMenu = (props) => {
  let {
    open,
    dispatch,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    usersCollapsable,
    activeType,
    drawerVariant,
    fetchNewType
  } = props;
  const location = useLocation();

  const selectedTypeSlug = localStorage.getItem("type-selected-slug");

  const [openOperations, setOpenOperations] = useState(false);
  const [openNamingClassification, setOpenNamingClassification] = useState(
    selectedItem === "naming-types" ||
      selectedItem === "naming-categories" ||
      selectedItem === "naming-attributes" ||
      selectedItem === "naming-rules" ||
      selectedItem === "naming" ||
      location.pathname === "/types" ||
      location.pathname === "/types/add-new" ||
      location.pathname.startsWith("/types/edit") ||
      location.pathname === "/attribute" ||
      location.pathname === "/attribute/add-new" ||
      location.pathname.startsWith("/attribute/edit") ||
      location.pathname === "/types" ||
      location.pathname === "/attribute" ||
      location.pathname === "/add/categories" ||
      location.pathname === "/categories" ||
      location.pathname.startsWith("/categories/edit")
      ? true
      : false
  );
  const [openTypes, setOpenTypes] = useState(
    location.pathname.startsWith("/type/") ||
      location.pathname.startsWith("/type/add") ||
      location.pathname.startsWith("/type/edit") ||
      location.pathname.startsWith("/type/view") ||
      location.pathname.startsWith(`/type/edit/${selectedTypeSlug}`)
      ? true
      : false
  );
  const [openUsers, setOpenUsers] = useState(
    location.pathname === "/settings" ||
      location.pathname === "/profile-fields" ||
      location.pathname === "/users" ||
      location.pathname === "/groups" ||
      location.pathname === "/add/groups" ||
      location.pathname.startsWith("/group/edit/") ||
      location.pathname === "/add/users" ||
      location.pathname.startsWith("/user/edit/")
      ? true
      : false
  );

  const { menuFor,
    adminAccessible } = useSelector((state) => state?.drawerMenu);
  const [getAllTypes, setGetAllTypes] = useState([]);
  const [drawerVarient, setDrawerVarient] = useState("permanent");

  const [showMenuFor, setshowMenuFor] = useState("")


  const navigate = useNavigate();

  const userToken = localStorage.getItem("accessToken");

  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  const handleClickForOperationsMenu = () => {
    setOpenOperations(!openOperations);
  };

  const handleClickForNamingClassificationMenu = () => {
    let updateOpen = nameCollapsable == false ? true : false;
    setOpenNamingClassification(updateOpen);
    dispatch(drawerMenuAction.handleTypesCollapsable(false));
    setOpenOperations(false);
    dispatch(drawerMenuAction.handleUsersCollapsable(false));
    setOpenUsers(false);
    dispatch(drawerMenuAction.handleNameCollapsable(updateOpen));
  };

  const handleClickForUsersMenu = () => {
    let updateOpen = usersCollapsable == false ? true : false;
    setOpenUsers(updateOpen);

    dispatch(drawerMenuAction.handleUsersCollapsable(updateOpen));
  };

  const handleClickForTypesMenu = () => {
    let updateOpen = typeCollapsable == false ? true : false;
    setOpenTypes(updateOpen);
    dispatch(drawerMenuAction.handleNameCollapsable(false));
    setOpenNamingClassification(false);
    dispatch(drawerMenuAction.handleUsersCollapsable(false));
    setOpenUsers(false);
    dispatch(drawerMenuAction.handleTypesCollapsable(updateOpen));
  };

  const updateIcon = () => {
    if (window.innerWidth <= 768) {
      dispatch(drawerMenuAction.handleDrawerForSmallScreen(false));
      dispatch(drawerMenuAction.handleDrawerVariant("persistent"));
      setDrawerVarient("persistent");
    } else if (window.innerWidth <= 1024) {
      dispatch(drawerMenuAction.handleDrawerForSmallScreen(false));
    } else {
      dispatch(drawerMenuAction.handleDrawerVariant("permanent"));
      setDrawerVarient("permanent");
    }
  };

  const fetchAllTypes = async (type, order) => {
    try {
      const response = await api.get(`admin/type/master/list/menu`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setGetAllTypes(response?.data);
      dispatch(drawerMenuAction?.handleFetchNewType(false))
    } catch (err) {
      console.log("err`or in fetch news api::", err);
    }
  };

  useEffect(() => {
    updateIcon();
    window.addEventListener("resize", updateIcon);
    return () => {
      window.removeEventListener("resize", updateIcon);
    };
  }, []);

  useEffect(() => {
    if (loggedUser?.user_type === "admin" && adminAccessible == false) {
      fetchAllTypes();
      setshowMenuFor("admin")
    } else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
      setshowMenuFor("user")
    } else if (loggedUser?.user_type === "user") {
      setshowMenuFor("user")
    }
  }, [adminAccessible])

  useEffect(() => {
    if (fetchNewType == true) {
      fetchAllTypes();
    }
  }, [fetchNewType])

  return (
    <>
      <Drawer
        variant={drawerVariant}
        open={open}
        className={`drawer ${open == false && "short-drawer-width"} `}
      >
        {showMenuFor === "admin" ? (
          <AdminMenu
            selectedItem={selectedItem}
            open={open}
            openOperation={openOperations}
            openNamingClassification={openNamingClassification}
            handleClickForOperationsMenu={handleClickForOperationsMenu}
            handleClickForNamingClassificationMenu={
              handleClickForNamingClassificationMenu
            }
            dispatch={dispatch}
            navigate={navigate}
            location={location}
            getAllTypes={getAllTypes}
            setOpenNamingClassification={setOpenNamingClassification}
            nameCollapsable={nameCollapsable}
            typeCollapsable={typeCollapsable}
            handleClickForTypesMenu={handleClickForTypesMenu}
            openTypes={openTypes}
            setOpenTypes={setOpenTypes}
            activeType={activeType}
            handleClickForUsersMenu={handleClickForUsersMenu}
            openUsers={openUsers}
            setOpenUsers={setOpenUsers}
          />
        ) : showMenuFor === "user" ? (
          <UserMenu
            selectedItem={selectedItem}
            open={open}
            openOperation={openOperations}
            handleClickForOperationsMenu={handleClickForOperationsMenu}
            dispatch={dispatch}
            navigate={navigate}
            location={location}
            adminAccessible={adminAccessible}
          />
        ) : (
          ""
        )}

        <Divider />
      </Drawer>
    </>
  );
};

export default DrawerMenu;
