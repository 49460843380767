import * as Yup from "yup";
export const addNewGroupvalidationSchema = Yup.object().shape({
  groupTitle: Yup.string("Please enter group title.").required(
    "Group title is required"
  ),
  groupType: Yup.string("Please select group type.").required(
    "Group type is required"
  ),
  isGroupManger: Yup.boolean(),
  groupManagers: Yup.array().when("isGroupManger", (isGroupManger, schema) => {
    const isUsers = isGroupManger.flat().includes(true);
    if (isUsers) {
      return Yup.array()
        .min(1, "At least one group manager is required")
        .label("At least one group manager is required")
        .required("At least one group manager is required");
    }
    return schema;
  }),
  allowView: Yup.boolean(),
  allowEdit: Yup.boolean(),
  iconName: Yup.string("Please Select icon.").required(
    "Group icon is required."
  ),
  iconColor: Yup.string("Please Select icon color.").required(
    "Group icon color is required."
  ),
  iconBGColor: Yup.string("Please Select icon background color.").required(
    "Group icon background color is required."
  ),
});
