import { lazy, Suspense } from "react";
import AddNews from "../pages/home/addNews";
import AuthGuard from "../utils/authGuard";

const ViewNews = lazy(() => import("../pages/news/viewNews"));

const newsRoutes = [
  {
    path: "/view-news/:id",
    element: (
      <Suspense>
        <AuthGuard>
          <ViewNews />
        </AuthGuard>
      </Suspense>
    ),
  },
];

export default newsRoutes;
