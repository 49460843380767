export const columns_user_header = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 100,
    className: "tbl-head-class-2",
    showInfoIcon: true,
  },
  {
    key: 2,
    disablePadding: true,
    id: "groups",
    label: "Groups",
    minWidth: 250,
  },
  {
    key: 3,
    disablePadding: true,
    id: "date-created",
    label: "Date Created",
    minWidth: 100,
  },

  {
    key: 7,
    id: "status",
    disablePadding: true,
    label: "Status",
    minWidth: 90,
    className: "other-color",
  },
];
