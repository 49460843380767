export const columns_group_data = [
    {
        key: 1,
        disablePadding: true,
        id: "name",
        label: "Name",
        minWidth: 350,
        className: "other-color tbl-cell-people-group-1 mt-2",
    },
    {
        key: 2,
        disablePadding: true,
        id: "members",
        label: "Members",
        minWidth: 150,
        className: "other-color tbl-cell-people-group-1 mt-2",
    },
]