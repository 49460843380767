export const columns_atttributes_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "tbl-head-class-2",
    showInfoIcon: true,
  },
  {
    key: 2,
    disablePadding: true,
    id: "tab",
    label: "Tab",
    minWidth: 190,
  },
  {
    key: 3,
    id: "field_type",
    disablePadding: true,
    label: "Field Type",
    minWidth: 70,
    className: "other-color",
  },
  {
    key: 4,
    id: "repeat",
    disablePadding: true,
    label: "Repeats?",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 5,
    id: "creator",
    disablePadding: true,
    label: "Creator",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 6,
    id: "status",
    disablePadding: true,
    label: "Status",
    minWidth: 50,
    className: "other-color",
  },
];
