import React, { useEffect, useState } from "react";
import Header from "../../components/layout/header";
import DrawerMenu from "../../components/layout/drawer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Avatar,
  Paper,
  Button,
  Stack,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useParams } from "react-router-dom";
import { Row, Col, Card, Image } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullhorn,
  faCircleInfo,
  faSearch,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import { AntSwitch } from "../../components/common/switch/antSwitch";
import api from "../../components/common/commonFunctionForApi";
import { Toaster } from "react-hot-toast";
import { Toast } from "../../utils/toast";
import Loader from "../../components/common/loader";
import { MuiColorInput } from "mui-color-input";
import logoutHelper from "../../helpers/logoutHelper";
import { addNewGroupvalidationSchema } from "../../helpers/validations/addGroups";
import { Form, Formik } from "formik";
import { groupTabAction } from "../../redux/slices/groups/groups";
import InputGroup from "react-bootstrap/InputGroup";
import { CSVLink } from "react-csv";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import TablePagination from "@mui/material/TablePagination";

import { columns_attribute_changelog_header } from "../../helpers/theaderColumns/attributes/attributeChangelog";
import { dateWithTime } from "../../helpers/DateTimeFormat/dateTimeFormat";
import { exportXlsx } from "../../helpers/Exports/FileExport";

const processedHTML = (htmlContent) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent || ""; // Ensure there's a default value if htmlContent is undefined
  return tempDiv;
};

const AddGroups = () => {
  let { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [getAllIcons, setGetAllIcons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [getAllGroupTypes, setGetAllGroupTypes] = useState([]);
  const [getUserGroupDetail, setGetUserGroupDetail] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getAllChangeLogs, setGetAllChangeLogs] = useState();
  const [search, setSearch] = useState("");
  const [istype, setIstype] = useState(false);
  const [getChangelogCsvData, setGetChangelogCsvData] = useState([]);

  const debounceTimeout = 500; // Adjust the debounce timeout as needed
  let debounceTimer;

  const header_csv = [
    { label: "No", key: "no" },
    { label: "User", key: "user" },
    { label: "Date", key: "date" },
    { label: "Area", key: "area" },
    { label: "Details", key: "details" },
  ];

  const total_changelog = parseInt(getAllChangeLogs?.meta?.total);

  const initialValues = {
    groupTitle: id ? getUserGroupDetail?.groupTitle : "",
    groupType: id ? getUserGroupDetail?.groupType : "",
    isGroupManger: id ? getUserGroupDetail?.isGroupManger : false,
    groupManagers: id ? getUserGroupDetail?.groupManagers : [],
    allowView: id ? getUserGroupDetail?.allowView : false,
    allowEdit: id ? getUserGroupDetail?.allowEdit : false,
    users: id ? getUserGroupDetail?.users : [],
    groupStatus: id ? getUserGroupDetail?.groupStatus : true,
    iconName: id ? getUserGroupDetail?.iconName : "",
    iconColor: id ? getUserGroupDetail?.iconColor : "#FFFFFF",
    iconBGColor: id ? getUserGroupDetail?.iconBGColor : "#660C60",
  };

  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    } else if (newPage < page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    drawerVariant,
    usersCollapsable,
  } = useSelector((state) => state?.drawerMenu);

  const userToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  const fetchAllIcons = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/icon-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setGetAllIcons(response?.response);

        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/add/groups`);

        navigate("/add/groups");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/groups`);

        navigate("/groups");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/add/groups`);

        navigate("/add/groups");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const fetchAllUser = async () => {
    try {
      const response = await api.get(`users-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let users = response?.response?.map((user) => ({
          value: user?.id,
          label: user?.name,
        }));

        setGetAllUsers(users);
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchAllGroupTypeList = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/group-type-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setGetAllGroupTypes(response?.response);

        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/add/groups`);

        navigate("/add/groups");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/groups`);

        navigate("/groups");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/add/groups`);

        navigate("/add/groups");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const onSubmit = async (values) => {
    if (id) {
      const viewUsers = values?.users?.map((user) => user);
      const usersString = viewUsers.join(",");
      let data = {
        title: values?.groupTitle,
        group_type_id: values?.groupType,
        can_view: values?.allowView == true ? "enable" : "disable",
        can_edit: values?.allowEdit == true ? "enable" : "disable",
        icon_id: values?.iconName,
        colour: values?.iconColor,
        background: values?.iconBGColor,
        status: values?.groupStatus == true ? "enable" : "disable",
        manager_exist: values?.isGroupManger == true ? "enable" : "disable",
        group_users: usersString,
        id: id,
      };
      if (values?.isGroupManger == true && values?.groupManagers?.length > 0) {
        const viewGroupManagers = values?.groupManagers?.map((user) => user);
        const usersStringManager = viewGroupManagers.join(",");

        data.group_managers = usersStringManager;
      } else {
        data.group_managers = "";
      }

      try {
        setLoading(true);
        const response = await api.post(
          `/admin/group/update/${parseInt(id)}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response?.success == true) {
          setLoading(false);
          setIsSuccessMessage(true);
          setSuccessMessage(response?.message);
          setIsSuccessMessage(true);
          setSuccessMessage(response?.message);
          Toast.success(response?.message);
          localStorage.setItem("last-redirect-page", `/groups`);
          dispatch(groupTabAction.handleSelectedTab(0));

          navigate("/groups");
        } else if (response?.success == false) {
          setLoading(false);
          dispatch(groupTabAction.handleSelectedTab(0));
          setIsWarningMessage(true);
          setWarningMessage(response?.message);
          Toast.error(response?.message);
        }
      } catch (err) {
        console.log("error in ctreate news api::", err);
        setLoading(false);

        if (err?.response?.status == 401) {
          setLoading(false);
          logoutHelper(dispatch, navigate, userToken);
        }
        if (err?.response?.status == 422) {
          setLoading(false);
          setIsWarningMessage(true);
          setWarningMessage(err?.response?.data?.errors?.title);
          const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
        }
        if (err?.response?.status == 429) {
          Toast.error("Too many attemps wait for 2 min.")
        }
      }
    } else {
      const viewUsers = values?.users?.map((user) => user);
      const usersString = viewUsers.join(",");
      let data = {
        title: values?.groupTitle,
        group_type_id: values?.groupType,
        can_view: values?.allowView == true ? "enable" : "disable",
        can_edit: values?.allowEdit == true ? "enable" : "disable",
        icon_id: values?.iconName,
        colour: values?.iconColor,
        background: values?.iconBGColor,
        status: values?.groupStatus == true ? "enable" : "disable",
        manager_exist: values?.isGroupManger == true ? "enable" : "disable",
        group_users: usersString,
      };

      if (values?.isGroupManger == true && values?.groupManagers?.length > 0) {
        const viewGroupManagers = values?.groupManagers?.map((user) => user);
        const usersStringManager = viewGroupManagers.join(",");

        data.group_managers = usersStringManager;
      } else {
        data.group_managers = "";
      }
      try {
        setLoading(true);
        const response = await api.post("/admin/group/store", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        });
        if (response?.success == true) {
          setLoading(false);
          setIsSuccessMessage(true);
          setSuccessMessage(response?.message);
          setIsSuccessMessage(true);
          setSuccessMessage(response?.message);
          Toast.success(response?.message);
          localStorage.setItem("last-redirect-page", `/groups`);
          dispatch(groupTabAction.handleSelectedTab(0));

          navigate("/groups");
        } else if (response?.success == false) {
          setLoading(false);
          dispatch(groupTabAction.handleSelectedTab(0));
          setIsWarningMessage(true);
          setWarningMessage(response?.message);
          Toast.error(response?.message);
        }
      } catch (err) {
        console.log("error in ctreate news api::", err);
        setLoading(false);

        if (err?.response?.status == 401) {
          setLoading(false);
          logoutHelper(dispatch, navigate, userToken);
        }
        if (err?.response?.status == 422) {
          setLoading(false);
          setIsWarningMessage(true);
          setWarningMessage(err?.response?.data?.errors?.title);
          const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
        }
        if (err?.response?.status == 429) {
          Toast.error("Too many attemps wait for 2 min.")
        }
      }
    }
  };

  const fetchGroupDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/group/show/${id}}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let categoryData = {
          groupTitle: response?.response?.title,
          groupType: response?.response?.group_type_id,
          isGroupManger:
            response?.response?.manager_exist === "enable" ? true : false,
          groupStatus: response?.response?.status === "enable" ? true : false,
          iconName: response?.response?.icons?.id,
          iconColor: response?.response?.colour,
          iconBGColor: response?.response?.background,
          users: response?.response?.group_member?.map((data) => data?.user_id),
        };
        if (categoryData?.isGroupManger == true) {
          categoryData.groupManagers = response?.response?.group_managers?.map(
            (data) => data?.user_id
          );
          categoryData.allowView =
            response?.response?.can_view === "enable" ? true : false;
          categoryData.allowEdit =
            response?.response?.can_edit === "enable" ? true : false;
        } else {
          categoryData.groupManagers = [];
          categoryData.allowView = false;
          categoryData.allowEdit = false;
        }

        setGetUserGroupDetail(categoryData);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/groups`);

        navigate("/groups");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);

        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);

        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        setWarningMessage(err?.response?.data?.message);
        Toast.warning(err?.response?.data?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/categories");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const fetchAllChangeLogs = async (page) => {
    try {
      setLoading(true);
      const response = await api.get(
        `admin/group/changelog/${id}?per_page=${rowsPerPage}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        response.response.data = response?.response?.data.map((log) => ({
          ...log,
          changed_at: dateWithTime(log.changed_at, true, true, true) || log.changed_at,
        }));

        setGetAllChangeLogs(response?.response);

        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const handleChangeLogCsvDownload = async () => {
    try {
      setLoading(true);

      const result = await exportXlsx(`admin/group/changelog/${id}?search=${search}&pagination=no`, 'group_change_log');
      if (!result) {
        Toast.error("Failed to download file");
      }

    } catch (error) {
      console.error("Error while downloading csv changelog::", error);
      Toast.error("Failed to download file");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (parseInt(id)) {
      fetchAllGroupTypeList();
      fetchAllIcons();
      fetchAllUser();
      fetchGroupDetails();

      fetchAllChangeLogs(page + 1);
    } else {
      fetchAllGroupTypeList();
      fetchAllIcons();
      fetchAllUser();
    }
  }, [parseInt(id)]);

  useEffect(() => {
    if (search.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        setPage(0);
        fetchAllChangeLogs(page);
      }, debounceTimeout);
    }
    else if (search === "") {
      setPage(0);
      fetchAllChangeLogs(page);
    }
    else {
      clearTimeout(debounceTimer);
    }

    return () => clearTimeout(debounceTimer);
  }, [search]);

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage == true && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage == true && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <Box className="">
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  underline="hover"
                  key="1"
                  color="inherit"
                  href="/"
                  component="h3"
                >
                  Admin
                </Link>
                <Typography
                  key="3"
                  component="h3"
                  color="inherit"
                  className="cursor-pointer"
                  onClick={() => {
                    localStorage.setItem("last-redirect-page", `/groups`);
                    navigate("/groups");
                  }}
                >
                  Groups
                </Typography>
                <Typography key="3" component="h3" color="text.primary">
                  {id ? "Edit" : "Add"} Group
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Box>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={initialValues}
            validationSchema={addNewGroupvalidationSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({
              values,
              setFieldValue,
              touched,
              errors,
              handleSubmit,
              handleBlur,
              isValid,
              dirty,
              handleChange,
            }) => {
              const handleChangeForIconColor = (newValue) => {
                setFieldValue("iconColor", newValue);
              };

              const handleChangeForIconBGColor = (newValue) => {
                setFieldValue("iconBGColor", newValue);
              };
              return (
                <Form>
                  <Row className="mt-1">
                    <Col lg="9" md="7">
                      <Stack direction="row" className="gap-30">
                        <Accordion
                          defaultActiveKey={["0", "1", "2"]}
                          alwaysOpen
                          className="w-100"
                        >
                          <Accordion.Item
                            eventKey="0"
                            className="bg-white card-wrapper w-100"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text">
                              Content
                            </Accordion.Header>
                            <Accordion.Body>
                              <Row>
                                <Col lg="12">
                                  <Typography className="fw-400 dark-text">
                                    Title
                                    <span className="required">*</span>
                                  </Typography>
                                  <TextField
                                    className="input-field-group mt-2"
                                    fullWidth
                                    id="outlined-basic username"
                                    variant="outlined"
                                    size="small"
                                    name="groupTitle"
                                    placeholder="Enter title here..."
                                    value={values?.groupTitle}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "groupTitle",
                                        event.target.value
                                      );
                                    }}
                                    helperText={
                                      touched.groupTitle && errors.groupTitle
                                    }
                                    error={
                                      touched.groupTitle &&
                                      Boolean(errors.groupTitle)
                                    }
                                  />
                                </Col>
                                <Col lg="12">
                                  <Typography className="fw-400 dark-text font-inter">
                                    Group Type
                                    <span className="required">*</span>
                                  </Typography>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="groupType"
                                    className="mt-1 d-flex flex-wrap align-items-center"
                                    value={values?.groupType}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    {getAllGroupTypes?.map((attribute) => (
                                      <>
                                        <FormControlLabel
                                          key={attribute.id}
                                          value={attribute.id}
                                          control={
                                            <Radio
                                              size="small"
                                              checked={
                                                values?.groupType ==
                                                attribute?.id
                                              }
                                              value={attribute?.id}
                                            />
                                          }
                                          label={attribute?.name}
                                          className="font-inter"
                                        />
                                        {attribute?.label !== null && (
                                          <BootstrapTooltip
                                            title={attribute?.label}
                                            placement="auto"
                                          >
                                            <FontAwesomeIcon
                                              icon={faCircleInfo}
                                              className="mandatory-icon fw-900"
                                              aria-haspopup="true"
                                            />
                                          </BootstrapTooltip>
                                        )}
                                      </>
                                    ))}
                                  </RadioGroup>
                                  {errors?.groupType && touched.groupType && (
                                    <p
                                      style={{ color: "#d32f2f" }}
                                      className="error-msg"
                                    >
                                      {errors?.groupType}
                                    </p>
                                  )}
                                </Col>
                                <Col lg="12" className="repeatable-col">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values?.isGroupManger == true}
                                        name="isGroupManger"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    }
                                    label="Does this Group/team have a leader/manager?"
                                    className="font-inter"
                                  />
                                </Col>
                                {values?.isGroupManger == true && (
                                  <>
                                    <Col lg="12" className="repeatable-col">
                                      <Typography className="fw-400 dark-text">
                                        Group Managers
                                      </Typography>
                                      <Select
                                        closeMenuOnSelect={false}
                                        options={getAllUsers}
                                        menuPlacement="top"
                                        name="groupManagers"
                                        value={getAllUsers?.filter((option) =>
                                          values?.groupManagers?.includes(
                                            option?.value
                                          )
                                        )}
                                        placeholder="Select group managers..."
                                        menuPosition="fixed"
                                        isMulti
                                        onChange={(selectedOption) => {
                                          const users = selectedOption.map(
                                            (option) => option.value
                                          );
                                          setFieldValue("groupManagers", users);
                                        }}
                                        className={`muilt-select-field mt-2 `}
                                        classNamePrefix="select"
                                        styles={{
                                          option: (provided, state) => {
                                            return {
                                              ...provided,
                                              backgroundColor: state.isSelected
                                                ? "#660c605c"
                                                : provided.backgroundColor,
                                              color: state.isSelected
                                                ? "var(--dark-color)"
                                                : provided.color,
                                            };
                                          },
                                        }}
                                      />
                                      {errors?.groupManagers &&
                                        touched.groupManagers && (
                                          <p
                                            style={{ color: "#d32f2f" }}
                                            className="error-msg"
                                          >
                                            {errors?.groupManagers}
                                          </p>
                                        )}
                                    </Col>
                                    <Col lg="12" className="repeatable-col">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values?.allowView == true}
                                            name="allowView"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        }
                                        label="Allow managers to view all Group/Team members data"
                                        className="font-inter"
                                      />
                                    </Col>
                                    <Col lg="12" className="repeatable-col">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values?.allowEdit == true}
                                            name="allowEdit"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        }
                                        label="Allow managers to edit all Group/Team members data"
                                        className="font-inter"
                                      />
                                    </Col>
                                  </>
                                )}
                                <Col lg="12" className="repeatable-col">
                                  <Typography className="fw-400 dark-text">
                                    Users
                                  </Typography>
                                  <Select
                                    closeMenuOnSelect={false}
                                    options={getAllUsers}
                                    menuPlacement="bottom"
                                    name="users"
                                    value={getAllUsers?.filter((option) =>
                                      values?.users?.includes(option?.value)
                                    )}
                                    placeholder="Select users..."
                                    isMulti
                                    menuPosition="fixed"
                                    onChange={(selectedOption) => {
                                      const users = selectedOption.map(
                                        (option) => option.value
                                      );
                                      setFieldValue("users", users);
                                    }}
                                    className={`muilt-select-field mt-2 `}
                                    classNamePrefix="select"
                                    styles={{
                                      option: (provided, state) => {
                                        return {
                                          ...provided,
                                          backgroundColor: state.isSelected
                                            ? "#660c605c"
                                            : provided.backgroundColor,
                                          color: state.isSelected
                                            ? "var(--dark-color)"
                                            : provided.color,
                                        };
                                      },
                                    }}
                                  />
                                  {errors?.users && touched.users && (
                                    <p
                                      style={{ color: "#d32f2f" }}
                                      className="error-msg"
                                    >
                                      {errors?.users}
                                    </p>
                                  )}
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                          {id && (
                            <Accordion.Item
                              eventKey="2"
                              className="bg-white card-wrapper mt-4"
                            >
                              <Accordion.Header className="bg-white fw-700 primary-text">
                                Changelog
                              </Accordion.Header>
                              <Accordion.Body className="p-0 changelog-body">
                                <Grid
                                  container
                                  spacing={1}
                                  className="changelog-div  "
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={9}
                                    xl={9}
                                  >
                                    <Box className="">
                                      <InputGroup className="">
                                        <TextField
                                          className="search-changelog-input"
                                          fullWidth
                                          id="outlined-basic username"
                                          variant="outlined"
                                          size="small"
                                          name="attributeTitle"
                                          value={search}
                                          placeholder="search changelog"
                                          onChange={(e) => {
                                            setIstype(true);
                                            setSearch(e.target.value);
                                            if (e.target.value === "") {
                                              fetchAllChangeLogs(page);
                                            }
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                              fetchAllChangeLogs(page);
                                            }
                                          }}
                                        />
                                        <Button
                                          className="search-changelog"
                                          onClick={() => {
                                            fetchAllChangeLogs(page);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faSearch}
                                            className="search-changelog-icon"
                                          />
                                        </Button>
                                      </InputGroup>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={3}
                                    xl={3}
                                  >
                                    <div className="w-100">

                                      <Button
                                        variant="outlined"
                                        startIcon={
                                          <FontAwesomeIcon icon={faFileCsv} />
                                        }
                                        className="export-csv-btn"
                                        onClick={handleChangeLogCsvDownload}
                                      >
                                        Export as CSV
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Paper
                                  sx={{ width: "100%" }}
                                  className="changelog-table-div mt-4"
                                >
                                  <TableContainer className="">
                                    <Table
                                      sx={{ minWidth: 750 }}
                                      aria-labelledby="tableTitle"
                                      stickyHeader
                                    >
                                      <TableHeaderForSorting
                                        columns={
                                          columns_attribute_changelog_header
                                        }
                                        className="z-0"
                                      />
                                      <TableBody>
                                        {getAllChangeLogs?.data?.map(
                                          (data, index) => (
                                            <>
                                              <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index + 1}
                                                className=" tbl-changelog mt-2"
                                              >
                                                <TableCell
                                                  align="left"
                                                  className="tbl-border-left tbl-cell-5"
                                                >
                                                  <Box>
                                                    <Stack
                                                      direction="row"
                                                      alignContent="center"
                                                      alignItems="center"
                                                      spacing={1}
                                                      className="z-n1"
                                                    >
                                                      <Avatar
                                                        alt={data?.changed_by}
                                                        src="/static/images/avatar/1.jpg"
                                                        className="author-avtar"
                                                      />
                                                      <Typography className="fw-400 fs-14 ">
                                                        {data?.changed_by
                                                          ? data?.changed_by
                                                          : "-"}
                                                      </Typography>
                                                    </Stack>
                                                  </Box>
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  className="text-nowrap"
                                                >
                                                  {data?.changed_at
                                                    ? data?.changed_at
                                                    : "-"}
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  className="text-nowrap"
                                                >
                                                  {data?.area
                                                    ? data?.area
                                                    : "-"}
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  className="tbl-border-right"
                                                >
                                                  <Typography
                                                    className="fw-400 fs-14"
                                                    dangerouslySetInnerHTML={{
                                                      __html: data?.description
                                                        ? processedHTML(
                                                          data?.description
                                                        ).innerHTML
                                                        : "-",
                                                    }}
                                                  ></Typography>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow className="extra-row"></TableRow>
                                            </>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Paper>
                                {total_changelog > rowsPerPage && (
                                  <TablePagination
                                    component="div"
                                    count={total_changelog}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPage
                                    }
                                    labelDisplayedRows={({
                                      from,
                                      to,
                                      count,
                                    }) => {
                                      const currentPage = page + 1;
                                      const start =
                                        currentPage * rowsPerPage -
                                        rowsPerPage +
                                        1;
                                      const end = Math.min(
                                        currentPage * rowsPerPage,
                                        count
                                      );
                                      return (
                                        <>
                                          {`Showing ${start} to ${end} of `}
                                          <span>{count}</span>
                                          {" records"}
                                        </>
                                      );
                                    }}
                                  />
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </Accordion>
                      </Stack>
                    </Col>
                    <Col lg="3" md="5">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        gap={2}
                        flexWrap="wrap"
                        className="publish-div"
                      >
                        <Card className="bg-white card-wrapper w-100">
                          <Card.Header className="bg-white fw-700 primary-text">
                            Publish
                          </Card.Header>
                          <Card.Body className="p-15 card-publish">
                            <Button
                              className="btn primary-btn w-100"
                              type="submit"
                            >
                              <FontAwesomeIcon icon={faBullhorn} />
                              Publish
                            </Button>
                            <div>
                              <div className="mt-3 switch-items-1">
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  gap={2}
                                  flexWrap="wrap"
                                >
                                  <div className="d-flex flex-wrap align-items-center gap-3">
                                    <Typography
                                      className="fw-400 dark-text font-inter "
                                      whiteSpace="nowrap"
                                    >
                                      Enable Group
                                    </Typography>
                                    <BootstrapTooltip
                                      title="You can enable/disable group."
                                      placement="bottom"
                                    >
                                      <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        className="mandatory-icon fw-900"
                                        aria-haspopup="true"
                                      />
                                    </BootstrapTooltip>
                                  </div>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    className="h-100"
                                  >
                                    <AntSwitch
                                      name="groupStatus"
                                      checked={values?.groupStatus}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "groupStatus",
                                          event.target.checked
                                        );
                                      }}
                                      inputProps={{
                                        "aria-label": "ant design",
                                      }}
                                    />
                                  </Stack>
                                </Stack>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                        <Card className="bg-white card-wrapper">
                          <Card.Header className="bg-white fw-700 primary-text">
                            Customization
                          </Card.Header>
                          <Card.Body className="p-15">
                            {" "}
                            <Typography component="p" className="fs-14">
                              Icon
                            </Typography>
                            <Stack
                              direction="row"
                              className="d-flex flex-wrap gap-1 mt-2"
                            >
                              {getAllIcons?.map((icon, index) => (
                                <div
                                  className={`icon-div d-flex justify-content-center align-items-center icons-div ${values?.iconName === icon?.id &&
                                    "selected-icon"
                                    }`}
                                  key={index}
                                  onClick={() => {
                                    setFieldValue("iconName", icon?.id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={icon?.icon_value}
                                    className="icon"
                                  />
                                </div>
                              ))}
                              {errors?.iconName && touched.iconName && (
                                <p
                                  style={{ color: "#d32f2f" }}
                                  className="error-msg"
                                >
                                  {errors?.iconName}
                                </p>
                              )}
                            </Stack>
                            <div className="mt-3">
                              <Stack className="customization-icon-color">
                                <Typography
                                  className="fw-400 dark-text font-inter "
                                  whiteSpace="nowrap"
                                >
                                  Color
                                </Typography>

                                <MuiColorInput
                                  format="hex"
                                  value={values?.iconColor}
                                  onChange={handleChangeForIconColor}
                                  className="icon-color-picker"
                                  fullWidth
                                  id="outlined-basic username"
                                  variant="outlined"
                                  size="small"
                                  name="iconColor"
                                />
                              </Stack>
                              {errors?.iconColor && touched.iconColor && (
                                <p
                                  style={{ color: "#d32f2f" }}
                                  className="error-msg"
                                >
                                  {errors?.iconColor}
                                </p>
                              )}
                            </div>
                            <div className="mt-3">
                              <Stack className="customization-icon-color">
                                <Typography
                                  className="fw-400 dark-text font-inter "
                                  whiteSpace="nowrap"
                                >
                                  Background
                                </Typography>

                                <MuiColorInput
                                  format="hex"
                                  value={values?.iconBGColor}
                                  onChange={handleChangeForIconBGColor}
                                  className="icon-color-picker2"
                                  fullWidth
                                  id="outlined-basic username"
                                  variant="outlined"
                                  size="small"
                                  name="iconBGColor"
                                />
                              </Stack>
                              {errors?.iconBGColor && touched.iconBGColor && (
                                <p
                                  style={{ color: "#d32f2f" }}
                                  className="error-msg"
                                >
                                  {errors?.iconBGColor}
                                </p>
                              )}
                            </div>
                          </Card.Body>
                        </Card>
                      </Stack>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </div>
      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default AddGroups;
