import "@fortawesome/fontawesome-svg-core/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "./styles/global.scss";
import "./styles/layout.scss";
import "./styles/authentication.scss";
import "./styles/home.scss";
import "./styles/news.scss";

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";

import routes from "../src/routes";
import PusherProvider from "./components/common/PusherContext";
import CheckDomain from "./components/common/domainCheck";
import Layout from "./components/layout";

const AppContent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    CheckDomain(navigate);
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken && window.location.pathname === "/") {
      navigate("/sign-in"); // Navigate to sign-in page
    }
  }, [navigate])

  return (
    <Routes>
      {routes.map((route, index) => {
        // Check if the route is `sign-in` or any other specific route
        let isAuthRoute = route?.path === "/sign-in"; // Add other routes as needed
        let isGAuthRoute = route?.path === "/auth/google"
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken && window.location.pathname === "/") {
          isAuthRoute = true;
        } else if (!accessToken && window.location.pathname === "/auth/google") {
          isGAuthRoute = true
        }

        return (
          <Route
            key={index}
            path={route.path}
            element={
              isAuthRoute || isGAuthRoute ? (
                route.element // Do not apply Layout for sign-in
              ) : (
                <Layout>
                  {route.element}
                </Layout>
              )
            }
          />
        );
      })}
    </Routes>
  );
};


const App = () => {

  const userToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);
  const selectedChatRoomID = localStorage?.getItem("selected-chat-room-id");

  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>

    </Provider>
  );
};

export default App;
