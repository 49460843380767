import React from "react";

import ReactDOM from "react-dom";

import { Backdrop, CircularProgress } from "@mui/material";

import "../../styles/common.scss";

const Loader = ({ isLoading }) => {
  return (
    <>
      {isLoading
        ? ReactDOM.createPortal(
            <>
              <Backdrop
                sx={{ color: "#fff", zIndex: 99998 }}
                open={isLoading}
                className="loader"
              >
                <CircularProgress />
              </Backdrop>
            </>,
            document.body
          )
        : null}
    </>
  );
};

export default Loader;
