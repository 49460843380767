import { createSlice } from "@reduxjs/toolkit";
const intialUserSignInState = {
  user: {},
};

const signInUser = createSlice({
  name: "userSignInDetails",
  initialState: intialUserSignInState,
  reducers: {
    getUser(state, action) {
      state.user = action.payload;
    },
    logout(state, action) {
      state.user = {};
    },
  },
});

export const userSigInAction = signInUser.actions;
export default signInUser.reducer;
