export const columns_categories_data = [
    {
        key: 1,
        disablePadding: true,
        id: "name",
        label: "Name",
        minWidth: 530,
        className: "other-color tbl-cell-people-group-1 mt-2",
    },
    {
        key: 2,
        disablePadding: true,
        id: "value",
        label: "Value",
        minWidth: 150,
        className: "other-color tbl-cell-people-group-1 mt-2",
    },
]