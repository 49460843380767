import React from "react";
import { Navigate } from "react-router-dom";

const AuthGuardForSuperAdmin = ({ children }) => {
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  if (loggedUser?.user_type !== "admin") {
    return <Navigate to="/" />;
  }

  return children;
};

export default AuthGuardForSuperAdmin;
