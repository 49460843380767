import * as Yup from "yup";

const nameRegex = /^[a-zA-Z]+$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for email validation
const phoneRegex = /^[0-9]{10}$/; // Regex for phone number validation
const usernameRegex = /^(?=.{3,20}$)(?!.*[_.]{2})[a-zA-Z0-9._]+$/; // Username regex for 4-8 characters with at least one special character
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{4,20}$/; // Password regex for 4-8 characters with at least one digit, one lowercase, one uppercase, and one special character

export const EditNewUsersvalidationSchema = Yup.object().shape({
  profileAccountType: Yup.string("Please select account type option.").required(
    "Account type is required"
  ),
  profileFirstName: Yup.string("Please enter first name.")
    .matches(nameRegex, "Last name should only contain alphabets")
    .required("First name is required"),
  profileLastName: Yup.string("Please enter last name.")
    .matches(nameRegex, "Last name should only contain alphabets")
    .required("Last name is required"),
  profileJobTitle: Yup.string("Please enter job title.").required(
    "Job title is required"
  ),
  profileBio: Yup.string("Please enter bio.").required("Bio is required"),

  contactDetails: Yup.array()
    .of(
      Yup.object().shape({
        service: Yup.string().required("Service name is required"),
        value: Yup.string()
          .required("Value is required")
          .when("service", (service, schema) => {
            const isEmail = service.flat().includes("email");
            const isPhone = service.flat().includes("phone");
            const isAddress = service.flat().includes("address");
            const isTwitter = service.flat().includes("twitter");
            const isLinkdein = service.flat().includes("linkdein");

            if (isEmail) {
              return Yup.string()
                .matches(emailRegex, "Please enter a valid email address")
                .required("Plesse enter email value");
            }
            if (isPhone) {
              return Yup.string()
                .matches(phoneRegex, "Please enter a valid phone number")
                .required("Plesse enter phone value");
            }
            if (isAddress) {
              return Yup.string("Please enter address value.").required(
                "Address value is required"
              );
            }
            if (isTwitter) {
              return Yup.string("Please enter twitter value.")
                .trim()
                .strict(true)
                .url("Twitter url must be a valid URL")
                .required("Twitter value is required");
            }
            if (isLinkdein) {
              return Yup.string("Please enter linkdein value.")
                .trim()
                .strict(true)
                .url("Linkdein url must be a valid URL")
                .required("Linkdein value is required");
            }
            return schema;
          }),
      })
    )
    .min(1, "Please add minimum 1 contact option"),
  authenticationUserName: Yup.string().when(
    "profileAccountType",
    (profileAccountType, schema) => {
      const isUsers = profileAccountType.flat().includes("1");
      if (isUsers) {
        return Yup.string()
          .matches(
            usernameRegex,
            "Username must be between 3 and 20 characters long, and can contain alphanumeric characters, underscores, and dots, but consecutive underscores or dots are not allowed, and no other special characters or spaces are permitted"
          )
          .required("Username is required");
      }
      return schema;
    }
  ),

  authenticationPassword: Yup.string().when(
    "profileAccountType",
    (profileAccountType, schema) => {
      const isUsers = profileAccountType.flat().includes("1");
      if (isUsers) {
        return Yup.string().matches(
          passwordRegex,
          "Password must be 4-20 characters and contain at least one digit, one lowercase, one uppercase, and one special character"
        );
      }
      return schema;
    }
  ),

  authenticationConfirmPassword: Yup.string().when(
    "profileAccountType",
    (profileAccountType, schema) => {
      const isUsers = profileAccountType.flat().includes("1");
      if (isUsers) {
        return Yup.string().oneOf(
          [Yup.ref("authenticationPassword"), null],
          "Passwords must match"
        );
      }
      return schema;
    }
  ),

  organisationManagers: Yup.array(),
  organisationDirectReports: Yup.array(),
  image: Yup.mixed().required("Image is required"),
});
