import { createSlice } from "@reduxjs/toolkit";
const initialData = {
    selectedAttributeTab: 0
};

const attributeTab = createSlice({
    name: "attributeTabDetails",
    initialState: initialData,
    reducers: {
        handleSelectedTab(state, action) {
            state.selectedAttributeTab = action?.payload
        }
    }
})

export const attributeTabAction = attributeTab.actions;
export default attributeTab.reducer;