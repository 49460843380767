export const columns_type_post_header = [
    {
        key: 1,
        disablePadding: true,
        id: "name",
        label: "Name",
        minWidth: 100,
        className: "tbl-head-class-2",
        showInfoIcon: true,
    },

    {
        key: 2,
        disablePadding: true,
        id: "last-modified",
        label: "Last Modified",
        minWidth: 100,
    },

    {
        key: 3,
        disablePadding: true,
        id: "created-by",
        label: "Created BY",
        minWidth: 100,
    },

    {
        key: 4,
        id: "status",
        disablePadding: true,
        label: "Status",
        minWidth: 90,
        className: "other-color",
    },
];
