import React, { useEffect, useState, useRef, useCallback } from "react";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import Header from "../../components/layout/header";
import DrawerMenu from "../../components/layout/drawer";
import { Popper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/userDashboard.scss";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import {
    Box,
    Grid,
    Tabs,
    Tab,
    Paper,
    CardHeader,
    Avatar,
    IconButton,
    TextField,
    Stack,
    Divider,
    Popover,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faList,
    faArrowRight,
    faBriefcase,
    faCheck,
    faImage,
    faChevronUp,
    faChevronDown,
    faThumbsUp,
    faComments,
    faAdd,
    faHeart,
    faSmile,
    faAngry,
    faPhone,
    faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import {
    faFacebook,
    faGoogle,
    faRocketchat,
    faSlack,
    faTelegram,
    faTwitter,
    faWindows,
} from "@fortawesome/free-brands-svg-icons";
import PropTypes from "prop-types";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { faClock, faFaceAngry, faFaceSmile, faHeart as faHeartRegular, faThumbsUp as faThumbsUpRegular } from "@fortawesome/free-regular-svg-icons";
import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../../components/common/loader";
import { Toaster } from "react-hot-toast";
import api from "../../components/common/commonFunctionForApi";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "../../utils/toast";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import moment from "moment";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CardActions from "@mui/material/CardActions";
import { Mention, MentionsInput } from "react-mentions";
import mentionStyle from "../../helpers/styles/dashboard/mentionStyle";
import mentionsInputStyle from "../../helpers/styles/dashboard/mentionsInputStyle";
import DummyUser from "../../assets/images/dummy-user.jpg";
import { useLocation } from "react-router-dom";
import ModalEngagement from "../../components/common/modal/ModalEngagement";
import { dateWithTime, onlyDateFormat } from "../../helpers/DateTimeFormat/dateTimeFormat";

function CustomTabPanel(props) {
    const { children, value, className, index, errorTab, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
            {...other}
        >
            {value === index && <Box className="tab-box">{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const UserDashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const {
        open,
        selectedItem,
        nameCollapsable,
        typeCollapsable,
        drawerVariant,
        usersCollapsable,
        menuFor,
        adminAccessible,
    } = useSelector((state) => state?.drawerMenu);

    const [value, setValue] = useState(0);
    const [isWarningMessage, setIsWarningMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);
    // const [getAllPost, setGetAllPost] = useState(postData);
    const [getAllPost, setGetAllPost] = useState([]);

    const [openPostPopup, setOpenPostPopup] = useState(false);
    const [content, setContent] = useState("");
    const [showBelowError, setShowBelowError] = useState(false);
    const [postImage, setPostImage] = useState();
    const [openFileUploadModel, setOpenFileUploadModel] = useState(false);
    const [contentForPost, setContentForPost] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedImageFile, setSelectedImageFile] = useState(null);
    const fileInputRef = useRef(null);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [attributesData, setAttributes] = useState([]);
    const [formData, setFormData] = useState({});
    const [selectedFileForAtt, setSelectedFileForAtt] = useState(null);
    const [page, setPage] = useState(1);

    const [selectedImageFileForAtt, setSelectedImageFileForAtt] =
        useState(null);

    const [attData, setAttData] = useState();
    const [additionalTextFields, setAdditionalTextFields] = useState([]);

    const [anchorElForReaction, setAnchorElForReaction] = useState(null);
    const [currentPostId, setCurrentPostId] = useState(null);

    const [anchorElForReplyReaction, setAnchorElForReplyReaction] =
        useState(null);
    const [anchorElForPopupUser, setAnchorElForPopupUser] = useState(null);
    const [currentPostIdForReply, setCurrentPostIdForReply] = useState(null);
    const [fetchAll, setFetchAll] = useState(false);
    const [getMentionUsers, setGetMentionUsers] = useState([]);
    const [ackQuestions, setAckQuestions] = useState([]);

    const [openAckQuePopup, setOpenAckQuePopup] = useState(false);
    const [postIdForAckQue, setPostIdForAckQue] = useState();

    const [openAckQuePopupForce, setOpenAckQuePopupForce] = useState(false);
    const [postIdForAckQueForce, setPostIdForAckQueForce] = useState();

    const [isPopupUserExist, setIsPopupUserExist] = useState(false);
    const [popupUserDetails, setPopupUserDetails] = useState();

    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);

    const openForReaction = Boolean(anchorElForReaction);
    const openForReactionReply = Boolean(anchorElForReplyReaction);
    const openForPopupUser = Boolean(anchorElForPopupUser);

    const idPopupUser = openForPopupUser ? "simple-popover" : undefined;

    const [isSelectingSuggestion, setIsSelectingSuggestion] = useState(false);

    const [timeoutId, setTimeoutId] = useState(null);

    const [elements, setElements] = useState([]);
    const [modelPosts, setModelPosts] = useState([]);
    const [currentPostIndex, setCurrentPostIndex] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [modelPostsForAck, setModelPostsForAck] = useState([]);
    const [currentPostIndexForAck, setCurrentPostIndexForAck] = useState(null);
    const [isPopupOpenForAck, setIsPopupOpenForAck] = useState(false);
    const [popupAckData, setPopupAckData] = useState();

    //State used for posts paginations
    const [totalPages, setTotalPages] = useState(null);
    const [nextPage, setNextPage] = useState(false);
    const [isDataFetching, setIsDataFetching] = useState(false);

    const unseenPosts = getAllPost.filter(
        (post) => !post.is_seen && !post?.is_feed
    );
    const seenPosts = getAllPost.filter(
        (post) => post.is_seen && !post?.is_feed
    );
    const unseenFeedPosts = getAllPost.filter(
        (post) => post.is_feed && !post.is_seen
    );
    const seenFeedPosts = getAllPost.filter(
        (post) => post.is_feed && post.is_seen
    );

    /**
     * This function is used to convert utc time to local time like "27 Aug, 05:59am" or "20 Aug 22, 05:59pm" to equivalent local time string
     * @param {string} utcDateTime - It will accept the UTC time
     * @returns string - It will return equivalent local time string
     */
    const formattedPostPublishedDate = (utcDateTime) => {
        // Parse the input string into a Date object assuming it's in UTC
        const date = new Date(`${utcDateTime} UTC`);

        // Get the current year and the year from the date
        const currentYear = new Date().getFullYear();
        const dateYear = date.getFullYear();

        // Format the date components
        const day = date.toLocaleString(undefined, { day: '2-digit' });
        const month = date.toLocaleString(undefined, { month: 'short' });
        const hour = date.toLocaleString(undefined, { hour: '2-digit', hour12: true });
        const hourArr = hour.split(' ');
        const onlyHour = hourArr[0];
        const amPm = hourArr[1];
        const minute = date.toLocaleString(undefined, { minute: '2-digit' }).padStart(2, '0');;

        // Construct the formatted date string
        let formattedDate = `${day} ${month}, ${onlyHour}:${minute}${amPm}`;

        // Adjust the format if the date is from a previous year
        if (dateYear < currentYear) {
            const year = date.toLocaleString(undefined, { year: '2-digit' });
            formattedDate = `${day} ${month} ${year}, ${onlyHour}:${minute}${amPm}`;
        }

        return formattedDate;
    };



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function PreviousItem(props) {
        return (
            <Button
                {...props}
                startIcon={<ArrowBackIcon />}
                className="text-capitalize fs-12-pagination pagination-btns"
            ></Button>
        );
    }

    function NextItem(props) {
        return (
            <Button
                {...props}
                endIcon={<ArrowForwardIcon />}
                className="text-capitalize fs-12-pagination pagination-btns"
            ></Button>
        );
    }

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setPage(newPage);
            // fetchAllPostList(newPage);
        } else if (newPage < page) {
            setPage(newPage);
            // fetchAllPostList(newPage);
        }
    };

    const [openEngagementModal, setOpenEngagementModal] = useState(false);
    const [enagagementModalData, setEngagementModalData] = useState([]);

    const handleEngagementModalApi = async (id) => {
        try {
            setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.get(
                `user/post/engagement/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                setEngagementModalData([response?.response]);
                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                Toast.warning(response?.message);
            }
        } catch (err) {
            console.log("error in fetch post engagement api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                if (loggedUser?.user_type === "user") {
                    localStorage.setItem("last-redirect-page-user", `/`);
                    navigate("/");
                } else if (
                    loggedUser?.user_type === "admin" &&
                    adminAccessible == true
                ) {
                    localStorage.setItem("last-redirect-page-admin-user", `/`);
                    navigate(`/`);
                }
            }
        }
    }

    const handleCommentEngagementModalApi = async (id) => {
        try {
            setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.get(
                `user/comment/engagement/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                setEngagementModalData([response?.response]);
                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                Toast.warning(response?.message);
            }
        } catch (err) {
            console.log("error in fetch post engagement api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                if (loggedUser?.user_type === "user") {
                    localStorage.setItem("last-redirect-page-user", `/`);
                    navigate("/");
                } else if (
                    loggedUser?.user_type === "admin" &&
                    adminAccessible == true
                ) {
                    localStorage.setItem("last-redirect-page-admin-user", `/`);
                    navigate(`/`);
                }
            }
        }
    }

    const handleEngagementModalOpen = async (id, type = "post") => {
        console.log("handle enagagement modal::", id)
        console.log("id type is ::", type);
        setLoading(true);
        console.log("after loader::", id);
        if (type === 'post') {
            await handleEngagementModalApi(id);
        } else if (type === 'comment') {
            await handleCommentEngagementModalApi(id);
        }
        setOpenEngagementModal(true);
        setLoading(false);
    }

    const handleEngagementModalClose = () => {
        setOpenEngagementModal(false);
        setEngagementModalData([]);
    }

    const handleClickOpen = () => {
        setOpenPostPopup(true);
    };

    const handleClose = () => {
        setContent();
        setPostImage();
        setOpenPostPopup(false);
    };

    const handleCloseForAckQue = () => {
        setContent();
        setPostImage();
        setOpenAckQuePopup(false);
        setAckQuestions([]);
        setPostIdForAckQue("");
    };

    const handleCloseForAckQueForce = () => {
        if (popupAckData?.acknowledged_on !== "") {
            setContent();
            setPostImage();
            setOpenAckQuePopupForce(false);
            setAckQuestions([]);
            setPostIdForAckQueForce("");
            setPopupAckData();
        } else {
            setIsPopupOpenForAck(true);
            setCurrentPostIndexForAck(0);
            setAckQuestions([]);
        }
    };

    const handleInputChangeForAckQuestions = (event, index) => {
        const { value } = event.target;
        setAckQuestions((prevQuestions) =>
            prevQuestions.map((que, i) =>
                i === index ? { ...que, ans: value } : que
            )
        );
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPostImage(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOpen = () => setOpenFileUploadModel(true);
    const handleCloseForFileUpload = () => setOpenFileUploadModel(false);

    const isValidURL = (url) => {
        const pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name and extension
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        return !!pattern.test(url);
    };

    const isValidEmail = (email) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };

    const handleAddTextField = (
        nameValue,
        label,
        section_id,
        fieldtype,
        options,
        is_mandatory,
        repeatableAtt,
        valueAtt
    ) => {
        const lastId =
            additionalTextFields.length > 0
                ? additionalTextFields[additionalTextFields.length - 1].id
                : null;

        const newTextField = {
            id: lastId + 1, // Generate a unique ID for the new text field
            nameValue: nameValue,
            value: "",
            label: label,
            section_id: section_id, // Set the appropriate tab value
            fieldtype: fieldtype,
            is_mandatory: is_mandatory,
            ...(options && { options: options }), // Conditionally include options if available
        };
        setAdditionalTextFields([...additionalTextFields, newTextField]);
        if (repeatableAtt == true) {
            const updatedValue = Array.isArray(valueAtt)
                ? [...valueAtt, newTextField]
                : [newTextField];
            handleChangeForFormData(nameValue, updatedValue, section_id);
        }
    };

    // Function to toggle like status
    const toggleLike = (postId, user_has_liked) => {
        setGetAllPost((prevPosts) =>
            prevPosts.map((post) =>
                post.id == postId
                    ? {
                        ...post,
                        user_has_liked:
                            post.user_has_liked === "yes" ? "no" : "yes",
                        like_count:
                            post.user_has_liked === "yes"
                                ? post.like_count - 1
                                : post.like_count + 1,
                    }
                    : post
            )
        );
        handleLikePostAPI(postId, user_has_liked === "yes" ? "no" : "yes");
    };

    const toggleReaction = async (postId, reaction, isLiked) => {
        let newUserLikedData;

        await setGetAllPost((prevPosts) =>
            prevPosts.map((post) => {
                if (post.id === postId) {
                    let newUserLiked = post?.user_has_liked;
                    let newReaction = post.reaction;
                    let newLikeCount = post.like_count;

                    if (newUserLiked === "no") {
                        // No reaction was selected before, adding a new reaction
                        newUserLiked = "yes";
                        newReaction = reaction;
                        newLikeCount += 1; // Increase like count when adding a new reaction
                    } else if (newReaction !== reaction && isLiked == false) {
                        newUserLiked = "no";
                        newReaction = "";
                        newLikeCount -= 1;
                    } else if (newReaction !== reaction && isLiked == true) {
                        // Switching to a different reaction
                        newReaction = reaction;
                        // Like count remains the same since only the reaction is changing
                    } else {
                        // Removing the current reaction
                        newUserLiked = "no";
                        newReaction = "no";
                        newLikeCount -= 1; // Decrease like count when removing the reaction
                    }

                    newUserLikedData = newUserLiked;

                    return {
                        ...post,
                        user_has_liked: newUserLiked,
                        reaction: newReaction,
                        like_count: newLikeCount,
                    };
                }
                return post;
            })
        );
        await handleLikePostAPI(postId, newUserLikedData, reaction);
        await setAnchorElForReaction(null); // Close the popover after selecting a reaction
    };

    const handlePopoverOpen = (event, postId) => {
        handlePopoverCloseForReplyLike();
        setAnchorElForReaction(event.currentTarget);
        setCurrentPostId(postId);
    };

    const handlePopoverClose = () => {
        setAnchorElForReaction(null);
    };

    const handlePopoverOpenForReplyLike = (event, postId, commentID) => {
        handlePopoverClose();
        setCurrentPostId(postId);
        setCurrentPostIdForReply(commentID);
        setAnchorElForReplyReaction(event.currentTarget);
    };

    const handlePopoverOpenForMentionUser = (event, id, commentID) => {
        handlePopoverClose();
        setAnchorElForPopupUser(event?.target);
        fetchMentionUserAPI(id);
    };

    const handlePopoverCloseForReplyLike = () => {
        setAnchorElForReplyReaction(null);
    };

    const handlePopoverCloseForMentionUser = () => {
        setAnchorElForPopupUser(null);
        setPopupUserDetails();
    };

    const toggleLikeForComments = (postId, user_has_liked, commentId) => {
        setGetAllPost((prevPosts) =>
            prevPosts.map((post) =>
                post.id === postId
                    ? {
                        ...post,
                        comments: post.comments.map((comment) =>
                            comment.id === commentId
                                ? {
                                    ...comment,
                                    has_user_liked_comment:
                                        comment.has_user_liked_comment ===
                                            "yes"
                                            ? "no"
                                            : "yes",
                                    comment_likes_count:
                                        comment.has_user_liked_comment ===
                                            "yes"
                                            ? comment.comment_likes_count -
                                            1
                                            : comment.comment_likes_count +
                                            1,
                                }
                                : comment
                        ),
                    }
                    : post
            )
        );
        handleLikePostForCommentsAPI(
            commentId,
            user_has_liked === "yes" ? "no" : "yes",
            postId
        );
    };

    const toggleLikeForCommentsReaction = async (
        postId,
        reaction,
        commentId,
        isLiked
    ) => {
        let newUserLikedData;

        await setGetAllPost((prevPosts) =>
            prevPosts.map((post) => {
                if (post.id == postId) {
                    return {
                        ...post,
                        comments: post.comments.map((comment) => {
                            if (comment.id === commentId) {
                                let newUserLiked =
                                    comment?.has_user_liked_comment;
                                let newReaction = comment.reaction;
                                let newLikeCount = comment.comment_likes_count;

                                if (newUserLiked === "no") {
                                    // No reaction was selected before, adding a new reaction
                                    newUserLiked = "yes";
                                    newReaction = reaction;
                                    newLikeCount += 1; // Increase like count when adding a new reaction
                                } else if (
                                    newReaction !== reaction &&
                                    isLiked === false
                                ) {
                                    // Removing current reaction
                                    newUserLiked = "no";
                                    newReaction = "";
                                    newLikeCount -= 1;
                                } else if (
                                    newReaction !== reaction &&
                                    isLiked === true
                                ) {
                                    // Switching to a different reaction
                                    newReaction = reaction;
                                    // Like count remains the same since only the reaction is changing
                                } else {
                                    // Removing the current reaction
                                    newUserLiked = "no";
                                    newReaction = "";
                                    newLikeCount -= 1; // Decrease like count when removing the reaction
                                }

                                newUserLikedData = newUserLiked;

                                return {
                                    ...comment,
                                    has_user_liked_comment: newUserLiked,
                                    reaction: newReaction,
                                    comment_likes_count: newLikeCount,
                                };
                            }
                            return comment;
                        }),
                    };
                }
                return post;
            })
        );

        await handleLikePostForCommentsAPI(
            commentId,
            newUserLikedData,
            postId,
            reaction
        );
        await handlePopoverCloseForReplyLike();
    };

    const handleCommentToggle = (postId) => {
        setGetAllPost((prevPosts) =>
            prevPosts.map((post) =>
                post.id == postId
                    ? {
                        ...post,
                        commentToggle:
                            post?.commentToggle == true ? false : true,
                    }
                    : post
            )
        );
    };

    const handleCommentReplyToggle = (postId, commentId) => {
        setGetAllPost((prevPosts) =>
            prevPosts.map((post) =>
                post.id === postId
                    ? {
                        ...post,
                        comments: post.comments.map((comment) =>
                            comment.id === commentId
                                ? {
                                    ...comment,
                                    commentReplyToggle:
                                        !comment.commentReplyToggle,
                                }
                                : comment
                        ),
                    }
                    : post
            )
        );
    };

    const handleCommentText = (e, postId) => {
        const regex = /@(\w{2,})$/;
        if (!isSelectingSuggestion && regex.test(e)) {
            const cleanedText = e.match(regex)[1]; // Extract the matched text without @
            fetchMentionUsers(cleanedText);
        }

        setGetAllPost((prevPosts) =>
            prevPosts.map((post) =>
                post.id == postId
                    ? {
                        ...post,
                        commentText: e,
                    }
                    : post
            )
        );

        setIsSelectingSuggestion(false); // Reset the flag after setting the text
    };

    const handleCommentReplyText = (e, postId, commentId) => {
        const regex = /@(\w{1,})$/;
        if (!isSelectingSuggestion && regex.test(e)) {
            const cleanedText = e.match(regex)[1]; // Extract the matched text without @
            fetchMentionUsers(cleanedText);
        }

        setGetAllPost((prevPosts) =>
            prevPosts.map((post) =>
                post.id === postId
                    ? {
                        ...post,
                        comments: post.comments.map((comment) =>
                            comment.id === commentId
                                ? {
                                    ...comment,
                                    commentReplyText: e,
                                }
                                : comment
                        ),
                    }
                    : post
            )
        );
        setIsSelectingSuggestion(false); // Reset the flag after setting the text
    };

    const handleAddComments = async (postId, data) => {
        await handleCommentToggle(postId);
        await handleCommentText("", postId);
        let newData = {
            id: data?.id,
            post_id: data?.post_id,
            user_id: data?.user_id,
            comment: data?.comment,
            is_parent: data?.is_parent,
            parent_id: data?.parent_id,
            created_at: data?.created_at,
            updated_at: data?.updated_at,
            replies_count: data?.replies_count,
            comment_likes_count: data?.comment_likes_count,
            has_user_liked_comment: data?.has_user_liked_comment,
            reaction: data?.reaction,
            user_reactions: data?.user_reactions,
            user: {
                id: data?.user?.id,
                name: data?.user?.name,
                thumbnail: data?.user?.thumbnail,
            },
            replies: data?.replies,
        };
        await setGetAllPost((prevPosts) =>
            prevPosts.map((post) =>
                post.id == postId
                    ? {
                        ...post,
                        comment_count: data?.comment_count,
                        parent_comment_count: data?.parent_comment_count,
                        comments: [newData, ...post?.comments],
                    }
                    : post
            )
        );
    };

    const handleAppendReplyComments = async (
        postId,
        commentId,
        replyContent
    ) => {
        await handleCommentReplyToggle(postId, commentId);
        await handleCommentReplyText("", postId, commentId);
        await setGetAllPost((prevPosts) =>
            prevPosts.map((post) => {
                if (post.id === postId) {
                    return {
                        ...post,
                        comment_count: post?.comment_count + 1,
                        comments: post.comments.map((comment) => {
                            if (comment.id === commentId) {
                                const newReply = {
                                    id: replyContent?.id, // Generate a unique ID for the reply
                                    post_id: replyContent?.post_id,
                                    user_id: replyContent?.user_id,
                                    comment: replyContent?.comment,
                                    is_parent: replyContent?.is_parent,
                                    parent_id: replyContent?.parent_id,
                                    created_at: replyContent?.created_at,
                                    user: {
                                        id: replyContent?.user?.id,
                                        name: replyContent?.user?.name,
                                        thumbnail:
                                            replyContent?.user?.thumbnail,
                                    },
                                };
                                return {
                                    ...comment,
                                    replies: [newReply, ...comment.replies],
                                    replies_count: comment.replies_count + 1,
                                };
                            }
                            return comment;
                        }),
                    };
                }
                return post;
            })
        );
    };

    const appendExtraComments = (postId, data, comment_page) => {
        setLoading(true);

        setGetAllPost((prevPosts) => {
            // Create a new array to ensure immutability
            return prevPosts.map((post) => {
                if (post.id === postId) {
                    let updatedComments;
                    if (post?.comment_page == 0) {
                        updatedComments = data;
                    } else {
                        updatedComments = Array.isArray(data)
                            ? [...post.comments, ...data]
                            : [...post.comments, data];
                    }
                    return {
                        ...post,
                        comments: updatedComments,
                        comment_page: comment_page,
                    };
                }

                return post;
            });
        });

        setLoading(false);
    };

    const appendPostLikeData = (postId, data) => {
        console.log("Post Id", postId);
        console.log("Post data", data);
        setLoading(true);
        setGetAllPost((prevPosts) =>
            prevPosts.map((post) =>
                post.id == postId
                    ? {
                        ...post,
                        like_count: data?.like_count,
                        reaction: data?.reaction,
                        reactions: data?.reactions,
                        user_has_liked: data?.user_has_liked,
                    }
                    : post
            )
        );
        setLoading(false);
    };

    const appendCommentReplyPostLikeData = (postId, commentId, data) => {
        setLoading(true);
        setGetAllPost((prevPosts) =>
            prevPosts.map((post) =>
                post?.id === postId
                    ? {
                        ...post,
                        comments: post.comments.map((comment) =>
                            comment?.id === commentId
                                ? {
                                    ...comment,
                                    comment_likes_count:
                                        data?.comment_likes_count,
                                    has_user_liked_comment:
                                        data?.has_user_liked_comment,
                                    reaction: data?.reaction,
                                    user_reactions: data?.user_reactions,
                                }
                                : comment
                        ),
                    }
                    : post
            )
        );
        setLoading(false);
    };

    const handleChangeForFormData = (
        nameValue,
        value,
        section_id,
        isChildAtt
    ) => {
        setAttributes((prevAttributes) => {
            const updatedAttributes = prevAttributes.map((attribute) => {
                if (
                    attribute?.section_id === section_id &&
                    attribute?.nameValue === nameValue
                ) {
                    // Update the value of the attribute
                    let updatedAttribute = { ...attribute, value };

                    // If the fieldtype is radio_single_choice and options are provided
                    if (
                        attribute?.fieldtype === "radio_single_choice" &&
                        attribute?.options
                    ) {
                        const updatedOptions = attribute?.options?.map(
                            (option) => {
                                // Set the checked property based on the selected value
                                return {
                                    ...option,
                                    checked: option?.id === parseInt(value),
                                };
                            }
                        );
                        // Update the options of the attribute
                        updatedAttribute = {
                            ...updatedAttribute,
                            options: updatedOptions,
                        };
                    }

                    if (attribute?.fieldtype === "tags") {
                        // Initialize options with current options or an empty array
                        let options = attribute?.options || [];

                        // Separate existing and new options
                        const existingOptions = value?.filter(
                            (option) => !option.__isNew__
                        );
                        const newOptions = value?.filter(
                            (option) => option?.__isNew__
                        );

                        // Add new options to the existing options list
                        if (newOptions?.length > 0) {
                            options = [
                                ...options,
                                ...newOptions?.map((option) => ({
                                    value: option?.value,
                                    label: option?.label,
                                })),
                            ];
                        }

                        // Extract selected values from the value array
                        const selectedValues = value?.map(
                            (option) => option?.value
                        );

                        // Update the attribute with new options and selected values
                        updatedAttribute = {
                            ...updatedAttribute,
                            options: options,
                            value: selectedValues,
                        };
                    }

                    // If the fieldtype is checkbox_multiple_choice
                    if (
                        attribute?.fieldtype === "checkbox_multiple_choice" &&
                        attribute?.options
                    ) {
                        const updatedOptions = attribute?.options?.map(
                            (option) => {
                                // Set the checked property based on whether the option ID is included in the selected values array
                                return {
                                    ...option,
                                    checked: value?.includes(option?.id),
                                };
                            }
                        );
                        // Update the options of the attribute
                        updatedAttribute = {
                            ...updatedAttribute,
                            options: updatedOptions,
                        };
                    }

                    if (isChildAtt == true) {
                        updatedAttribute = {
                            ...updatedAttribute,
                            childAtts: value,
                        };
                    }

                    return updatedAttribute;
                }
                return attribute;
            });

            return updatedAttributes;
        });

        setFormData((prevFormData) => ({
            ...prevFormData,
            [nameValue]: value,
        }));
    };

    const handleChangeForChildAtts = (
        nameValue,
        value,
        section_id,
        childAtts,
        attdata
    ) => {
        const findAtt = attributesData
            .filter((data) => data?.id === attdata?.id)
            .map((data) => {
                return {
                    id: data?.id,
                    section_id: data?.section_id,
                    label: data?.label,
                    is_mandatory: data?.is_mandatory,
                    nameValue: data?.nameValue,
                    fieldtype: data?.fieldtype,
                    options: data?.options,
                    parentID: data?.parentID,
                    childAtts: data?.childAtts,
                    value: data?.value,
                };
            });

        const updatedChildAtts = findAtt.flatMap((att) => {
            return att.childAtts.map((childAtt) => {
                if (
                    childAtt.section_id === section_id &&
                    childAtt.nameValue === nameValue
                ) {
                    // If the fieldtype is checkbox_multiple_choice
                    if (
                        childAtt.fieldtype === "checkbox_multiple_choice" &&
                        childAtt.options
                    ) {
                        const updatedOptions = childAtt.options.map(
                            (option) => {
                                // Set the checked property based on whether the option ID is included in the selected values array
                                return {
                                    ...option,
                                    checked: value.includes(option.id),
                                };
                            }
                        );

                        // Update the options of the child attribute
                        return { ...childAtt, options: updatedOptions, value };
                    } else {
                        // If the fieldtype is not checkbox_multiple_choice, simply update the value
                        return { ...childAtt, value };
                    }
                }
                return childAtt;
            });
        });

        const updatedAttData = attributesData.map((att) => {
            if (att.id === attdata.id) {
                return { ...att, childAtts: updatedChildAtts };
            }
            return att;
        });
        setAttributes(updatedAttData);
    };

    const handleChangeForAdditionalTextField = (
        id,
        value,
        nameValue,
        section_id
    ) => {
        const updatedTextFields = additionalTextFields.map((field) =>
            field.id === id && field?.nameValue === nameValue
                ? { ...field, value: value }
                : field
        );
        setAdditionalTextFields(updatedTextFields);

        const finalData = updatedTextFields?.filter(
            (data) => data?.nameValue == nameValue
        );

        handleChangeForFormData(nameValue, finalData, section_id);
    };

    const removeExtraFields = (id, nameValue, section_id) => {
        const updatedTextFields = additionalTextFields?.filter(
            (field) => field?.id !== id
        );
        setAdditionalTextFields(updatedTextFields);
        handleChangeForFormData(nameValue, updatedTextFields, section_id);
    };

    // const fetchAllTypePost = async () => {
    //   try {
    //     // setLoading(true);
    //     const userToken = localStorage.getItem("accessToken");
    //     const response = await api.post(`user/post-lists`, "", {
    //       headers: {
    //         Authorization: `Bearer ${userToken}`,
    //       },
    //     });
    //     setGetAllPost(response?.data);
    //     setLoading(false);
    //   } catch (err) {
    //     console.log("err`or in fetch news api::", err);
    //     if (err?.response?.status == 401) {
    //       setLoading(false);
    //       const userToken = localStorage.getItem("accessToken");
    //       logoutHelper(dispatch, navigate, userToken);
    //     }
    //     setLoading(false);
    //   }
    // };

    const fetchAllTypePostReset = async () => {
        try {
            // setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.post(`user/post-lists`, "", {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            setGetAllPost(response?.data);
            setPage(1);
            setTotalPages(response?.meta.last_page);
            if (page + 1 <= response?.meta.last_page) {
                setNextPage(true);
            }
            setLoading(false);
        } catch (err) {
            console.log("err`or in fetch news api::", err);
            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            setLoading(false);
        }
    };

    const fetchAllTypePost = async (page) => {
        try {
            const userToken = localStorage.getItem("accessToken");
            const response = await api.post(
                `user/post-lists`,
                { page },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            const newPosts = response?.data;
            setGetAllPost((prevPosts) => {
                const uniquePosts = newPosts.filter(
                    (newPost) =>
                        !prevPosts.some((post) => post.id === newPost.id)
                );
                return [...prevPosts, ...uniquePosts];
            });

            setTotalPages(response?.meta.last_page);
            if (page + 1 <= response?.meta.last_page) {
                setNextPage(true);
            }
        } catch (err) {
            console.log("error in fetch news api::", err);
            if (err?.response?.status === 401) {
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
        } finally {
            setIsDataFetching(false); // Reset fetching state after the API call
            setLoading(false);
        }
    };

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }

    const handleScroll = async () => {
        const scrollableHeight = document.documentElement.scrollHeight;
        const scrollPosition = window.innerHeight + window.scrollY;
        const remainingScrollThreshold = scrollableHeight * 0.3;

        if (
            scrollableHeight - scrollPosition < remainingScrollThreshold &&
            !isDataFetching &&
            page < totalPages
        ) {
            console.log("inside handle scroll if condition");
            setIsDataFetching(true);
            const nextPageNum = page + 1;
            setPage(nextPageNum);
            await fetchAllTypePost(nextPageNum);
        }
    };

    useEffect(() => {
        console.log("inside useEffect scroll");

        const debouncedHandleScroll = debounce(handleScroll, 200);

        window.addEventListener("scroll", debouncedHandleScroll);
        return () =>
            window.removeEventListener("scroll", debouncedHandleScroll);
    }, [isDataFetching, page, totalPages]);

    const handleImageFileValue = (setFieldValue) => {
        if (
            selectedImageFile instanceof Blob ||
            selectedImageFile instanceof File ||
            selectedFile instanceof Blob ||
            selectedFile instanceof File
        ) {
            if (contentForPost === "image") {
                setFieldValue("image", URL.createObjectURL(selectedImageFile));
                setOpenFileUploadModel(false);
            } else {
                setFieldValue("file", selectedFile);
                setOpenFileUploadModel(false);
            }
        } else {
            console.error("Selected file is not a valid Blob or File object");
        }
    };

    const handleClick = (contentImg) => {
        setContent(contentImg);
        setOpenFileUploadModel(true);
    };

    const fetchElementList = async () => {
        try {
            setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.get(
                `user/post/update-type-post/attributes`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                let acknowledgementQuestionsArray = [];

                setAttributes(response?.response);
                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                Toast.warning(response?.message);
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                if (loggedUser?.user_type === "user") {
                    localStorage.setItem("last-redirect-page-user", `/`);
                    navigate("/");
                } else if (
                    loggedUser?.user_type === "admin" &&
                    adminAccessible == true
                ) {
                    localStorage.setItem("last-redirect-page-admin-user", `/`);
                    navigate(`/`);
                }
            }
        }
    };

    function a11yProps1(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                value == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }
    function a11yProps2(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                value == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }

    const handleCheckboxChange = async (id) => {
        try {
            setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.post(
                `/user/post/acknowledge/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                Toast.success(response?.message);
                setLoading(false);
                handleAckCheckboxSuccess(id);
                // fetchAllTypePost();
            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in acknowledge user::", err);
            setLoading(false);
            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            Toast.error(err?.response?.data?.message);
        }
    };

    const handleAckCheckboxSuccess = async (postId) => {
        setGetAllPost((prevPosts) =>
            prevPosts.map((post) =>
                post.id == postId
                    ? {
                        ...post,
                        acknowledged_on: getCurrentUTCDateTime(),
                    }
                    : post
            )
        );
    };

    const getCurrentUTCDateTime = () => {
        const today = new Date();
        const year = today.getUTCFullYear();
        const month = String(today.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
        const day = String(today.getUTCDate()).padStart(2, "0");
        const hours = String(today.getUTCHours()).padStart(2, "0");
        const minutes = String(today.getUTCMinutes()).padStart(2, "0");
        const seconds = String(today.getUTCSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };



    const handleCheckboxChangeForce = async (id) => {
        try {
            setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.post(
                `/user/post/acknowledge/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                Toast.success(response?.message);
                setLoading(false);
                fetchAllTypePost();
                closePopupForAck();
                setModelPostsForAck([]);
                setCurrentPostIndexForAck(null);
                handleCloseForAckQueForce();
                setPostIdForAckQueForce();
                handleAcknowledgementPopup();
            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in acknowledge user::", err);
            setLoading(false);
            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            Toast.error(err?.response?.data?.message);
        }
    };

    const handleAckQuestionsAPI = async () => {
        let data = {
            answer: ackQuestions?.map((data) => {
                return {
                    id: data?.id,
                    answer: data?.ans,
                };
            }),
        };
        try {
            setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.post(
                `/user/acknowledge/answer/${postIdForAckQue}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                Toast.success(response?.message);
                setLoading(false);
                // fetchAllTypePostReset();
                handleAckCheckboxSuccess(postIdForAckQue);
                handleCloseForAckQue();
                setPostIdForAckQue();
            } else {
                setLoading(false);
                Toast.error(response?.message || response?.response);
            }
        } catch (err) {
            console.log("error in acknowledge user::", err);
            setLoading(false);
            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            Toast.error(err?.response?.data?.message);
        }
    };

    const handleAckQuestionsForceAPI = async () => {
        let data = {
            answer: ackQuestions?.map((data) => {
                return {
                    id: data?.id,
                    answer: data?.ans,
                };
            }),
        };
        try {
            setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.post(
                `/user/acknowledge/answer/${postIdForAckQueForce}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                Toast.success(response?.message);
                setLoading(false);
                fetchAllTypePostReset();
                closePopupForAck();
                setAckQuestions([]);
                setModelPostsForAck([]);
                setCurrentPostIndexForAck(null);
                setIsPopupOpenForAck(false);
                setOpenAckQuePopupForce(false);
                setPopupAckData();
                setPostIdForAckQueForce();
                handleAcknowledgementPopup();
            } else {
                setLoading(false);
                Toast.error(response?.message || response?.response);
            }
        } catch (err) {
            console.log("error in acknowledge user::", err);
            setLoading(false);
            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            Toast.error(err?.response?.data?.message);
        }
    };

    const validateData = () => {
        let isValid = true;
        let errorMessages = [];

        if (content === "" || content === null || content === undefined) {
            isValid = false;
        }
        return { isValid, errorMessages };
    };

    const sumbitPost = async () => {
        const validate = await validateData();

        if (validate?.isValid == false) {
            setShowBelowError(true);
        } else {
            setShowBelowError(false);
            const formData = new FormData();

            formData.append("description", content);
            if (postImage) {
                formData.append("image", postImage);
            }

            function extractFileName(url) {
                const baseUrl =
                    "https://staging-intranet.s3.ap-southeast-2.amazonaws.com/images/";
                return url.replace(baseUrl, "");
            }

            const transformedAttributesData = attributesData?.reduce(
                (acc, attribute) => {
                    let attributeValue = attribute?.value;
                    let attributeIsChild =
                        attribute?.childAtts?.length > 0 ? "yes" : "no";
                    let attributeChildAtts = [];

                    // If the fieldtype is checkbox_multiple_choice or dropdodown_multiple_choice, convert the value to a comma-separated string
                    if (
                        attribute?.fieldtype === "checkbox_multiple_choice" ||
                        attribute?.fieldtype === "dropdodown_multiple_choice" ||
                        attribute?.fieldtype === "users_list" ||
                        attribute?.fieldtype === "group_list" ||
                        attribute?.fieldtype === "tags"
                    ) {
                        attributeValue = Array.isArray(attribute?.value)
                            ? attribute?.value.join(",")
                            : attribute?.value;
                    }

                    if (attribute?.fieldtype === "checkbox_single_choice") {
                        attributeValue =
                            attribute?.value === true ? "true" : "false";
                    }

                    // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

                    if (attribute?.fieldtype === "date") {
                        const date = dayjs(attribute?.value)?.format(
                            "YYYY-MM-DD"
                        );
                        attributeValue = date;
                    }
                    if (attribute?.fieldtype === "time") {
                        const time = dayjs(attribute?.value, "HH:mm A")?.format(
                            "HH:mm A"
                        );
                        attributeValue = time;
                    }
                    if (attribute?.fieldtype === "date_time") {
                        const dateTime = dayjs(attribute?.value)?.format(
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                        );
                        attributeValue = dateTime;
                    }
                    if (attribute?.fieldtype === "date_range") {
                        const startDate = moment(attribute.value.startDate);
                        const endDate = moment(attribute.value.endDate);
                        const formattedStartDate =
                            startDate?.format("YYYY-MM-DD");
                        const formattedEndDate = endDate?.format("YYYY-MM-DD");
                        // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
                        attributeValue = `${formattedStartDate} - ${formattedEndDate}`;
                    }

                    if (attribute?.fieldtype === "image") {
                        if (
                            selectedImageFileForAtt instanceof Blob ||
                            selectedImageFileForAtt instanceof File
                        ) {
                            attributeValue = "";
                        } else {
                            attributeValue = extractFileName(attribute.value);
                        }
                    }

                    if (attribute?.fieldtype === "file") {
                        if (
                            selectedFileForAtt instanceof Blob ||
                            selectedFileForAtt instanceof File
                        ) {
                            attributeValue = "";
                        } else {
                            attributeValue = extractFileName(attribute.value);
                        }
                    }

                    if (attribute?.is_repeatable == true) {
                        attributeValue = JSON?.stringify(attribute?.value);
                    }

                    if (attribute?.childAtts?.length > 0) {
                        if (attribute.value == false) {
                            attributeChildAtts = attribute?.childAtts?.reduce(
                                (acc, childAtt) => {
                                    let attributeValueForChild = "";

                                    // If the fieldtype is checkbox_multiple_choice or dropdodown_multiple_choice, convert the value to a comma-separated string
                                    if (
                                        childAtt?.fieldtype ===
                                        "checkbox_multiple_choice" ||
                                        childAtt?.fieldtype ===
                                        "dropdodown_multiple_choice" ||
                                        childAtt?.fieldtype === "users_list" ||
                                        childAtt?.fieldtype === "group_list" ||
                                        attribute?.fieldtype === "tags"
                                    ) {
                                        attributeValueForChild = "";
                                    }

                                    if (
                                        childAtt?.fieldtype ===
                                        "checkbox_single_choice"
                                    ) {
                                        attributeValueForChild = "";
                                    }

                                    // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

                                    if (childAtt?.fieldtype === "date") {
                                        const date = dayjs(
                                            childAtt?.value
                                        )?.format("YYYY-MM-DD");
                                        attributeValueForChild = "";
                                    }
                                    if (childAtt?.fieldtype === "time") {
                                        const time = dayjs(
                                            childAtt?.value,
                                            "HH:mm A"
                                        )?.format("HH:mm A");
                                        attributeValueForChild = "";
                                    }
                                    if (childAtt?.fieldtype === "date_time") {
                                        const dateTime = dayjs(
                                            childAtt?.value
                                        )?.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
                                        attributeValueForChild = "";
                                    }
                                    if (childAtt?.fieldtype === "date_range") {
                                        const startDate = moment(
                                            childAtt?.value?.startDate
                                        );
                                        const endDate = moment(
                                            childAtt?.value?.endDate
                                        );
                                        const formattedStartDate =
                                            startDate?.format("YYYY-MM-DD");
                                        const formattedEndDate =
                                            endDate?.format("YYYY-MM-DD");
                                        // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
                                        attributeValueForChild = "";
                                    }

                                    if (childAtt?.fieldtype === "image") {
                                        if (
                                            selectedImageFileForAtt instanceof
                                            Blob ||
                                            selectedImageFileForAtt instanceof
                                            File
                                        ) {
                                            attributeValueForChild = "";
                                        } else {
                                            attributeValueForChild = "";
                                        }
                                    }

                                    if (childAtt?.fieldtype === "file") {
                                        if (
                                            selectedFileForAtt instanceof
                                            Blob ||
                                            selectedFileForAtt instanceof File
                                        ) {
                                            attributeValueForChild = "";
                                        } else {
                                            attributeValueForChild = "";
                                        }
                                    }

                                    if (childAtt?.is_repeatable == true) {
                                        attributeValueForChild = "";
                                    }

                                    acc[childAtt?.nameValue] = {
                                        parentAttId: attribute?.id,
                                        attribute_id: childAtt?.attribute_id,
                                        attribute_core_name:
                                            childAtt?.nameValue,
                                        attribute_data_type:
                                            childAtt?.fieldtype ===
                                                "checkbox_multiple_choice" ||
                                                childAtt?.fieldtype ===
                                                "dropdodown_multiple_choice"
                                                ? "array"
                                                : childAtt?.fieldtype === "file"
                                                    ? "file"
                                                    : childAtt?.fieldtype ===
                                                        "image"
                                                        ? "image"
                                                        : "string",
                                        attribute_value: attributeValueForChild,
                                        is_child: "no",
                                    };

                                    return acc;
                                },
                                {}
                            );
                        } else if (attribute.value != 1) {
                            attributeChildAtts = attribute?.childAtts?.reduce(
                                (acc, childAtt) => {
                                    let attributeValueForChild = "";

                                    // If the fieldtype is checkbox_multiple_choice or dropdodown_multiple_choice, convert the value to a comma-separated string
                                    if (
                                        childAtt?.fieldtype ===
                                        "checkbox_multiple_choice" ||
                                        childAtt?.fieldtype ===
                                        "dropdodown_multiple_choice" ||
                                        childAtt?.fieldtype === "users_list" ||
                                        childAtt?.fieldtype === "group_list" ||
                                        attribute?.fieldtype === "tags"
                                    ) {
                                        attributeValueForChild = "";
                                    }

                                    if (
                                        childAtt?.fieldtype ===
                                        "checkbox_single_choice"
                                    ) {
                                        attributeValueForChild = "";
                                    }

                                    // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

                                    if (childAtt?.fieldtype === "date") {
                                        const date = dayjs(
                                            childAtt?.value
                                        )?.format("YYYY-MM-DD");
                                        attributeValueForChild = "";
                                    }
                                    if (childAtt?.fieldtype === "time") {
                                        const time = dayjs(
                                            childAtt?.value,
                                            "HH:mm A"
                                        )?.format("HH:mm A");
                                        attributeValueForChild = "";
                                    }
                                    if (childAtt?.fieldtype === "date_time") {
                                        const dateTime = dayjs(
                                            childAtt?.value
                                        )?.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
                                        attributeValueForChild = "";
                                    }
                                    if (childAtt?.fieldtype === "date_range") {
                                        const startDate = moment(
                                            childAtt?.value?.startDate
                                        );
                                        const endDate = moment(
                                            childAtt?.value?.endDate
                                        );
                                        const formattedStartDate =
                                            startDate?.format("YYYY-MM-DD");
                                        const formattedEndDate =
                                            endDate?.format("YYYY-MM-DD");
                                        // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
                                        attributeValueForChild = "";
                                    }

                                    if (childAtt?.fieldtype === "image") {
                                        if (
                                            selectedImageFileForAtt instanceof
                                            Blob ||
                                            selectedImageFileForAtt instanceof
                                            File
                                        ) {
                                            attributeValueForChild = "";
                                        } else {
                                            attributeValueForChild = "";
                                        }
                                    }

                                    if (childAtt?.fieldtype === "file") {
                                        if (
                                            selectedFileForAtt instanceof
                                            Blob ||
                                            selectedFileForAtt instanceof File
                                        ) {
                                            attributeValueForChild = "";
                                        } else {
                                            attributeValueForChild = "";
                                        }
                                    }

                                    if (childAtt?.is_repeatable == true) {
                                        attributeValueForChild = "";
                                    }

                                    acc[childAtt?.nameValue] = {
                                        parentAttId: attribute?.id,
                                        attribute_id: childAtt?.attribute_id,
                                        attribute_core_name:
                                            childAtt?.nameValue,
                                        attribute_data_type:
                                            childAtt?.fieldtype ===
                                                "checkbox_multiple_choice" ||
                                                childAtt?.fieldtype ===
                                                "dropdodown_multiple_choice"
                                                ? "array"
                                                : childAtt?.fieldtype === "file"
                                                    ? "file"
                                                    : childAtt?.fieldtype ===
                                                        "image"
                                                        ? "image"
                                                        : "string",
                                        attribute_value: attributeValueForChild,
                                        is_child: "no",
                                    };

                                    return acc;
                                },
                                {}
                            );
                        } else {
                            attributeChildAtts = attribute?.childAtts?.reduce(
                                (acc, childAtt) => {
                                    let attributeValueForChild =
                                        childAtt?.value;

                                    // If the fieldtype is checkbox_multiple_choice or dropdodown_multiple_choice, convert the value to a comma-separated string
                                    if (
                                        childAtt?.fieldtype ===
                                        "checkbox_multiple_choice" ||
                                        childAtt?.fieldtype ===
                                        "dropdodown_multiple_choice" ||
                                        childAtt?.fieldtype === "users_list" ||
                                        childAtt?.fieldtype === "group_list"
                                    ) {
                                        attributeValueForChild = Array?.isArray(
                                            childAtt?.value
                                        )
                                            ? childAtt?.value.join(",")
                                            : childAtt?.value;
                                    }

                                    if (
                                        childAtt?.fieldtype ===
                                        "checkbox_single_choice"
                                    ) {
                                        attributeValueForChild = String(
                                            attribute?.value
                                        );
                                    }

                                    // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

                                    if (childAtt?.fieldtype === "date") {
                                        const date = dayjs(
                                            childAtt?.value
                                        )?.format("YYYY-MM-DD");
                                        attributeValueForChild = date;
                                    }
                                    if (childAtt?.fieldtype === "time") {
                                        const time = dayjs(
                                            childAtt?.value,
                                            "HH:mm A"
                                        )?.format("HH:mm A");
                                        attributeValueForChild = time;
                                    }
                                    if (childAtt?.fieldtype === "date_time") {
                                        const dateTime = dayjs(
                                            childAtt?.value
                                        )?.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
                                        attributeValueForChild = dateTime;
                                    }
                                    if (childAtt?.fieldtype === "date_range") {
                                        const startDate = moment(
                                            childAtt?.value?.startDate
                                        );
                                        const endDate = moment(
                                            childAtt?.value?.endDate
                                        );
                                        const formattedStartDate =
                                            startDate?.format("YYYY-MM-DD");
                                        const formattedEndDate =
                                            endDate?.format("YYYY-MM-DD");
                                        // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
                                        attributeValueForChild = `${formattedStartDate} - ${formattedEndDate}`;
                                    }

                                    if (childAtt?.fieldtype === "image") {
                                        if (
                                            selectedImageFileForAtt instanceof
                                            Blob ||
                                            selectedImageFileForAtt instanceof
                                            File
                                        ) {
                                            attributeValueForChild = "";
                                        } else {
                                            attributeValueForChild =
                                                extractFileName(childAtt.value);
                                        }
                                    }

                                    if (childAtt?.fieldtype === "file") {
                                        if (
                                            selectedFileForAtt instanceof
                                            Blob ||
                                            selectedFileForAtt instanceof File
                                        ) {
                                            attributeValueForChild = "";
                                        } else {
                                            attributeValueForChild =
                                                extractFileName(childAtt.value);
                                        }
                                    }

                                    if (childAtt?.is_repeatable == true) {
                                        attributeValueForChild =
                                            JSON?.stringify(childAtt?.value);
                                    }

                                    acc[childAtt?.nameValue] = {
                                        parentAttId: attribute?.id,
                                        attribute_id: childAtt?.attribute_id,
                                        attribute_core_name:
                                            childAtt?.nameValue,
                                        attribute_data_type:
                                            childAtt?.fieldtype ===
                                                "checkbox_multiple_choice" ||
                                                childAtt?.fieldtype ===
                                                "dropdodown_multiple_choice"
                                                ? "array"
                                                : childAtt?.fieldtype === "file"
                                                    ? "file"
                                                    : childAtt?.fieldtype ===
                                                        "image"
                                                        ? "image"
                                                        : "string",
                                        attribute_value: attributeValueForChild,
                                        is_child: "no",
                                    };

                                    return acc;
                                },
                                {}
                            );
                        }
                    }

                    acc[attribute?.nameValue] = {
                        attribute_id: attribute?.attribute_id,
                        attribute_core_name: attribute?.nameValue,
                        attribute_data_type:
                            attribute?.fieldtype ===
                                "checkbox_multiple_choice" ||
                                attribute?.fieldtype ===
                                "dropdodown_multiple_choice"
                                ? "array"
                                : attribute?.fieldtype === "file"
                                    ? "file"
                                    : attribute?.fieldtype === "image"
                                        ? "image"
                                        : "string",
                        attribute_value: attributeValue,
                        is_child: attributeIsChild,
                        childAtts: attributeChildAtts,
                    };

                    return acc;
                },
                {}
            );

            formData.append(
                "attributes",
                JSON.stringify([transformedAttributesData])
            );

            try {
                setLoading(true);
                const userToken = localStorage.getItem("accessToken");
                const response = await api.post(
                    `user/update-type-post`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );
                if (response?.success == true) {
                    setLoading(false);
                    setContent("");
                    setSelectedImageFile("");
                    setPostImage();
                    setSuccessMessage(response?.message);
                    handleClose();
                    fetchAllTypePostReset();

                    Toast.success(response?.message);
                } else {
                    setLoading(false);
                    setContent("");
                    setSelectedImageFile("");
                    setIsWarningMessage(true);
                    setWarningMessage(response?.message);
                    Toast.error(response?.message);
                }
                setContent("");
                setSelectedImageFile("");
                setIsCollapsed(false);
                fetchElementList();
            } catch (err) {
                console.log("error in ctreate news api::", err);
                setLoading(false);

                if (err?.response?.status == 401) {
                    setLoading(false);
                    const userToken = localStorage.getItem("accessToken");
                    logoutHelper(dispatch, navigate, userToken);
                }
                if (err?.response?.status == 422) {
                    setLoading(false);
                    setIsWarningMessage(true);
                    setWarningMessage(err?.response?.data?.errors?.image);
                    const errorData = Object.values(
                        err?.response?.data?.errors
                    ).forEach((value) => Toast.error(value));
                }
            }
        }
    };

    const handleLikePostAPI = async (id, newUserLiked, reaction) => {
        let data = {
            like: newUserLiked,
            reaction: reaction,
        };
        try {
            setLoading(false);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.post(`user/post/like/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                const responseData = response?.response?.data[0];
                if (fetchAll == true) {
                    appendPostLikeData(id, responseData);
                } else {
                    appendPostLikeData(id, responseData);
                    // fetchAllTypePost();
                }
                setLoading(false);
                handlePopoverClose();
            } else {
                handlePopoverClose();
                setLoading(false);
                fetchAllTypePost();

                setIsWarningMessage(true);
                setWarningMessage(response?.message);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 422) {
                setLoading(false);
                setIsWarningMessage(true);
                handlePopoverClose();

                setWarningMessage(err?.response?.data?.errors?.image);
                const errorData = Object.values(
                    err?.response?.data?.errors
                ).forEach((value) => Toast.error(value));
            }
        }
    };

    const handleLikePostForCommentsAPI = async (
        id,
        newUserLiked,
        post_id,
        reaction
    ) => {
        let data = {
            like: newUserLiked,
            post_id: post_id,
            reaction: reaction,
        };
        try {
            setLoading(false);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.post(
                `user/post/comment-like/${id}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                if (fetchAll == true) {
                    appendCommentReplyPostLikeData(
                        post_id,
                        id,
                        response?.response?.data[0]
                    );
                } else {
                    appendCommentReplyPostLikeData(
                        post_id,
                        id,
                        response?.response?.data[0]
                    );
                    // fetchAllTypePost();
                }
                setLoading(false);
            } else {
                setLoading(false);
                fetchAllTypePost();

                setIsWarningMessage(true);
                setWarningMessage(response?.message);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 422) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data?.errors?.image);
                const errorData = Object.values(
                    err?.response?.data?.errors
                ).forEach((value) => Toast.error(value));
            }
        }
    };

    const handleCommentsAPI = async (id, isParent) => {
        const postData = getAllPost?.filter((post) => post?.id === id);
        let data = {
            comment: postData[0]?.commentText,
            is_parent: isParent,
        };
        if (!postData[0]?.commentText || postData[0]?.commentText == "") {
            return Toast.error("Please enter at least one charactor!");
        }
        try {
            setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.post(`user/post/comment/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                if (fetchAll == true) {
                    handleAddComments(id, response?.response?.data[0]);
                } else {
                    handleAddComments(id, response?.response?.data[0]);
                    // fetchAllTypePost();
                }
                setLoading(false);
            } else {
                setLoading(false);
                fetchAllTypePost();

                setIsWarningMessage(true);
                setWarningMessage(response?.message);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 422) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data?.errors?.image);
                const errorData = Object.values(
                    err?.response?.data?.errors
                ).forEach((value) => Toast.error(value));
            }
        }
    };

    const handleCommentsReplyAPI = async (id, isParent, commentId) => {
        const postData = getAllPost?.filter((post) => post?.id === id);
        let commentData;
        if (postData) {
            commentData = postData[0]?.comments?.find(
                (comment) => comment?.id === commentId
            );
        } else {
            console.log("Post not found");
        }

        let data = {
            comment: commentData?.commentReplyText,
            is_parent: isParent,
            parent_id: commentId,
        };

        if (
            !commentData?.commentReplyText ||
            commentData?.commentReplyText == ""
        ) {
            return Toast.error("Please enter at least one charactor!");
        }
        try {
            setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.post(`user/post/comment/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                if (fetchAll == true) {
                    handleAppendReplyComments(
                        postData[0]?.id,
                        commentId,
                        response?.response
                    );
                } else {
                    handleAppendReplyComments(
                        postData[0]?.id,
                        commentId,
                        response?.response
                    );
                    // fetchAllTypePost();
                }
                setLoading(false);
            } else {
                setLoading(false);
                fetchAllTypePost();
                setIsWarningMessage(true);
                setWarningMessage(response?.message);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 422) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data?.errors?.image);
                const errorData = Object.values(
                    err?.response?.data?.errors
                ).forEach((value) => Toast.error(value));
            }
        }
    };

    const fetchLoadMoreCommentsAPI = async (post) => {
        try {
            setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.get(
                `user/post/get-comment/${post?.id}?page=${post?.comment_page + 1
                }`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                setLoading(false);
                const allComments = response?.response?.data;
                appendExtraComments(
                    post?.id,
                    allComments,
                    post?.comment_page + 1
                );
            } else {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(response?.message);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 422) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data?.errors?.image);
                const errorData = Object.values(
                    err?.response?.data?.errors
                ).forEach((value) => Toast.error(value));
            }
        }
    };

    const fetchMentionUserAPI = async (id) => {
        try {
            // setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.get(`user/mention/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                setLoading(false);
                setPopupUserDetails(response?.response);
                setIsPopupUserExist(true);
            } else {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(response?.message);
                setIsPopupUserExist(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 422) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data?.errors?.image);
                const errorData = Object.values(
                    err?.response?.data?.errors
                ).forEach((value) => Toast.error(value));
            }
        }
    };

    const handleMouseEnter = (event, mentionId) => {
        const id = setTimeout(() => {
            handlePopoverOpenForMentionUser(event, mentionId);
        }, 1000);
        setTimeoutId(id);
    };

    const handleMouseLeave = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
    };

    const renderCommentText = (text) => {
        const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
        const parts = text.split(mentionRegex);

        return parts.map((part, index) => {
            if (index % 3 === 1) {
                // Mention text
                const mentionId = parts[index + 1];
                return (
                    <span
                        key={index}
                        className="fs-14-bold cursor-pointer"
                        id={`${mentionId} `}
                        aria-describedby={idPopupUser}
                        onMouseEnter={(e) => {
                            const id = setTimeout(() => {
                                handlePopoverOpenForMentionUser(e, mentionId);
                            }, 1000);
                            setTimeoutId(id);
                        }}
                        onMouseLeave={() => {
                            if (timeoutId) {
                                clearTimeout(timeoutId);
                                setTimeoutId(null);
                                // handlePopoverCloseForMentionUser()
                            }
                        }}
                    >
                        {part}
                    </span>
                );
            } else if (index % 3 === 2) {
                // Mention identifier (we skip it)
                return null;
            } else {
                // Normal text
                return (
                    <span key={index} className="fs-14">
                        {part}
                    </span>
                );
            }
        });
    };

    const fetchMentionUsers = async (text) => {
        try {
            setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.get(
                `user/suggestion/user-list?search=${text}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                setLoading(false);

                const allUsers = response?.response?.map((data) => {
                    return {
                        id: data?.id,
                        display: `${data?.name} ${data?.last_name}`,
                        photo: data?.thumbnail,
                        job_title: data?.job_title,
                        full_name: data?.full_name,
                    };
                });
                setGetMentionUsers(allUsers);
            } else {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(response?.message);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 422) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data?.errors?.image);
                const errorData = Object.values(
                    err?.response?.data?.errors
                ).forEach((value) => Toast.error(value));
            }
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const paste = (event.clipboardData || window.clipboardData).getData(
            "text"
        );

        // Optionally, you can process the pasted text here if needed.
        const sanitizedText = paste.replace(/[@]/g, "@ "); // Example: add a space after @

        document.execCommand("insertText", false, sanitizedText);
    };

    const openPopup = (index) => {
        setCurrentPostIndex(index);
        setIsPopupOpen(true);
    };

    const closePopup = async (postId) => {
        setIsPopupOpen(false);
        setCurrentPostIndex((prevIndex) => prevIndex + 1);
        const userToken = localStorage.getItem("accessToken");
        try {
            // setLoading(true);
            const response = await api.post(
                `user/highlight-dismis/${postId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setLoading(false);
        } catch (err) {
            console.log("err`or in fetch news api::", err);
            if (err?.response?.status == 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            setLoading(false);
        }
    };

    const closePopupForAck = async (postId) => {
        setIsPopupOpenForAck(false);
    };

    const CustomPopup = ({ post, onClose }) => {
        return (
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={true}
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle
                    sx={{ m: 0, p: 2 }}
                    id="customized-dialog-title"
                    className="highlight-model-title-div"
                >
                    <Avatar
                        aria-label="recipe"
                        className="author-avtar-creator"
                        src={post?.profile}
                    >
                        {post?.created_by?.charAt(0)}
                    </Avatar>
                    <Typography className="text-center">
                        <span>
                            <b>{post?.created_by}</b> posted a{" "}
                            <b>{post?.type}</b>
                        </span>
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        className="alert-close-btn"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers className="no-border">
                    <CardMedia sx={{ height: 222 }} image={post?.image} />
                    <div className="main-content-div-1 mt-3">
                        <div className="main-div-posted">
                            <div className="icon-bd-div">
                                <FontAwesomeIcon icon={faClock} />
                            </div>
                            <Typography className="fs-14 opacity-50">
                                {` Posted ${onlyDateFormat(post?.published_date, false, true, true)}`}
                            </Typography>
                        </div>
                    </div>
                    <Box>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="content-title text-break"
                        >
                            {post?.title}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            className="fs-14 content-desc text-break"
                        >
                            {post?.content}
                        </Typography>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        );
    };

    const CustomPopupForAck = ({ post, onClose }) => {
        return (
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={true}
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle
                    sx={{ m: 0, p: 2 }}
                    id="customized-dialog-title"
                    className="highlight-model-title-div"
                >
                    <Avatar
                        aria-label="recipe"
                        className="author-avtar-creator"
                        src={post?.profile}
                    >
                        {post?.created_by?.charAt(0)}
                    </Avatar>
                    <Typography className="text-center">
                        <span>
                            <b>{post?.created_by}</b> posted a{" "}
                            <b>{post?.type}</b>
                        </span>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers className="no-border">
                    <CardMedia sx={{ height: 222 }} image={post?.image} />
                    <div className="main-content-div-1 mt-3">
                        <div className="main-div-posted">
                            <div className="icon-bd-div">
                                <FontAwesomeIcon icon={faClock} />
                            </div>
                            <Typography className="fs-14 opacity-50">
                                {` Posted ${onlyDateFormat(post?.published_date, false, true, true)}`}
                            </Typography>
                        </div>
                    </div>
                    <Box>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="content-title text-break"
                        >
                            {post?.title}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            className="fs-14 content-desc text-break"
                        >
                            {post?.content}
                        </Typography>
                    </Box>
                    <Box
                        className={`custom-checkbox mt-3 ${post?.acknowledged_on !== "" && "active-checkbox"
                            }`}
                    >
                        <label className="checkbox-style">
                            {post?.acknowledged_on !== ""
                                ? `You acknowledged this on ${onlyDateFormat(post?.acknowledged_on, true, false, true)}`
                                : ((post?.acknowledge_data && (typeof post?.acknowledge_data === "string")) ? `${post?.acknowledge_data}` : `This requires your acknowledgement`)}
                            <input
                                type="checkbox"
                                checked={post?.acknowledged_on !== ""}
                                onChange={() => {
                                    if (post?.acknowledge_type === "question") {
                                        setPopupAckData(post);

                                        setAckQuestions(post?.acknowledge_data);
                                        setPostIdForAckQueForce(post?.id);
                                        onClose();
                                        setOpenAckQuePopupForce(true);
                                    } else if (
                                        post?.acknowledge_type === "checkbox"
                                    ) {
                                        handleCheckboxChangeForce(post?.id);
                                    }
                                }}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        );
    };

    const callAPI = async (endpoint, intervalName) => {
        try {
            const userToken = localStorage.getItem("accessToken");
            const response = await api.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            localStorage.setItem(
                `lastCallTime_${intervalName}`,
                new Date().toISOString()
            );
            setModelPosts(response?.response?.data);
            setLoading(false);
        } catch (err) {
            console.log(`error in fetch news api:: ${intervalName}`, err);
            if (err?.response?.status === 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            setLoading(false);
        }
    };

    const handleAcknowledgementPopup = async () => {
        try {
            // setLoading(true);
            const userToken = localStorage.getItem("accessToken");
            const response = await api.get(`user/acknowledge/popup`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            setModelPostsForAck(response?.response);
            setLoading(false);
        } catch (err) {
            console.log("err`or in fetch news api::", err);
            if (err?.response?.status == 401) {
                setLoading(false);
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            }
            setLoading(false);
        }
    };

    const handleHighlightModelEveryMinute = () =>
        callAPI("user/highlight-post/min", "minute");
    const handleHighlightModelEveryHour = () =>
        callAPI("user/highlight-post/hour", "hour");
    const handleHighlightModelEveryDay = () =>
        callAPI("user/highlight-post/day", "day");
    const handleHighlightModelEveryWeek = () =>
        callAPI("user/highlight-post/week", "week");

    const startTimers = () => {
        const loginTime = new Date(localStorage.getItem("loginTime"));

        const minuteInterval = setInterval(() => {
            const lastCallTime = new Date(
                localStorage.getItem("lastCallTime_minute") || loginTime
            );
            if (new Date() - lastCallTime >= 60000) {
                handleHighlightModelEveryMinute();
            }
        }, 60000);

        const hourInterval = setInterval(() => {
            const lastCallTime = new Date(
                localStorage.getItem("lastCallTime_hour") || loginTime
            );
            if (new Date() - lastCallTime >= 3600000) {
                handleHighlightModelEveryHour();
            }
        }, 3600000);

        const dayInterval = setInterval(() => {
            const lastCallTime = new Date(
                localStorage.getItem("lastCallTime_day") || loginTime
            );
            if (new Date() - lastCallTime >= 86400000) {
                handleHighlightModelEveryDay();
            }
        }, 86400000);

        const weekInterval = setInterval(() => {
            const lastCallTime = new Date(
                localStorage.getItem("lastCallTime_week") || loginTime
            );
            if (new Date() - lastCallTime >= 604800000) {
                handleHighlightModelEveryWeek();
            }
        }, 604800000);

        return () => {
            clearInterval(minuteInterval);
            clearInterval(hourInterval);
            clearInterval(dayInterval);
            clearInterval(weekInterval);
        };
    };

    useEffect(() => {
        if (
            currentPostIndex !== null &&
            currentPostIndex < modelPosts?.length
        ) {
            setIsPopupOpen(true);
        }
    }, [currentPostIndex]);

    useEffect(() => {
        if (modelPosts?.length > 0) {
            setCurrentPostIndex(0);
        }
    }, [modelPosts]);

    useEffect(() => {
        if (
            currentPostIndexForAck !== null &&
            currentPostIndexForAck < modelPostsForAck?.length
        ) {
            setIsPopupOpenForAck(true);
        }
    }, [currentPostIndexForAck]);

    useEffect(() => {
        if (modelPostsForAck?.length > 0) {
            setCurrentPostIndexForAck(0);
        }
    }, [modelPostsForAck]);

    useEffect(() => {
        setLoading(true);
        fetchAllTypePost();
        fetchElementList();
        handleAcknowledgementPopup();

        if (location?.pathname === "/dashboard") {
            const clearTimers = startTimers();
            return () => clearTimers();
        }
    }, [location.pathname]);

    const setElementsCallback = useCallback(
        (el) => {
            if (el && !elements.includes(el)) {
                setElements((prev) => [...prev, el]);
            }
        },
        [elements]
    );

    // useEffect(() => {
    //   const observer = new IntersectionObserver(
    //     (entries) => {
    //       entries.forEach((entry) => {
    //         if (entry.isIntersecting) {
    //           const postId = entry.target.getAttribute('data-post-id');
    //           const postSeen = entry.target.getAttribute('data-post-view') === 'true';

    //           if (!postSeen) {
    //             setSeenPosts((prevSeenPosts) => new Set([...prevSeenPosts, postId]));
    //           }
    //         }
    //       });
    //     },
    //     {
    //       threshold: 0.5, // Adjust the threshold as needed
    //     }
    //   );

    //   elements.forEach((element) => observer.observe(element));

    //   return () => {
    //     elements.forEach((element) => observer.unobserve(element));
    //   };
    // }, [elements]);

    // useEffect(() => {
    //   console.log('Seen Posts:', seenPosts);
    // }, [seenPosts]);

    return (
        <>
            <div className="bg-img-dashboard"></div>
            <div
                className={`main-content-wrapper-3 ${open == true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"
                        }`}
                >
                    <Row className="mt-1">
                        <Col lg="8" md="7">
                            <Box className="serach-box w-100">
                                <InputGroup className="">
                                    <Form.Control
                                        placeholder="Post an update"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        className="search-input w-100"
                                        onClick={handleClickOpen}
                                    />
                                    <Button className="search-btn-dash-2">
                                        <FontAwesomeIcon icon={faTelegram} />
                                    </Button>
                                </InputGroup>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    marginTop: "20px",
                                    marginBottom: "10px",
                                }}
                            >
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                    className="box-attribute"
                                >
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                        // variant={isTablet && "scrollable"}
                                        variant="scrollable"
                                        className={"tabs"}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#660C60",
                                            },
                                        }}
                                    >
                                        <Tab
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={faList}
                                                />
                                            }
                                            label="All Posts"
                                            iconPosition="start"
                                            {...a11yProps1(0)}
                                        />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={value} index={0}>
                                    <Paper
                                        sx={{ width: "100%" }}
                                        className="type-list-paper"
                                    >
                                        {unseenFeedPosts?.length > 0 && (
                                            <>
                                                {unseenFeedPosts?.map(
                                                    (post, index) => (
                                                        <>
                                                            {post?.type ==
                                                                "Update" ? (
                                                                <Card
                                                                    className="mt-4"
                                                                    data-post-id={
                                                                        post?.id
                                                                    }
                                                                    ref={
                                                                        setElementsCallback
                                                                    }
                                                                    data-post-view={
                                                                        post?.is_post_seen
                                                                    }
                                                                >
                                                                    <CardHeader
                                                                        avatar={
                                                                            <Avatar
                                                                                aria-label="recipe"
                                                                                className="author-avtar-creator"
                                                                                src={
                                                                                    post?.profile
                                                                                }
                                                                            >
                                                                                {post?.created_by?.charAt(
                                                                                    0
                                                                                )}
                                                                            </Avatar>
                                                                        }
                                                                        title={
                                                                            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                                                                <span>
                                                                                    <b>{post?.created_by}</b>{" "}posted a{" "}<b>{post?.type}</b>
                                                                                </span>
                                                                                <Typography variant="caption">
                                                                                    {dateWithTime(post?.published_date_time, false, false, false)}
                                                                                </Typography>
                                                                            </Box>
                                                                        }
                                                                        className="card-header-content"
                                                                    />
                                                                    {post?.image !==
                                                                        "" && (
                                                                            <CardMedia
                                                                                sx={{
                                                                                    height: 222,
                                                                                }}
                                                                                image={
                                                                                    post?.image
                                                                                }
                                                                            />
                                                                        )}

                                                                    <CardContent className="content-div-body">
                                                                        <Box>
                                                                            <Typography
                                                                                variant="h3"
                                                                                component="h3"
                                                                                className="content-title-update text-break"
                                                                            >
                                                                                {
                                                                                    post?.content
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                        {post?.is_require_acknowledge ==
                                                                            true && (
                                                                                <Box
                                                                                    className={`custom-checkbox mt-3 ${post?.acknowledged_on !==
                                                                                        "" &&
                                                                                        "active-checkbox"
                                                                                        }`}
                                                                                >
                                                                                    <label className="checkbox-style">
                                                                                        {post?.acknowledged_on !==
                                                                                            ""
                                                                                            ? `You acknowledged this on ${onlyDateFormat(post?.acknowledged_on, true, false, true)}`
                                                                                            : ((post?.acknowledge_data && (typeof post?.acknowledge_data === "string")) ? `${post?.acknowledge_data}` : `This requires your acknowledgement`)}
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                                post?.acknowledged_on !==
                                                                                                ""
                                                                                            }
                                                                                            onChange={() => {
                                                                                                if (
                                                                                                    post?.acknowledge_type ===
                                                                                                    "question"
                                                                                                ) {
                                                                                                    setAckQuestions(
                                                                                                        post?.acknowledge_data
                                                                                                    );
                                                                                                    setPostIdForAckQue(
                                                                                                        post?.id
                                                                                                    );
                                                                                                    setOpenAckQuePopup(
                                                                                                        true
                                                                                                    );
                                                                                                } else if (
                                                                                                    post?.acknowledge_type ===
                                                                                                    "checkbox"
                                                                                                ) {
                                                                                                    handleCheckboxChange(
                                                                                                        post?.id
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            disabled={
                                                                                                post?.acknowledged_on !==
                                                                                                ""
                                                                                            }
                                                                                        />
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                </Box>
                                                                            )}
                                                                    </CardContent>
                                                                    {post?.is_post_comment ===
                                                                        true ||
                                                                        post?.is_post_like ===
                                                                        true ? (
                                                                        <>
                                                                            <CardActions
                                                                                className="bg-white"
                                                                                sx={{
                                                                                    border: "1px solid #EEEEEE",
                                                                                    padding:
                                                                                        "10px",
                                                                                }}
                                                                                onMouseEnter={() => {
                                                                                    handlePopoverClose();
                                                                                    handlePopoverCloseForReplyLike();
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    handlePopoverClose();
                                                                                    handlePopoverCloseForReplyLike();
                                                                                }}
                                                                            >
                                                                                {post?.is_post_like ==
                                                                                    true && (
                                                                                        <Box className="action-like w-100 d-flex flex-wrap align-items-center gap-2 cursor-pointer"
                                                                                            onClick={() => { handleEngagementModalOpen(post?.id) }}
                                                                                        >
                                                                                            {post?.reactions?.map(
                                                                                                (
                                                                                                    reaction
                                                                                                ) => (
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={
                                                                                                            reaction ===
                                                                                                                "love"
                                                                                                                ? faHeart
                                                                                                                : reaction ===
                                                                                                                    "smile"
                                                                                                                    ? faSmile
                                                                                                                    : reaction ===
                                                                                                                        "angry"
                                                                                                                        ? faAngry
                                                                                                                        : reaction ===
                                                                                                                            "like"
                                                                                                                            ? faThumbsUp
                                                                                                                            : ""
                                                                                                        }
                                                                                                        className={
                                                                                                            reaction ===
                                                                                                                "love"
                                                                                                                ? "fa-love-btn"
                                                                                                                : reaction ===
                                                                                                                    "smile"
                                                                                                                    ? "fa-smile-btn"
                                                                                                                    : reaction ===
                                                                                                                        "angry"
                                                                                                                        ? "fa-angry-btn"
                                                                                                                        : reaction ===
                                                                                                                            "like"
                                                                                                                            ? "fa-like-btn"
                                                                                                                            : ""
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            )}

                                                                                            <Box className="like-counts-box">
                                                                                                <Typography className="fs-12-action-btn">
                                                                                                    {
                                                                                                        post?.like_count
                                                                                                    }
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    )}
                                                                                {post?.is_post_comment ==
                                                                                    true && (
                                                                                        <Box className="w-100 ms-0">
                                                                                            <Typography className="fs-12-action-btn d-flex  justify-content-end">
                                                                                                {`${post?.comment_count} comments`}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                            </CardActions>
                                                                            <CardActions
                                                                                className="bg-white"
                                                                                sx={{
                                                                                    border: "1px solid #EEEEEE",
                                                                                    padding:
                                                                                        "10px",
                                                                                }}
                                                                            >
                                                                                {post?.is_post_like ==
                                                                                    true && (
                                                                                        <Box
                                                                                            className="action-like w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                            onClick={() => {
                                                                                                toggleReaction(
                                                                                                    post?.id,
                                                                                                    post?.user_has_liked ===
                                                                                                        "no"
                                                                                                        ? "like"
                                                                                                        : "no",
                                                                                                    post?.user_has_liked ===
                                                                                                        "no"
                                                                                                        ? true
                                                                                                        : false
                                                                                                );
                                                                                                handlePopoverClose();
                                                                                            }}
                                                                                            onMouseEnter={(
                                                                                                e
                                                                                            ) =>
                                                                                                handlePopoverOpen(
                                                                                                    e,
                                                                                                    post.id
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    post?.reaction ===
                                                                                                        "like"
                                                                                                        ? faThumbsUp
                                                                                                        : post?.reaction ===
                                                                                                            "love"
                                                                                                            ? faHeart
                                                                                                            : post?.reaction ===
                                                                                                                "smile"
                                                                                                                ? faSmile
                                                                                                                : post?.reaction ===
                                                                                                                    "angry"
                                                                                                                    ? faAngry
                                                                                                                    : faThumbsUp
                                                                                                }
                                                                                                className={`fs-12-action-btn ${post?.reaction ===
                                                                                                    "like"
                                                                                                    ? "liked-post-text"
                                                                                                    : post?.reaction ===
                                                                                                        "love"
                                                                                                        ? "love-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "smile"
                                                                                                            ? "smile-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "angry"
                                                                                                                ? "angry-post-text"
                                                                                                                : ""
                                                                                                    }`}
                                                                                            />
                                                                                            <Typography
                                                                                                className={`fs-12-action-btn text-capitalize ${post?.reaction ===
                                                                                                    "like"
                                                                                                    ? "liked-post-text"
                                                                                                    : post?.reaction ===
                                                                                                        "love"
                                                                                                        ? "love-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "smile"
                                                                                                            ? "smile-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "angry"
                                                                                                                ? "angry-post-text"
                                                                                                                : ""
                                                                                                    }`}
                                                                                            >
                                                                                                {post?.user_has_liked !==
                                                                                                    "no"
                                                                                                    ? post.reaction
                                                                                                    : "Like"}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                                {post?.is_post_comment ==
                                                                                    true && (
                                                                                        <Box
                                                                                            className="action-comments w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                            onClick={() =>
                                                                                                handleCommentToggle(
                                                                                                    post?.id
                                                                                                )
                                                                                            }
                                                                                            onMouseEnter={() => {
                                                                                                handlePopoverClose();
                                                                                                handlePopoverCloseForReplyLike();
                                                                                            }}
                                                                                            onMouseLeave={() => {
                                                                                                handlePopoverClose();
                                                                                                handlePopoverCloseForReplyLike();
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    faComments
                                                                                                }
                                                                                                className="fs-12-action-btn"
                                                                                            />
                                                                                            <Typography className="fs-12-action-btn">
                                                                                                Comments
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                            </CardActions>
                                                                            {post?.is_post_comment ==
                                                                                true && (
                                                                                    <CardActions
                                                                                        className="bg-white card-action-2"
                                                                                        sx={{
                                                                                            border: "1px solid #EEEEEE",
                                                                                            padding:
                                                                                                "15px",
                                                                                        }}
                                                                                        onMouseEnter={() => {
                                                                                            handlePopoverClose();
                                                                                        }}
                                                                                        onMouseLeave={() => {
                                                                                            handlePopoverClose();
                                                                                        }}
                                                                                    >
                                                                                        {post?.is_post_comment ==
                                                                                            true && (
                                                                                                <>
                                                                                                    {post?.commentToggle ==
                                                                                                        true && (
                                                                                                            <Box
                                                                                                                className="w-100"
                                                                                                                onMouseEnter={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                                onMouseLeave={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                            >
                                                                                                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                    <Avatar
                                                                                                                        alt={
                                                                                                                            loggedUser?.name
                                                                                                                        }
                                                                                                                        className="author-avtar-creator"
                                                                                                                        src={
                                                                                                                            loggedUser?.thumbnail
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {loggedUser?.name?.charAt(
                                                                                                                            0
                                                                                                                        )}
                                                                                                                    </Avatar>
                                                                                                                    <InputGroup
                                                                                                                        className=""
                                                                                                                        style={{
                                                                                                                            height: "32px",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {/* <Form.Control
                                                      placeholder="Add a comment.."
                                                      aria-label="Recipient's username"
                                                      aria-describedby="basic-addon2"
                                                      className="search-input-2 w-100 h-100"
                                                      onChange={(event) =>
                                                        handleCommentText(
                                                          event.target.value,
                                                          post?.id
                                                        )
                                                      }
                                                      onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                          e.preventDefault();
                                                          handleCommentsAPI(
                                                            post?.id,
                                                            "yes"
                                                          );
                                                        }
                                                      }}
                                                    /> */}
                                                                                                                        <MentionsInput
                                                                                                                            placeholder="Add Comment. Use '@' for mention and '&' for emojis"
                                                                                                                            value={
                                                                                                                                post?.commentText
                                                                                                                            }
                                                                                                                            onChange={(
                                                                                                                                event
                                                                                                                            ) =>
                                                                                                                                handleCommentText(
                                                                                                                                    event
                                                                                                                                        .target
                                                                                                                                        .value,
                                                                                                                                    post?.id
                                                                                                                                )
                                                                                                                            }
                                                                                                                            onKeyDown={(
                                                                                                                                e
                                                                                                                            ) => {
                                                                                                                                if (
                                                                                                                                    e.key ===
                                                                                                                                    "Enter"
                                                                                                                                ) {
                                                                                                                                    e.preventDefault();
                                                                                                                                    handleCommentsAPI(
                                                                                                                                        post?.id,
                                                                                                                                        "yes"
                                                                                                                                    );
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            className="input-comments"
                                                                                                                            singleLine={
                                                                                                                                true
                                                                                                                            }
                                                                                                                            style={
                                                                                                                                mentionsInputStyle
                                                                                                                            }
                                                                                                                            a11ySuggestionsListLabel={
                                                                                                                                "Suggested mentions"
                                                                                                                            }
                                                                                                                            onPaste={
                                                                                                                                handlePaste
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <Mention
                                                                                                                                style={
                                                                                                                                    mentionStyle
                                                                                                                                }
                                                                                                                                className="mention-popup-class"
                                                                                                                                onAdd={() =>
                                                                                                                                    setIsSelectingSuggestion(
                                                                                                                                        true
                                                                                                                                    )
                                                                                                                                } // Set the flag when a suggestion is selected
                                                                                                                                data={
                                                                                                                                    getMentionUsers
                                                                                                                                }
                                                                                                                                renderSuggestion={(
                                                                                                                                    suggestion,
                                                                                                                                    search,
                                                                                                                                    highlightedDisplay,
                                                                                                                                    index,
                                                                                                                                    focused
                                                                                                                                ) => (
                                                                                                                                    <div
                                                                                                                                        className="list-class"
                                                                                                                                        key={
                                                                                                                                            index
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <div className="list-class-div-1">
                                                                                                                                            <img
                                                                                                                                                src={
                                                                                                                                                    suggestion.photo
                                                                                                                                                }
                                                                                                                                                alt={
                                                                                                                                                    suggestion.display
                                                                                                                                                }
                                                                                                                                                className="list-class-div-1-img"
                                                                                                                                            />
                                                                                                                                            <div className="list-class-div-2">
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontWeight:
                                                                                                                                                            "bold",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    {
                                                                                                                                                        suggestion?.full_name
                                                                                                                                                    }
                                                                                                                                                </span>
                                                                                                                                                <br />
                                                                                                                                                <span>
                                                                                                                                                    {
                                                                                                                                                        suggestion?.job_title
                                                                                                                                                    }
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                            />
                                                                                                                        </MentionsInput>
                                                                                                                        <Button
                                                                                                                            className="search-btn-dash-2"
                                                                                                                            style={{
                                                                                                                                height: "100%",
                                                                                                                            }}
                                                                                                                            onClick={() =>
                                                                                                                                handleCommentsAPI(
                                                                                                                                    post?.id,
                                                                                                                                    "yes"
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon
                                                                                                                                icon={
                                                                                                                                    faAdd
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </Button>
                                                                                                                    </InputGroup>
                                                                                                                </Box>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                </>
                                                                                            )}
                                                                                        {post
                                                                                            ?.comments
                                                                                            ?.length >
                                                                                            0 && (
                                                                                                <>
                                                                                                    {post?.comments?.map(
                                                                                                        (
                                                                                                            comment
                                                                                                        ) => (
                                                                                                            <>
                                                                                                                {comment?.is_parent ===
                                                                                                                    "yes" && (
                                                                                                                        <>
                                                                                                                            <Box
                                                                                                                                className="w-100 ms-0"
                                                                                                                                onMouseEnter={
                                                                                                                                    handlePopoverClose
                                                                                                                                }
                                                                                                                                onMouseLeave={
                                                                                                                                    handlePopoverClose
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                    <Avatar
                                                                                                                                        alt={
                                                                                                                                            comment
                                                                                                                                                ?.user
                                                                                                                                                ?.name
                                                                                                                                        }
                                                                                                                                        className="author-avtar-creator mb-auto"
                                                                                                                                        src={
                                                                                                                                            comment
                                                                                                                                                ?.user
                                                                                                                                                ?.thumbnail
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        {comment?.user?.name?.charAt(
                                                                                                                                            0
                                                                                                                                        )}
                                                                                                                                    </Avatar>
                                                                                                                                    <Box
                                                                                                                                        sx={{
                                                                                                                                            padding:
                                                                                                                                                "15px",
                                                                                                                                        }}
                                                                                                                                        className="comment-box w-100"
                                                                                                                                    >
                                                                                                                                        <Typography className="fs-14-700">
                                                                                                                                            {
                                                                                                                                                comment
                                                                                                                                                    ?.user
                                                                                                                                                    ?.name
                                                                                                                                            }
                                                                                                                                        </Typography>
                                                                                                                                        <Typography className="fs-14 mt-2">
                                                                                                                                            {renderCommentText(
                                                                                                                                                comment?.comment
                                                                                                                                            )}
                                                                                                                                        </Typography>
                                                                                                                                        <Stack
                                                                                                                                            direction="row"
                                                                                                                                            divider={
                                                                                                                                                <Divider
                                                                                                                                                    orientation="vertical"
                                                                                                                                                    flexItem
                                                                                                                                                    className="nested-divider"
                                                                                                                                                />
                                                                                                                                            }
                                                                                                                                            className="mt-2 align-items-center"
                                                                                                                                            spacing={
                                                                                                                                                1
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <Box>
                                                                                                                                                <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                    <Typography
                                                                                                                                                        className={`fs-10 cursor-pointer  ${comment?.reaction ===
                                                                                                                                                            "like"
                                                                                                                                                            ? "liked-post-text"
                                                                                                                                                            : comment?.reaction ===
                                                                                                                                                                "love"
                                                                                                                                                                ? "love-post-text"
                                                                                                                                                                : comment?.reaction ===
                                                                                                                                                                    "smile"
                                                                                                                                                                    ? "smile-post-text"
                                                                                                                                                                    : comment?.reaction ===
                                                                                                                                                                        "angry"
                                                                                                                                                                        ? "angry-post-text"
                                                                                                                                                                        : ""
                                                                                                                                                            }`}
                                                                                                                                                        onClick={() => {
                                                                                                                                                            toggleLikeForCommentsReaction(
                                                                                                                                                                post?.id,
                                                                                                                                                                comment?.has_user_liked_comment ===
                                                                                                                                                                    "no"
                                                                                                                                                                    ? "like"
                                                                                                                                                                    : "no",
                                                                                                                                                                comment?.id,
                                                                                                                                                                comment?.has_user_liked_comment ===
                                                                                                                                                                    "no"
                                                                                                                                                                    ? true
                                                                                                                                                                    : false
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                        onMouseEnter={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            setCurrentPostId(
                                                                                                                                                                post?.id
                                                                                                                                                            );

                                                                                                                                                            setCurrentPostIdForReply(
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                            handlePopoverOpenForReplyLike(
                                                                                                                                                                e,
                                                                                                                                                                post.id,
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                        onMouseLeave={() => {
                                                                                                                                                            setCurrentPostId(
                                                                                                                                                                post?.id
                                                                                                                                                            );

                                                                                                                                                            setCurrentPostIdForReply(
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        {comment?.has_user_liked_comment !==
                                                                                                                                                            "no"
                                                                                                                                                            ? comment.reaction
                                                                                                                                                            : "Like"}
                                                                                                                                                    </Typography>
                                                                                                                                                    <Box
                                                                                                                                                        className="action-like w-100 d-flex flex-wrap align-items-center gap-1 cursor-pointer"
                                                                                                                                                        onMouseEnter={() => {
                                                                                                                                                            handlePopoverClose();
                                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                                        }}
                                                                                                                                                        onMouseLeave={() => {
                                                                                                                                                            handlePopoverClose();
                                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                                        }}
                                                                                                                                                        onClick={() => { handleEngagementModalOpen(comment?.id, 'comment') }}
                                                                                                                                                    >

                                                                                                                                                        {comment?.user_reactions?.map(
                                                                                                                                                            (
                                                                                                                                                                reaction
                                                                                                                                                            ) => (
                                                                                                                                                                <FontAwesomeIcon
                                                                                                                                                                    icon={
                                                                                                                                                                        reaction ===
                                                                                                                                                                            "love"
                                                                                                                                                                            ? faHeart
                                                                                                                                                                            : reaction ===
                                                                                                                                                                                "smile"
                                                                                                                                                                                ? faSmile
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "angry"
                                                                                                                                                                                    ? faAngry
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "like"
                                                                                                                                                                                        ? faThumbsUp
                                                                                                                                                                                        : ""
                                                                                                                                                                    }
                                                                                                                                                                    className={
                                                                                                                                                                        reaction ===
                                                                                                                                                                            "love"
                                                                                                                                                                            ? "fa-love-btn-2"
                                                                                                                                                                            : reaction ===
                                                                                                                                                                                "smile"
                                                                                                                                                                                ? "fa-smile-btn-2"
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "angry"
                                                                                                                                                                                    ? "fa-angry-btn-2"
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "like"
                                                                                                                                                                                        ? "fa-like-btn-2"
                                                                                                                                                                                        : ""
                                                                                                                                                                    }
                                                                                                                                                                />
                                                                                                                                                            )
                                                                                                                                                        )}

                                                                                                                                                        <Box className="like-counts-box">
                                                                                                                                                            <Typography className="fs-10">
                                                                                                                                                                {
                                                                                                                                                                    comment?.comment_likes_count
                                                                                                                                                                }
                                                                                                                                                            </Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                            <Box
                                                                                                                                                onMouseEnter={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                                onMouseLeave={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Box>
                                                                                                                                                    <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                        <Typography
                                                                                                                                                            className="fs-10 cursor-pointer"
                                                                                                                                                            onClick={() =>
                                                                                                                                                                handleCommentReplyToggle(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    comment?.id
                                                                                                                                                                )
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            Reply
                                                                                                                                                        </Typography>
                                                                                                                                                        <Typography className="fs-10 cursor-pointer">
                                                                                                                                                            {`${comment?.replies_count} reply`}
                                                                                                                                                        </Typography>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                        </Stack>
                                                                                                                                    </Box>
                                                                                                                                </Box>
                                                                                                                            </Box>
                                                                                                                            {comment?.commentReplyToggle ==
                                                                                                                                true && (
                                                                                                                                    <Box
                                                                                                                                        className="w-100 ms-0"
                                                                                                                                        sx={{
                                                                                                                                            paddingLeft:
                                                                                                                                                "62px",
                                                                                                                                        }}
                                                                                                                                        onMouseEnter={() => {
                                                                                                                                            handlePopoverClose();
                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                        }}
                                                                                                                                        onMouseLeave={() => {
                                                                                                                                            handlePopoverClose();
                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <Box className="w-100 mt-2 mb-1">
                                                                                                                                            <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                <Avatar
                                                                                                                                                    alt={
                                                                                                                                                        loggedUser?.name
                                                                                                                                                    }
                                                                                                                                                    className="author-avtar-creator-2"
                                                                                                                                                    src={
                                                                                                                                                        loggedUser?.thumbnail
                                                                                                                                                    }
                                                                                                                                                >
                                                                                                                                                    {loggedUser?.name?.charAt(
                                                                                                                                                        0
                                                                                                                                                    )}
                                                                                                                                                </Avatar>
                                                                                                                                                <InputGroup
                                                                                                                                                    className=""
                                                                                                                                                    style={{
                                                                                                                                                        height: "25px",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <MentionsInput
                                                                                                                                                        placeholder="Add Comment. Use '@' for mention"
                                                                                                                                                        value={
                                                                                                                                                            comment?.commentReplyText
                                                                                                                                                        }
                                                                                                                                                        singleLine={
                                                                                                                                                            true
                                                                                                                                                        }
                                                                                                                                                        onChange={(
                                                                                                                                                            event
                                                                                                                                                        ) => {
                                                                                                                                                            console.log(
                                                                                                                                                                "event:::",
                                                                                                                                                                event
                                                                                                                                                            );
                                                                                                                                                            handleCommentReplyText(
                                                                                                                                                                event
                                                                                                                                                                    .target
                                                                                                                                                                    .value,
                                                                                                                                                                post?.id,
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                        onKeyDown={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            if (
                                                                                                                                                                e.key ===
                                                                                                                                                                "Enter"
                                                                                                                                                            ) {
                                                                                                                                                                e.preventDefault();
                                                                                                                                                                handleCommentsReplyAPI(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    "no",
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }
                                                                                                                                                        }}
                                                                                                                                                        className="input-comments"
                                                                                                                                                        style={
                                                                                                                                                            mentionsInputStyle
                                                                                                                                                        }
                                                                                                                                                        a11ySuggestionsListLabel={
                                                                                                                                                            "Suggested mentions"
                                                                                                                                                        }
                                                                                                                                                        onPaste={
                                                                                                                                                            handlePaste
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        <Mention
                                                                                                                                                            style={
                                                                                                                                                                mentionStyle
                                                                                                                                                            }
                                                                                                                                                            className="mention-popup-class"
                                                                                                                                                            data={
                                                                                                                                                                getMentionUsers
                                                                                                                                                            }
                                                                                                                                                            onAdd={() =>
                                                                                                                                                                setIsSelectingSuggestion(
                                                                                                                                                                    true
                                                                                                                                                                )
                                                                                                                                                            } // Set the flag when a suggestion is selected
                                                                                                                                                            appendSpaceOnAdd={
                                                                                                                                                                true
                                                                                                                                                            }
                                                                                                                                                            renderSuggestion={(
                                                                                                                                                                suggestion,
                                                                                                                                                                search,
                                                                                                                                                                highlightedDisplay,
                                                                                                                                                                index,
                                                                                                                                                                focused
                                                                                                                                                            ) => (
                                                                                                                                                                <div
                                                                                                                                                                    className="list-class"
                                                                                                                                                                    key={
                                                                                                                                                                        index
                                                                                                                                                                    }
                                                                                                                                                                >
                                                                                                                                                                    <div className="list-class-div-1">
                                                                                                                                                                        <img
                                                                                                                                                                            src={
                                                                                                                                                                                suggestion.photo
                                                                                                                                                                            }
                                                                                                                                                                            alt={
                                                                                                                                                                                suggestion.display
                                                                                                                                                                            }
                                                                                                                                                                            className="list-class-div-1-img"
                                                                                                                                                                        />
                                                                                                                                                                        <div className="list-class-div-2">
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    fontWeight:
                                                                                                                                                                                        "bold",
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                {
                                                                                                                                                                                    suggestion?.full_name
                                                                                                                                                                                }
                                                                                                                                                                            </span>
                                                                                                                                                                            <br />
                                                                                                                                                                            <span>
                                                                                                                                                                                {
                                                                                                                                                                                    suggestion?.job_title
                                                                                                                                                                                }
                                                                                                                                                                            </span>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            )}
                                                                                                                                                        />
                                                                                                                                                    </MentionsInput>
                                                                                                                                                    <Button
                                                                                                                                                        className="search-btn-dash-3"
                                                                                                                                                        style={{
                                                                                                                                                            height: "100%",
                                                                                                                                                        }}
                                                                                                                                                        onClick={() =>
                                                                                                                                                            handleCommentsReplyAPI(
                                                                                                                                                                post?.id,
                                                                                                                                                                "no",
                                                                                                                                                                comment?.id
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        <FontAwesomeIcon
                                                                                                                                                            icon={
                                                                                                                                                                faAdd
                                                                                                                                                            }
                                                                                                                                                        />
                                                                                                                                                    </Button>
                                                                                                                                                </InputGroup>
                                                                                                                                            </Box>
                                                                                                                                        </Box>
                                                                                                                                    </Box>
                                                                                                                                )}
                                                                                                                            {comment
                                                                                                                                ?.replies
                                                                                                                                ?.length >
                                                                                                                                0 ? (
                                                                                                                                <>
                                                                                                                                    {comment?.replies?.map(
                                                                                                                                        (
                                                                                                                                            reply
                                                                                                                                        ) => (
                                                                                                                                            <Box
                                                                                                                                                className="w-100 ms-0"
                                                                                                                                                sx={{
                                                                                                                                                    paddingLeft:
                                                                                                                                                        "62px",
                                                                                                                                                }}
                                                                                                                                                onMouseEnter={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                                onMouseLeave={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Box className="w-100 mt-2">
                                                                                                                                                    <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                        <Avatar
                                                                                                                                                            alt={
                                                                                                                                                                reply
                                                                                                                                                                    ?.user
                                                                                                                                                                    ?.name
                                                                                                                                                            }
                                                                                                                                                            className="author-avtar-creator-2 mb-auto"
                                                                                                                                                            src={
                                                                                                                                                                reply
                                                                                                                                                                    ?.user
                                                                                                                                                                    ?.thumbnail
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            {reply?.user?.name?.charAt(
                                                                                                                                                                0
                                                                                                                                                            )}
                                                                                                                                                        </Avatar>
                                                                                                                                                        <Box
                                                                                                                                                            sx={{
                                                                                                                                                                padding:
                                                                                                                                                                    "10px",
                                                                                                                                                            }}
                                                                                                                                                            className="comment-box w-100"
                                                                                                                                                        >
                                                                                                                                                            <Typography className="fs-12-700">
                                                                                                                                                                {
                                                                                                                                                                    reply
                                                                                                                                                                        ?.user
                                                                                                                                                                        ?.name
                                                                                                                                                                }
                                                                                                                                                            </Typography>
                                                                                                                                                            <Typography className="fs-12 mt-1">
                                                                                                                                                                {renderCommentText(
                                                                                                                                                                    reply?.comment
                                                                                                                                                                )}
                                                                                                                                                            </Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                        )
                                                                                                                                    )}
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                ""
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                            </>
                                                                                                        )
                                                                                                    )}
                                                                                                    {post
                                                                                                        ?.comments
                                                                                                        ?.length <
                                                                                                        post?.parent_comment_count && (
                                                                                                            <Box
                                                                                                                className="w-100 ms-0 d-flex justify-content-center"
                                                                                                                onMouseEnter={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                                onMouseLeave={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                            >
                                                                                                                <Button
                                                                                                                    className="w-50 mt-4 btn-load-comments"
                                                                                                                    onClick={() => {
                                                                                                                        setFetchAll(
                                                                                                                            true
                                                                                                                        );
                                                                                                                        fetchLoadMoreCommentsAPI(
                                                                                                                            post
                                                                                                                        );
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <FontAwesomeIcon
                                                                                                                        icon={
                                                                                                                            faComments
                                                                                                                        }
                                                                                                                    />
                                                                                                                    Load
                                                                                                                    More
                                                                                                                    Comments
                                                                                                                </Button>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                </>
                                                                                            )}
                                                                                    </CardActions>
                                                                                )}
                                                                        </>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Card>
                                                            ) : (
                                                                <>
                                                                    <Card
                                                                        className="mt-4"
                                                                        data-post-id={
                                                                            post?.id
                                                                        }
                                                                        data-post-view={
                                                                            post?.is_post_seen
                                                                        }
                                                                        ref={
                                                                            setElementsCallback
                                                                        }
                                                                    >
                                                                        <CardHeader
                                                                            avatar={
                                                                                <Avatar
                                                                                    aria-label="recipe"
                                                                                    className="author-avtar-creator"
                                                                                    src={
                                                                                        post?.profile
                                                                                    }
                                                                                >
                                                                                    {post?.created_by?.charAt(
                                                                                        0
                                                                                    )}
                                                                                </Avatar>
                                                                            }
                                                                            title={
                                                                                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                                                                    <span>
                                                                                        <b>{post?.created_by}</b>{" "}posted a{" "}<b>{post?.type}</b>
                                                                                    </span>
                                                                                    <Typography variant="caption">
                                                                                        {dateWithTime(post?.published_date_time, false, false, false)}
                                                                                    </Typography>
                                                                                </Box>
                                                                            }
                                                                            className="card-header-content"
                                                                        />
                                                                        <CardMedia
                                                                            sx={{
                                                                                height: 222,
                                                                            }}
                                                                            image={
                                                                                post?.image
                                                                            }
                                                                            className="cursor-pointer"
                                                                            onClick={() => {
                                                                                if (
                                                                                    loggedUser?.user_type ===
                                                                                    "user"
                                                                                ) {
                                                                                    localStorage.setItem(
                                                                                        "last-redirect-page-user",
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                    navigate(
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                } else if (
                                                                                    loggedUser?.user_type ===
                                                                                    "admin" &&
                                                                                    adminAccessible ==
                                                                                    true
                                                                                ) {
                                                                                    localStorage.setItem(
                                                                                        "last-redirect-page-admin-user",
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                    navigate(
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                        <CardContent className="content-div-body">
                                                                            <div className="main-content-div-1">
                                                                                <div className="main-div-posted">
                                                                                    <div className="icon-bd-div">
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                faClock
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <Typography className="fs-14 opacity-50">
                                                                                        {` Posted ${onlyDateFormat(post?.published_date_time, false, true, true)}`}
                                                                                    </Typography>
                                                                                </div>
                                                                                {post
                                                                                    ?.category
                                                                                    ?.length >
                                                                                    0 && (
                                                                                        <div className="main-div-workspace">
                                                                                            <div className="icon-bd-div">
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        faBriefcase
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <Typography className="fs-14 opacity-50">
                                                                                                {post?.category?.join(
                                                                                                    ", "
                                                                                                )}
                                                                                            </Typography>
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                            <Box>
                                                                                <Typography
                                                                                    gutterBottom
                                                                                    variant="h5"
                                                                                    component="div"
                                                                                    className="content-title text-break"
                                                                                >
                                                                                    {
                                                                                        post?.title
                                                                                    }
                                                                                </Typography>
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.secondary"
                                                                                    className="fs-14 content-desc text-break"
                                                                                >
                                                                                    {
                                                                                        post?.content
                                                                                    }
                                                                                </Typography>
                                                                            </Box>
                                                                            {post?.is_require_acknowledge ==
                                                                                true && (
                                                                                    <Box
                                                                                        className={`custom-checkbox mt-3 ${post?.acknowledged_on !==
                                                                                            "" &&
                                                                                            "active-checkbox"
                                                                                            }`}
                                                                                    >
                                                                                        <label className="checkbox-style">
                                                                                            {post?.acknowledged_on !==
                                                                                                ""
                                                                                                ? `You acknowledged this on ${onlyDateFormat(post?.acknowledged_on, true, false, true)}`
                                                                                                : ((post?.acknowledge_data && (typeof post?.acknowledge_data === "string")) ? `${post?.acknowledge_data}` : `This requires your acknowledgement`)}
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={
                                                                                                    post?.acknowledged_on !==
                                                                                                    ""
                                                                                                }
                                                                                                onChange={() => {
                                                                                                    if (
                                                                                                        post?.acknowledge_type ===
                                                                                                        "question"
                                                                                                    ) {
                                                                                                        setAckQuestions(
                                                                                                            post?.acknowledge_data
                                                                                                        );
                                                                                                        setPostIdForAckQue(
                                                                                                            post?.id
                                                                                                        );
                                                                                                        setOpenAckQuePopup(
                                                                                                            true
                                                                                                        );
                                                                                                    } else if (
                                                                                                        post?.acknowledge_type ===
                                                                                                        "checkbox"
                                                                                                    ) {
                                                                                                        handleCheckboxChange(
                                                                                                            post?.id
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                                disabled={
                                                                                                    post?.acknowledged_on !==
                                                                                                    ""
                                                                                                }
                                                                                            />
                                                                                            <span className="checkmark"></span>
                                                                                        </label>
                                                                                    </Box>
                                                                                )}
                                                                            <Button
                                                                                className="btn primary-btn mt-4"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        loggedUser?.user_type ===
                                                                                        "user"
                                                                                    ) {
                                                                                        localStorage.setItem(
                                                                                            "last-redirect-page-user",
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                        navigate(
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                    } else if (
                                                                                        loggedUser?.user_type ===
                                                                                        "admin" &&
                                                                                        adminAccessible ==
                                                                                        true
                                                                                    ) {
                                                                                        localStorage.setItem(
                                                                                            "last-redirect-page-admin-user",
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                        navigate(
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                View
                                                                                Article
                                                                                <FontAwesomeIcon
                                                                                    icon={
                                                                                        faArrowRight
                                                                                    }
                                                                                />
                                                                            </Button>
                                                                        </CardContent>
                                                                        {post?.is_post_comment ===
                                                                            true ||
                                                                            post?.is_post_like ===
                                                                            true ? (
                                                                            <>
                                                                                <CardActions
                                                                                    className="bg-white"
                                                                                    sx={{
                                                                                        border: "1px solid #EEEEEE",
                                                                                        padding:
                                                                                            "10px",
                                                                                    }}
                                                                                    onMouseEnter={() => {
                                                                                        handlePopoverClose();
                                                                                        handlePopoverCloseForReplyLike();
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        handlePopoverClose();
                                                                                        handlePopoverCloseForReplyLike();
                                                                                    }}
                                                                                >
                                                                                    {post?.is_post_like ==
                                                                                        true && (
                                                                                            <Box className="action-like w-100 d-flex flex-wrap align-items-center gap-2 cursor-pointer"
                                                                                                onClick={() => { handleEngagementModalOpen(post?.id) }}
                                                                                            >
                                                                                                {post?.reactions?.map(
                                                                                                    (
                                                                                                        reaction
                                                                                                    ) => (
                                                                                                        <FontAwesomeIcon
                                                                                                            icon={
                                                                                                                reaction ===
                                                                                                                    "love"
                                                                                                                    ? faHeart
                                                                                                                    : reaction ===
                                                                                                                        "smile"
                                                                                                                        ? faSmile
                                                                                                                        : reaction ===
                                                                                                                            "angry"
                                                                                                                            ? faAngry
                                                                                                                            : reaction ===
                                                                                                                                "like"
                                                                                                                                ? faThumbsUp
                                                                                                                                : ""
                                                                                                            }
                                                                                                            className={
                                                                                                                reaction ===
                                                                                                                    "love"
                                                                                                                    ? "fa-love-btn"
                                                                                                                    : reaction ===
                                                                                                                        "smile"
                                                                                                                        ? "fa-smile-btn"
                                                                                                                        : reaction ===
                                                                                                                            "angry"
                                                                                                                            ? "fa-angry-btn"
                                                                                                                            : reaction ===
                                                                                                                                "like"
                                                                                                                                ? "fa-like-btn"
                                                                                                                                : ""
                                                                                                            }
                                                                                                        />
                                                                                                    )
                                                                                                )}

                                                                                                <Box className="like-counts-box">
                                                                                                    <Typography className="fs-12-action-btn">
                                                                                                        {
                                                                                                            post?.like_count
                                                                                                        }
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        )}
                                                                                    {post?.is_post_comment ==
                                                                                        true && (
                                                                                            <Box className="w-100 ms-0">
                                                                                                <Typography className="fs-12-action-btn d-flex  justify-content-end">
                                                                                                    {`${post?.comment_count} comments`}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                </CardActions>
                                                                                <CardActions
                                                                                    className="bg-white"
                                                                                    sx={{
                                                                                        border: "1px solid #EEEEEE",
                                                                                        padding:
                                                                                            "10px",
                                                                                    }}
                                                                                >
                                                                                    {post?.is_post_like ==
                                                                                        true && (
                                                                                            <Box
                                                                                                className="action-like w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                                onClick={() => {
                                                                                                    toggleReaction(
                                                                                                        post?.id,
                                                                                                        post?.user_has_liked ===
                                                                                                            "no"
                                                                                                            ? "like"
                                                                                                            : "no",
                                                                                                        post?.user_has_liked ===
                                                                                                            "no"
                                                                                                            ? true
                                                                                                            : false
                                                                                                    );
                                                                                                    handlePopoverClose();
                                                                                                }}
                                                                                                onMouseEnter={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handlePopoverOpen(
                                                                                                        e,
                                                                                                        post.id
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        post?.reaction ===
                                                                                                            "like"
                                                                                                            ? faThumbsUp
                                                                                                            : post?.reaction ===
                                                                                                                "love"
                                                                                                                ? faHeart
                                                                                                                : post?.reaction ===
                                                                                                                    "smile"
                                                                                                                    ? faSmile
                                                                                                                    : post?.reaction ===
                                                                                                                        "angry"
                                                                                                                        ? faAngry
                                                                                                                        : faThumbsUp
                                                                                                    }
                                                                                                    className={`fs-12-action-btn ${post?.reaction ===
                                                                                                        "like"
                                                                                                        ? "liked-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "love"
                                                                                                            ? "love-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "smile"
                                                                                                                ? "smile-post-text"
                                                                                                                : post?.reaction ===
                                                                                                                    "angry"
                                                                                                                    ? "angry-post-text"
                                                                                                                    : ""
                                                                                                        }`}
                                                                                                />
                                                                                                <Typography
                                                                                                    className={`fs-12-action-btn text-capitalize ${post?.reaction ===
                                                                                                        "like"
                                                                                                        ? "liked-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "love"
                                                                                                            ? "love-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "smile"
                                                                                                                ? "smile-post-text"
                                                                                                                : post?.reaction ===
                                                                                                                    "angry"
                                                                                                                    ? "angry-post-text"
                                                                                                                    : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {post?.user_has_liked !==
                                                                                                        "no"
                                                                                                        ? post.reaction
                                                                                                        : "Like"}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                    {post?.is_post_comment ==
                                                                                        true && (
                                                                                            <Box
                                                                                                className="action-comments w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                                onClick={() =>
                                                                                                    handleCommentToggle(
                                                                                                        post?.id
                                                                                                    )
                                                                                                }
                                                                                                onMouseEnter={() => {
                                                                                                    handlePopoverClose();
                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                }}
                                                                                                onMouseLeave={() => {
                                                                                                    handlePopoverClose();
                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                }}
                                                                                            >
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        faComments
                                                                                                    }
                                                                                                    className="fs-12-action-btn"
                                                                                                />
                                                                                                <Typography className="fs-12-action-btn">
                                                                                                    Comments
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                </CardActions>
                                                                                {post?.is_post_comment ==
                                                                                    true && (
                                                                                        <CardActions
                                                                                            className="bg-white card-action-2"
                                                                                            sx={{
                                                                                                border: "1px solid #EEEEEE",
                                                                                                padding:
                                                                                                    "15px",
                                                                                            }}
                                                                                            onMouseEnter={() => {
                                                                                                handlePopoverClose();
                                                                                            }}
                                                                                            onMouseLeave={() => {
                                                                                                handlePopoverClose();
                                                                                            }}
                                                                                        >
                                                                                            {post?.is_post_comment ==
                                                                                                true && (
                                                                                                    <>
                                                                                                        {post?.commentToggle ==
                                                                                                            true && (
                                                                                                                <Box
                                                                                                                    className="w-100"
                                                                                                                    onMouseEnter={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                    onMouseLeave={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                        <Avatar
                                                                                                                            alt={
                                                                                                                                loggedUser?.name
                                                                                                                            }
                                                                                                                            className="author-avtar-creator"
                                                                                                                            src={
                                                                                                                                loggedUser?.thumbnail
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {loggedUser?.name?.charAt(
                                                                                                                                0
                                                                                                                            )}
                                                                                                                        </Avatar>
                                                                                                                        <InputGroup
                                                                                                                            className=""
                                                                                                                            style={{
                                                                                                                                height: "32px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <MentionsInput
                                                                                                                                placeholder="Add Comment. Use '@' for mention"
                                                                                                                                value={
                                                                                                                                    post?.commentText
                                                                                                                                }
                                                                                                                                singleLine={
                                                                                                                                    true
                                                                                                                                }
                                                                                                                                onChange={(
                                                                                                                                    event
                                                                                                                                ) => {
                                                                                                                                    handleCommentText(
                                                                                                                                        event
                                                                                                                                            .target
                                                                                                                                            .value,
                                                                                                                                        post?.id
                                                                                                                                    );
                                                                                                                                }}
                                                                                                                                onKeyDown={(
                                                                                                                                    e
                                                                                                                                ) => {
                                                                                                                                    if (
                                                                                                                                        e.key ===
                                                                                                                                        "Enter"
                                                                                                                                    ) {
                                                                                                                                        e.preventDefault();
                                                                                                                                        handleCommentsAPI(
                                                                                                                                            post?.id,
                                                                                                                                            "yes"
                                                                                                                                        );
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className="input-comments"
                                                                                                                                style={
                                                                                                                                    mentionsInputStyle
                                                                                                                                }
                                                                                                                                a11ySuggestionsListLabel={
                                                                                                                                    "Suggested mentions"
                                                                                                                                }
                                                                                                                                onPaste={
                                                                                                                                    handlePaste
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Mention
                                                                                                                                    style={
                                                                                                                                        mentionStyle
                                                                                                                                    }
                                                                                                                                    className="mention-popup-class"
                                                                                                                                    data={
                                                                                                                                        getMentionUsers
                                                                                                                                    }
                                                                                                                                    onAdd={() =>
                                                                                                                                        setIsSelectingSuggestion(
                                                                                                                                            true
                                                                                                                                        )
                                                                                                                                    } // Set the flag when a suggestion is selected
                                                                                                                                    appendSpaceOnAdd={
                                                                                                                                        true
                                                                                                                                    }
                                                                                                                                    renderSuggestion={(
                                                                                                                                        suggestion,
                                                                                                                                        search,
                                                                                                                                        highlightedDisplay,
                                                                                                                                        index,
                                                                                                                                        focused
                                                                                                                                    ) => (
                                                                                                                                        <div
                                                                                                                                            className="list-class"
                                                                                                                                            key={
                                                                                                                                                index
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <div className="list-class-div-1">
                                                                                                                                                <img
                                                                                                                                                    src={
                                                                                                                                                        suggestion.photo
                                                                                                                                                    }
                                                                                                                                                    alt={
                                                                                                                                                        suggestion.display
                                                                                                                                                    }
                                                                                                                                                    className="list-class-div-1-img"
                                                                                                                                                />
                                                                                                                                                <div className="list-class-div-2">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontWeight:
                                                                                                                                                                "bold",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        {
                                                                                                                                                            suggestion?.full_name
                                                                                                                                                        }
                                                                                                                                                    </span>
                                                                                                                                                    <br />
                                                                                                                                                    <span>
                                                                                                                                                        {
                                                                                                                                                            suggestion?.job_title
                                                                                                                                                        }
                                                                                                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                />
                                                                                                                            </MentionsInput>
                                                                                                                            <Button
                                                                                                                                className="search-btn-dash-2"
                                                                                                                                style={{
                                                                                                                                    height: "100%",
                                                                                                                                }}
                                                                                                                                onClick={() =>
                                                                                                                                    handleCommentsAPI(
                                                                                                                                        post?.id,
                                                                                                                                        "yes"
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <FontAwesomeIcon
                                                                                                                                    icon={
                                                                                                                                        faAdd
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </Button>
                                                                                                                        </InputGroup>
                                                                                                                    </Box>
                                                                                                                </Box>
                                                                                                            )}
                                                                                                    </>
                                                                                                )}
                                                                                            {post
                                                                                                ?.comments
                                                                                                ?.length >
                                                                                                0 && (
                                                                                                    <>
                                                                                                        {post?.comments?.map(
                                                                                                            (
                                                                                                                comment
                                                                                                            ) => (
                                                                                                                <>
                                                                                                                    {comment?.is_parent ===
                                                                                                                        "yes" && (
                                                                                                                            <>
                                                                                                                                <Box
                                                                                                                                    className="w-100 ms-0"
                                                                                                                                    onMouseEnter={
                                                                                                                                        handlePopoverClose
                                                                                                                                    }
                                                                                                                                    onMouseLeave={
                                                                                                                                        handlePopoverClose
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                        <Avatar
                                                                                                                                            alt={
                                                                                                                                                comment
                                                                                                                                                    ?.user
                                                                                                                                                    ?.name
                                                                                                                                            }
                                                                                                                                            className="author-avtar-creator mb-auto"
                                                                                                                                            src={
                                                                                                                                                comment
                                                                                                                                                    ?.user
                                                                                                                                                    ?.thumbnail
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            {comment?.user?.name?.charAt(
                                                                                                                                                0
                                                                                                                                            )}
                                                                                                                                        </Avatar>
                                                                                                                                        <Box
                                                                                                                                            sx={{
                                                                                                                                                padding:
                                                                                                                                                    "15px",
                                                                                                                                            }}
                                                                                                                                            className="comment-box w-100"
                                                                                                                                        >
                                                                                                                                            <Typography className="fs-14-700">
                                                                                                                                                {
                                                                                                                                                    comment
                                                                                                                                                        ?.user
                                                                                                                                                        ?.name
                                                                                                                                                }
                                                                                                                                            </Typography>
                                                                                                                                            <Typography className="fs-14 mt-2">
                                                                                                                                                {renderCommentText(
                                                                                                                                                    comment?.comment
                                                                                                                                                )}
                                                                                                                                            </Typography>
                                                                                                                                            <Stack
                                                                                                                                                direction="row"
                                                                                                                                                divider={
                                                                                                                                                    <Divider
                                                                                                                                                        orientation="vertical"
                                                                                                                                                        flexItem
                                                                                                                                                        className="nested-divider"
                                                                                                                                                    />
                                                                                                                                                }
                                                                                                                                                className="mt-2 align-items-center"
                                                                                                                                                spacing={
                                                                                                                                                    1
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <Box>
                                                                                                                                                    <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                        <Typography
                                                                                                                                                            className={`fs-10 cursor-pointer ${comment?.has_user_liked_comment ===
                                                                                                                                                                "yes" &&
                                                                                                                                                                "liked-post-text"
                                                                                                                                                                }`}
                                                                                                                                                            onClick={() => {
                                                                                                                                                                toggleLikeForCommentsReaction(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    comment?.has_user_liked_comment ===
                                                                                                                                                                        "no"
                                                                                                                                                                        ? "like"
                                                                                                                                                                        : "no",
                                                                                                                                                                    comment?.id,
                                                                                                                                                                    comment?.has_user_liked_comment ===
                                                                                                                                                                        "no"
                                                                                                                                                                        ? true
                                                                                                                                                                        : false
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onMouseEnter={(
                                                                                                                                                                e
                                                                                                                                                            ) => {
                                                                                                                                                                setCurrentPostId(
                                                                                                                                                                    post?.id
                                                                                                                                                                );

                                                                                                                                                                setCurrentPostIdForReply(
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                                handlePopoverOpenForReplyLike(
                                                                                                                                                                    e,
                                                                                                                                                                    post.id,
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onMouseLeave={() => {
                                                                                                                                                                setCurrentPostId(
                                                                                                                                                                    post?.id
                                                                                                                                                                );

                                                                                                                                                                setCurrentPostIdForReply(
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            Like
                                                                                                                                                        </Typography>
                                                                                                                                                        <Box
                                                                                                                                                            className="action-like w-100 d-flex flex-wrap align-items-center gap-1 cursor-pointer"
                                                                                                                                                            onMouseEnter={() => {
                                                                                                                                                                handlePopoverClose();
                                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                                            }}
                                                                                                                                                            onMouseLeave={() => {
                                                                                                                                                                handlePopoverClose();
                                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                                            }}
                                                                                                                                                            onClick={() => { handleEngagementModalOpen(comment?.id, 'comment') }}
                                                                                                                                                        >
                                                                                                                                                            {comment?.user_reactions?.map(
                                                                                                                                                                (
                                                                                                                                                                    reaction
                                                                                                                                                                ) => (
                                                                                                                                                                    <FontAwesomeIcon
                                                                                                                                                                        icon={
                                                                                                                                                                            reaction ===
                                                                                                                                                                                "love"
                                                                                                                                                                                ? faHeart
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "smile"
                                                                                                                                                                                    ? faSmile
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "angry"
                                                                                                                                                                                        ? faAngry
                                                                                                                                                                                        : reaction ===
                                                                                                                                                                                            "like"
                                                                                                                                                                                            ? faThumbsUp
                                                                                                                                                                                            : ""
                                                                                                                                                                        }
                                                                                                                                                                        className={
                                                                                                                                                                            reaction ===
                                                                                                                                                                                "love"
                                                                                                                                                                                ? "fa-love-btn-2"
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "smile"
                                                                                                                                                                                    ? "fa-smile-btn-2"
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "angry"
                                                                                                                                                                                        ? "fa-angry-btn-2"
                                                                                                                                                                                        : reaction ===
                                                                                                                                                                                            "like"
                                                                                                                                                                                            ? "fa-like-btn-2"
                                                                                                                                                                                            : ""
                                                                                                                                                                        }
                                                                                                                                                                    />
                                                                                                                                                                )
                                                                                                                                                            )}

                                                                                                                                                            <Box className="like-counts-box">
                                                                                                                                                                <Typography className="fs-10">
                                                                                                                                                                    {
                                                                                                                                                                        comment?.comment_likes_count
                                                                                                                                                                    }
                                                                                                                                                                </Typography>
                                                                                                                                                            </Box>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                                <Box
                                                                                                                                                    onMouseEnter={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                    onMouseLeave={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <Box>
                                                                                                                                                        <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                            <Typography
                                                                                                                                                                className="fs-10 cursor-pointer"
                                                                                                                                                                onClick={() =>
                                                                                                                                                                    handleCommentReplyToggle(
                                                                                                                                                                        post?.id,
                                                                                                                                                                        comment?.id
                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                            >
                                                                                                                                                                Reply
                                                                                                                                                            </Typography>
                                                                                                                                                            <Typography className="fs-10 cursor-pointer">
                                                                                                                                                                {`${comment?.replies_count} reply`}
                                                                                                                                                            </Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Stack>
                                                                                                                                        </Box>
                                                                                                                                    </Box>
                                                                                                                                </Box>
                                                                                                                                {comment?.commentReplyToggle ==
                                                                                                                                    true && (
                                                                                                                                        <Box
                                                                                                                                            className="w-100 ms-0"
                                                                                                                                            sx={{
                                                                                                                                                paddingLeft:
                                                                                                                                                    "62px",
                                                                                                                                            }}
                                                                                                                                            onMouseEnter={() => {
                                                                                                                                                handlePopoverClose();
                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                            }}
                                                                                                                                            onMouseLeave={() => {
                                                                                                                                                handlePopoverClose();
                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <Box className="w-100 mt-2 mb-1">
                                                                                                                                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                    <Avatar
                                                                                                                                                        alt={
                                                                                                                                                            loggedUser?.name
                                                                                                                                                        }
                                                                                                                                                        className="author-avtar-creator-2"
                                                                                                                                                        src={
                                                                                                                                                            loggedUser?.thumbnail
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        {loggedUser?.name?.charAt(
                                                                                                                                                            0
                                                                                                                                                        )}
                                                                                                                                                    </Avatar>
                                                                                                                                                    <InputGroup
                                                                                                                                                        className=""
                                                                                                                                                        style={{
                                                                                                                                                            height: "25px",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <MentionsInput
                                                                                                                                                            placeholder="Add Comment. Use '@' for mention"
                                                                                                                                                            value={
                                                                                                                                                                comment?.commentReplyText
                                                                                                                                                            }
                                                                                                                                                            singleLine={
                                                                                                                                                                true
                                                                                                                                                            }
                                                                                                                                                            onChange={(
                                                                                                                                                                event
                                                                                                                                                            ) => {
                                                                                                                                                                console.log(
                                                                                                                                                                    "event:::",
                                                                                                                                                                    event
                                                                                                                                                                );
                                                                                                                                                                handleCommentReplyText(
                                                                                                                                                                    event
                                                                                                                                                                        .target
                                                                                                                                                                        .value,
                                                                                                                                                                    post?.id,
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onKeyDown={(
                                                                                                                                                                e
                                                                                                                                                            ) => {
                                                                                                                                                                if (
                                                                                                                                                                    e.key ===
                                                                                                                                                                    "Enter"
                                                                                                                                                                ) {
                                                                                                                                                                    e.preventDefault();
                                                                                                                                                                    handleCommentsReplyAPI(
                                                                                                                                                                        post?.id,
                                                                                                                                                                        "no",
                                                                                                                                                                        comment?.id
                                                                                                                                                                    );
                                                                                                                                                                }
                                                                                                                                                            }}
                                                                                                                                                            className="input-comments"
                                                                                                                                                            style={
                                                                                                                                                                mentionsInputStyle
                                                                                                                                                            }
                                                                                                                                                            a11ySuggestionsListLabel={
                                                                                                                                                                "Suggested mentions"
                                                                                                                                                            }
                                                                                                                                                            onPaste={
                                                                                                                                                                handlePaste
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            <Mention
                                                                                                                                                                style={
                                                                                                                                                                    mentionStyle
                                                                                                                                                                }
                                                                                                                                                                className="mention-popup-class"
                                                                                                                                                                data={
                                                                                                                                                                    getMentionUsers
                                                                                                                                                                }
                                                                                                                                                                onAdd={() =>
                                                                                                                                                                    setIsSelectingSuggestion(
                                                                                                                                                                        true
                                                                                                                                                                    )
                                                                                                                                                                } // Set the flag when a suggestion is selected
                                                                                                                                                                appendSpaceOnAdd={
                                                                                                                                                                    true
                                                                                                                                                                }
                                                                                                                                                                renderSuggestion={(
                                                                                                                                                                    suggestion,
                                                                                                                                                                    search,
                                                                                                                                                                    highlightedDisplay,
                                                                                                                                                                    index,
                                                                                                                                                                    focused
                                                                                                                                                                ) => (
                                                                                                                                                                    <div
                                                                                                                                                                        className="list-class"
                                                                                                                                                                        key={
                                                                                                                                                                            index
                                                                                                                                                                        }
                                                                                                                                                                    >
                                                                                                                                                                        <div className="list-class-div-1">
                                                                                                                                                                            <img
                                                                                                                                                                                src={
                                                                                                                                                                                    suggestion.photo
                                                                                                                                                                                }
                                                                                                                                                                                alt={
                                                                                                                                                                                    suggestion.display
                                                                                                                                                                                }
                                                                                                                                                                                className="list-class-div-1-img"
                                                                                                                                                                            />
                                                                                                                                                                            <div className="list-class-div-2">
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontWeight:
                                                                                                                                                                                            "bold",
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    {
                                                                                                                                                                                        suggestion?.full_name
                                                                                                                                                                                    }
                                                                                                                                                                                </span>
                                                                                                                                                                                <br />
                                                                                                                                                                                <span>
                                                                                                                                                                                    {
                                                                                                                                                                                        suggestion?.job_title
                                                                                                                                                                                    }
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                )}
                                                                                                                                                            />
                                                                                                                                                        </MentionsInput>
                                                                                                                                                        <Button
                                                                                                                                                            className="search-btn-dash-3"
                                                                                                                                                            style={{
                                                                                                                                                                height: "100%",
                                                                                                                                                            }}
                                                                                                                                                            onClick={() =>
                                                                                                                                                                handleCommentsReplyAPI(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    "no",
                                                                                                                                                                    comment?.id
                                                                                                                                                                )
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            <FontAwesomeIcon
                                                                                                                                                                icon={
                                                                                                                                                                    faAdd
                                                                                                                                                                }
                                                                                                                                                            />
                                                                                                                                                        </Button>
                                                                                                                                                    </InputGroup>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                        </Box>
                                                                                                                                    )}
                                                                                                                                {comment
                                                                                                                                    ?.replies
                                                                                                                                    ?.length >
                                                                                                                                    0 ? (
                                                                                                                                    <>
                                                                                                                                        {comment?.replies?.map(
                                                                                                                                            (
                                                                                                                                                reply
                                                                                                                                            ) => (
                                                                                                                                                <Box
                                                                                                                                                    className="w-100 ms-0"
                                                                                                                                                    sx={{
                                                                                                                                                        paddingLeft:
                                                                                                                                                            "62px",
                                                                                                                                                    }}
                                                                                                                                                    onMouseEnter={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                    onMouseLeave={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <Box className="w-100 mt-2">
                                                                                                                                                        <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                            <Avatar
                                                                                                                                                                alt={
                                                                                                                                                                    reply
                                                                                                                                                                        ?.user
                                                                                                                                                                        ?.name
                                                                                                                                                                }
                                                                                                                                                                className="author-avtar-creator-2 mb-auto"
                                                                                                                                                                src={
                                                                                                                                                                    reply
                                                                                                                                                                        ?.user
                                                                                                                                                                        ?.thumbnail
                                                                                                                                                                }
                                                                                                                                                            >
                                                                                                                                                                {reply?.user?.name?.charAt(
                                                                                                                                                                    0
                                                                                                                                                                )}
                                                                                                                                                            </Avatar>
                                                                                                                                                            <Box
                                                                                                                                                                sx={{
                                                                                                                                                                    padding:
                                                                                                                                                                        "10px",
                                                                                                                                                                }}
                                                                                                                                                                className="comment-box w-100"
                                                                                                                                                            >
                                                                                                                                                                <Typography className="fs-12-700">
                                                                                                                                                                    {
                                                                                                                                                                        reply
                                                                                                                                                                            ?.user
                                                                                                                                                                            ?.name
                                                                                                                                                                    }
                                                                                                                                                                </Typography>
                                                                                                                                                                <Typography className="fs-12 mt-1">
                                                                                                                                                                    {renderCommentText(
                                                                                                                                                                        reply?.comment
                                                                                                                                                                    )}
                                                                                                                                                                </Typography>
                                                                                                                                                            </Box>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            )
                                                                                                                                        )}
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    ""
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                </>
                                                                                                            )
                                                                                                        )}
                                                                                                        {post
                                                                                                            ?.comments
                                                                                                            ?.length <
                                                                                                            post?.parent_comment_count && (
                                                                                                                <Box
                                                                                                                    className="w-100 ms-0 d-flex justify-content-center"
                                                                                                                    onMouseEnter={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                    onMouseLeave={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Button
                                                                                                                        className="w-50 mt-4 btn-load-comments"
                                                                                                                        onClick={() => {
                                                                                                                            setFetchAll(
                                                                                                                                true
                                                                                                                            );
                                                                                                                            fetchLoadMoreCommentsAPI(
                                                                                                                                post
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <FontAwesomeIcon
                                                                                                                            icon={
                                                                                                                                faComments
                                                                                                                            }
                                                                                                                        />
                                                                                                                        Load
                                                                                                                        More
                                                                                                                        Comments
                                                                                                                    </Button>
                                                                                                                </Box>
                                                                                                            )}
                                                                                                    </>
                                                                                                )}
                                                                                        </CardActions>
                                                                                    )}
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Card>
                                                                </>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                            </>
                                        )}

                                        {seenFeedPosts?.length > 0 && (
                                            <>
                                                {seenFeedPosts?.map(
                                                    (post, index) => (
                                                        <>
                                                            {post?.type ==
                                                                "Update" ? (
                                                                <Card
                                                                    className="mt-4"
                                                                    data-post-id={
                                                                        post?.id
                                                                    }
                                                                    ref={
                                                                        setElementsCallback
                                                                    }
                                                                    data-post-view={
                                                                        post?.is_post_seen
                                                                    }
                                                                >
                                                                    <CardHeader
                                                                        avatar={
                                                                            <Avatar
                                                                                aria-label="recipe"
                                                                                className="author-avtar-creator"
                                                                                src={
                                                                                    post?.profile
                                                                                }
                                                                            >
                                                                                {post?.created_by?.charAt(
                                                                                    0
                                                                                )}
                                                                            </Avatar>
                                                                        }
                                                                        title={
                                                                            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                                                                <span>
                                                                                    <b>{post?.created_by}</b>{" "}posted a{" "}<b>{post?.type}</b>
                                                                                </span>
                                                                                <Typography variant="caption">
                                                                                    {dateWithTime(post?.published_date_time, false, false, false)}
                                                                                </Typography>
                                                                            </Box>
                                                                        }
                                                                        className="card-header-content"
                                                                    />
                                                                    {post?.image !==
                                                                        "" && (
                                                                            <CardMedia
                                                                                sx={{
                                                                                    height: 222,
                                                                                }}
                                                                                image={
                                                                                    post?.image
                                                                                }
                                                                            />
                                                                        )}

                                                                    <CardContent className="content-div-body">
                                                                        <Box>
                                                                            <Typography
                                                                                variant="h3"
                                                                                component="h3"
                                                                                className="content-title-update text-break"
                                                                            >
                                                                                {
                                                                                    post?.content
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                        {post?.is_require_acknowledge ==
                                                                            true && (
                                                                                <Box
                                                                                    className={`custom-checkbox mt-3 ${post?.acknowledged_on !==
                                                                                        "" &&
                                                                                        "active-checkbox"
                                                                                        }`}
                                                                                >
                                                                                    <label className="checkbox-style">
                                                                                        {post?.acknowledged_on !==
                                                                                            ""
                                                                                            ? `You acknowledged this on ${onlyDateFormat(post?.acknowledged_on, true, false, true)}`
                                                                                            : ((post?.acknowledge_data && (typeof post?.acknowledge_data === "string")) ? `${post?.acknowledge_data}` : `This requires your acknowledgement`)}
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                                post?.acknowledged_on !==
                                                                                                ""
                                                                                            }
                                                                                            onChange={() => {
                                                                                                if (
                                                                                                    post?.acknowledge_type ===
                                                                                                    "question"
                                                                                                ) {
                                                                                                    setAckQuestions(
                                                                                                        post?.acknowledge_data
                                                                                                    );
                                                                                                    setPostIdForAckQue(
                                                                                                        post?.id
                                                                                                    );
                                                                                                    setOpenAckQuePopup(
                                                                                                        true
                                                                                                    );
                                                                                                } else if (
                                                                                                    post?.acknowledge_type ===
                                                                                                    "checkbox"
                                                                                                ) {
                                                                                                    handleCheckboxChange(
                                                                                                        post?.id
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            disabled={
                                                                                                post?.acknowledged_on !==
                                                                                                ""
                                                                                            }
                                                                                        />
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                </Box>
                                                                            )}
                                                                    </CardContent>
                                                                    {post?.is_post_comment ===
                                                                        true ||
                                                                        post?.is_post_like ===
                                                                        true ? (
                                                                        <>
                                                                            <CardActions
                                                                                className="bg-white"
                                                                                sx={{
                                                                                    border: "1px solid #EEEEEE",
                                                                                    padding:
                                                                                        "10px",
                                                                                }}
                                                                                onMouseEnter={() => {
                                                                                    handlePopoverClose();
                                                                                    handlePopoverCloseForReplyLike();
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    handlePopoverClose();
                                                                                    handlePopoverCloseForReplyLike();
                                                                                }}
                                                                            >
                                                                                {post?.is_post_like ==
                                                                                    true && (
                                                                                        <Box className="action-like w-100 d-flex flex-wrap align-items-center gap-2 cursor-pointer"
                                                                                            onClick={() => { handleEngagementModalOpen(post?.id) }}
                                                                                        >
                                                                                            {post?.reactions?.map(
                                                                                                (
                                                                                                    reaction
                                                                                                ) => (
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={
                                                                                                            reaction ===
                                                                                                                "love"
                                                                                                                ? faHeart
                                                                                                                : reaction ===
                                                                                                                    "smile"
                                                                                                                    ? faSmile
                                                                                                                    : reaction ===
                                                                                                                        "angry"
                                                                                                                        ? faAngry
                                                                                                                        : reaction ===
                                                                                                                            "like"
                                                                                                                            ? faThumbsUp
                                                                                                                            : ""
                                                                                                        }
                                                                                                        className={
                                                                                                            reaction ===
                                                                                                                "love"
                                                                                                                ? "fa-love-btn"
                                                                                                                : reaction ===
                                                                                                                    "smile"
                                                                                                                    ? "fa-smile-btn"
                                                                                                                    : reaction ===
                                                                                                                        "angry"
                                                                                                                        ? "fa-angry-btn"
                                                                                                                        : reaction ===
                                                                                                                            "like"
                                                                                                                            ? "fa-like-btn"
                                                                                                                            : ""
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            )}

                                                                                            <Box className="like-counts-box">
                                                                                                <Typography className="fs-12-action-btn">
                                                                                                    {
                                                                                                        post?.like_count
                                                                                                    }
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    )}
                                                                                {post?.is_post_comment ==
                                                                                    true && (
                                                                                        <Box className="w-100 ms-0">
                                                                                            <Typography className="fs-12-action-btn d-flex  justify-content-end">
                                                                                                {`${post?.comment_count} comments`}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                            </CardActions>
                                                                            <CardActions
                                                                                className="bg-white"
                                                                                sx={{
                                                                                    border: "1px solid #EEEEEE",
                                                                                    padding:
                                                                                        "10px",
                                                                                }}
                                                                            >
                                                                                {post?.is_post_like ==
                                                                                    true && (
                                                                                        <Box
                                                                                            className="action-like w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                            onClick={() => {
                                                                                                toggleReaction(
                                                                                                    post?.id,
                                                                                                    post?.user_has_liked ===
                                                                                                        "no"
                                                                                                        ? "like"
                                                                                                        : "no",
                                                                                                    post?.user_has_liked ===
                                                                                                        "no"
                                                                                                        ? true
                                                                                                        : false
                                                                                                );
                                                                                                handlePopoverClose();
                                                                                            }}
                                                                                            onMouseEnter={(
                                                                                                e
                                                                                            ) =>
                                                                                                handlePopoverOpen(
                                                                                                    e,
                                                                                                    post.id
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    post?.reaction ===
                                                                                                        "like"
                                                                                                        ? faThumbsUp
                                                                                                        : post?.reaction ===
                                                                                                            "love"
                                                                                                            ? faHeart
                                                                                                            : post?.reaction ===
                                                                                                                "smile"
                                                                                                                ? faSmile
                                                                                                                : post?.reaction ===
                                                                                                                    "angry"
                                                                                                                    ? faAngry
                                                                                                                    : faThumbsUp
                                                                                                }
                                                                                                className={`fs-12-action-btn ${post?.reaction ===
                                                                                                    "like"
                                                                                                    ? "liked-post-text"
                                                                                                    : post?.reaction ===
                                                                                                        "love"
                                                                                                        ? "love-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "smile"
                                                                                                            ? "smile-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "angry"
                                                                                                                ? "angry-post-text"
                                                                                                                : ""
                                                                                                    }`}
                                                                                            />
                                                                                            <Typography
                                                                                                className={`fs-12-action-btn text-capitalize ${post?.reaction ===
                                                                                                    "like"
                                                                                                    ? "liked-post-text"
                                                                                                    : post?.reaction ===
                                                                                                        "love"
                                                                                                        ? "love-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "smile"
                                                                                                            ? "smile-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "angry"
                                                                                                                ? "angry-post-text"
                                                                                                                : ""
                                                                                                    }`}
                                                                                            >
                                                                                                {post?.user_has_liked !==
                                                                                                    "no"
                                                                                                    ? post.reaction
                                                                                                    : "Like"}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                                {post?.is_post_comment ==
                                                                                    true && (
                                                                                        <Box
                                                                                            className="action-comments w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                            onClick={() =>
                                                                                                handleCommentToggle(
                                                                                                    post?.id
                                                                                                )
                                                                                            }
                                                                                            onMouseEnter={() => {
                                                                                                handlePopoverClose();
                                                                                                handlePopoverCloseForReplyLike();
                                                                                            }}
                                                                                            onMouseLeave={() => {
                                                                                                handlePopoverClose();
                                                                                                handlePopoverCloseForReplyLike();
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    faComments
                                                                                                }
                                                                                                className="fs-12-action-btn"
                                                                                            />
                                                                                            <Typography className="fs-12-action-btn">
                                                                                                Comments
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                            </CardActions>
                                                                            {post?.is_post_comment ==
                                                                                true && (
                                                                                    <CardActions
                                                                                        className="bg-white card-action-2"
                                                                                        sx={{
                                                                                            border: "1px solid #EEEEEE",
                                                                                            padding:
                                                                                                "15px",
                                                                                        }}
                                                                                        onMouseEnter={() => {
                                                                                            handlePopoverClose();
                                                                                        }}
                                                                                        onMouseLeave={() => {
                                                                                            handlePopoverClose();
                                                                                        }}
                                                                                    >
                                                                                        {post?.is_post_comment ==
                                                                                            true && (
                                                                                                <>
                                                                                                    {post?.commentToggle ==
                                                                                                        true && (
                                                                                                            <Box
                                                                                                                className="w-100"
                                                                                                                onMouseEnter={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                                onMouseLeave={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                            >
                                                                                                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                    <Avatar
                                                                                                                        alt={
                                                                                                                            loggedUser?.name
                                                                                                                        }
                                                                                                                        className="author-avtar-creator"
                                                                                                                        src={
                                                                                                                            loggedUser?.thumbnail
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {loggedUser?.name?.charAt(
                                                                                                                            0
                                                                                                                        )}
                                                                                                                    </Avatar>
                                                                                                                    <InputGroup
                                                                                                                        className=""
                                                                                                                        style={{
                                                                                                                            height: "32px",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <MentionsInput
                                                                                                                            placeholder="Add Comment. Use '@' for mention and '&' for emojis"
                                                                                                                            value={
                                                                                                                                post?.commentText
                                                                                                                            }
                                                                                                                            onChange={(
                                                                                                                                event
                                                                                                                            ) =>
                                                                                                                                handleCommentText(
                                                                                                                                    event
                                                                                                                                        .target
                                                                                                                                        .value,
                                                                                                                                    post?.id
                                                                                                                                )
                                                                                                                            }
                                                                                                                            onKeyDown={(
                                                                                                                                e
                                                                                                                            ) => {
                                                                                                                                if (
                                                                                                                                    e.key ===
                                                                                                                                    "Enter"
                                                                                                                                ) {
                                                                                                                                    e.preventDefault();
                                                                                                                                    handleCommentsAPI(
                                                                                                                                        post?.id,
                                                                                                                                        "yes"
                                                                                                                                    );
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            className="input-comments"
                                                                                                                            singleLine={
                                                                                                                                true
                                                                                                                            }
                                                                                                                            style={
                                                                                                                                mentionsInputStyle
                                                                                                                            }
                                                                                                                            a11ySuggestionsListLabel={
                                                                                                                                "Suggested mentions"
                                                                                                                            }
                                                                                                                            onPaste={
                                                                                                                                handlePaste
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <Mention
                                                                                                                                style={
                                                                                                                                    mentionStyle
                                                                                                                                }
                                                                                                                                className="mention-popup-class"
                                                                                                                                data={
                                                                                                                                    getMentionUsers
                                                                                                                                }
                                                                                                                                onAdd={() =>
                                                                                                                                    setIsSelectingSuggestion(
                                                                                                                                        true
                                                                                                                                    )
                                                                                                                                } // Set the flag when a suggestion is selected
                                                                                                                                renderSuggestion={(
                                                                                                                                    suggestion,
                                                                                                                                    search,
                                                                                                                                    highlightedDisplay,
                                                                                                                                    index,
                                                                                                                                    focused
                                                                                                                                ) => (
                                                                                                                                    <div
                                                                                                                                        className="list-class"
                                                                                                                                        key={
                                                                                                                                            index
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <div className="list-class-div-1">
                                                                                                                                            <img
                                                                                                                                                src={
                                                                                                                                                    suggestion.photo
                                                                                                                                                }
                                                                                                                                                alt={
                                                                                                                                                    suggestion.display
                                                                                                                                                }
                                                                                                                                                className="list-class-div-1-img"
                                                                                                                                            />
                                                                                                                                            <div className="list-class-div-2">
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontWeight:
                                                                                                                                                            "bold",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    {
                                                                                                                                                        suggestion?.full_name
                                                                                                                                                    }
                                                                                                                                                </span>
                                                                                                                                                <br />
                                                                                                                                                <span>
                                                                                                                                                    {
                                                                                                                                                        suggestion?.job_title
                                                                                                                                                    }
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                            />
                                                                                                                        </MentionsInput>
                                                                                                                        <Button
                                                                                                                            className="search-btn-dash-2"
                                                                                                                            style={{
                                                                                                                                height: "100%",
                                                                                                                            }}
                                                                                                                            onClick={() =>
                                                                                                                                handleCommentsAPI(
                                                                                                                                    post?.id,
                                                                                                                                    "yes"
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon
                                                                                                                                icon={
                                                                                                                                    faAdd
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </Button>
                                                                                                                    </InputGroup>
                                                                                                                </Box>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                </>
                                                                                            )}
                                                                                        {post
                                                                                            ?.comments
                                                                                            ?.length >
                                                                                            0 && (
                                                                                                <>
                                                                                                    {post?.comments?.map(
                                                                                                        (
                                                                                                            comment
                                                                                                        ) => (
                                                                                                            <>
                                                                                                                {comment?.is_parent ===
                                                                                                                    "yes" && (
                                                                                                                        <>
                                                                                                                            <Box
                                                                                                                                className="w-100 ms-0"
                                                                                                                                onMouseEnter={
                                                                                                                                    handlePopoverClose
                                                                                                                                }
                                                                                                                                onMouseLeave={
                                                                                                                                    handlePopoverClose
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                    <Avatar
                                                                                                                                        alt={
                                                                                                                                            comment
                                                                                                                                                ?.user
                                                                                                                                                ?.name
                                                                                                                                        }
                                                                                                                                        className="author-avtar-creator mb-auto"
                                                                                                                                        src={
                                                                                                                                            comment
                                                                                                                                                ?.user
                                                                                                                                                ?.thumbnail
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        {comment?.user?.name?.charAt(
                                                                                                                                            0
                                                                                                                                        )}
                                                                                                                                    </Avatar>
                                                                                                                                    <Box
                                                                                                                                        sx={{
                                                                                                                                            padding:
                                                                                                                                                "15px",
                                                                                                                                        }}
                                                                                                                                        className="comment-box w-100"
                                                                                                                                    >
                                                                                                                                        <Typography className="fs-14-700">
                                                                                                                                            {
                                                                                                                                                comment
                                                                                                                                                    ?.user
                                                                                                                                                    ?.name
                                                                                                                                            }
                                                                                                                                        </Typography>
                                                                                                                                        <Typography className="fs-14 mt-2">
                                                                                                                                            {renderCommentText(
                                                                                                                                                comment?.comment
                                                                                                                                            )}
                                                                                                                                        </Typography>
                                                                                                                                        <Stack
                                                                                                                                            direction="row"
                                                                                                                                            divider={
                                                                                                                                                <Divider
                                                                                                                                                    orientation="vertical"
                                                                                                                                                    flexItem
                                                                                                                                                    className="nested-divider"
                                                                                                                                                />
                                                                                                                                            }
                                                                                                                                            className="mt-2 align-items-center"
                                                                                                                                            spacing={
                                                                                                                                                1
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <Box>
                                                                                                                                                <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                    <Typography
                                                                                                                                                        className={`fs-10 cursor-pointer  ${comment?.reaction ===
                                                                                                                                                            "like"
                                                                                                                                                            ? "liked-post-text"
                                                                                                                                                            : comment?.reaction ===
                                                                                                                                                                "love"
                                                                                                                                                                ? "love-post-text"
                                                                                                                                                                : comment?.reaction ===
                                                                                                                                                                    "smile"
                                                                                                                                                                    ? "smile-post-text"
                                                                                                                                                                    : comment?.reaction ===
                                                                                                                                                                        "angry"
                                                                                                                                                                        ? "angry-post-text"
                                                                                                                                                                        : ""
                                                                                                                                                            }`}
                                                                                                                                                        onClick={() => {
                                                                                                                                                            toggleLikeForCommentsReaction(
                                                                                                                                                                post?.id,
                                                                                                                                                                comment?.has_user_liked_comment ===
                                                                                                                                                                    "no"
                                                                                                                                                                    ? "like"
                                                                                                                                                                    : "no",
                                                                                                                                                                comment?.id,
                                                                                                                                                                comment?.has_user_liked_comment ===
                                                                                                                                                                    "no"
                                                                                                                                                                    ? true
                                                                                                                                                                    : false
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                        onMouseEnter={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            setCurrentPostId(
                                                                                                                                                                post?.id
                                                                                                                                                            );

                                                                                                                                                            setCurrentPostIdForReply(
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                            handlePopoverOpenForReplyLike(
                                                                                                                                                                e,
                                                                                                                                                                post.id,
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                        onMouseLeave={() => {
                                                                                                                                                            setCurrentPostId(
                                                                                                                                                                post?.id
                                                                                                                                                            );

                                                                                                                                                            setCurrentPostIdForReply(
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        {comment?.has_user_liked_comment !==
                                                                                                                                                            "no"
                                                                                                                                                            ? comment.reaction
                                                                                                                                                            : "Like"}
                                                                                                                                                    </Typography>
                                                                                                                                                    <Box
                                                                                                                                                        className="action-like w-100 d-flex flex-wrap align-items-center gap-1 cursor-pointer"
                                                                                                                                                        onMouseEnter={() => {
                                                                                                                                                            handlePopoverClose();
                                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                                        }}
                                                                                                                                                        onMouseLeave={() => {
                                                                                                                                                            handlePopoverClose();
                                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                                        }}
                                                                                                                                                        onClick={() => { handleEngagementModalOpen(comment?.id, 'comment') }}
                                                                                                                                                    >
                                                                                                                                                        {comment?.user_reactions?.map(
                                                                                                                                                            (
                                                                                                                                                                reaction
                                                                                                                                                            ) => (
                                                                                                                                                                <FontAwesomeIcon
                                                                                                                                                                    icon={
                                                                                                                                                                        reaction ===
                                                                                                                                                                            "love"
                                                                                                                                                                            ? faHeart
                                                                                                                                                                            : reaction ===
                                                                                                                                                                                "smile"
                                                                                                                                                                                ? faSmile
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "angry"
                                                                                                                                                                                    ? faAngry
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "like"
                                                                                                                                                                                        ? faThumbsUp
                                                                                                                                                                                        : ""
                                                                                                                                                                    }
                                                                                                                                                                    className={
                                                                                                                                                                        reaction ===
                                                                                                                                                                            "love"
                                                                                                                                                                            ? "fa-love-btn-2"
                                                                                                                                                                            : reaction ===
                                                                                                                                                                                "smile"
                                                                                                                                                                                ? "fa-smile-btn-2"
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "angry"
                                                                                                                                                                                    ? "fa-angry-btn-2"
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "like"
                                                                                                                                                                                        ? "fa-like-btn-2"
                                                                                                                                                                                        : ""
                                                                                                                                                                    }
                                                                                                                                                                />
                                                                                                                                                            )
                                                                                                                                                        )}

                                                                                                                                                        <Box className="like-counts-box">
                                                                                                                                                            <Typography className="fs-10">
                                                                                                                                                                {
                                                                                                                                                                    comment?.comment_likes_count
                                                                                                                                                                }
                                                                                                                                                            </Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                            <Box
                                                                                                                                                onMouseEnter={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                                onMouseLeave={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Box>
                                                                                                                                                    <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                        <Typography
                                                                                                                                                            className="fs-10 cursor-pointer"
                                                                                                                                                            onClick={() =>
                                                                                                                                                                handleCommentReplyToggle(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    comment?.id
                                                                                                                                                                )
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            Reply
                                                                                                                                                        </Typography>
                                                                                                                                                        <Typography className="fs-10 cursor-pointer">
                                                                                                                                                            {`${comment?.replies_count} reply`}
                                                                                                                                                        </Typography>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                        </Stack>
                                                                                                                                    </Box>
                                                                                                                                </Box>
                                                                                                                            </Box>
                                                                                                                            {comment?.commentReplyToggle ==
                                                                                                                                true && (
                                                                                                                                    <Box
                                                                                                                                        className="w-100 ms-0"
                                                                                                                                        sx={{
                                                                                                                                            paddingLeft:
                                                                                                                                                "62px",
                                                                                                                                        }}
                                                                                                                                        onMouseEnter={() => {
                                                                                                                                            handlePopoverClose();
                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                        }}
                                                                                                                                        onMouseLeave={() => {
                                                                                                                                            handlePopoverClose();
                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <Box className="w-100 mt-2 mb-1">
                                                                                                                                            <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                <Avatar
                                                                                                                                                    alt={
                                                                                                                                                        loggedUser?.name
                                                                                                                                                    }
                                                                                                                                                    className="author-avtar-creator-2"
                                                                                                                                                    src={
                                                                                                                                                        loggedUser?.thumbnail
                                                                                                                                                    }
                                                                                                                                                >
                                                                                                                                                    {loggedUser?.name?.charAt(
                                                                                                                                                        0
                                                                                                                                                    )}
                                                                                                                                                </Avatar>
                                                                                                                                                <InputGroup
                                                                                                                                                    className=""
                                                                                                                                                    style={{
                                                                                                                                                        height: "25px",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <MentionsInput
                                                                                                                                                        placeholder="Add Comment. Use '@' for mention"
                                                                                                                                                        value={
                                                                                                                                                            comment?.commentReplyText
                                                                                                                                                        }
                                                                                                                                                        singleLine={
                                                                                                                                                            true
                                                                                                                                                        }
                                                                                                                                                        onChange={(
                                                                                                                                                            event
                                                                                                                                                        ) => {
                                                                                                                                                            console.log(
                                                                                                                                                                "event:::",
                                                                                                                                                                event
                                                                                                                                                            );
                                                                                                                                                            handleCommentReplyText(
                                                                                                                                                                event
                                                                                                                                                                    .target
                                                                                                                                                                    .value,
                                                                                                                                                                post?.id,
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                        onKeyDown={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            if (
                                                                                                                                                                e.key ===
                                                                                                                                                                "Enter"
                                                                                                                                                            ) {
                                                                                                                                                                e.preventDefault();
                                                                                                                                                                handleCommentsReplyAPI(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    "no",
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }
                                                                                                                                                        }}
                                                                                                                                                        className="input-comments"
                                                                                                                                                        style={
                                                                                                                                                            mentionsInputStyle
                                                                                                                                                        }
                                                                                                                                                        a11ySuggestionsListLabel={
                                                                                                                                                            "Suggested mentions"
                                                                                                                                                        }
                                                                                                                                                        onPaste={
                                                                                                                                                            handlePaste
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        <Mention
                                                                                                                                                            style={
                                                                                                                                                                mentionStyle
                                                                                                                                                            }
                                                                                                                                                            className="mention-popup-class"
                                                                                                                                                            data={
                                                                                                                                                                getMentionUsers
                                                                                                                                                            }
                                                                                                                                                            appendSpaceOnAdd={
                                                                                                                                                                true
                                                                                                                                                            }
                                                                                                                                                            onAdd={() =>
                                                                                                                                                                setIsSelectingSuggestion(
                                                                                                                                                                    true
                                                                                                                                                                )
                                                                                                                                                            } // Set the flag when a suggestion is selected
                                                                                                                                                            renderSuggestion={(
                                                                                                                                                                suggestion,
                                                                                                                                                                search,
                                                                                                                                                                highlightedDisplay,
                                                                                                                                                                index,
                                                                                                                                                                focused
                                                                                                                                                            ) => (
                                                                                                                                                                <div
                                                                                                                                                                    className="list-class"
                                                                                                                                                                    key={
                                                                                                                                                                        index
                                                                                                                                                                    }
                                                                                                                                                                >
                                                                                                                                                                    <div className="list-class-div-1">
                                                                                                                                                                        <img
                                                                                                                                                                            src={
                                                                                                                                                                                suggestion.photo
                                                                                                                                                                            }
                                                                                                                                                                            alt={
                                                                                                                                                                                suggestion.display
                                                                                                                                                                            }
                                                                                                                                                                            className="list-class-div-1-img"
                                                                                                                                                                        />
                                                                                                                                                                        <div className="list-class-div-2">
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    fontWeight:
                                                                                                                                                                                        "bold",
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                {
                                                                                                                                                                                    suggestion?.full_name
                                                                                                                                                                                }
                                                                                                                                                                            </span>
                                                                                                                                                                            <br />
                                                                                                                                                                            <span>
                                                                                                                                                                                {
                                                                                                                                                                                    suggestion?.job_title
                                                                                                                                                                                }
                                                                                                                                                                            </span>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            )}
                                                                                                                                                        />
                                                                                                                                                    </MentionsInput>
                                                                                                                                                    <Button
                                                                                                                                                        className="search-btn-dash-3"
                                                                                                                                                        style={{
                                                                                                                                                            height: "100%",
                                                                                                                                                        }}
                                                                                                                                                        onClick={() =>
                                                                                                                                                            handleCommentsReplyAPI(
                                                                                                                                                                post?.id,
                                                                                                                                                                "no",
                                                                                                                                                                comment?.id
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        <FontAwesomeIcon
                                                                                                                                                            icon={
                                                                                                                                                                faAdd
                                                                                                                                                            }
                                                                                                                                                        />
                                                                                                                                                    </Button>
                                                                                                                                                </InputGroup>
                                                                                                                                            </Box>
                                                                                                                                        </Box>
                                                                                                                                    </Box>
                                                                                                                                )}
                                                                                                                            {comment
                                                                                                                                ?.replies
                                                                                                                                ?.length >
                                                                                                                                0 ? (
                                                                                                                                <>
                                                                                                                                    {comment?.replies?.map(
                                                                                                                                        (
                                                                                                                                            reply
                                                                                                                                        ) => (
                                                                                                                                            <Box
                                                                                                                                                className="w-100 ms-0"
                                                                                                                                                sx={{
                                                                                                                                                    paddingLeft:
                                                                                                                                                        "62px",
                                                                                                                                                }}
                                                                                                                                                onMouseEnter={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                                onMouseLeave={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Box className="w-100 mt-2">
                                                                                                                                                    <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                        <Avatar
                                                                                                                                                            alt={
                                                                                                                                                                reply
                                                                                                                                                                    ?.user
                                                                                                                                                                    ?.name
                                                                                                                                                            }
                                                                                                                                                            className="author-avtar-creator-2 mb-auto"
                                                                                                                                                            src={
                                                                                                                                                                reply
                                                                                                                                                                    ?.user
                                                                                                                                                                    ?.thumbnail
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            {reply?.user?.name?.charAt(
                                                                                                                                                                0
                                                                                                                                                            )}
                                                                                                                                                        </Avatar>
                                                                                                                                                        <Box
                                                                                                                                                            sx={{
                                                                                                                                                                padding:
                                                                                                                                                                    "10px",
                                                                                                                                                            }}
                                                                                                                                                            className="comment-box w-100"
                                                                                                                                                        >
                                                                                                                                                            <Typography className="fs-12-700">
                                                                                                                                                                {
                                                                                                                                                                    reply
                                                                                                                                                                        ?.user
                                                                                                                                                                        ?.name
                                                                                                                                                                }
                                                                                                                                                            </Typography>
                                                                                                                                                            <Typography className="fs-12 mt-1">
                                                                                                                                                                {renderCommentText(
                                                                                                                                                                    reply?.comment
                                                                                                                                                                )}
                                                                                                                                                            </Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                        )
                                                                                                                                    )}
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                ""
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                            </>
                                                                                                        )
                                                                                                    )}
                                                                                                    {post
                                                                                                        ?.comments
                                                                                                        ?.length <
                                                                                                        post?.parent_comment_count && (
                                                                                                            <Box
                                                                                                                className="w-100 ms-0 d-flex justify-content-center"
                                                                                                                onMouseEnter={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                                onMouseLeave={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                            >
                                                                                                                <Button
                                                                                                                    className="w-50 mt-4 btn-load-comments"
                                                                                                                    onClick={() => {
                                                                                                                        setFetchAll(
                                                                                                                            true
                                                                                                                        );
                                                                                                                        fetchLoadMoreCommentsAPI(
                                                                                                                            post
                                                                                                                        );
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <FontAwesomeIcon
                                                                                                                        icon={
                                                                                                                            faComments
                                                                                                                        }
                                                                                                                    />
                                                                                                                    Load
                                                                                                                    More
                                                                                                                    Comments
                                                                                                                </Button>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                </>
                                                                                            )}
                                                                                    </CardActions>
                                                                                )}
                                                                        </>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Card>
                                                            ) : (
                                                                <>
                                                                    <Card
                                                                        className="mt-4"
                                                                        data-post-id={
                                                                            post?.id
                                                                        }
                                                                        data-post-view={
                                                                            post?.is_post_seen
                                                                        }
                                                                        ref={
                                                                            setElementsCallback
                                                                        }
                                                                    >
                                                                        <CardHeader
                                                                            avatar={
                                                                                <Avatar
                                                                                    aria-label="recipe"
                                                                                    className="author-avtar-creator"
                                                                                    src={
                                                                                        post?.profile
                                                                                    }
                                                                                >
                                                                                    {post?.created_by?.charAt(
                                                                                        0
                                                                                    )}
                                                                                </Avatar>
                                                                            }
                                                                            title={
                                                                                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                                                                    <span>
                                                                                        <b>{post?.created_by}</b>{" "}posted a{" "}<b>{post?.type}</b>
                                                                                    </span>
                                                                                    <Typography variant="caption">
                                                                                        {dateWithTime(post?.published_date_time, false, false, false)}
                                                                                    </Typography>
                                                                                </Box>
                                                                            }
                                                                            className="card-header-content"
                                                                        />
                                                                        <CardMedia
                                                                            sx={{
                                                                                height: 222,
                                                                            }}
                                                                            image={
                                                                                post?.image
                                                                            }
                                                                            className="cursor-pointer"
                                                                            onClick={() => {
                                                                                if (
                                                                                    loggedUser?.user_type ===
                                                                                    "user"
                                                                                ) {
                                                                                    localStorage.setItem(
                                                                                        "last-redirect-page-user",
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                    navigate(
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                } else if (
                                                                                    loggedUser?.user_type ===
                                                                                    "admin" &&
                                                                                    adminAccessible ==
                                                                                    true
                                                                                ) {
                                                                                    localStorage.setItem(
                                                                                        "last-redirect-page-admin-user",
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                    navigate(
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                        <CardContent className="content-div-body">
                                                                            <div className="main-content-div-1">
                                                                                <div className="main-div-posted">
                                                                                    <div className="icon-bd-div">
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                faClock
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <Typography className="fs-14 opacity-50">
                                                                                        {` Posted ${onlyDateFormat(post?.published_date_time, false, true, true)}`}
                                                                                    </Typography>
                                                                                </div>
                                                                                {post
                                                                                    ?.category
                                                                                    ?.length >
                                                                                    0 && (
                                                                                        <div className="main-div-workspace">
                                                                                            <div className="icon-bd-div">
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        faBriefcase
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <Typography className="fs-14 opacity-50">
                                                                                                {post?.category?.join(
                                                                                                    ", "
                                                                                                )}
                                                                                            </Typography>
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                            <Box>
                                                                                <Typography
                                                                                    gutterBottom
                                                                                    variant="h5"
                                                                                    component="div"
                                                                                    className="content-title text-break"
                                                                                >
                                                                                    {
                                                                                        post?.title
                                                                                    }
                                                                                </Typography>
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.secondary"
                                                                                    className="fs-14 content-desc text-break"
                                                                                >
                                                                                    {
                                                                                        post?.content
                                                                                    }
                                                                                </Typography>
                                                                            </Box>
                                                                            {post?.is_require_acknowledge ==
                                                                                true && (
                                                                                    <Box
                                                                                        className={`custom-checkbox mt-3 ${post?.acknowledged_on !==
                                                                                            "" &&
                                                                                            "active-checkbox"
                                                                                            }`}
                                                                                    >
                                                                                        <label className="checkbox-style">
                                                                                            {post?.acknowledged_on !==
                                                                                                ""
                                                                                                ? `You acknowledged this on ${onlyDateFormat(post?.acknowledged_on, true, false, true)}`
                                                                                                : ((post?.acknowledge_data && (typeof post?.acknowledge_data === "string")) ? `${post?.acknowledge_data}` : `This requires your acknowledgement`)}
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={
                                                                                                    post?.acknowledged_on !==
                                                                                                    ""
                                                                                                }
                                                                                                onChange={() => {
                                                                                                    if (
                                                                                                        post?.acknowledge_type ===
                                                                                                        "question"
                                                                                                    ) {
                                                                                                        setAckQuestions(
                                                                                                            post?.acknowledge_data
                                                                                                        );
                                                                                                        setPostIdForAckQue(
                                                                                                            post?.id
                                                                                                        );
                                                                                                        setOpenAckQuePopup(
                                                                                                            true
                                                                                                        );
                                                                                                    } else if (
                                                                                                        post?.acknowledge_type ===
                                                                                                        "checkbox"
                                                                                                    ) {
                                                                                                        handleCheckboxChange(
                                                                                                            post?.id
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                                disabled={
                                                                                                    post?.acknowledged_on !==
                                                                                                    ""
                                                                                                }
                                                                                            />
                                                                                            <span className="checkmark"></span>
                                                                                        </label>
                                                                                    </Box>
                                                                                )}
                                                                            <Button
                                                                                className="btn primary-btn mt-4"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        loggedUser?.user_type ===
                                                                                        "user"
                                                                                    ) {
                                                                                        localStorage.setItem(
                                                                                            "last-redirect-page-user",
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                        navigate(
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                    } else if (
                                                                                        loggedUser?.user_type ===
                                                                                        "admin" &&
                                                                                        adminAccessible ==
                                                                                        true
                                                                                    ) {
                                                                                        localStorage.setItem(
                                                                                            "last-redirect-page-admin-user",
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                        navigate(
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                View
                                                                                Article
                                                                                <FontAwesomeIcon
                                                                                    icon={
                                                                                        faArrowRight
                                                                                    }
                                                                                />
                                                                            </Button>
                                                                        </CardContent>
                                                                        {post?.is_post_comment ===
                                                                            true ||
                                                                            post?.is_post_like ===
                                                                            true ? (
                                                                            <>
                                                                                <CardActions
                                                                                    className="bg-white"
                                                                                    sx={{
                                                                                        border: "1px solid #EEEEEE",
                                                                                        padding:
                                                                                            "10px",
                                                                                    }}
                                                                                    onMouseEnter={() => {
                                                                                        handlePopoverClose();
                                                                                        handlePopoverCloseForReplyLike();
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        handlePopoverClose();
                                                                                        handlePopoverCloseForReplyLike();
                                                                                    }}
                                                                                >
                                                                                    {post?.is_post_like ==
                                                                                        true && (
                                                                                            <Box className="action-like w-100 d-flex flex-wrap align-items-center gap-2 cursor-pointer"
                                                                                                onClick={() => { handleEngagementModalOpen(post?.id) }}
                                                                                            >
                                                                                                {post?.reactions?.map(
                                                                                                    (
                                                                                                        reaction
                                                                                                    ) => (
                                                                                                        <FontAwesomeIcon
                                                                                                            icon={
                                                                                                                reaction ===
                                                                                                                    "love"
                                                                                                                    ? faHeart
                                                                                                                    : reaction ===
                                                                                                                        "smile"
                                                                                                                        ? faSmile
                                                                                                                        : reaction ===
                                                                                                                            "angry"
                                                                                                                            ? faAngry
                                                                                                                            : reaction ===
                                                                                                                                "like"
                                                                                                                                ? faThumbsUp
                                                                                                                                : ""
                                                                                                            }
                                                                                                            className={
                                                                                                                reaction ===
                                                                                                                    "love"
                                                                                                                    ? "fa-love-btn"
                                                                                                                    : reaction ===
                                                                                                                        "smile"
                                                                                                                        ? "fa-smile-btn"
                                                                                                                        : reaction ===
                                                                                                                            "angry"
                                                                                                                            ? "fa-angry-btn"
                                                                                                                            : reaction ===
                                                                                                                                "like"
                                                                                                                                ? "fa-like-btn"
                                                                                                                                : ""
                                                                                                            }
                                                                                                        />
                                                                                                    )
                                                                                                )}

                                                                                                <Box className="like-counts-box">
                                                                                                    <Typography className="fs-12-action-btn">
                                                                                                        {
                                                                                                            post?.like_count
                                                                                                        }
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        )}
                                                                                    {post?.is_post_comment ==
                                                                                        true && (
                                                                                            <Box className="w-100 ms-0">
                                                                                                <Typography className="fs-12-action-btn d-flex  justify-content-end">
                                                                                                    {`${post?.comment_count} comments`}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                </CardActions>
                                                                                <CardActions
                                                                                    className="bg-white"
                                                                                    sx={{
                                                                                        border: "1px solid #EEEEEE",
                                                                                        padding:
                                                                                            "10px",
                                                                                    }}
                                                                                >
                                                                                    {post?.is_post_like ==
                                                                                        true && (
                                                                                            <Box
                                                                                                className="action-like w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                                onClick={() => {
                                                                                                    toggleReaction(
                                                                                                        post?.id,
                                                                                                        post?.user_has_liked ===
                                                                                                            "no"
                                                                                                            ? "like"
                                                                                                            : "no",
                                                                                                        post?.user_has_liked ===
                                                                                                            "no"
                                                                                                            ? true
                                                                                                            : false
                                                                                                    );
                                                                                                    handlePopoverClose();
                                                                                                }}
                                                                                                onMouseEnter={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handlePopoverOpen(
                                                                                                        e,
                                                                                                        post.id
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        post?.reaction ===
                                                                                                            "like"
                                                                                                            ? faThumbsUp
                                                                                                            : post?.reaction ===
                                                                                                                "love"
                                                                                                                ? faHeart
                                                                                                                : post?.reaction ===
                                                                                                                    "smile"
                                                                                                                    ? faSmile
                                                                                                                    : post?.reaction ===
                                                                                                                        "angry"
                                                                                                                        ? faAngry
                                                                                                                        : faThumbsUp
                                                                                                    }
                                                                                                    className={`fs-12-action-btn ${post?.reaction ===
                                                                                                        "like"
                                                                                                        ? "liked-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "love"
                                                                                                            ? "love-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "smile"
                                                                                                                ? "smile-post-text"
                                                                                                                : post?.reaction ===
                                                                                                                    "angry"
                                                                                                                    ? "angry-post-text"
                                                                                                                    : ""
                                                                                                        }`}
                                                                                                />
                                                                                                <Typography
                                                                                                    className={`fs-12-action-btn text-capitalize ${post?.reaction ===
                                                                                                        "like"
                                                                                                        ? "liked-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "love"
                                                                                                            ? "love-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "smile"
                                                                                                                ? "smile-post-text"
                                                                                                                : post?.reaction ===
                                                                                                                    "angry"
                                                                                                                    ? "angry-post-text"
                                                                                                                    : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {post?.user_has_liked !==
                                                                                                        "no"
                                                                                                        ? post.reaction
                                                                                                        : "Like"}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                    {post?.is_post_comment ==
                                                                                        true && (
                                                                                            <Box
                                                                                                className="action-comments w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                                onClick={() =>
                                                                                                    handleCommentToggle(
                                                                                                        post?.id
                                                                                                    )
                                                                                                }
                                                                                                onMouseEnter={() => {
                                                                                                    handlePopoverClose();
                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                }}
                                                                                                onMouseLeave={() => {
                                                                                                    handlePopoverClose();
                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                }}
                                                                                            >
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        faComments
                                                                                                    }
                                                                                                    className="fs-12-action-btn"
                                                                                                />
                                                                                                <Typography className="fs-12-action-btn">
                                                                                                    Comments
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                </CardActions>
                                                                                {post?.is_post_comment ==
                                                                                    true && (
                                                                                        <CardActions
                                                                                            className="bg-white card-action-2"
                                                                                            sx={{
                                                                                                border: "1px solid #EEEEEE",
                                                                                                padding:
                                                                                                    "15px",
                                                                                            }}
                                                                                            onMouseEnter={() => {
                                                                                                handlePopoverClose();
                                                                                            }}
                                                                                            onMouseLeave={() => {
                                                                                                handlePopoverClose();
                                                                                            }}
                                                                                        >
                                                                                            {post?.is_post_comment ==
                                                                                                true && (
                                                                                                    <>
                                                                                                        {post?.commentToggle ==
                                                                                                            true && (
                                                                                                                <Box
                                                                                                                    className="w-100"
                                                                                                                    onMouseEnter={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                    onMouseLeave={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                        <Avatar
                                                                                                                            alt={
                                                                                                                                loggedUser?.name
                                                                                                                            }
                                                                                                                            className="author-avtar-creator"
                                                                                                                            src={
                                                                                                                                loggedUser?.thumbnail
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {loggedUser?.name?.charAt(
                                                                                                                                0
                                                                                                                            )}
                                                                                                                        </Avatar>
                                                                                                                        <InputGroup
                                                                                                                            className=""
                                                                                                                            style={{
                                                                                                                                height: "32px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <MentionsInput
                                                                                                                                placeholder="Add Comment. Use '@' for mention"
                                                                                                                                value={
                                                                                                                                    post?.commentText
                                                                                                                                }
                                                                                                                                singleLine={
                                                                                                                                    true
                                                                                                                                }
                                                                                                                                onChange={(
                                                                                                                                    event
                                                                                                                                ) => {
                                                                                                                                    handleCommentText(
                                                                                                                                        event
                                                                                                                                            .target
                                                                                                                                            .value,
                                                                                                                                        post?.id
                                                                                                                                    );
                                                                                                                                }}
                                                                                                                                onKeyDown={(
                                                                                                                                    e
                                                                                                                                ) => {
                                                                                                                                    if (
                                                                                                                                        e.key ===
                                                                                                                                        "Enter"
                                                                                                                                    ) {
                                                                                                                                        e.preventDefault();
                                                                                                                                        handleCommentsAPI(
                                                                                                                                            post?.id,
                                                                                                                                            "yes"
                                                                                                                                        );
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className="input-comments"
                                                                                                                                style={
                                                                                                                                    mentionsInputStyle
                                                                                                                                }
                                                                                                                                a11ySuggestionsListLabel={
                                                                                                                                    "Suggested mentions"
                                                                                                                                }
                                                                                                                                onPaste={
                                                                                                                                    handlePaste
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Mention
                                                                                                                                    style={
                                                                                                                                        mentionStyle
                                                                                                                                    }
                                                                                                                                    className="mention-popup-class"
                                                                                                                                    data={
                                                                                                                                        getMentionUsers
                                                                                                                                    }
                                                                                                                                    appendSpaceOnAdd={
                                                                                                                                        true
                                                                                                                                    }
                                                                                                                                    onAdd={() =>
                                                                                                                                        setIsSelectingSuggestion(
                                                                                                                                            true
                                                                                                                                        )
                                                                                                                                    } // Set the flag when a suggestion is selected
                                                                                                                                    renderSuggestion={(
                                                                                                                                        suggestion,
                                                                                                                                        search,
                                                                                                                                        highlightedDisplay,
                                                                                                                                        index,
                                                                                                                                        focused
                                                                                                                                    ) => (
                                                                                                                                        <div
                                                                                                                                            className="list-class"
                                                                                                                                            key={
                                                                                                                                                index
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <div className="list-class-div-1">
                                                                                                                                                <img
                                                                                                                                                    src={
                                                                                                                                                        suggestion.photo
                                                                                                                                                    }
                                                                                                                                                    alt={
                                                                                                                                                        suggestion.display
                                                                                                                                                    }
                                                                                                                                                    className="list-class-div-1-img"
                                                                                                                                                />
                                                                                                                                                <div className="list-class-div-2">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontWeight:
                                                                                                                                                                "bold",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        {
                                                                                                                                                            suggestion?.full_name
                                                                                                                                                        }
                                                                                                                                                    </span>
                                                                                                                                                    <br />
                                                                                                                                                    <span>
                                                                                                                                                        {
                                                                                                                                                            suggestion?.job_title
                                                                                                                                                        }
                                                                                                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                />
                                                                                                                            </MentionsInput>
                                                                                                                            <Button
                                                                                                                                className="search-btn-dash-2"
                                                                                                                                style={{
                                                                                                                                    height: "100%",
                                                                                                                                }}
                                                                                                                                onClick={() =>
                                                                                                                                    handleCommentsAPI(
                                                                                                                                        post?.id,
                                                                                                                                        "yes"
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <FontAwesomeIcon
                                                                                                                                    icon={
                                                                                                                                        faAdd
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </Button>
                                                                                                                        </InputGroup>
                                                                                                                    </Box>
                                                                                                                </Box>
                                                                                                            )}
                                                                                                    </>
                                                                                                )}
                                                                                            {post
                                                                                                ?.comments
                                                                                                ?.length >
                                                                                                0 && (
                                                                                                    <>
                                                                                                        {post?.comments?.map(
                                                                                                            (
                                                                                                                comment
                                                                                                            ) => (
                                                                                                                <>
                                                                                                                    {comment?.is_parent ===
                                                                                                                        "yes" && (
                                                                                                                            <>
                                                                                                                                <Box
                                                                                                                                    className="w-100 ms-0"
                                                                                                                                    onMouseEnter={
                                                                                                                                        handlePopoverClose
                                                                                                                                    }
                                                                                                                                    onMouseLeave={
                                                                                                                                        handlePopoverClose
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                        <Avatar
                                                                                                                                            alt={
                                                                                                                                                comment
                                                                                                                                                    ?.user
                                                                                                                                                    ?.name
                                                                                                                                            }
                                                                                                                                            className="author-avtar-creator mb-auto"
                                                                                                                                            src={
                                                                                                                                                comment
                                                                                                                                                    ?.user
                                                                                                                                                    ?.thumbnail
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            {comment?.user?.name?.charAt(
                                                                                                                                                0
                                                                                                                                            )}
                                                                                                                                        </Avatar>
                                                                                                                                        <Box
                                                                                                                                            sx={{
                                                                                                                                                padding:
                                                                                                                                                    "15px",
                                                                                                                                            }}
                                                                                                                                            className="comment-box w-100"
                                                                                                                                        >
                                                                                                                                            <Typography className="fs-14-700">
                                                                                                                                                {
                                                                                                                                                    comment
                                                                                                                                                        ?.user
                                                                                                                                                        ?.name
                                                                                                                                                }
                                                                                                                                            </Typography>
                                                                                                                                            <Typography className="fs-14 mt-2">
                                                                                                                                                {renderCommentText(
                                                                                                                                                    comment?.comment
                                                                                                                                                )}
                                                                                                                                            </Typography>
                                                                                                                                            <Stack
                                                                                                                                                direction="row"
                                                                                                                                                divider={
                                                                                                                                                    <Divider
                                                                                                                                                        orientation="vertical"
                                                                                                                                                        flexItem
                                                                                                                                                        className="nested-divider"
                                                                                                                                                    />
                                                                                                                                                }
                                                                                                                                                className="mt-2 align-items-center"
                                                                                                                                                spacing={
                                                                                                                                                    1
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <Box>
                                                                                                                                                    <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                        <Typography
                                                                                                                                                            className={`fs-10 cursor-pointer ${comment?.has_user_liked_comment ===
                                                                                                                                                                "yes" &&
                                                                                                                                                                "liked-post-text"
                                                                                                                                                                }`}
                                                                                                                                                            onClick={() => {
                                                                                                                                                                toggleLikeForCommentsReaction(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    comment?.has_user_liked_comment ===
                                                                                                                                                                        "no"
                                                                                                                                                                        ? "like"
                                                                                                                                                                        : "no",
                                                                                                                                                                    comment?.id,
                                                                                                                                                                    comment?.has_user_liked_comment ===
                                                                                                                                                                        "no"
                                                                                                                                                                        ? true
                                                                                                                                                                        : false
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onMouseEnter={(
                                                                                                                                                                e
                                                                                                                                                            ) => {
                                                                                                                                                                setCurrentPostId(
                                                                                                                                                                    post?.id
                                                                                                                                                                );

                                                                                                                                                                setCurrentPostIdForReply(
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                                handlePopoverOpenForReplyLike(
                                                                                                                                                                    e,
                                                                                                                                                                    post.id,
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onMouseLeave={() => {
                                                                                                                                                                setCurrentPostId(
                                                                                                                                                                    post?.id
                                                                                                                                                                );

                                                                                                                                                                setCurrentPostIdForReply(
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            Like
                                                                                                                                                        </Typography>
                                                                                                                                                        <Box
                                                                                                                                                            className="action-like w-100 d-flex flex-wrap align-items-center gap-1 cursor-pointer"
                                                                                                                                                            onMouseEnter={() => {
                                                                                                                                                                handlePopoverClose();
                                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                                            }}
                                                                                                                                                            onMouseLeave={() => {
                                                                                                                                                                handlePopoverClose();
                                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                                            }}
                                                                                                                                                            onClick={() => { handleEngagementModalOpen(comment?.id, 'comment') }}
                                                                                                                                                        >
                                                                                                                                                            {comment?.user_reactions?.map(
                                                                                                                                                                (
                                                                                                                                                                    reaction
                                                                                                                                                                ) => (
                                                                                                                                                                    <FontAwesomeIcon
                                                                                                                                                                        icon={
                                                                                                                                                                            reaction ===
                                                                                                                                                                                "love"
                                                                                                                                                                                ? faHeart
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "smile"
                                                                                                                                                                                    ? faSmile
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "angry"
                                                                                                                                                                                        ? faAngry
                                                                                                                                                                                        : reaction ===
                                                                                                                                                                                            "like"
                                                                                                                                                                                            ? faThumbsUp
                                                                                                                                                                                            : ""
                                                                                                                                                                        }
                                                                                                                                                                        className={
                                                                                                                                                                            reaction ===
                                                                                                                                                                                "love"
                                                                                                                                                                                ? "fa-love-btn-2"
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "smile"
                                                                                                                                                                                    ? "fa-smile-btn-2"
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "angry"
                                                                                                                                                                                        ? "fa-angry-btn-2"
                                                                                                                                                                                        : reaction ===
                                                                                                                                                                                            "like"
                                                                                                                                                                                            ? "fa-like-btn-2"
                                                                                                                                                                                            : ""
                                                                                                                                                                        }
                                                                                                                                                                    />
                                                                                                                                                                )
                                                                                                                                                            )}

                                                                                                                                                            <Box className="like-counts-box">
                                                                                                                                                                <Typography className="fs-10">
                                                                                                                                                                    {
                                                                                                                                                                        comment?.comment_likes_count
                                                                                                                                                                    }
                                                                                                                                                                </Typography>
                                                                                                                                                            </Box>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                                <Box
                                                                                                                                                    onMouseEnter={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                    onMouseLeave={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <Box>
                                                                                                                                                        <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                            <Typography
                                                                                                                                                                className="fs-10 cursor-pointer"
                                                                                                                                                                onClick={() =>
                                                                                                                                                                    handleCommentReplyToggle(
                                                                                                                                                                        post?.id,
                                                                                                                                                                        comment?.id
                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                            >
                                                                                                                                                                Reply
                                                                                                                                                            </Typography>
                                                                                                                                                            <Typography className="fs-10 cursor-pointer">
                                                                                                                                                                {`${comment?.replies_count} reply`}
                                                                                                                                                            </Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Stack>
                                                                                                                                        </Box>
                                                                                                                                    </Box>
                                                                                                                                </Box>
                                                                                                                                {comment?.commentReplyToggle ==
                                                                                                                                    true && (
                                                                                                                                        <Box
                                                                                                                                            className="w-100 ms-0"
                                                                                                                                            sx={{
                                                                                                                                                paddingLeft:
                                                                                                                                                    "62px",
                                                                                                                                            }}
                                                                                                                                            onMouseEnter={() => {
                                                                                                                                                handlePopoverClose();
                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                            }}
                                                                                                                                            onMouseLeave={() => {
                                                                                                                                                handlePopoverClose();
                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <Box className="w-100 mt-2 mb-1">
                                                                                                                                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                    <Avatar
                                                                                                                                                        alt={
                                                                                                                                                            loggedUser?.name
                                                                                                                                                        }
                                                                                                                                                        className="author-avtar-creator-2"
                                                                                                                                                        src={
                                                                                                                                                            loggedUser?.thumbnail
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        {loggedUser?.name?.charAt(
                                                                                                                                                            0
                                                                                                                                                        )}
                                                                                                                                                    </Avatar>
                                                                                                                                                    <InputGroup
                                                                                                                                                        className=""
                                                                                                                                                        style={{
                                                                                                                                                            height: "25px",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <MentionsInput
                                                                                                                                                            placeholder="Add Comment. Use '@' for mention"
                                                                                                                                                            value={
                                                                                                                                                                comment?.commentReplyText
                                                                                                                                                            }
                                                                                                                                                            singleLine={
                                                                                                                                                                true
                                                                                                                                                            }
                                                                                                                                                            onChange={(
                                                                                                                                                                event
                                                                                                                                                            ) => {
                                                                                                                                                                console.log(
                                                                                                                                                                    "event:::",
                                                                                                                                                                    event
                                                                                                                                                                );
                                                                                                                                                                handleCommentReplyText(
                                                                                                                                                                    event
                                                                                                                                                                        .target
                                                                                                                                                                        .value,
                                                                                                                                                                    post?.id,
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onKeyDown={(
                                                                                                                                                                e
                                                                                                                                                            ) => {
                                                                                                                                                                if (
                                                                                                                                                                    e.key ===
                                                                                                                                                                    "Enter"
                                                                                                                                                                ) {
                                                                                                                                                                    e.preventDefault();
                                                                                                                                                                    handleCommentsReplyAPI(
                                                                                                                                                                        post?.id,
                                                                                                                                                                        "no",
                                                                                                                                                                        comment?.id
                                                                                                                                                                    );
                                                                                                                                                                }
                                                                                                                                                            }}
                                                                                                                                                            className="input-comments"
                                                                                                                                                            style={
                                                                                                                                                                mentionsInputStyle
                                                                                                                                                            }
                                                                                                                                                            a11ySuggestionsListLabel={
                                                                                                                                                                "Suggested mentions"
                                                                                                                                                            }
                                                                                                                                                            onPaste={
                                                                                                                                                                handlePaste
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            <Mention
                                                                                                                                                                style={
                                                                                                                                                                    mentionStyle
                                                                                                                                                                }
                                                                                                                                                                className="mention-popup-class"
                                                                                                                                                                data={
                                                                                                                                                                    getMentionUsers
                                                                                                                                                                }
                                                                                                                                                                onAdd={() =>
                                                                                                                                                                    setIsSelectingSuggestion(
                                                                                                                                                                        true
                                                                                                                                                                    )
                                                                                                                                                                } // Set the flag when a suggestion is selected
                                                                                                                                                                appendSpaceOnAdd={
                                                                                                                                                                    true
                                                                                                                                                                }
                                                                                                                                                                renderSuggestion={(
                                                                                                                                                                    suggestion,
                                                                                                                                                                    search,
                                                                                                                                                                    highlightedDisplay,
                                                                                                                                                                    index,
                                                                                                                                                                    focused
                                                                                                                                                                ) => (
                                                                                                                                                                    <div
                                                                                                                                                                        className="list-class"
                                                                                                                                                                        key={
                                                                                                                                                                            index
                                                                                                                                                                        }
                                                                                                                                                                    >
                                                                                                                                                                        <div className="list-class-div-1">
                                                                                                                                                                            <img
                                                                                                                                                                                src={
                                                                                                                                                                                    suggestion.photo
                                                                                                                                                                                }
                                                                                                                                                                                alt={
                                                                                                                                                                                    suggestion.display
                                                                                                                                                                                }
                                                                                                                                                                                className="list-class-div-1-img"
                                                                                                                                                                            />
                                                                                                                                                                            <div className="list-class-div-2">
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontWeight:
                                                                                                                                                                                            "bold",
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    {
                                                                                                                                                                                        suggestion?.full_name
                                                                                                                                                                                    }
                                                                                                                                                                                </span>
                                                                                                                                                                                <br />
                                                                                                                                                                                <span>
                                                                                                                                                                                    {
                                                                                                                                                                                        suggestion?.job_title
                                                                                                                                                                                    }
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                )}
                                                                                                                                                            />
                                                                                                                                                        </MentionsInput>
                                                                                                                                                        <Button
                                                                                                                                                            className="search-btn-dash-3"
                                                                                                                                                            style={{
                                                                                                                                                                height: "100%",
                                                                                                                                                            }}
                                                                                                                                                            onClick={() =>
                                                                                                                                                                handleCommentsReplyAPI(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    "no",
                                                                                                                                                                    comment?.id
                                                                                                                                                                )
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            <FontAwesomeIcon
                                                                                                                                                                icon={
                                                                                                                                                                    faAdd
                                                                                                                                                                }
                                                                                                                                                            />
                                                                                                                                                        </Button>
                                                                                                                                                    </InputGroup>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                        </Box>
                                                                                                                                    )}
                                                                                                                                {comment
                                                                                                                                    ?.replies
                                                                                                                                    ?.length >
                                                                                                                                    0 ? (
                                                                                                                                    <>
                                                                                                                                        {comment?.replies?.map(
                                                                                                                                            (
                                                                                                                                                reply
                                                                                                                                            ) => (
                                                                                                                                                <Box
                                                                                                                                                    className="w-100 ms-0"
                                                                                                                                                    sx={{
                                                                                                                                                        paddingLeft:
                                                                                                                                                            "62px",
                                                                                                                                                    }}
                                                                                                                                                    onMouseEnter={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                    onMouseLeave={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <Box className="w-100 mt-2">
                                                                                                                                                        <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                            <Avatar
                                                                                                                                                                alt={
                                                                                                                                                                    reply
                                                                                                                                                                        ?.user
                                                                                                                                                                        ?.name
                                                                                                                                                                }
                                                                                                                                                                className="author-avtar-creator-2 mb-auto"
                                                                                                                                                                src={
                                                                                                                                                                    reply
                                                                                                                                                                        ?.user
                                                                                                                                                                        ?.thumbnail
                                                                                                                                                                }
                                                                                                                                                            >
                                                                                                                                                                {reply?.user?.name?.charAt(
                                                                                                                                                                    0
                                                                                                                                                                )}
                                                                                                                                                            </Avatar>
                                                                                                                                                            <Box
                                                                                                                                                                sx={{
                                                                                                                                                                    padding:
                                                                                                                                                                        "10px",
                                                                                                                                                                }}
                                                                                                                                                                className="comment-box w-100"
                                                                                                                                                            >
                                                                                                                                                                <Typography className="fs-12-700">
                                                                                                                                                                    {
                                                                                                                                                                        reply
                                                                                                                                                                            ?.user
                                                                                                                                                                            ?.name
                                                                                                                                                                    }
                                                                                                                                                                </Typography>
                                                                                                                                                                <Typography className="fs-12 mt-1">
                                                                                                                                                                    {renderCommentText(
                                                                                                                                                                        reply?.comment
                                                                                                                                                                    )}
                                                                                                                                                                </Typography>
                                                                                                                                                            </Box>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            )
                                                                                                                                        )}
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    ""
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                </>
                                                                                                            )
                                                                                                        )}
                                                                                                        {post
                                                                                                            ?.comments
                                                                                                            ?.length <
                                                                                                            post?.parent_comment_count && (
                                                                                                                <Box
                                                                                                                    className="w-100 ms-0 d-flex justify-content-center"
                                                                                                                    onMouseEnter={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                    onMouseLeave={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Button
                                                                                                                        className="w-50 mt-4 btn-load-comments"
                                                                                                                        onClick={() => {
                                                                                                                            setFetchAll(
                                                                                                                                true
                                                                                                                            );
                                                                                                                            fetchLoadMoreCommentsAPI(
                                                                                                                                post
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <FontAwesomeIcon
                                                                                                                            icon={
                                                                                                                                faComments
                                                                                                                            }
                                                                                                                        />
                                                                                                                        Load
                                                                                                                        More
                                                                                                                        Comments
                                                                                                                    </Button>
                                                                                                                </Box>
                                                                                                            )}
                                                                                                    </>
                                                                                                )}
                                                                                        </CardActions>
                                                                                    )}
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Card>
                                                                </>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                            </>
                                        )}

                                        {unseenPosts?.length > 0 && (
                                            <>
                                                {unseenPosts?.map(
                                                    (post, index) => (
                                                        <>
                                                            {post?.type ==
                                                                "Update" ? (
                                                                <Card
                                                                    className="mt-4"
                                                                    data-post-id={
                                                                        post?.id
                                                                    }
                                                                    ref={
                                                                        setElementsCallback
                                                                    }
                                                                    data-post-view={
                                                                        post?.is_post_seen
                                                                    }
                                                                >
                                                                    <CardHeader
                                                                        avatar={
                                                                            <Avatar
                                                                                aria-label="recipe"
                                                                                className="author-avtar-creator"
                                                                                src={
                                                                                    post?.profile
                                                                                }
                                                                            >
                                                                                {post?.created_by?.charAt(
                                                                                    0
                                                                                )}
                                                                            </Avatar>
                                                                        }
                                                                        title={
                                                                            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                                                                <span>
                                                                                    <b>{post?.created_by}</b>{" "}posted a{" "}<b>{post?.type}</b>
                                                                                </span>
                                                                                <Typography variant="caption">
                                                                                    {dateWithTime(post?.published_date_time, false, false, false)}
                                                                                </Typography>
                                                                            </Box>
                                                                        }
                                                                        className="card-header-content"
                                                                    />
                                                                    {post?.image !==
                                                                        "" && (
                                                                            <CardMedia
                                                                                sx={{
                                                                                    height: 222,
                                                                                }}
                                                                                image={
                                                                                    post?.image
                                                                                }
                                                                            />
                                                                        )}

                                                                    <CardContent className="content-div-body">
                                                                        <Box>
                                                                            <Typography
                                                                                variant="h3"
                                                                                component="h3"
                                                                                className="content-title-update text-break"
                                                                            >
                                                                                {
                                                                                    post?.content
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                        {post?.is_require_acknowledge ==
                                                                            true && (
                                                                                <Box
                                                                                    className={`custom-checkbox mt-3 ${post?.acknowledged_on !==
                                                                                        "" &&
                                                                                        "active-checkbox"
                                                                                        }`}
                                                                                >
                                                                                    <label className="checkbox-style">
                                                                                        {post?.acknowledged_on !==
                                                                                            ""
                                                                                            ? `You acknowledged this on ${onlyDateFormat(post?.acknowledged_on, true, false, true)}`
                                                                                            : ((post?.acknowledge_data && (typeof post?.acknowledge_data === "string")) ? `${post?.acknowledge_data}` : `This requires your acknowledgement`)}
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                                post?.acknowledged_on !==
                                                                                                ""
                                                                                            }
                                                                                            onChange={() => {
                                                                                                if (
                                                                                                    post?.acknowledge_type ===
                                                                                                    "question"
                                                                                                ) {
                                                                                                    setAckQuestions(
                                                                                                        post?.acknowledge_data
                                                                                                    );
                                                                                                    setPostIdForAckQue(
                                                                                                        post?.id
                                                                                                    );
                                                                                                    setOpenAckQuePopup(
                                                                                                        true
                                                                                                    );
                                                                                                } else if (
                                                                                                    post?.acknowledge_type ===
                                                                                                    "checkbox"
                                                                                                ) {
                                                                                                    handleCheckboxChange(
                                                                                                        post?.id
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            disabled={
                                                                                                post?.acknowledged_on !==
                                                                                                ""
                                                                                            }
                                                                                        />
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                </Box>
                                                                            )}
                                                                    </CardContent>
                                                                    {post?.is_post_comment ===
                                                                        true ||
                                                                        post?.is_post_like ===
                                                                        true ? (
                                                                        <>
                                                                            <CardActions
                                                                                className="bg-white"
                                                                                sx={{
                                                                                    border: "1px solid #EEEEEE",
                                                                                    padding:
                                                                                        "10px",
                                                                                }}
                                                                                onMouseEnter={() => {
                                                                                    handlePopoverClose();
                                                                                    handlePopoverCloseForReplyLike();
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    handlePopoverClose();
                                                                                    handlePopoverCloseForReplyLike();
                                                                                }}
                                                                            >
                                                                                {post?.is_post_like ==
                                                                                    true && (
                                                                                        <Box className="action-like w-100 d-flex flex-wrap align-items-center gap-2 cursor-pointer"
                                                                                            onClick={() => { handleEngagementModalOpen(post?.id) }}
                                                                                        >
                                                                                            {post?.reactions?.map(
                                                                                                (
                                                                                                    reaction
                                                                                                ) => (
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={
                                                                                                            reaction ===
                                                                                                                "love"
                                                                                                                ? faHeart
                                                                                                                : reaction ===
                                                                                                                    "smile"
                                                                                                                    ? faSmile
                                                                                                                    : reaction ===
                                                                                                                        "angry"
                                                                                                                        ? faAngry
                                                                                                                        : reaction ===
                                                                                                                            "like"
                                                                                                                            ? faThumbsUp
                                                                                                                            : ""
                                                                                                        }
                                                                                                        className={
                                                                                                            reaction ===
                                                                                                                "love"
                                                                                                                ? "fa-love-btn"
                                                                                                                : reaction ===
                                                                                                                    "smile"
                                                                                                                    ? "fa-smile-btn"
                                                                                                                    : reaction ===
                                                                                                                        "angry"
                                                                                                                        ? "fa-angry-btn"
                                                                                                                        : reaction ===
                                                                                                                            "like"
                                                                                                                            ? "fa-like-btn"
                                                                                                                            : ""
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            )}

                                                                                            <Box className="like-counts-box">
                                                                                                <Typography className="fs-12-action-btn">
                                                                                                    {
                                                                                                        post?.like_count
                                                                                                    }
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    )}
                                                                                {post?.is_post_comment ==
                                                                                    true && (
                                                                                        <Box className="w-100 ms-0">
                                                                                            <Typography className="fs-12-action-btn d-flex  justify-content-end">
                                                                                                {`${post?.comment_count} comments`}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                            </CardActions>
                                                                            <CardActions
                                                                                className="bg-white"
                                                                                sx={{
                                                                                    border: "1px solid #EEEEEE",
                                                                                    padding:
                                                                                        "10px",
                                                                                }}
                                                                            >
                                                                                {post?.is_post_like ==
                                                                                    true && (
                                                                                        <Box
                                                                                            className="action-like w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                            onClick={() => {
                                                                                                toggleReaction(
                                                                                                    post?.id,
                                                                                                    post?.user_has_liked ===
                                                                                                        "no"
                                                                                                        ? "like"
                                                                                                        : "no",
                                                                                                    post?.user_has_liked ===
                                                                                                        "no"
                                                                                                        ? true
                                                                                                        : false
                                                                                                );
                                                                                                handlePopoverClose();
                                                                                            }}
                                                                                            onMouseEnter={(
                                                                                                e
                                                                                            ) =>
                                                                                                handlePopoverOpen(
                                                                                                    e,
                                                                                                    post.id
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    post?.reaction ===
                                                                                                        "like"
                                                                                                        ? faThumbsUp
                                                                                                        : post?.reaction ===
                                                                                                            "love"
                                                                                                            ? faHeart
                                                                                                            : post?.reaction ===
                                                                                                                "smile"
                                                                                                                ? faSmile
                                                                                                                : post?.reaction ===
                                                                                                                    "angry"
                                                                                                                    ? faAngry
                                                                                                                    : faThumbsUp
                                                                                                }
                                                                                                className={`fs-12-action-btn ${post?.reaction ===
                                                                                                    "like"
                                                                                                    ? "liked-post-text"
                                                                                                    : post?.reaction ===
                                                                                                        "love"
                                                                                                        ? "love-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "smile"
                                                                                                            ? "smile-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "angry"
                                                                                                                ? "angry-post-text"
                                                                                                                : ""
                                                                                                    }`}
                                                                                            />
                                                                                            <Typography
                                                                                                className={`fs-12-action-btn text-capitalize ${post?.reaction ===
                                                                                                    "like"
                                                                                                    ? "liked-post-text"
                                                                                                    : post?.reaction ===
                                                                                                        "love"
                                                                                                        ? "love-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "smile"
                                                                                                            ? "smile-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "angry"
                                                                                                                ? "angry-post-text"
                                                                                                                : ""
                                                                                                    }`}
                                                                                            >
                                                                                                {post?.user_has_liked !==
                                                                                                    "no"
                                                                                                    ? post.reaction
                                                                                                    : "Like"}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                                {post?.is_post_comment ==
                                                                                    true && (
                                                                                        <Box
                                                                                            className="action-comments w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                            onClick={() =>
                                                                                                handleCommentToggle(
                                                                                                    post?.id
                                                                                                )
                                                                                            }
                                                                                            onMouseEnter={() => {
                                                                                                handlePopoverClose();
                                                                                                handlePopoverCloseForReplyLike();
                                                                                            }}
                                                                                            onMouseLeave={() => {
                                                                                                handlePopoverClose();
                                                                                                handlePopoverCloseForReplyLike();
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    faComments
                                                                                                }
                                                                                                className="fs-12-action-btn"
                                                                                            />
                                                                                            <Typography className="fs-12-action-btn">
                                                                                                Comments
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                            </CardActions>
                                                                            {post?.is_post_comment ==
                                                                                true && (
                                                                                    <CardActions
                                                                                        className="bg-white card-action-2"
                                                                                        sx={{
                                                                                            border: "1px solid #EEEEEE",
                                                                                            padding:
                                                                                                "15px",
                                                                                        }}
                                                                                        onMouseEnter={() => {
                                                                                            handlePopoverClose();
                                                                                        }}
                                                                                        onMouseLeave={() => {
                                                                                            handlePopoverClose();
                                                                                        }}
                                                                                    >
                                                                                        {post?.is_post_comment ==
                                                                                            true && (
                                                                                                <>
                                                                                                    {post?.commentToggle ==
                                                                                                        true && (
                                                                                                            <Box
                                                                                                                className="w-100"
                                                                                                                onMouseEnter={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                                onMouseLeave={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                            >
                                                                                                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                    <Avatar
                                                                                                                        alt={
                                                                                                                            loggedUser?.name
                                                                                                                        }
                                                                                                                        className="author-avtar-creator"
                                                                                                                        src={
                                                                                                                            loggedUser?.thumbnail
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {loggedUser?.name?.charAt(
                                                                                                                            0
                                                                                                                        )}
                                                                                                                    </Avatar>
                                                                                                                    <InputGroup
                                                                                                                        className=""
                                                                                                                        style={{
                                                                                                                            height: "32px",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <MentionsInput
                                                                                                                            placeholder="Add Comment. Use '@' for mention and '&' for emojis"
                                                                                                                            value={
                                                                                                                                post?.commentText
                                                                                                                            }
                                                                                                                            onChange={(
                                                                                                                                event
                                                                                                                            ) =>
                                                                                                                                handleCommentText(
                                                                                                                                    event
                                                                                                                                        .target
                                                                                                                                        .value,
                                                                                                                                    post?.id
                                                                                                                                )
                                                                                                                            }
                                                                                                                            onKeyDown={(
                                                                                                                                e
                                                                                                                            ) => {
                                                                                                                                if (
                                                                                                                                    e.key ===
                                                                                                                                    "Enter"
                                                                                                                                ) {
                                                                                                                                    e.preventDefault();
                                                                                                                                    handleCommentsAPI(
                                                                                                                                        post?.id,
                                                                                                                                        "yes"
                                                                                                                                    );
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            className="input-comments"
                                                                                                                            singleLine={
                                                                                                                                true
                                                                                                                            }
                                                                                                                            style={
                                                                                                                                mentionsInputStyle
                                                                                                                            }
                                                                                                                            a11ySuggestionsListLabel={
                                                                                                                                "Suggested mentions"
                                                                                                                            }
                                                                                                                            onPaste={
                                                                                                                                handlePaste
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <Mention
                                                                                                                                style={
                                                                                                                                    mentionStyle
                                                                                                                                }
                                                                                                                                className="mention-popup-class"
                                                                                                                                data={
                                                                                                                                    getMentionUsers
                                                                                                                                }
                                                                                                                                onAdd={() =>
                                                                                                                                    setIsSelectingSuggestion(
                                                                                                                                        true
                                                                                                                                    )
                                                                                                                                } // Set the flag when a suggestion is selected
                                                                                                                                renderSuggestion={(
                                                                                                                                    suggestion,
                                                                                                                                    search,
                                                                                                                                    highlightedDisplay,
                                                                                                                                    index,
                                                                                                                                    focused
                                                                                                                                ) => (
                                                                                                                                    <div
                                                                                                                                        className="list-class"
                                                                                                                                        key={
                                                                                                                                            index
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <div className="list-class-div-1">
                                                                                                                                            <img
                                                                                                                                                src={
                                                                                                                                                    suggestion.photo
                                                                                                                                                }
                                                                                                                                                alt={
                                                                                                                                                    suggestion.display
                                                                                                                                                }
                                                                                                                                                className="list-class-div-1-img"
                                                                                                                                            />
                                                                                                                                            <div className="list-class-div-2">
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontWeight:
                                                                                                                                                            "bold",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    {
                                                                                                                                                        suggestion?.full_name
                                                                                                                                                    }
                                                                                                                                                </span>
                                                                                                                                                <br />
                                                                                                                                                <span>
                                                                                                                                                    {
                                                                                                                                                        suggestion?.job_title
                                                                                                                                                    }
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                            />
                                                                                                                        </MentionsInput>
                                                                                                                        <Button
                                                                                                                            className="search-btn-dash-2"
                                                                                                                            style={{
                                                                                                                                height: "100%",
                                                                                                                            }}
                                                                                                                            onClick={() =>
                                                                                                                                handleCommentsAPI(
                                                                                                                                    post?.id,
                                                                                                                                    "yes"
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon
                                                                                                                                icon={
                                                                                                                                    faAdd
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </Button>
                                                                                                                    </InputGroup>
                                                                                                                </Box>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                </>
                                                                                            )}
                                                                                        {post
                                                                                            ?.comments
                                                                                            ?.length >
                                                                                            0 && (
                                                                                                <>
                                                                                                    {post?.comments?.map(
                                                                                                        (
                                                                                                            comment
                                                                                                        ) => (
                                                                                                            <>
                                                                                                                {comment?.is_parent ===
                                                                                                                    "yes" && (
                                                                                                                        <>
                                                                                                                            <Box
                                                                                                                                className="w-100 ms-0"
                                                                                                                                onMouseEnter={
                                                                                                                                    handlePopoverClose
                                                                                                                                }
                                                                                                                                onMouseLeave={
                                                                                                                                    handlePopoverClose
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                    <Avatar
                                                                                                                                        alt={
                                                                                                                                            comment
                                                                                                                                                ?.user
                                                                                                                                                ?.name
                                                                                                                                        }
                                                                                                                                        className="author-avtar-creator mb-auto"
                                                                                                                                        src={
                                                                                                                                            comment
                                                                                                                                                ?.user
                                                                                                                                                ?.thumbnail
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        {comment?.user?.name?.charAt(
                                                                                                                                            0
                                                                                                                                        )}
                                                                                                                                    </Avatar>
                                                                                                                                    <Box
                                                                                                                                        sx={{
                                                                                                                                            padding:
                                                                                                                                                "15px",
                                                                                                                                        }}
                                                                                                                                        className="comment-box w-100"
                                                                                                                                    >
                                                                                                                                        <Typography className="fs-14-700">
                                                                                                                                            {
                                                                                                                                                comment
                                                                                                                                                    ?.user
                                                                                                                                                    ?.name
                                                                                                                                            }
                                                                                                                                        </Typography>
                                                                                                                                        <Typography className="fs-14 mt-2">
                                                                                                                                            {renderCommentText(
                                                                                                                                                comment?.comment
                                                                                                                                            )}
                                                                                                                                        </Typography>
                                                                                                                                        <Stack
                                                                                                                                            direction="row"
                                                                                                                                            divider={
                                                                                                                                                <Divider
                                                                                                                                                    orientation="vertical"
                                                                                                                                                    flexItem
                                                                                                                                                    className="nested-divider"
                                                                                                                                                />
                                                                                                                                            }
                                                                                                                                            className="mt-2 align-items-center"
                                                                                                                                            spacing={
                                                                                                                                                1
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <Box>
                                                                                                                                                <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                    <Typography
                                                                                                                                                        className={`fs-10 cursor-pointer  ${comment?.reaction ===
                                                                                                                                                            "like"
                                                                                                                                                            ? "liked-post-text"
                                                                                                                                                            : comment?.reaction ===
                                                                                                                                                                "love"
                                                                                                                                                                ? "love-post-text"
                                                                                                                                                                : comment?.reaction ===
                                                                                                                                                                    "smile"
                                                                                                                                                                    ? "smile-post-text"
                                                                                                                                                                    : comment?.reaction ===
                                                                                                                                                                        "angry"
                                                                                                                                                                        ? "angry-post-text"
                                                                                                                                                                        : ""
                                                                                                                                                            }`}
                                                                                                                                                        onClick={() => {
                                                                                                                                                            toggleLikeForCommentsReaction(
                                                                                                                                                                post?.id,
                                                                                                                                                                comment?.has_user_liked_comment ===
                                                                                                                                                                    "no"
                                                                                                                                                                    ? "like"
                                                                                                                                                                    : "no",
                                                                                                                                                                comment?.id,
                                                                                                                                                                comment?.has_user_liked_comment ===
                                                                                                                                                                    "no"
                                                                                                                                                                    ? true
                                                                                                                                                                    : false
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                        onMouseEnter={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            setCurrentPostId(
                                                                                                                                                                post?.id
                                                                                                                                                            );

                                                                                                                                                            setCurrentPostIdForReply(
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                            handlePopoverOpenForReplyLike(
                                                                                                                                                                e,
                                                                                                                                                                post.id,
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                        onMouseLeave={() => {
                                                                                                                                                            setCurrentPostId(
                                                                                                                                                                post?.id
                                                                                                                                                            );

                                                                                                                                                            setCurrentPostIdForReply(
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        {comment?.has_user_liked_comment !==
                                                                                                                                                            "no"
                                                                                                                                                            ? comment.reaction
                                                                                                                                                            : "Like"}
                                                                                                                                                    </Typography>
                                                                                                                                                    <Box
                                                                                                                                                        className="action-like w-100 d-flex flex-wrap align-items-center gap-1 cursor-pointer"
                                                                                                                                                        onMouseEnter={() => {
                                                                                                                                                            handlePopoverClose();
                                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                                        }}
                                                                                                                                                        onMouseLeave={() => {
                                                                                                                                                            handlePopoverClose();
                                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                                        }}
                                                                                                                                                        onClick={() => { handleEngagementModalOpen(comment?.id, 'comment') }}
                                                                                                                                                    >
                                                                                                                                                        {comment?.user_reactions?.map(
                                                                                                                                                            (
                                                                                                                                                                reaction
                                                                                                                                                            ) => (
                                                                                                                                                                <FontAwesomeIcon
                                                                                                                                                                    icon={
                                                                                                                                                                        reaction ===
                                                                                                                                                                            "love"
                                                                                                                                                                            ? faHeart
                                                                                                                                                                            : reaction ===
                                                                                                                                                                                "smile"
                                                                                                                                                                                ? faSmile
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "angry"
                                                                                                                                                                                    ? faAngry
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "like"
                                                                                                                                                                                        ? faThumbsUp
                                                                                                                                                                                        : ""
                                                                                                                                                                    }
                                                                                                                                                                    className={
                                                                                                                                                                        reaction ===
                                                                                                                                                                            "love"
                                                                                                                                                                            ? "fa-love-btn-2"
                                                                                                                                                                            : reaction ===
                                                                                                                                                                                "smile"
                                                                                                                                                                                ? "fa-smile-btn-2"
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "angry"
                                                                                                                                                                                    ? "fa-angry-btn-2"
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "like"
                                                                                                                                                                                        ? "fa-like-btn-2"
                                                                                                                                                                                        : ""
                                                                                                                                                                    }
                                                                                                                                                                />
                                                                                                                                                            )
                                                                                                                                                        )}

                                                                                                                                                        <Box className="like-counts-box">
                                                                                                                                                            <Typography className="fs-10">
                                                                                                                                                                {
                                                                                                                                                                    comment?.comment_likes_count
                                                                                                                                                                }
                                                                                                                                                            </Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                            <Box
                                                                                                                                                onMouseEnter={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                                onMouseLeave={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Box>
                                                                                                                                                    <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                        <Typography
                                                                                                                                                            className="fs-10 cursor-pointer"
                                                                                                                                                            onClick={() =>
                                                                                                                                                                handleCommentReplyToggle(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    comment?.id
                                                                                                                                                                )
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            Reply
                                                                                                                                                        </Typography>
                                                                                                                                                        <Typography className="fs-10 cursor-pointer">
                                                                                                                                                            {`${comment?.replies_count} reply`}
                                                                                                                                                        </Typography>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                        </Stack>
                                                                                                                                    </Box>
                                                                                                                                </Box>
                                                                                                                            </Box>
                                                                                                                            {comment?.commentReplyToggle ==
                                                                                                                                true && (
                                                                                                                                    <Box
                                                                                                                                        className="w-100 ms-0"
                                                                                                                                        sx={{
                                                                                                                                            paddingLeft:
                                                                                                                                                "62px",
                                                                                                                                        }}
                                                                                                                                        onMouseEnter={() => {
                                                                                                                                            handlePopoverClose();
                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                        }}
                                                                                                                                        onMouseLeave={() => {
                                                                                                                                            handlePopoverClose();
                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <Box className="w-100 mt-2 mb-1">
                                                                                                                                            <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                <Avatar
                                                                                                                                                    alt={
                                                                                                                                                        loggedUser?.name
                                                                                                                                                    }
                                                                                                                                                    className="author-avtar-creator-2"
                                                                                                                                                    src={
                                                                                                                                                        loggedUser?.thumbnail
                                                                                                                                                    }
                                                                                                                                                >
                                                                                                                                                    {loggedUser?.name?.charAt(
                                                                                                                                                        0
                                                                                                                                                    )}
                                                                                                                                                </Avatar>
                                                                                                                                                <InputGroup
                                                                                                                                                    className=""
                                                                                                                                                    style={{
                                                                                                                                                        height: "25px",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <MentionsInput
                                                                                                                                                        placeholder="Add Comment. Use '@' for mention"
                                                                                                                                                        value={
                                                                                                                                                            comment?.commentReplyText
                                                                                                                                                        }
                                                                                                                                                        singleLine={
                                                                                                                                                            true
                                                                                                                                                        }
                                                                                                                                                        onChange={(
                                                                                                                                                            event
                                                                                                                                                        ) => {
                                                                                                                                                            handleCommentReplyText(
                                                                                                                                                                event
                                                                                                                                                                    .target
                                                                                                                                                                    .value,
                                                                                                                                                                post?.id,
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                        onKeyDown={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            if (
                                                                                                                                                                e.key ===
                                                                                                                                                                "Enter"
                                                                                                                                                            ) {
                                                                                                                                                                e.preventDefault();
                                                                                                                                                                handleCommentsReplyAPI(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    "no",
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }
                                                                                                                                                        }}
                                                                                                                                                        className="input-comments"
                                                                                                                                                        style={
                                                                                                                                                            mentionsInputStyle
                                                                                                                                                        }
                                                                                                                                                        a11ySuggestionsListLabel={
                                                                                                                                                            "Suggested mentions"
                                                                                                                                                        }
                                                                                                                                                        onPaste={
                                                                                                                                                            handlePaste
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        <Mention
                                                                                                                                                            style={
                                                                                                                                                                mentionStyle
                                                                                                                                                            }
                                                                                                                                                            className="mention-popup-class"
                                                                                                                                                            data={
                                                                                                                                                                getMentionUsers
                                                                                                                                                            }
                                                                                                                                                            appendSpaceOnAdd={
                                                                                                                                                                true
                                                                                                                                                            }
                                                                                                                                                            onAdd={() =>
                                                                                                                                                                setIsSelectingSuggestion(
                                                                                                                                                                    true
                                                                                                                                                                )
                                                                                                                                                            } // Set the flag when a suggestion is selected
                                                                                                                                                            renderSuggestion={(
                                                                                                                                                                suggestion,
                                                                                                                                                                search,
                                                                                                                                                                highlightedDisplay,
                                                                                                                                                                index,
                                                                                                                                                                focused
                                                                                                                                                            ) => (
                                                                                                                                                                <div
                                                                                                                                                                    className="list-class"
                                                                                                                                                                    key={
                                                                                                                                                                        index
                                                                                                                                                                    }
                                                                                                                                                                >
                                                                                                                                                                    <div className="list-class-div-1">
                                                                                                                                                                        <img
                                                                                                                                                                            src={
                                                                                                                                                                                suggestion.photo
                                                                                                                                                                            }
                                                                                                                                                                            alt={
                                                                                                                                                                                suggestion.display
                                                                                                                                                                            }
                                                                                                                                                                            className="list-class-div-1-img"
                                                                                                                                                                        />
                                                                                                                                                                        <div className="list-class-div-2">
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    fontWeight:
                                                                                                                                                                                        "bold",
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                {
                                                                                                                                                                                    suggestion?.full_name
                                                                                                                                                                                }
                                                                                                                                                                            </span>
                                                                                                                                                                            <br />
                                                                                                                                                                            <span>
                                                                                                                                                                                {
                                                                                                                                                                                    suggestion?.job_title
                                                                                                                                                                                }
                                                                                                                                                                            </span>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            )}
                                                                                                                                                        />
                                                                                                                                                    </MentionsInput>
                                                                                                                                                    <Button
                                                                                                                                                        className="search-btn-dash-3"
                                                                                                                                                        style={{
                                                                                                                                                            height: "100%",
                                                                                                                                                        }}
                                                                                                                                                        onClick={() =>
                                                                                                                                                            handleCommentsReplyAPI(
                                                                                                                                                                post?.id,
                                                                                                                                                                "no",
                                                                                                                                                                comment?.id
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        <FontAwesomeIcon
                                                                                                                                                            icon={
                                                                                                                                                                faAdd
                                                                                                                                                            }
                                                                                                                                                        />
                                                                                                                                                    </Button>
                                                                                                                                                </InputGroup>
                                                                                                                                            </Box>
                                                                                                                                        </Box>
                                                                                                                                    </Box>
                                                                                                                                )}
                                                                                                                            {comment
                                                                                                                                ?.replies
                                                                                                                                ?.length >
                                                                                                                                0 ? (
                                                                                                                                <>
                                                                                                                                    {comment?.replies?.map(
                                                                                                                                        (
                                                                                                                                            reply
                                                                                                                                        ) => (
                                                                                                                                            <Box
                                                                                                                                                className="w-100 ms-0"
                                                                                                                                                sx={{
                                                                                                                                                    paddingLeft:
                                                                                                                                                        "62px",
                                                                                                                                                }}
                                                                                                                                                onMouseEnter={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                                onMouseLeave={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Box className="w-100 mt-2">
                                                                                                                                                    <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                        <Avatar
                                                                                                                                                            alt={
                                                                                                                                                                reply
                                                                                                                                                                    ?.user
                                                                                                                                                                    ?.name
                                                                                                                                                            }
                                                                                                                                                            className="author-avtar-creator-2 mb-auto"
                                                                                                                                                            src={
                                                                                                                                                                reply
                                                                                                                                                                    ?.user
                                                                                                                                                                    ?.thumbnail
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            {reply?.user?.name?.charAt(
                                                                                                                                                                0
                                                                                                                                                            )}
                                                                                                                                                        </Avatar>
                                                                                                                                                        <Box
                                                                                                                                                            sx={{
                                                                                                                                                                padding:
                                                                                                                                                                    "10px",
                                                                                                                                                            }}
                                                                                                                                                            className="comment-box w-100"
                                                                                                                                                        >
                                                                                                                                                            <Typography className="fs-12-700">
                                                                                                                                                                {
                                                                                                                                                                    reply
                                                                                                                                                                        ?.user
                                                                                                                                                                        ?.name
                                                                                                                                                                }
                                                                                                                                                            </Typography>
                                                                                                                                                            <Typography className="fs-12 mt-1">
                                                                                                                                                                {renderCommentText(
                                                                                                                                                                    reply?.comment
                                                                                                                                                                )}
                                                                                                                                                            </Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                        )
                                                                                                                                    )}
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                ""
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                            </>
                                                                                                        )
                                                                                                    )}
                                                                                                    {post
                                                                                                        ?.comments
                                                                                                        ?.length <
                                                                                                        post?.parent_comment_count && (
                                                                                                            <Box
                                                                                                                className="w-100 ms-0 d-flex justify-content-center"
                                                                                                                onMouseEnter={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                                onMouseLeave={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                            >
                                                                                                                <Button
                                                                                                                    className="w-50 mt-4 btn-load-comments"
                                                                                                                    onClick={() => {
                                                                                                                        setFetchAll(
                                                                                                                            true
                                                                                                                        );
                                                                                                                        fetchLoadMoreCommentsAPI(
                                                                                                                            post
                                                                                                                        );
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <FontAwesomeIcon
                                                                                                                        icon={
                                                                                                                            faComments
                                                                                                                        }
                                                                                                                    />
                                                                                                                    Load
                                                                                                                    More
                                                                                                                    Comments
                                                                                                                </Button>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                </>
                                                                                            )}
                                                                                    </CardActions>
                                                                                )}
                                                                        </>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Card>
                                                            ) : (
                                                                <>
                                                                    <Card
                                                                        className="mt-4"
                                                                        data-post-id={
                                                                            post?.id
                                                                        }
                                                                        data-post-view={
                                                                            post?.is_post_seen
                                                                        }
                                                                        ref={
                                                                            setElementsCallback
                                                                        }
                                                                    >
                                                                        <CardHeader
                                                                            avatar={
                                                                                <Avatar
                                                                                    aria-label="recipe"
                                                                                    className="author-avtar-creator"
                                                                                    src={
                                                                                        post?.profile
                                                                                    }
                                                                                >
                                                                                    {post?.created_by?.charAt(
                                                                                        0
                                                                                    )}
                                                                                </Avatar>
                                                                            }
                                                                            title={
                                                                                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                                                                    <span>
                                                                                        <b>{post?.created_by}</b>{" "}posted a{" "}<b>{post?.type}</b>
                                                                                    </span>
                                                                                    <Typography variant="caption">
                                                                                        {dateWithTime(post?.published_date_time, false, false, false)}
                                                                                    </Typography>
                                                                                </Box>
                                                                            }
                                                                            className="card-header-content"
                                                                        />
                                                                        <CardMedia
                                                                            sx={{
                                                                                height: 222,
                                                                            }}
                                                                            image={
                                                                                post?.image
                                                                            }
                                                                            className="cursor-pointer"
                                                                            onClick={() => {
                                                                                if (
                                                                                    loggedUser?.user_type ===
                                                                                    "user"
                                                                                ) {
                                                                                    localStorage.setItem(
                                                                                        "last-redirect-page-user",
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                    navigate(
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                } else if (
                                                                                    loggedUser?.user_type ===
                                                                                    "admin" &&
                                                                                    adminAccessible ==
                                                                                    true
                                                                                ) {
                                                                                    localStorage.setItem(
                                                                                        "last-redirect-page-admin-user",
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                    navigate(
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                        <CardContent className="content-div-body">
                                                                            <div className="main-content-div-1">
                                                                                <div className="main-div-posted">
                                                                                    <div className="icon-bd-div">
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                faClock
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <Typography className="fs-14 opacity-50">
                                                                                        {` Posted ${onlyDateFormat(post?.published_date_time, false, true, true)}`}
                                                                                    </Typography>
                                                                                </div>
                                                                                {post
                                                                                    ?.category
                                                                                    ?.length >
                                                                                    0 && (
                                                                                        <div className="main-div-workspace">
                                                                                            <div className="icon-bd-div">
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        faBriefcase
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <Typography className="fs-14 opacity-50">
                                                                                                {post?.category?.join(
                                                                                                    ", "
                                                                                                )}
                                                                                            </Typography>
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                            <Box>
                                                                                <Typography
                                                                                    gutterBottom
                                                                                    variant="h5"
                                                                                    component="div"
                                                                                    className="content-title text-break"
                                                                                >
                                                                                    {
                                                                                        post?.title
                                                                                    }
                                                                                </Typography>
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.secondary"
                                                                                    className="fs-14 content-desc text-break"
                                                                                >
                                                                                    {
                                                                                        post?.content
                                                                                    }
                                                                                </Typography>
                                                                            </Box>
                                                                            {post?.is_require_acknowledge ==
                                                                                true && (
                                                                                    <Box
                                                                                        className={`custom-checkbox mt-3 ${post?.acknowledged_on !==
                                                                                            "" &&
                                                                                            "active-checkbox"
                                                                                            }`}
                                                                                    >
                                                                                        <label className="checkbox-style">
                                                                                            {post?.acknowledged_on !==
                                                                                                ""
                                                                                                ? `You acknowledged this on ${onlyDateFormat(post?.acknowledged_on, true, false, true)}`
                                                                                                : ((post?.acknowledge_data && (typeof post?.acknowledge_data === "string")) ? `${post?.acknowledge_data}` : `This requires your acknowledgement`)}
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={
                                                                                                    post?.acknowledged_on !==
                                                                                                    ""
                                                                                                }
                                                                                                onChange={() => {
                                                                                                    if (
                                                                                                        post?.acknowledge_type ===
                                                                                                        "question"
                                                                                                    ) {
                                                                                                        setAckQuestions(
                                                                                                            post?.acknowledge_data
                                                                                                        );
                                                                                                        setPostIdForAckQue(
                                                                                                            post?.id
                                                                                                        );
                                                                                                        setOpenAckQuePopup(
                                                                                                            true
                                                                                                        );
                                                                                                    } else if (
                                                                                                        post?.acknowledge_type ===
                                                                                                        "checkbox"
                                                                                                    ) {
                                                                                                        handleCheckboxChange(
                                                                                                            post?.id
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                                disabled={
                                                                                                    post?.acknowledged_on !==
                                                                                                    ""
                                                                                                }
                                                                                            />
                                                                                            <span className="checkmark"></span>
                                                                                        </label>
                                                                                    </Box>
                                                                                )}
                                                                            <Button
                                                                                className="btn primary-btn mt-4"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        loggedUser?.user_type ===
                                                                                        "user"
                                                                                    ) {
                                                                                        localStorage.setItem(
                                                                                            "last-redirect-page-user",
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                        navigate(
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                    } else if (
                                                                                        loggedUser?.user_type ===
                                                                                        "admin" &&
                                                                                        adminAccessible ==
                                                                                        true
                                                                                    ) {
                                                                                        localStorage.setItem(
                                                                                            "last-redirect-page-admin-user",
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                        navigate(
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                View
                                                                                Article
                                                                                <FontAwesomeIcon
                                                                                    icon={
                                                                                        faArrowRight
                                                                                    }
                                                                                />
                                                                            </Button>
                                                                        </CardContent>
                                                                        {post?.is_post_comment ===
                                                                            true ||
                                                                            post?.is_post_like ===
                                                                            true ? (
                                                                            <>
                                                                                <CardActions
                                                                                    className="bg-white"
                                                                                    sx={{
                                                                                        border: "1px solid #EEEEEE",
                                                                                        padding:
                                                                                            "10px",
                                                                                    }}
                                                                                    onMouseEnter={() => {
                                                                                        handlePopoverClose();
                                                                                        handlePopoverCloseForReplyLike();
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        handlePopoverClose();
                                                                                        handlePopoverCloseForReplyLike();
                                                                                    }}
                                                                                >
                                                                                    {post?.is_post_like ==
                                                                                        true && (
                                                                                            <Box className="action-like w-100 d-flex flex-wrap align-items-center gap-2 cursor-pointer"
                                                                                                onClick={() => { handleEngagementModalOpen(post?.id) }}
                                                                                            >
                                                                                                {post?.reactions?.map(
                                                                                                    (
                                                                                                        reaction
                                                                                                    ) => (
                                                                                                        <FontAwesomeIcon
                                                                                                            icon={
                                                                                                                reaction ===
                                                                                                                    "love"
                                                                                                                    ? faHeart
                                                                                                                    : reaction ===
                                                                                                                        "smile"
                                                                                                                        ? faSmile
                                                                                                                        : reaction ===
                                                                                                                            "angry"
                                                                                                                            ? faAngry
                                                                                                                            : reaction ===
                                                                                                                                "like"
                                                                                                                                ? faThumbsUp
                                                                                                                                : ""
                                                                                                            }
                                                                                                            className={
                                                                                                                reaction ===
                                                                                                                    "love"
                                                                                                                    ? "fa-love-btn"
                                                                                                                    : reaction ===
                                                                                                                        "smile"
                                                                                                                        ? "fa-smile-btn"
                                                                                                                        : reaction ===
                                                                                                                            "angry"
                                                                                                                            ? "fa-angry-btn"
                                                                                                                            : reaction ===
                                                                                                                                "like"
                                                                                                                                ? "fa-like-btn"
                                                                                                                                : ""
                                                                                                            }
                                                                                                        />
                                                                                                    )
                                                                                                )}

                                                                                                <Box className="like-counts-box">
                                                                                                    <Typography className="fs-12-action-btn">
                                                                                                        {
                                                                                                            post?.like_count
                                                                                                        }
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        )}
                                                                                    {post?.is_post_comment ==
                                                                                        true && (
                                                                                            <Box className="w-100 ms-0">
                                                                                                <Typography className="fs-12-action-btn d-flex  justify-content-end">
                                                                                                    {`${post?.comment_count} comments`}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                </CardActions>
                                                                                <CardActions
                                                                                    className="bg-white"
                                                                                    sx={{
                                                                                        border: "1px solid #EEEEEE",
                                                                                        padding:
                                                                                            "10px",
                                                                                    }}
                                                                                >
                                                                                    {post?.is_post_like ==
                                                                                        true && (
                                                                                            <Box
                                                                                                className="action-like w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                                onClick={() => {
                                                                                                    toggleReaction(
                                                                                                        post?.id,
                                                                                                        post?.user_has_liked ===
                                                                                                            "no"
                                                                                                            ? "like"
                                                                                                            : "no",
                                                                                                        post?.user_has_liked ===
                                                                                                            "no"
                                                                                                            ? true
                                                                                                            : false
                                                                                                    );
                                                                                                    handlePopoverClose();
                                                                                                }}
                                                                                                onMouseEnter={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handlePopoverOpen(
                                                                                                        e,
                                                                                                        post.id
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        post?.reaction ===
                                                                                                            "like"
                                                                                                            ? faThumbsUp
                                                                                                            : post?.reaction ===
                                                                                                                "love"
                                                                                                                ? faHeart
                                                                                                                : post?.reaction ===
                                                                                                                    "smile"
                                                                                                                    ? faSmile
                                                                                                                    : post?.reaction ===
                                                                                                                        "angry"
                                                                                                                        ? faAngry
                                                                                                                        : faThumbsUp
                                                                                                    }
                                                                                                    className={`fs-12-action-btn ${post?.reaction ===
                                                                                                        "like"
                                                                                                        ? "liked-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "love"
                                                                                                            ? "love-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "smile"
                                                                                                                ? "smile-post-text"
                                                                                                                : post?.reaction ===
                                                                                                                    "angry"
                                                                                                                    ? "angry-post-text"
                                                                                                                    : ""
                                                                                                        }`}
                                                                                                />
                                                                                                <Typography
                                                                                                    className={`fs-12-action-btn text-capitalize ${post?.reaction ===
                                                                                                        "like"
                                                                                                        ? "liked-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "love"
                                                                                                            ? "love-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "smile"
                                                                                                                ? "smile-post-text"
                                                                                                                : post?.reaction ===
                                                                                                                    "angry"
                                                                                                                    ? "angry-post-text"
                                                                                                                    : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {post?.user_has_liked !==
                                                                                                        "no"
                                                                                                        ? post.reaction
                                                                                                        : "Like"}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                    {post?.is_post_comment ==
                                                                                        true && (
                                                                                            <Box
                                                                                                className="action-comments w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                                onClick={() =>
                                                                                                    handleCommentToggle(
                                                                                                        post?.id
                                                                                                    )
                                                                                                }
                                                                                                onMouseEnter={() => {
                                                                                                    handlePopoverClose();
                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                }}
                                                                                                onMouseLeave={() => {
                                                                                                    handlePopoverClose();
                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                }}
                                                                                            >
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        faComments
                                                                                                    }
                                                                                                    className="fs-12-action-btn"
                                                                                                />
                                                                                                <Typography className="fs-12-action-btn">
                                                                                                    Comments
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                </CardActions>
                                                                                {post?.is_post_comment ==
                                                                                    true && (
                                                                                        <CardActions
                                                                                            className="bg-white card-action-2"
                                                                                            sx={{
                                                                                                border: "1px solid #EEEEEE",
                                                                                                padding:
                                                                                                    "15px",
                                                                                            }}
                                                                                            onMouseEnter={() => {
                                                                                                handlePopoverClose();
                                                                                            }}
                                                                                            onMouseLeave={() => {
                                                                                                handlePopoverClose();
                                                                                            }}
                                                                                        >
                                                                                            {post?.is_post_comment ==
                                                                                                true && (
                                                                                                    <>
                                                                                                        {post?.commentToggle ==
                                                                                                            true && (
                                                                                                                <Box
                                                                                                                    className="w-100"
                                                                                                                    onMouseEnter={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                    onMouseLeave={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                        <Avatar
                                                                                                                            alt={
                                                                                                                                loggedUser?.name
                                                                                                                            }
                                                                                                                            className="author-avtar-creator"
                                                                                                                            src={
                                                                                                                                loggedUser?.thumbnail
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {loggedUser?.name?.charAt(
                                                                                                                                0
                                                                                                                            )}
                                                                                                                        </Avatar>
                                                                                                                        <InputGroup
                                                                                                                            className=""
                                                                                                                            style={{
                                                                                                                                height: "32px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <MentionsInput
                                                                                                                                placeholder="Add Comment. Use '@' for mention"
                                                                                                                                value={
                                                                                                                                    post?.commentText
                                                                                                                                }
                                                                                                                                singleLine={
                                                                                                                                    true
                                                                                                                                }
                                                                                                                                onChange={(
                                                                                                                                    event
                                                                                                                                ) => {
                                                                                                                                    handleCommentText(
                                                                                                                                        event
                                                                                                                                            .target
                                                                                                                                            .value,
                                                                                                                                        post?.id
                                                                                                                                    );
                                                                                                                                }}
                                                                                                                                onKeyDown={(
                                                                                                                                    e
                                                                                                                                ) => {
                                                                                                                                    if (
                                                                                                                                        e.key ===
                                                                                                                                        "Enter"
                                                                                                                                    ) {
                                                                                                                                        e.preventDefault();
                                                                                                                                        handleCommentsAPI(
                                                                                                                                            post?.id,
                                                                                                                                            "yes"
                                                                                                                                        );
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className="input-comments"
                                                                                                                                style={
                                                                                                                                    mentionsInputStyle
                                                                                                                                }
                                                                                                                                a11ySuggestionsListLabel={
                                                                                                                                    "Suggested mentions"
                                                                                                                                }
                                                                                                                                onPaste={
                                                                                                                                    handlePaste
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Mention
                                                                                                                                    style={
                                                                                                                                        mentionStyle
                                                                                                                                    }
                                                                                                                                    className="mention-popup-class"
                                                                                                                                    data={
                                                                                                                                        getMentionUsers
                                                                                                                                    }
                                                                                                                                    onAdd={() =>
                                                                                                                                        setIsSelectingSuggestion(
                                                                                                                                            true
                                                                                                                                        )
                                                                                                                                    } // Set the flag when a suggestion is selected
                                                                                                                                    appendSpaceOnAdd={
                                                                                                                                        true
                                                                                                                                    }
                                                                                                                                    renderSuggestion={(
                                                                                                                                        suggestion,
                                                                                                                                        search,
                                                                                                                                        highlightedDisplay,
                                                                                                                                        index,
                                                                                                                                        focused
                                                                                                                                    ) => (
                                                                                                                                        <div
                                                                                                                                            className="list-class"
                                                                                                                                            key={
                                                                                                                                                index
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <div className="list-class-div-1">
                                                                                                                                                <img
                                                                                                                                                    src={
                                                                                                                                                        suggestion.photo
                                                                                                                                                    }
                                                                                                                                                    alt={
                                                                                                                                                        suggestion.display
                                                                                                                                                    }
                                                                                                                                                    className="list-class-div-1-img"
                                                                                                                                                />
                                                                                                                                                <div className="list-class-div-2">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontWeight:
                                                                                                                                                                "bold",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        {
                                                                                                                                                            suggestion?.full_name
                                                                                                                                                        }
                                                                                                                                                    </span>
                                                                                                                                                    <br />
                                                                                                                                                    <span>
                                                                                                                                                        {
                                                                                                                                                            suggestion?.job_title
                                                                                                                                                        }
                                                                                                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                />
                                                                                                                            </MentionsInput>
                                                                                                                            <Button
                                                                                                                                className="search-btn-dash-2"
                                                                                                                                style={{
                                                                                                                                    height: "100%",
                                                                                                                                }}
                                                                                                                                onClick={() =>
                                                                                                                                    handleCommentsAPI(
                                                                                                                                        post?.id,
                                                                                                                                        "yes"
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <FontAwesomeIcon
                                                                                                                                    icon={
                                                                                                                                        faAdd
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </Button>
                                                                                                                        </InputGroup>
                                                                                                                    </Box>
                                                                                                                </Box>
                                                                                                            )}
                                                                                                    </>
                                                                                                )}
                                                                                            {post
                                                                                                ?.comments
                                                                                                ?.length >
                                                                                                0 && (
                                                                                                    <>
                                                                                                        {post?.comments?.map(
                                                                                                            (
                                                                                                                comment
                                                                                                            ) => (
                                                                                                                <>
                                                                                                                    {comment?.is_parent ===
                                                                                                                        "yes" && (
                                                                                                                            <>
                                                                                                                                <Box
                                                                                                                                    className="w-100 ms-0"
                                                                                                                                    onMouseEnter={
                                                                                                                                        handlePopoverClose
                                                                                                                                    }
                                                                                                                                    onMouseLeave={
                                                                                                                                        handlePopoverClose
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                        <Avatar
                                                                                                                                            alt={
                                                                                                                                                comment
                                                                                                                                                    ?.user
                                                                                                                                                    ?.name
                                                                                                                                            }
                                                                                                                                            className="author-avtar-creator mb-auto"
                                                                                                                                            src={
                                                                                                                                                comment
                                                                                                                                                    ?.user
                                                                                                                                                    ?.thumbnail
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            {comment?.user?.name?.charAt(
                                                                                                                                                0
                                                                                                                                            )}
                                                                                                                                        </Avatar>
                                                                                                                                        <Box
                                                                                                                                            sx={{
                                                                                                                                                padding:
                                                                                                                                                    "15px",
                                                                                                                                            }}
                                                                                                                                            className="comment-box w-100"
                                                                                                                                        >
                                                                                                                                            <Typography className="fs-14-700">
                                                                                                                                                {
                                                                                                                                                    comment
                                                                                                                                                        ?.user
                                                                                                                                                        ?.name
                                                                                                                                                }
                                                                                                                                            </Typography>
                                                                                                                                            <Typography className="fs-14 mt-2">
                                                                                                                                                {renderCommentText(
                                                                                                                                                    comment?.comment
                                                                                                                                                )}
                                                                                                                                            </Typography>
                                                                                                                                            <Stack
                                                                                                                                                direction="row"
                                                                                                                                                divider={
                                                                                                                                                    <Divider
                                                                                                                                                        orientation="vertical"
                                                                                                                                                        flexItem
                                                                                                                                                        className="nested-divider"
                                                                                                                                                    />
                                                                                                                                                }
                                                                                                                                                className="mt-2 align-items-center"
                                                                                                                                                spacing={
                                                                                                                                                    1
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <Box>
                                                                                                                                                    <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                        <Typography
                                                                                                                                                            className={`fs-10 cursor-pointer ${comment?.has_user_liked_comment ===
                                                                                                                                                                "yes" &&
                                                                                                                                                                "liked-post-text"
                                                                                                                                                                }`}
                                                                                                                                                            onClick={() => {
                                                                                                                                                                toggleLikeForCommentsReaction(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    comment?.has_user_liked_comment ===
                                                                                                                                                                        "no"
                                                                                                                                                                        ? "like"
                                                                                                                                                                        : "no",
                                                                                                                                                                    comment?.id,
                                                                                                                                                                    comment?.has_user_liked_comment ===
                                                                                                                                                                        "no"
                                                                                                                                                                        ? true
                                                                                                                                                                        : false
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onMouseEnter={(
                                                                                                                                                                e
                                                                                                                                                            ) => {
                                                                                                                                                                setCurrentPostId(
                                                                                                                                                                    post?.id
                                                                                                                                                                );

                                                                                                                                                                setCurrentPostIdForReply(
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                                handlePopoverOpenForReplyLike(
                                                                                                                                                                    e,
                                                                                                                                                                    post.id,
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onMouseLeave={() => {
                                                                                                                                                                setCurrentPostId(
                                                                                                                                                                    post?.id
                                                                                                                                                                );

                                                                                                                                                                setCurrentPostIdForReply(
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            Like
                                                                                                                                                        </Typography>
                                                                                                                                                        <Box
                                                                                                                                                            className="action-like w-100 d-flex flex-wrap align-items-center gap-1 cursor-pointer"
                                                                                                                                                            onMouseEnter={() => {
                                                                                                                                                                handlePopoverClose();
                                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                                            }}
                                                                                                                                                            onMouseLeave={() => {
                                                                                                                                                                handlePopoverClose();
                                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                                            }}
                                                                                                                                                            onClick={() => { handleEngagementModalOpen(comment?.id, 'comment') }}
                                                                                                                                                        >
                                                                                                                                                            {comment?.user_reactions?.map(
                                                                                                                                                                (
                                                                                                                                                                    reaction
                                                                                                                                                                ) => (
                                                                                                                                                                    <FontAwesomeIcon
                                                                                                                                                                        icon={
                                                                                                                                                                            reaction ===
                                                                                                                                                                                "love"
                                                                                                                                                                                ? faHeart
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "smile"
                                                                                                                                                                                    ? faSmile
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "angry"
                                                                                                                                                                                        ? faAngry
                                                                                                                                                                                        : reaction ===
                                                                                                                                                                                            "like"
                                                                                                                                                                                            ? faThumbsUp
                                                                                                                                                                                            : ""
                                                                                                                                                                        }
                                                                                                                                                                        className={
                                                                                                                                                                            reaction ===
                                                                                                                                                                                "love"
                                                                                                                                                                                ? "fa-love-btn-2"
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "smile"
                                                                                                                                                                                    ? "fa-smile-btn-2"
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "angry"
                                                                                                                                                                                        ? "fa-angry-btn-2"
                                                                                                                                                                                        : reaction ===
                                                                                                                                                                                            "like"
                                                                                                                                                                                            ? "fa-like-btn-2"
                                                                                                                                                                                            : ""
                                                                                                                                                                        }
                                                                                                                                                                    />
                                                                                                                                                                )
                                                                                                                                                            )}

                                                                                                                                                            <Box className="like-counts-box">
                                                                                                                                                                <Typography className="fs-10">
                                                                                                                                                                    {
                                                                                                                                                                        comment?.comment_likes_count
                                                                                                                                                                    }
                                                                                                                                                                </Typography>
                                                                                                                                                            </Box>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                                <Box
                                                                                                                                                    onMouseEnter={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                    onMouseLeave={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <Box>
                                                                                                                                                        <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                            <Typography
                                                                                                                                                                className="fs-10 cursor-pointer"
                                                                                                                                                                onClick={() =>
                                                                                                                                                                    handleCommentReplyToggle(
                                                                                                                                                                        post?.id,
                                                                                                                                                                        comment?.id
                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                            >
                                                                                                                                                                Reply
                                                                                                                                                            </Typography>
                                                                                                                                                            <Typography className="fs-10 cursor-pointer">
                                                                                                                                                                {`${comment?.replies_count} reply`}
                                                                                                                                                            </Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Stack>
                                                                                                                                        </Box>
                                                                                                                                    </Box>
                                                                                                                                </Box>
                                                                                                                                {comment?.commentReplyToggle ==
                                                                                                                                    true && (
                                                                                                                                        <Box
                                                                                                                                            className="w-100 ms-0"
                                                                                                                                            sx={{
                                                                                                                                                paddingLeft:
                                                                                                                                                    "62px",
                                                                                                                                            }}
                                                                                                                                            onMouseEnter={() => {
                                                                                                                                                handlePopoverClose();
                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                            }}
                                                                                                                                            onMouseLeave={() => {
                                                                                                                                                handlePopoverClose();
                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <Box className="w-100 mt-2 mb-1">
                                                                                                                                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                    <Avatar
                                                                                                                                                        alt={
                                                                                                                                                            loggedUser?.name
                                                                                                                                                        }
                                                                                                                                                        className="author-avtar-creator-2"
                                                                                                                                                        src={
                                                                                                                                                            loggedUser?.thumbnail
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        {loggedUser?.name?.charAt(
                                                                                                                                                            0
                                                                                                                                                        )}
                                                                                                                                                    </Avatar>
                                                                                                                                                    <InputGroup
                                                                                                                                                        className=""
                                                                                                                                                        style={{
                                                                                                                                                            height: "25px",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <MentionsInput
                                                                                                                                                            placeholder="Add Comment. Use '@' for mention"
                                                                                                                                                            value={
                                                                                                                                                                comment?.commentReplyText
                                                                                                                                                            }
                                                                                                                                                            singleLine={
                                                                                                                                                                true
                                                                                                                                                            }
                                                                                                                                                            onChange={(
                                                                                                                                                                event
                                                                                                                                                            ) => {
                                                                                                                                                                console.log(
                                                                                                                                                                    "event:::",
                                                                                                                                                                    event
                                                                                                                                                                );
                                                                                                                                                                handleCommentReplyText(
                                                                                                                                                                    event
                                                                                                                                                                        .target
                                                                                                                                                                        .value,
                                                                                                                                                                    post?.id,
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onKeyDown={(
                                                                                                                                                                e
                                                                                                                                                            ) => {
                                                                                                                                                                if (
                                                                                                                                                                    e.key ===
                                                                                                                                                                    "Enter"
                                                                                                                                                                ) {
                                                                                                                                                                    e.preventDefault();
                                                                                                                                                                    handleCommentsReplyAPI(
                                                                                                                                                                        post?.id,
                                                                                                                                                                        "no",
                                                                                                                                                                        comment?.id
                                                                                                                                                                    );
                                                                                                                                                                }
                                                                                                                                                            }}
                                                                                                                                                            className="input-comments"
                                                                                                                                                            style={
                                                                                                                                                                mentionsInputStyle
                                                                                                                                                            }
                                                                                                                                                            a11ySuggestionsListLabel={
                                                                                                                                                                "Suggested mentions"
                                                                                                                                                            }
                                                                                                                                                            onPaste={
                                                                                                                                                                handlePaste
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            <Mention
                                                                                                                                                                style={
                                                                                                                                                                    mentionStyle
                                                                                                                                                                }
                                                                                                                                                                className="mention-popup-class"
                                                                                                                                                                data={
                                                                                                                                                                    getMentionUsers
                                                                                                                                                                }
                                                                                                                                                                appendSpaceOnAdd={
                                                                                                                                                                    true
                                                                                                                                                                }
                                                                                                                                                                onAdd={() =>
                                                                                                                                                                    setIsSelectingSuggestion(
                                                                                                                                                                        true
                                                                                                                                                                    )
                                                                                                                                                                } // Set the flag when a suggestion is selected
                                                                                                                                                                renderSuggestion={(
                                                                                                                                                                    suggestion,
                                                                                                                                                                    search,
                                                                                                                                                                    highlightedDisplay,
                                                                                                                                                                    index,
                                                                                                                                                                    focused
                                                                                                                                                                ) => (
                                                                                                                                                                    <div
                                                                                                                                                                        className="list-class"
                                                                                                                                                                        key={
                                                                                                                                                                            index
                                                                                                                                                                        }
                                                                                                                                                                    >
                                                                                                                                                                        <div className="list-class-div-1">
                                                                                                                                                                            <img
                                                                                                                                                                                src={
                                                                                                                                                                                    suggestion.photo
                                                                                                                                                                                }
                                                                                                                                                                                alt={
                                                                                                                                                                                    suggestion.display
                                                                                                                                                                                }
                                                                                                                                                                                className="list-class-div-1-img"
                                                                                                                                                                            />
                                                                                                                                                                            <div className="list-class-div-2">
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontWeight:
                                                                                                                                                                                            "bold",
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    {
                                                                                                                                                                                        suggestion?.full_name
                                                                                                                                                                                    }
                                                                                                                                                                                </span>
                                                                                                                                                                                <br />
                                                                                                                                                                                <span>
                                                                                                                                                                                    {
                                                                                                                                                                                        suggestion?.job_title
                                                                                                                                                                                    }
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                )}
                                                                                                                                                            />
                                                                                                                                                        </MentionsInput>
                                                                                                                                                        <Button
                                                                                                                                                            className="search-btn-dash-3"
                                                                                                                                                            style={{
                                                                                                                                                                height: "100%",
                                                                                                                                                            }}
                                                                                                                                                            onClick={() =>
                                                                                                                                                                handleCommentsReplyAPI(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    "no",
                                                                                                                                                                    comment?.id
                                                                                                                                                                )
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            <FontAwesomeIcon
                                                                                                                                                                icon={
                                                                                                                                                                    faAdd
                                                                                                                                                                }
                                                                                                                                                            />
                                                                                                                                                        </Button>
                                                                                                                                                    </InputGroup>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                        </Box>
                                                                                                                                    )}
                                                                                                                                {comment
                                                                                                                                    ?.replies
                                                                                                                                    ?.length >
                                                                                                                                    0 ? (
                                                                                                                                    <>
                                                                                                                                        {comment?.replies?.map(
                                                                                                                                            (
                                                                                                                                                reply
                                                                                                                                            ) => (
                                                                                                                                                <Box
                                                                                                                                                    className="w-100 ms-0"
                                                                                                                                                    sx={{
                                                                                                                                                        paddingLeft:
                                                                                                                                                            "62px",
                                                                                                                                                    }}
                                                                                                                                                    onMouseEnter={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                    onMouseLeave={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <Box className="w-100 mt-2">
                                                                                                                                                        <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                            <Avatar
                                                                                                                                                                alt={
                                                                                                                                                                    reply
                                                                                                                                                                        ?.user
                                                                                                                                                                        ?.name
                                                                                                                                                                }
                                                                                                                                                                className="author-avtar-creator-2 mb-auto"
                                                                                                                                                                src={
                                                                                                                                                                    reply
                                                                                                                                                                        ?.user
                                                                                                                                                                        ?.thumbnail
                                                                                                                                                                }
                                                                                                                                                            >
                                                                                                                                                                {reply?.user?.name?.charAt(
                                                                                                                                                                    0
                                                                                                                                                                )}
                                                                                                                                                            </Avatar>
                                                                                                                                                            <Box
                                                                                                                                                                sx={{
                                                                                                                                                                    padding:
                                                                                                                                                                        "10px",
                                                                                                                                                                }}
                                                                                                                                                                className="comment-box w-100"
                                                                                                                                                            >
                                                                                                                                                                <Typography className="fs-12-700">
                                                                                                                                                                    {
                                                                                                                                                                        reply
                                                                                                                                                                            ?.user
                                                                                                                                                                            ?.name
                                                                                                                                                                    }
                                                                                                                                                                </Typography>
                                                                                                                                                                <Typography className="fs-12 mt-1">
                                                                                                                                                                    {renderCommentText(
                                                                                                                                                                        reply?.comment
                                                                                                                                                                    )}
                                                                                                                                                                </Typography>
                                                                                                                                                            </Box>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            )
                                                                                                                                        )}
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    ""
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                </>
                                                                                                            )
                                                                                                        )}
                                                                                                        {post
                                                                                                            ?.comments
                                                                                                            ?.length <
                                                                                                            post?.parent_comment_count && (
                                                                                                                <Box
                                                                                                                    className="w-100 ms-0 d-flex justify-content-center"
                                                                                                                    onMouseEnter={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                    onMouseLeave={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Button
                                                                                                                        className="w-50 mt-4 btn-load-comments"
                                                                                                                        onClick={() => {
                                                                                                                            setFetchAll(
                                                                                                                                true
                                                                                                                            );
                                                                                                                            fetchLoadMoreCommentsAPI(
                                                                                                                                post
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <FontAwesomeIcon
                                                                                                                            icon={
                                                                                                                                faComments
                                                                                                                            }
                                                                                                                        />
                                                                                                                        Load
                                                                                                                        More
                                                                                                                        Comments
                                                                                                                    </Button>
                                                                                                                </Box>
                                                                                                            )}
                                                                                                    </>
                                                                                                )}
                                                                                        </CardActions>
                                                                                    )}
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Card>
                                                                </>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                            </>
                                        )}

                                        {unseenPosts.length > 0 &&
                                            seenPosts.length > 0 && (
                                                <>
                                                    <Divider className="upto-date-divider">
                                                        <Box className="upto-date-box-div">
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                className="fs-14-primary"
                                                            />
                                                        </Box>
                                                    </Divider>
                                                    <Box>
                                                        <Typography
                                                            variant="h4"
                                                            className="fs-20 text-center mt-2 mb-2"
                                                        >
                                                            You’re Up to Date!
                                                        </Typography>
                                                    </Box>
                                                </>
                                            )}

                                        {seenPosts?.length > 0 && (
                                            <>
                                                {seenPosts?.map(
                                                    (post, index) => (
                                                        <>
                                                            {post?.type ==
                                                                "Update" ? (
                                                                <Card
                                                                    className="mt-4"
                                                                    data-post-id={
                                                                        post?.id
                                                                    }
                                                                    ref={
                                                                        setElementsCallback
                                                                    }
                                                                    data-post-view={
                                                                        post?.is_post_seen
                                                                    }
                                                                >
                                                                    <CardHeader
                                                                        avatar={
                                                                            <Avatar
                                                                                aria-label="recipe"
                                                                                className="author-avtar-creator"
                                                                                src={
                                                                                    post?.profile
                                                                                }
                                                                            >
                                                                                {post?.created_by?.charAt(
                                                                                    0
                                                                                )}
                                                                            </Avatar>
                                                                        }
                                                                        title={
                                                                            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                                                                <span>
                                                                                    <b>{post?.created_by}</b>{" "}posted a{" "}<b>{post?.type}</b>
                                                                                </span>
                                                                                <Typography variant="caption">
                                                                                    {dateWithTime(post?.published_date_time, false, false, false)}
                                                                                </Typography>
                                                                            </Box>
                                                                        }
                                                                        className="card-header-content"
                                                                    />
                                                                    {post?.image !==
                                                                        "" && (
                                                                            <CardMedia
                                                                                sx={{
                                                                                    height: 222,
                                                                                }}
                                                                                image={
                                                                                    post?.image
                                                                                }
                                                                            />
                                                                        )}

                                                                    <CardContent className="content-div-body">
                                                                        <Box>
                                                                            <Typography
                                                                                variant="h3"
                                                                                component="h3"
                                                                                className="content-title-update text-break break-1"
                                                                            >
                                                                                {
                                                                                    post?.content
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                        {post?.is_require_acknowledge ==
                                                                            true && (
                                                                                <Box
                                                                                    className={`custom-checkbox mt-3 ${post?.acknowledged_on !==
                                                                                        "" &&
                                                                                        "active-checkbox"
                                                                                        }`}
                                                                                >
                                                                                    <label className="checkbox-style">
                                                                                        {post?.acknowledged_on !==
                                                                                            ""
                                                                                            ? `You acknowledged this on ${onlyDateFormat(post?.acknowledged_on, true, false, true)}`
                                                                                            : ((post?.acknowledge_data && (typeof post?.acknowledge_data === "string")) ? `${post?.acknowledge_data}` : `This requires your acknowledgement`)}
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                                post?.acknowledged_on !==
                                                                                                ""
                                                                                            }
                                                                                            onChange={() => {
                                                                                                if (
                                                                                                    post?.acknowledge_type ===
                                                                                                    "question"
                                                                                                ) {
                                                                                                    setAckQuestions(
                                                                                                        post?.acknowledge_data
                                                                                                    );
                                                                                                    setPostIdForAckQue(
                                                                                                        post?.id
                                                                                                    );
                                                                                                    setOpenAckQuePopup(
                                                                                                        true
                                                                                                    );
                                                                                                } else if (
                                                                                                    post?.acknowledge_type ===
                                                                                                    "checkbox"
                                                                                                ) {
                                                                                                    handleCheckboxChange(
                                                                                                        post?.id
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            disabled={
                                                                                                post?.acknowledged_on !==
                                                                                                ""
                                                                                            }
                                                                                        />
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                </Box>
                                                                            )}
                                                                    </CardContent>
                                                                    {post?.is_post_comment ===
                                                                        true ||
                                                                        post?.is_post_like ===
                                                                        true ? (
                                                                        <>
                                                                            <CardActions
                                                                                className="bg-white"
                                                                                sx={{
                                                                                    border: "1px solid #EEEEEE",
                                                                                    padding:
                                                                                        "10px",
                                                                                }}
                                                                                onMouseEnter={() => {
                                                                                    handlePopoverClose();
                                                                                    handlePopoverCloseForReplyLike();
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    handlePopoverClose();
                                                                                    handlePopoverCloseForReplyLike();
                                                                                }}
                                                                            >
                                                                                {post?.is_post_like ==
                                                                                    true && (
                                                                                        <Box className="action-like w-100 d-flex flex-wrap align-items-center gap-2 cursor-pointer"
                                                                                            onClick={() => { handleEngagementModalOpen(post?.id) }}
                                                                                        >
                                                                                            {post?.reactions?.map(
                                                                                                (
                                                                                                    reaction
                                                                                                ) => (
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={
                                                                                                            reaction ===
                                                                                                                "love"
                                                                                                                ? faHeart
                                                                                                                : reaction ===
                                                                                                                    "smile"
                                                                                                                    ? faSmile
                                                                                                                    : reaction ===
                                                                                                                        "angry"
                                                                                                                        ? faAngry
                                                                                                                        : reaction ===
                                                                                                                            "like"
                                                                                                                            ? faThumbsUp
                                                                                                                            : ""
                                                                                                        }
                                                                                                        className={
                                                                                                            reaction ===
                                                                                                                "love"
                                                                                                                ? "fa-love-btn"
                                                                                                                : reaction ===
                                                                                                                    "smile"
                                                                                                                    ? "fa-smile-btn"
                                                                                                                    : reaction ===
                                                                                                                        "angry"
                                                                                                                        ? "fa-angry-btn"
                                                                                                                        : reaction ===
                                                                                                                            "like"
                                                                                                                            ? "fa-like-btn"
                                                                                                                            : ""
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            )}

                                                                                            <Box className="like-counts-box">
                                                                                                <Typography className="fs-12-action-btn">
                                                                                                    {
                                                                                                        post?.like_count
                                                                                                    }
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    )}
                                                                                {post?.is_post_comment ==
                                                                                    true && (
                                                                                        <Box className="w-100 ms-0">
                                                                                            <Typography className="fs-12-action-btn d-flex  justify-content-end">
                                                                                                {`${post?.comment_count} comments`}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                            </CardActions>
                                                                            <CardActions
                                                                                className="bg-white"
                                                                                sx={{
                                                                                    border: "1px solid #EEEEEE",
                                                                                    padding:
                                                                                        "10px",
                                                                                }}
                                                                            >
                                                                                {post?.is_post_like ==
                                                                                    true && (
                                                                                        <Box
                                                                                            className="action-like w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                            onClick={() => {
                                                                                                toggleReaction(
                                                                                                    post?.id,
                                                                                                    post?.user_has_liked ===
                                                                                                        "no"
                                                                                                        ? "like"
                                                                                                        : "no",
                                                                                                    post?.user_has_liked ===
                                                                                                        "no"
                                                                                                        ? true
                                                                                                        : false
                                                                                                );
                                                                                                handlePopoverClose();
                                                                                            }}
                                                                                            onMouseEnter={(
                                                                                                e
                                                                                            ) =>
                                                                                                handlePopoverOpen(
                                                                                                    e,
                                                                                                    post.id
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    post?.reaction ===
                                                                                                        "like"
                                                                                                        ? faThumbsUp
                                                                                                        : post?.reaction ===
                                                                                                            "love"
                                                                                                            ? faHeart
                                                                                                            : post?.reaction ===
                                                                                                                "smile"
                                                                                                                ? faSmile
                                                                                                                : post?.reaction ===
                                                                                                                    "angry"
                                                                                                                    ? faAngry
                                                                                                                    : faThumbsUp
                                                                                                }
                                                                                                className={`fs-12-action-btn ${post?.reaction ===
                                                                                                    "like"
                                                                                                    ? "liked-post-text"
                                                                                                    : post?.reaction ===
                                                                                                        "love"
                                                                                                        ? "love-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "smile"
                                                                                                            ? "smile-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "angry"
                                                                                                                ? "angry-post-text"
                                                                                                                : ""
                                                                                                    }`}
                                                                                            />
                                                                                            <Typography
                                                                                                className={`fs-12-action-btn text-capitalize ${post?.reaction ===
                                                                                                    "like"
                                                                                                    ? "liked-post-text"
                                                                                                    : post?.reaction ===
                                                                                                        "love"
                                                                                                        ? "love-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "smile"
                                                                                                            ? "smile-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "angry"
                                                                                                                ? "angry-post-text"
                                                                                                                : ""
                                                                                                    }`}
                                                                                            >
                                                                                                {post?.user_has_liked !==
                                                                                                    "no"
                                                                                                    ? post.reaction
                                                                                                    : "Like"}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                                {post?.is_post_comment ==
                                                                                    true && (
                                                                                        <Box
                                                                                            className="action-comments w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                            onClick={() =>
                                                                                                handleCommentToggle(
                                                                                                    post?.id
                                                                                                )
                                                                                            }
                                                                                            onMouseEnter={() => {
                                                                                                handlePopoverClose();
                                                                                                handlePopoverCloseForReplyLike();
                                                                                            }}
                                                                                            onMouseLeave={() => {
                                                                                                handlePopoverClose();
                                                                                                handlePopoverCloseForReplyLike();
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    faComments
                                                                                                }
                                                                                                className="fs-12-action-btn"
                                                                                            />
                                                                                            <Typography className="fs-12-action-btn">
                                                                                                Comments
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                            </CardActions>
                                                                            {post?.is_post_comment ==
                                                                                true && (
                                                                                    <CardActions
                                                                                        className="bg-white card-action-2"
                                                                                        sx={{
                                                                                            border: "1px solid #EEEEEE",
                                                                                            padding:
                                                                                                "15px",
                                                                                        }}
                                                                                        onMouseEnter={() => {
                                                                                            handlePopoverClose();
                                                                                        }}
                                                                                        onMouseLeave={() => {
                                                                                            handlePopoverClose();
                                                                                        }}
                                                                                    >
                                                                                        {post?.is_post_comment ==
                                                                                            true && (
                                                                                                <>
                                                                                                    {post?.commentToggle ==
                                                                                                        true && (
                                                                                                            <Box
                                                                                                                className="w-100"
                                                                                                                onMouseEnter={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                                onMouseLeave={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                            >
                                                                                                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                    <Avatar
                                                                                                                        alt={
                                                                                                                            loggedUser?.name
                                                                                                                        }
                                                                                                                        className="author-avtar-creator"
                                                                                                                        src={
                                                                                                                            loggedUser?.thumbnail
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {loggedUser?.name?.charAt(
                                                                                                                            0
                                                                                                                        )}
                                                                                                                    </Avatar>
                                                                                                                    <InputGroup
                                                                                                                        className=""
                                                                                                                        style={{
                                                                                                                            height: "32px",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <MentionsInput
                                                                                                                            placeholder="Add Comment. Use '@' for mention"
                                                                                                                            value={
                                                                                                                                post?.commentText
                                                                                                                            }
                                                                                                                            singleLine={
                                                                                                                                true
                                                                                                                            }
                                                                                                                            onChange={(
                                                                                                                                event
                                                                                                                            ) => {
                                                                                                                                console.log(
                                                                                                                                    "event:::",
                                                                                                                                    event
                                                                                                                                );
                                                                                                                                handleCommentText(
                                                                                                                                    event
                                                                                                                                        .target
                                                                                                                                        .value,
                                                                                                                                    post?.id
                                                                                                                                );
                                                                                                                            }}
                                                                                                                            onKeyDown={(
                                                                                                                                e
                                                                                                                            ) => {
                                                                                                                                if (
                                                                                                                                    e.key ===
                                                                                                                                    "Enter"
                                                                                                                                ) {
                                                                                                                                    e.preventDefault();
                                                                                                                                    handleCommentsAPI(
                                                                                                                                        post?.id,
                                                                                                                                        "yes"
                                                                                                                                    );
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            className="input-comments"
                                                                                                                            style={
                                                                                                                                mentionsInputStyle
                                                                                                                            }
                                                                                                                            a11ySuggestionsListLabel={
                                                                                                                                "Suggested mentions"
                                                                                                                            }
                                                                                                                            onPaste={
                                                                                                                                handlePaste
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <Mention
                                                                                                                                style={
                                                                                                                                    mentionStyle
                                                                                                                                }
                                                                                                                                className="mention-popup-class"
                                                                                                                                data={
                                                                                                                                    getMentionUsers
                                                                                                                                }
                                                                                                                                appendSpaceOnAdd={
                                                                                                                                    true
                                                                                                                                }
                                                                                                                                onAdd={() =>
                                                                                                                                    setIsSelectingSuggestion(
                                                                                                                                        true
                                                                                                                                    )
                                                                                                                                } // Set the flag when a suggestion is selected
                                                                                                                                renderSuggestion={(
                                                                                                                                    suggestion,
                                                                                                                                    search,
                                                                                                                                    highlightedDisplay,
                                                                                                                                    index,
                                                                                                                                    focused
                                                                                                                                ) => (
                                                                                                                                    <div
                                                                                                                                        className="list-class"
                                                                                                                                        key={
                                                                                                                                            index
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <div className="list-class-div-1">
                                                                                                                                            <img
                                                                                                                                                src={
                                                                                                                                                    suggestion.photo
                                                                                                                                                }
                                                                                                                                                alt={
                                                                                                                                                    suggestion.display
                                                                                                                                                }
                                                                                                                                                className="list-class-div-1-img"
                                                                                                                                            />
                                                                                                                                            <div className="list-class-div-2">
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontWeight:
                                                                                                                                                            "bold",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    {
                                                                                                                                                        suggestion?.full_name
                                                                                                                                                    }
                                                                                                                                                </span>
                                                                                                                                                <br />
                                                                                                                                                <span>
                                                                                                                                                    {
                                                                                                                                                        suggestion?.job_title
                                                                                                                                                    }
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                            />
                                                                                                                        </MentionsInput>
                                                                                                                        <Button
                                                                                                                            className="search-btn-dash-2"
                                                                                                                            style={{
                                                                                                                                height: "100%",
                                                                                                                            }}
                                                                                                                            onClick={() =>
                                                                                                                                handleCommentsAPI(
                                                                                                                                    post?.id,
                                                                                                                                    "yes"
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon
                                                                                                                                icon={
                                                                                                                                    faAdd
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </Button>
                                                                                                                    </InputGroup>
                                                                                                                </Box>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                </>
                                                                                            )}
                                                                                        {post
                                                                                            ?.comments
                                                                                            ?.length >
                                                                                            0 && (
                                                                                                <>
                                                                                                    {post?.comments?.map(
                                                                                                        (
                                                                                                            comment
                                                                                                        ) => (
                                                                                                            <>
                                                                                                                {comment?.is_parent ===
                                                                                                                    "yes" && (
                                                                                                                        <>
                                                                                                                            <Box
                                                                                                                                className="w-100 ms-0"
                                                                                                                                onMouseEnter={
                                                                                                                                    handlePopoverClose
                                                                                                                                }
                                                                                                                                onMouseLeave={
                                                                                                                                    handlePopoverClose
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                    <Avatar
                                                                                                                                        alt={
                                                                                                                                            comment
                                                                                                                                                ?.user
                                                                                                                                                ?.name
                                                                                                                                        }
                                                                                                                                        className="author-avtar-creator mb-auto"
                                                                                                                                        src={
                                                                                                                                            comment
                                                                                                                                                ?.user
                                                                                                                                                ?.thumbnail
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        {comment?.user?.name?.charAt(
                                                                                                                                            0
                                                                                                                                        )}
                                                                                                                                    </Avatar>
                                                                                                                                    <Box
                                                                                                                                        sx={{
                                                                                                                                            padding:
                                                                                                                                                "15px",
                                                                                                                                        }}
                                                                                                                                        className="comment-box w-100"
                                                                                                                                    >
                                                                                                                                        <Typography className="fs-14-700">
                                                                                                                                            {
                                                                                                                                                comment
                                                                                                                                                    ?.user
                                                                                                                                                    ?.name
                                                                                                                                            }
                                                                                                                                        </Typography>
                                                                                                                                        <Typography className="fs-14 mt-2">
                                                                                                                                            {renderCommentText(
                                                                                                                                                comment?.comment
                                                                                                                                            )}
                                                                                                                                        </Typography>
                                                                                                                                        <Stack
                                                                                                                                            direction="row"
                                                                                                                                            divider={
                                                                                                                                                <Divider
                                                                                                                                                    orientation="vertical"
                                                                                                                                                    flexItem
                                                                                                                                                    className="nested-divider"
                                                                                                                                                />
                                                                                                                                            }
                                                                                                                                            className="mt-2 align-items-center"
                                                                                                                                            spacing={
                                                                                                                                                1
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <Box>
                                                                                                                                                <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                    <Typography
                                                                                                                                                        className={`fs-10 cursor-pointer  ${comment?.reaction ===
                                                                                                                                                            "like"
                                                                                                                                                            ? "liked-post-text"
                                                                                                                                                            : comment?.reaction ===
                                                                                                                                                                "love"
                                                                                                                                                                ? "love-post-text"
                                                                                                                                                                : comment?.reaction ===
                                                                                                                                                                    "smile"
                                                                                                                                                                    ? "smile-post-text"
                                                                                                                                                                    : comment?.reaction ===
                                                                                                                                                                        "angry"
                                                                                                                                                                        ? "angry-post-text"
                                                                                                                                                                        : ""
                                                                                                                                                            }`}
                                                                                                                                                        onClick={() => {
                                                                                                                                                            toggleLikeForCommentsReaction(
                                                                                                                                                                post?.id,
                                                                                                                                                                comment?.has_user_liked_comment ===
                                                                                                                                                                    "no"
                                                                                                                                                                    ? "like"
                                                                                                                                                                    : "no",
                                                                                                                                                                comment?.id,
                                                                                                                                                                comment?.has_user_liked_comment ===
                                                                                                                                                                    "no"
                                                                                                                                                                    ? true
                                                                                                                                                                    : false
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                        onMouseEnter={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            setCurrentPostId(
                                                                                                                                                                post?.id
                                                                                                                                                            );

                                                                                                                                                            setCurrentPostIdForReply(
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                            handlePopoverOpenForReplyLike(
                                                                                                                                                                e,
                                                                                                                                                                post.id,
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                        onMouseLeave={() => {
                                                                                                                                                            setCurrentPostId(
                                                                                                                                                                post?.id
                                                                                                                                                            );

                                                                                                                                                            setCurrentPostIdForReply(
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        {comment?.has_user_liked_comment !==
                                                                                                                                                            "no"
                                                                                                                                                            ? comment.reaction
                                                                                                                                                            : "Like"}
                                                                                                                                                    </Typography>
                                                                                                                                                    <Box
                                                                                                                                                        className="action-like w-100 d-flex flex-wrap align-items-center gap-1 cursor-pointer"
                                                                                                                                                        onMouseEnter={() => {
                                                                                                                                                            handlePopoverClose();
                                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                                        }}
                                                                                                                                                        onMouseLeave={() => {
                                                                                                                                                            handlePopoverClose();
                                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                                        }}
                                                                                                                                                        onClick={() => { handleEngagementModalOpen(comment?.id, 'comment') }}
                                                                                                                                                    >
                                                                                                                                                        {comment?.user_reactions?.map(
                                                                                                                                                            (
                                                                                                                                                                reaction
                                                                                                                                                            ) => (
                                                                                                                                                                <FontAwesomeIcon
                                                                                                                                                                    icon={
                                                                                                                                                                        reaction ===
                                                                                                                                                                            "love"
                                                                                                                                                                            ? faHeart
                                                                                                                                                                            : reaction ===
                                                                                                                                                                                "smile"
                                                                                                                                                                                ? faSmile
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "angry"
                                                                                                                                                                                    ? faAngry
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "like"
                                                                                                                                                                                        ? faThumbsUp
                                                                                                                                                                                        : ""
                                                                                                                                                                    }
                                                                                                                                                                    className={
                                                                                                                                                                        reaction ===
                                                                                                                                                                            "love"
                                                                                                                                                                            ? "fa-love-btn-2"
                                                                                                                                                                            : reaction ===
                                                                                                                                                                                "smile"
                                                                                                                                                                                ? "fa-smile-btn-2"
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "angry"
                                                                                                                                                                                    ? "fa-angry-btn-2"
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "like"
                                                                                                                                                                                        ? "fa-like-btn-2"
                                                                                                                                                                                        : ""
                                                                                                                                                                    }
                                                                                                                                                                />
                                                                                                                                                            )
                                                                                                                                                        )}

                                                                                                                                                        <Box className="like-counts-box">
                                                                                                                                                            <Typography className="fs-10">
                                                                                                                                                                {
                                                                                                                                                                    comment?.comment_likes_count
                                                                                                                                                                }
                                                                                                                                                            </Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                            <Box
                                                                                                                                                onMouseEnter={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                                onMouseLeave={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Box>
                                                                                                                                                    <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                        <Typography
                                                                                                                                                            className="fs-10 cursor-pointer"
                                                                                                                                                            onClick={() =>
                                                                                                                                                                handleCommentReplyToggle(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    comment?.id
                                                                                                                                                                )
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            Reply
                                                                                                                                                        </Typography>
                                                                                                                                                        <Typography className="fs-10 cursor-pointer">
                                                                                                                                                            {`${comment?.replies_count} reply`}
                                                                                                                                                        </Typography>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                        </Stack>
                                                                                                                                    </Box>
                                                                                                                                </Box>
                                                                                                                            </Box>
                                                                                                                            {comment?.commentReplyToggle ==
                                                                                                                                true && (
                                                                                                                                    <Box
                                                                                                                                        className="w-100 ms-0"
                                                                                                                                        sx={{
                                                                                                                                            paddingLeft:
                                                                                                                                                "62px",
                                                                                                                                        }}
                                                                                                                                        onMouseEnter={() => {
                                                                                                                                            handlePopoverClose();
                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                        }}
                                                                                                                                        onMouseLeave={() => {
                                                                                                                                            handlePopoverClose();
                                                                                                                                            handlePopoverCloseForReplyLike();
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <Box className="w-100 mt-2 mb-1">
                                                                                                                                            <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                <Avatar
                                                                                                                                                    alt={
                                                                                                                                                        loggedUser?.name
                                                                                                                                                    }
                                                                                                                                                    className="author-avtar-creator-2"
                                                                                                                                                    src={
                                                                                                                                                        loggedUser?.thumbnail
                                                                                                                                                    }
                                                                                                                                                >
                                                                                                                                                    {loggedUser?.name?.charAt(
                                                                                                                                                        0
                                                                                                                                                    )}
                                                                                                                                                </Avatar>
                                                                                                                                                <InputGroup
                                                                                                                                                    className=""
                                                                                                                                                    style={{
                                                                                                                                                        height: "25px",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <MentionsInput
                                                                                                                                                        placeholder="Add Comment. Use '@' for mention"
                                                                                                                                                        value={
                                                                                                                                                            comment?.commentReplyText
                                                                                                                                                        }
                                                                                                                                                        singleLine={
                                                                                                                                                            true
                                                                                                                                                        }
                                                                                                                                                        onChange={(
                                                                                                                                                            event
                                                                                                                                                        ) => {
                                                                                                                                                            handleCommentReplyText(
                                                                                                                                                                event
                                                                                                                                                                    .target
                                                                                                                                                                    .value,
                                                                                                                                                                post?.id,
                                                                                                                                                                comment?.id
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                        onKeyDown={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            if (
                                                                                                                                                                e.key ===
                                                                                                                                                                "Enter"
                                                                                                                                                            ) {
                                                                                                                                                                e.preventDefault();
                                                                                                                                                                handleCommentsReplyAPI(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    "no",
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }
                                                                                                                                                        }}
                                                                                                                                                        className="input-comments"
                                                                                                                                                        style={
                                                                                                                                                            mentionsInputStyle
                                                                                                                                                        }
                                                                                                                                                        a11ySuggestionsListLabel={
                                                                                                                                                            "Suggested mentions"
                                                                                                                                                        }
                                                                                                                                                        onPaste={
                                                                                                                                                            handlePaste
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        <Mention
                                                                                                                                                            style={
                                                                                                                                                                mentionStyle
                                                                                                                                                            }
                                                                                                                                                            className="mention-popup-class"
                                                                                                                                                            data={
                                                                                                                                                                getMentionUsers
                                                                                                                                                            }
                                                                                                                                                            appendSpaceOnAdd={
                                                                                                                                                                true
                                                                                                                                                            }
                                                                                                                                                            onAdd={() =>
                                                                                                                                                                setIsSelectingSuggestion(
                                                                                                                                                                    true
                                                                                                                                                                )
                                                                                                                                                            } // Set the flag when a suggestion is selected
                                                                                                                                                            renderSuggestion={(
                                                                                                                                                                suggestion,
                                                                                                                                                                search,
                                                                                                                                                                highlightedDisplay,
                                                                                                                                                                index,
                                                                                                                                                                focused
                                                                                                                                                            ) => (
                                                                                                                                                                <div
                                                                                                                                                                    className="list-class"
                                                                                                                                                                    key={
                                                                                                                                                                        index
                                                                                                                                                                    }
                                                                                                                                                                >
                                                                                                                                                                    <div className="list-class-div-1">
                                                                                                                                                                        <img
                                                                                                                                                                            src={
                                                                                                                                                                                suggestion.photo
                                                                                                                                                                            }
                                                                                                                                                                            alt={
                                                                                                                                                                                suggestion.display
                                                                                                                                                                            }
                                                                                                                                                                            className="list-class-div-1-img"
                                                                                                                                                                        />
                                                                                                                                                                        <div className="list-class-div-2">
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    fontWeight:
                                                                                                                                                                                        "bold",
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                {
                                                                                                                                                                                    suggestion?.full_name
                                                                                                                                                                                }
                                                                                                                                                                            </span>
                                                                                                                                                                            <br />
                                                                                                                                                                            <span>
                                                                                                                                                                                {
                                                                                                                                                                                    suggestion?.job_title
                                                                                                                                                                                }
                                                                                                                                                                            </span>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            )}
                                                                                                                                                        />
                                                                                                                                                    </MentionsInput>
                                                                                                                                                    <Button
                                                                                                                                                        className="search-btn-dash-3"
                                                                                                                                                        style={{
                                                                                                                                                            height: "100%",
                                                                                                                                                        }}
                                                                                                                                                        onClick={() =>
                                                                                                                                                            handleCommentsReplyAPI(
                                                                                                                                                                post?.id,
                                                                                                                                                                "no",
                                                                                                                                                                comment?.id
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        <FontAwesomeIcon
                                                                                                                                                            icon={
                                                                                                                                                                faAdd
                                                                                                                                                            }
                                                                                                                                                        />
                                                                                                                                                    </Button>
                                                                                                                                                </InputGroup>
                                                                                                                                            </Box>
                                                                                                                                        </Box>
                                                                                                                                    </Box>
                                                                                                                                )}
                                                                                                                            {comment
                                                                                                                                ?.replies
                                                                                                                                ?.length >
                                                                                                                                0 ? (
                                                                                                                                <>
                                                                                                                                    {comment?.replies?.map(
                                                                                                                                        (
                                                                                                                                            reply
                                                                                                                                        ) => (
                                                                                                                                            <Box
                                                                                                                                                className="w-100 ms-0"
                                                                                                                                                sx={{
                                                                                                                                                    paddingLeft:
                                                                                                                                                        "62px",
                                                                                                                                                }}
                                                                                                                                                onMouseEnter={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                                onMouseLeave={() => {
                                                                                                                                                    handlePopoverClose();
                                                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Box className="w-100 mt-2">
                                                                                                                                                    <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                        <Avatar
                                                                                                                                                            alt={
                                                                                                                                                                reply
                                                                                                                                                                    ?.user
                                                                                                                                                                    ?.name
                                                                                                                                                            }
                                                                                                                                                            className="author-avtar-creator-2 mb-auto"
                                                                                                                                                            src={
                                                                                                                                                                reply
                                                                                                                                                                    ?.user
                                                                                                                                                                    ?.thumbnail
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            {reply?.user?.name?.charAt(
                                                                                                                                                                0
                                                                                                                                                            )}
                                                                                                                                                        </Avatar>
                                                                                                                                                        <Box
                                                                                                                                                            sx={{
                                                                                                                                                                padding:
                                                                                                                                                                    "10px",
                                                                                                                                                            }}
                                                                                                                                                            className="comment-box w-100"
                                                                                                                                                        >
                                                                                                                                                            <Typography className="fs-12-700">
                                                                                                                                                                {
                                                                                                                                                                    reply
                                                                                                                                                                        ?.user
                                                                                                                                                                        ?.name
                                                                                                                                                                }
                                                                                                                                                            </Typography>
                                                                                                                                                            <Typography className="fs-12 mt-1">
                                                                                                                                                                {renderCommentText(
                                                                                                                                                                    reply?.comment
                                                                                                                                                                )}
                                                                                                                                                            </Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                        )
                                                                                                                                    )}
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                ""
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                            </>
                                                                                                        )
                                                                                                    )}
                                                                                                    {post
                                                                                                        ?.comments
                                                                                                        ?.length <
                                                                                                        post?.parent_comment_count && (
                                                                                                            <Box
                                                                                                                className="w-100 ms-0 d-flex justify-content-center"
                                                                                                                onMouseEnter={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                                onMouseLeave={() => {
                                                                                                                    handlePopoverClose();
                                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                                }}
                                                                                                            >
                                                                                                                <Button
                                                                                                                    className="w-50 mt-4 btn-load-comments"
                                                                                                                    onClick={() => {
                                                                                                                        setFetchAll(
                                                                                                                            true
                                                                                                                        );
                                                                                                                        fetchLoadMoreCommentsAPI(
                                                                                                                            post
                                                                                                                        );
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <FontAwesomeIcon
                                                                                                                        icon={
                                                                                                                            faComments
                                                                                                                        }
                                                                                                                    />
                                                                                                                    Load
                                                                                                                    More
                                                                                                                    Comments
                                                                                                                </Button>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                </>
                                                                                            )}
                                                                                    </CardActions>
                                                                                )}
                                                                        </>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Card>
                                                            ) : (
                                                                <>
                                                                    <Card
                                                                        className="mt-4"
                                                                        data-post-id={
                                                                            post?.id
                                                                        }
                                                                        data-post-view={
                                                                            post?.is_post_seen
                                                                        }
                                                                        ref={
                                                                            setElementsCallback
                                                                        }
                                                                    >
                                                                        <CardHeader
                                                                            avatar={
                                                                                <Avatar
                                                                                    aria-label="recipe"
                                                                                    className="author-avtar-creator"
                                                                                    src={
                                                                                        post?.profile
                                                                                    }
                                                                                >
                                                                                    {post?.created_by?.charAt(
                                                                                        0
                                                                                    )}
                                                                                </Avatar>
                                                                            }
                                                                            title={
                                                                                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                                                                    <span>
                                                                                        <b>{post?.created_by}</b>{" "}posted a{" "}<b>{post?.type}</b>
                                                                                    </span>
                                                                                    <Typography variant="caption">
                                                                                        {dateWithTime(post?.published_date_time, false, false, false)}
                                                                                    </Typography>
                                                                                </Box>
                                                                            }
                                                                            className="card-header-content"
                                                                        />
                                                                        <CardMedia
                                                                            sx={{
                                                                                height: 222,
                                                                            }}
                                                                            image={
                                                                                post?.image
                                                                            }
                                                                            className="cursor-pointer"
                                                                            onClick={() => {
                                                                                if (
                                                                                    loggedUser?.user_type ===
                                                                                    "user"
                                                                                ) {
                                                                                    localStorage.setItem(
                                                                                        "last-redirect-page-user",
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                    navigate(
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                } else if (
                                                                                    loggedUser?.user_type ===
                                                                                    "admin" &&
                                                                                    adminAccessible ==
                                                                                    true
                                                                                ) {
                                                                                    localStorage.setItem(
                                                                                        "last-redirect-page-admin-user",
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                    navigate(
                                                                                        `/user-type/view/${post?.type}/${post?.id}`
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                        <CardContent className="content-div-body">
                                                                            <div className="main-content-div-1">
                                                                                <div className="main-div-posted">
                                                                                    <div className="icon-bd-div">
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                faClock
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <Typography className="fs-14 opacity-50">
                                                                                        {` Posted ${onlyDateFormat(post?.published_date_time, false, true, true)}`}
                                                                                    </Typography>
                                                                                </div>
                                                                                {post
                                                                                    ?.category
                                                                                    ?.length >
                                                                                    0 && (
                                                                                        <div className="main-div-workspace">
                                                                                            <div className="icon-bd-div">
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        faBriefcase
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <Typography className="fs-14 opacity-50">
                                                                                                {post?.category?.join(
                                                                                                    ", "
                                                                                                )}
                                                                                            </Typography>
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                            <Box>
                                                                                <Typography
                                                                                    gutterBottom
                                                                                    variant="h5"
                                                                                    component="div"
                                                                                    className="content-title text-break"
                                                                                >
                                                                                    {
                                                                                        post?.title
                                                                                    }
                                                                                </Typography>
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.secondary"
                                                                                    className="fs-14 content-desc text-break"
                                                                                >
                                                                                    {
                                                                                        post?.content
                                                                                    }
                                                                                </Typography>
                                                                            </Box>
                                                                            {post?.is_require_acknowledge ==
                                                                                true && (
                                                                                    <Box
                                                                                        className={`custom-checkbox mt-3 ${post?.acknowledged_on !==
                                                                                            "" &&
                                                                                            "active-checkbox"
                                                                                            }`}
                                                                                    >
                                                                                        <label className="checkbox-style">
                                                                                            {post?.acknowledged_on !==
                                                                                                ""
                                                                                                ? `You acknowledged this on ${onlyDateFormat(post?.acknowledged_on, true, false, true)}`
                                                                                                : ((post?.acknowledge_data && (typeof post?.acknowledge_data === "string")) ? `${post?.acknowledge_data}` : `This requires your acknowledgement`)}
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={
                                                                                                    post?.acknowledged_on !==
                                                                                                    ""
                                                                                                }
                                                                                                onChange={() => {
                                                                                                    if (
                                                                                                        post?.acknowledge_type ===
                                                                                                        "question"
                                                                                                    ) {
                                                                                                        setAckQuestions(
                                                                                                            post?.acknowledge_data
                                                                                                        );
                                                                                                        setPostIdForAckQue(
                                                                                                            post?.id
                                                                                                        );
                                                                                                        setOpenAckQuePopup(
                                                                                                            true
                                                                                                        );
                                                                                                    } else if (
                                                                                                        post?.acknowledge_type ===
                                                                                                        "checkbox"
                                                                                                    ) {
                                                                                                        handleCheckboxChange(
                                                                                                            post?.id
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                                disabled={
                                                                                                    post?.acknowledged_on !==
                                                                                                    ""
                                                                                                }
                                                                                            />
                                                                                            <span className="checkmark"></span>
                                                                                        </label>
                                                                                    </Box>
                                                                                )}
                                                                            <Button
                                                                                className="btn primary-btn mt-4"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        loggedUser?.user_type ===
                                                                                        "user"
                                                                                    ) {
                                                                                        localStorage.setItem(
                                                                                            "last-redirect-page-user",
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                        navigate(
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                    } else if (
                                                                                        loggedUser?.user_type ===
                                                                                        "admin" &&
                                                                                        adminAccessible ==
                                                                                        true
                                                                                    ) {
                                                                                        localStorage.setItem(
                                                                                            "last-redirect-page-admin-user",
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                        navigate(
                                                                                            `/user-type/view/${post?.type}/${post?.id}`
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                View
                                                                                Article
                                                                                <FontAwesomeIcon
                                                                                    icon={
                                                                                        faArrowRight
                                                                                    }
                                                                                />
                                                                            </Button>
                                                                        </CardContent>
                                                                        {post?.is_post_comment ===
                                                                            true ||
                                                                            post?.is_post_like ===
                                                                            true ? (
                                                                            <>
                                                                                <CardActions
                                                                                    className="bg-white"
                                                                                    sx={{
                                                                                        border: "1px solid #EEEEEE",
                                                                                        padding:
                                                                                            "10px",
                                                                                    }}
                                                                                    onMouseEnter={() => {
                                                                                        handlePopoverClose();
                                                                                        handlePopoverCloseForReplyLike();
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        handlePopoverClose();
                                                                                        handlePopoverCloseForReplyLike();
                                                                                    }}
                                                                                >
                                                                                    {post?.is_post_like ==
                                                                                        true && (
                                                                                            <Box className="action-like w-100 d-flex flex-wrap align-items-center gap-2 cursor-pointer"
                                                                                                onClick={() => { handleEngagementModalOpen(post?.id) }}
                                                                                            >
                                                                                                {post?.reactions?.map(
                                                                                                    (
                                                                                                        reaction
                                                                                                    ) => (
                                                                                                        <FontAwesomeIcon
                                                                                                            icon={
                                                                                                                reaction ===
                                                                                                                    "love"
                                                                                                                    ? faHeart
                                                                                                                    : reaction ===
                                                                                                                        "smile"
                                                                                                                        ? faSmile
                                                                                                                        : reaction ===
                                                                                                                            "angry"
                                                                                                                            ? faAngry
                                                                                                                            : reaction ===
                                                                                                                                "like"
                                                                                                                                ? faThumbsUp
                                                                                                                                : ""
                                                                                                            }
                                                                                                            className={
                                                                                                                reaction ===
                                                                                                                    "love"
                                                                                                                    ? "fa-love-btn"
                                                                                                                    : reaction ===
                                                                                                                        "smile"
                                                                                                                        ? "fa-smile-btn"
                                                                                                                        : reaction ===
                                                                                                                            "angry"
                                                                                                                            ? "fa-angry-btn"
                                                                                                                            : reaction ===
                                                                                                                                "like"
                                                                                                                                ? "fa-like-btn"
                                                                                                                                : ""
                                                                                                            }
                                                                                                        />
                                                                                                    )
                                                                                                )}

                                                                                                <Box className="like-counts-box">
                                                                                                    <Typography className="fs-12-action-btn">
                                                                                                        {
                                                                                                            post?.like_count
                                                                                                        }
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        )}
                                                                                    {post?.is_post_comment ==
                                                                                        true && (
                                                                                            <Box className="w-100 ms-0">
                                                                                                <Typography className="fs-12-action-btn d-flex  justify-content-end">
                                                                                                    {`${post?.comment_count} comments`}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                </CardActions>
                                                                                <CardActions
                                                                                    className="bg-white"
                                                                                    sx={{
                                                                                        border: "1px solid #EEEEEE",
                                                                                        padding:
                                                                                            "10px",
                                                                                    }}
                                                                                >
                                                                                    {post?.is_post_like ==
                                                                                        true && (
                                                                                            <Box
                                                                                                className="action-like w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                                onClick={() => {
                                                                                                    toggleReaction(
                                                                                                        post?.id,
                                                                                                        post?.user_has_liked ===
                                                                                                            "no"
                                                                                                            ? "like"
                                                                                                            : "no",
                                                                                                        post?.user_has_liked ===
                                                                                                            "no"
                                                                                                            ? true
                                                                                                            : false
                                                                                                    );
                                                                                                    handlePopoverClose();
                                                                                                }}
                                                                                                onMouseEnter={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handlePopoverOpen(
                                                                                                        e,
                                                                                                        post.id
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        post?.reaction ===
                                                                                                            "like"
                                                                                                            ? faThumbsUp
                                                                                                            : post?.reaction ===
                                                                                                                "love"
                                                                                                                ? faHeart
                                                                                                                : post?.reaction ===
                                                                                                                    "smile"
                                                                                                                    ? faSmile
                                                                                                                    : post?.reaction ===
                                                                                                                        "angry"
                                                                                                                        ? faAngry
                                                                                                                        : faThumbsUp
                                                                                                    }
                                                                                                    className={`fs-12-action-btn ${post?.reaction ===
                                                                                                        "like"
                                                                                                        ? "liked-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "love"
                                                                                                            ? "love-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "smile"
                                                                                                                ? "smile-post-text"
                                                                                                                : post?.reaction ===
                                                                                                                    "angry"
                                                                                                                    ? "angry-post-text"
                                                                                                                    : ""
                                                                                                        }`}
                                                                                                />
                                                                                                <Typography
                                                                                                    className={`fs-12-action-btn text-capitalize ${post?.reaction ===
                                                                                                        "like"
                                                                                                        ? "liked-post-text"
                                                                                                        : post?.reaction ===
                                                                                                            "love"
                                                                                                            ? "love-post-text"
                                                                                                            : post?.reaction ===
                                                                                                                "smile"
                                                                                                                ? "smile-post-text"
                                                                                                                : post?.reaction ===
                                                                                                                    "angry"
                                                                                                                    ? "angry-post-text"
                                                                                                                    : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {post?.user_has_liked !==
                                                                                                        "no"
                                                                                                        ? post.reaction
                                                                                                        : "Like"}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                    {post?.is_post_comment ==
                                                                                        true && (
                                                                                            <Box
                                                                                                className="action-comments w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                                                                                                onClick={() =>
                                                                                                    handleCommentToggle(
                                                                                                        post?.id
                                                                                                    )
                                                                                                }
                                                                                                onMouseEnter={() => {
                                                                                                    handlePopoverClose();
                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                }}
                                                                                                onMouseLeave={() => {
                                                                                                    handlePopoverClose();
                                                                                                    handlePopoverCloseForReplyLike();
                                                                                                }}
                                                                                            >
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        faComments
                                                                                                    }
                                                                                                    className="fs-12-action-btn"
                                                                                                />
                                                                                                <Typography className="fs-12-action-btn">
                                                                                                    Comments
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                </CardActions>
                                                                                {post?.is_post_comment ==
                                                                                    true && (
                                                                                        <CardActions
                                                                                            className="bg-white card-action-2"
                                                                                            sx={{
                                                                                                border: "1px solid #EEEEEE",
                                                                                                padding:
                                                                                                    "15px",
                                                                                            }}
                                                                                            onMouseEnter={() => {
                                                                                                handlePopoverClose();
                                                                                            }}
                                                                                            onMouseLeave={() => {
                                                                                                handlePopoverClose();
                                                                                            }}
                                                                                        >
                                                                                            {post?.is_post_comment ==
                                                                                                true && (
                                                                                                    <>
                                                                                                        {post?.commentToggle ==
                                                                                                            true && (
                                                                                                                <Box
                                                                                                                    className="w-100"
                                                                                                                    onMouseEnter={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                    onMouseLeave={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                        <Avatar
                                                                                                                            alt={
                                                                                                                                loggedUser?.name
                                                                                                                            }
                                                                                                                            className="author-avtar-creator"
                                                                                                                            src={
                                                                                                                                loggedUser?.thumbnail
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {loggedUser?.name?.charAt(
                                                                                                                                0
                                                                                                                            )}
                                                                                                                        </Avatar>
                                                                                                                        <InputGroup
                                                                                                                            className=""
                                                                                                                            style={{
                                                                                                                                height: "32px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <MentionsInput
                                                                                                                                placeholder="Add Comment. Use '@' for mention"
                                                                                                                                value={
                                                                                                                                    post?.commentText
                                                                                                                                }
                                                                                                                                singleLine={
                                                                                                                                    true
                                                                                                                                }
                                                                                                                                onChange={(
                                                                                                                                    event
                                                                                                                                ) => {
                                                                                                                                    handleCommentText(
                                                                                                                                        event
                                                                                                                                            .target
                                                                                                                                            .value,
                                                                                                                                        post?.id
                                                                                                                                    );
                                                                                                                                }}
                                                                                                                                onKeyDown={(
                                                                                                                                    e
                                                                                                                                ) => {
                                                                                                                                    if (
                                                                                                                                        e.key ===
                                                                                                                                        "Enter"
                                                                                                                                    ) {
                                                                                                                                        e.preventDefault();
                                                                                                                                        handleCommentsAPI(
                                                                                                                                            post?.id,
                                                                                                                                            "yes"
                                                                                                                                        );
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className="input-comments"
                                                                                                                                style={
                                                                                                                                    mentionsInputStyle
                                                                                                                                }
                                                                                                                                a11ySuggestionsListLabel={
                                                                                                                                    "Suggested mentions"
                                                                                                                                }
                                                                                                                                onPaste={
                                                                                                                                    handlePaste
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Mention
                                                                                                                                    style={
                                                                                                                                        mentionStyle
                                                                                                                                    }
                                                                                                                                    className="mention-popup-class"
                                                                                                                                    data={
                                                                                                                                        getMentionUsers
                                                                                                                                    }
                                                                                                                                    onAdd={() =>
                                                                                                                                        setIsSelectingSuggestion(
                                                                                                                                            true
                                                                                                                                        )
                                                                                                                                    } // Set the flag when a suggestion is selected
                                                                                                                                    appendSpaceOnAdd={
                                                                                                                                        true
                                                                                                                                    }
                                                                                                                                    renderSuggestion={(
                                                                                                                                        suggestion,
                                                                                                                                        search,
                                                                                                                                        highlightedDisplay,
                                                                                                                                        index,
                                                                                                                                        focused
                                                                                                                                    ) => (
                                                                                                                                        <div
                                                                                                                                            className="list-class"
                                                                                                                                            key={
                                                                                                                                                index
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <div className="list-class-div-1">
                                                                                                                                                <img
                                                                                                                                                    src={
                                                                                                                                                        suggestion.photo
                                                                                                                                                    }
                                                                                                                                                    alt={
                                                                                                                                                        suggestion.display
                                                                                                                                                    }
                                                                                                                                                    className="list-class-div-1-img"
                                                                                                                                                />
                                                                                                                                                <div className="list-class-div-2">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontWeight:
                                                                                                                                                                "bold",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        {
                                                                                                                                                            suggestion?.full_name
                                                                                                                                                        }
                                                                                                                                                    </span>
                                                                                                                                                    <br />
                                                                                                                                                    <span>
                                                                                                                                                        {
                                                                                                                                                            suggestion?.job_title
                                                                                                                                                        }
                                                                                                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                />
                                                                                                                            </MentionsInput>
                                                                                                                            <Button
                                                                                                                                className="search-btn-dash-2"
                                                                                                                                style={{
                                                                                                                                    height: "100%",
                                                                                                                                }}
                                                                                                                                onClick={() =>
                                                                                                                                    handleCommentsAPI(
                                                                                                                                        post?.id,
                                                                                                                                        "yes"
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <FontAwesomeIcon
                                                                                                                                    icon={
                                                                                                                                        faAdd
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </Button>
                                                                                                                        </InputGroup>
                                                                                                                    </Box>
                                                                                                                </Box>
                                                                                                            )}
                                                                                                    </>
                                                                                                )}
                                                                                            {post
                                                                                                ?.comments
                                                                                                ?.length >
                                                                                                0 && (
                                                                                                    <>
                                                                                                        {post?.comments?.map(
                                                                                                            (
                                                                                                                comment
                                                                                                            ) => (
                                                                                                                <>
                                                                                                                    {comment?.is_parent ===
                                                                                                                        "yes" && (
                                                                                                                            <>
                                                                                                                                <Box
                                                                                                                                    className="w-100 ms-0"
                                                                                                                                    onMouseEnter={
                                                                                                                                        handlePopoverClose
                                                                                                                                    }
                                                                                                                                    onMouseLeave={
                                                                                                                                        handlePopoverClose
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                        <Avatar
                                                                                                                                            alt={
                                                                                                                                                comment
                                                                                                                                                    ?.user
                                                                                                                                                    ?.name
                                                                                                                                            }
                                                                                                                                            className="author-avtar-creator mb-auto"
                                                                                                                                            src={
                                                                                                                                                comment
                                                                                                                                                    ?.user
                                                                                                                                                    ?.thumbnail
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            {comment?.user?.name?.charAt(
                                                                                                                                                0
                                                                                                                                            )}
                                                                                                                                        </Avatar>
                                                                                                                                        <Box
                                                                                                                                            sx={{
                                                                                                                                                padding:
                                                                                                                                                    "15px",
                                                                                                                                            }}
                                                                                                                                            className="comment-box w-100"
                                                                                                                                        >
                                                                                                                                            <Typography className="fs-14-700">
                                                                                                                                                {
                                                                                                                                                    comment
                                                                                                                                                        ?.user
                                                                                                                                                        ?.name
                                                                                                                                                }
                                                                                                                                            </Typography>
                                                                                                                                            <Typography className="fs-14 mt-2">
                                                                                                                                                {renderCommentText(
                                                                                                                                                    comment?.comment
                                                                                                                                                )}
                                                                                                                                            </Typography>
                                                                                                                                            <Stack
                                                                                                                                                direction="row"
                                                                                                                                                divider={
                                                                                                                                                    <Divider
                                                                                                                                                        orientation="vertical"
                                                                                                                                                        flexItem
                                                                                                                                                        className="nested-divider"
                                                                                                                                                    />
                                                                                                                                                }
                                                                                                                                                className="mt-2 align-items-center"
                                                                                                                                                spacing={
                                                                                                                                                    1
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <Box>
                                                                                                                                                    <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                        <Typography
                                                                                                                                                            className={`fs-10 cursor-pointer ${comment?.has_user_liked_comment ===
                                                                                                                                                                "yes" &&
                                                                                                                                                                "liked-post-text"
                                                                                                                                                                }`}
                                                                                                                                                            onClick={() => {
                                                                                                                                                                toggleLikeForCommentsReaction(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    comment?.has_user_liked_comment ===
                                                                                                                                                                        "no"
                                                                                                                                                                        ? "like"
                                                                                                                                                                        : "no",
                                                                                                                                                                    comment?.id,
                                                                                                                                                                    comment?.has_user_liked_comment ===
                                                                                                                                                                        "no"
                                                                                                                                                                        ? true
                                                                                                                                                                        : false
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onMouseEnter={(
                                                                                                                                                                e
                                                                                                                                                            ) => {
                                                                                                                                                                setCurrentPostId(
                                                                                                                                                                    post?.id
                                                                                                                                                                );

                                                                                                                                                                setCurrentPostIdForReply(
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                                handlePopoverOpenForReplyLike(
                                                                                                                                                                    e,
                                                                                                                                                                    post.id,
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onMouseLeave={() => {
                                                                                                                                                                setCurrentPostId(
                                                                                                                                                                    post?.id
                                                                                                                                                                );

                                                                                                                                                                setCurrentPostIdForReply(
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            Like
                                                                                                                                                        </Typography>
                                                                                                                                                        <Box
                                                                                                                                                            className="action-like w-100 d-flex flex-wrap align-items-center gap-1 cursor-pointer"
                                                                                                                                                            onMouseEnter={() => {
                                                                                                                                                                handlePopoverClose();
                                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                                            }}
                                                                                                                                                            onMouseLeave={() => {
                                                                                                                                                                handlePopoverClose();
                                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                                            }}
                                                                                                                                                            onClick={() => { handleEngagementModalOpen(comment?.id, 'comment') }}
                                                                                                                                                        >
                                                                                                                                                            {comment?.user_reactions?.map(
                                                                                                                                                                (
                                                                                                                                                                    reaction
                                                                                                                                                                ) => (
                                                                                                                                                                    <FontAwesomeIcon
                                                                                                                                                                        icon={
                                                                                                                                                                            reaction ===
                                                                                                                                                                                "love"
                                                                                                                                                                                ? faHeart
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "smile"
                                                                                                                                                                                    ? faSmile
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "angry"
                                                                                                                                                                                        ? faAngry
                                                                                                                                                                                        : reaction ===
                                                                                                                                                                                            "like"
                                                                                                                                                                                            ? faThumbsUp
                                                                                                                                                                                            : ""
                                                                                                                                                                        }
                                                                                                                                                                        className={
                                                                                                                                                                            reaction ===
                                                                                                                                                                                "love"
                                                                                                                                                                                ? "fa-love-btn-2"
                                                                                                                                                                                : reaction ===
                                                                                                                                                                                    "smile"
                                                                                                                                                                                    ? "fa-smile-btn-2"
                                                                                                                                                                                    : reaction ===
                                                                                                                                                                                        "angry"
                                                                                                                                                                                        ? "fa-angry-btn-2"
                                                                                                                                                                                        : reaction ===
                                                                                                                                                                                            "like"
                                                                                                                                                                                            ? "fa-like-btn-2"
                                                                                                                                                                                            : ""
                                                                                                                                                                        }
                                                                                                                                                                    />
                                                                                                                                                                )
                                                                                                                                                            )}

                                                                                                                                                            <Box className="like-counts-box">
                                                                                                                                                                <Typography className="fs-10">
                                                                                                                                                                    {
                                                                                                                                                                        comment?.comment_likes_count
                                                                                                                                                                    }
                                                                                                                                                                </Typography>
                                                                                                                                                            </Box>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                                <Box
                                                                                                                                                    onMouseEnter={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                    onMouseLeave={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <Box>
                                                                                                                                                        <Box className="w-100 d-flex gap-2 align-items-center">
                                                                                                                                                            <Typography
                                                                                                                                                                className="fs-10 cursor-pointer"
                                                                                                                                                                onClick={() =>
                                                                                                                                                                    handleCommentReplyToggle(
                                                                                                                                                                        post?.id,
                                                                                                                                                                        comment?.id
                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                            >
                                                                                                                                                                Reply
                                                                                                                                                            </Typography>
                                                                                                                                                            <Typography className="fs-10 cursor-pointer">
                                                                                                                                                                {`${comment?.replies_count} reply`}
                                                                                                                                                            </Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            </Stack>
                                                                                                                                        </Box>
                                                                                                                                    </Box>
                                                                                                                                </Box>
                                                                                                                                {comment?.commentReplyToggle ==
                                                                                                                                    true && (
                                                                                                                                        <Box
                                                                                                                                            className="w-100 ms-0"
                                                                                                                                            sx={{
                                                                                                                                                paddingLeft:
                                                                                                                                                    "62px",
                                                                                                                                            }}
                                                                                                                                            onMouseEnter={() => {
                                                                                                                                                handlePopoverClose();
                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                            }}
                                                                                                                                            onMouseLeave={() => {
                                                                                                                                                handlePopoverClose();
                                                                                                                                                handlePopoverCloseForReplyLike();
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <Box className="w-100 mt-2 mb-1">
                                                                                                                                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                    <Avatar
                                                                                                                                                        alt={
                                                                                                                                                            loggedUser?.name
                                                                                                                                                        }
                                                                                                                                                        className="author-avtar-creator-2"
                                                                                                                                                        src={
                                                                                                                                                            loggedUser?.thumbnail
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        {loggedUser?.name?.charAt(
                                                                                                                                                            0
                                                                                                                                                        )}
                                                                                                                                                    </Avatar>
                                                                                                                                                    <InputGroup
                                                                                                                                                        className=""
                                                                                                                                                        style={{
                                                                                                                                                            height: "25px",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <MentionsInput
                                                                                                                                                            placeholder="Add Comment. Use '@' for mention"
                                                                                                                                                            value={
                                                                                                                                                                comment?.commentReplyText
                                                                                                                                                            }
                                                                                                                                                            singleLine={
                                                                                                                                                                true
                                                                                                                                                            }
                                                                                                                                                            onChange={(
                                                                                                                                                                event
                                                                                                                                                            ) => {
                                                                                                                                                                handleCommentReplyText(
                                                                                                                                                                    event
                                                                                                                                                                        ?.target
                                                                                                                                                                        ?.value,
                                                                                                                                                                    post?.id,
                                                                                                                                                                    comment?.id
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onKeyDown={(
                                                                                                                                                                e
                                                                                                                                                            ) => {
                                                                                                                                                                if (
                                                                                                                                                                    e.key ===
                                                                                                                                                                    "Enter"
                                                                                                                                                                ) {
                                                                                                                                                                    e.preventDefault();
                                                                                                                                                                    handleCommentsReplyAPI(
                                                                                                                                                                        post?.id,
                                                                                                                                                                        "no",
                                                                                                                                                                        comment?.id
                                                                                                                                                                    );
                                                                                                                                                                }
                                                                                                                                                            }}
                                                                                                                                                            className="input-comments"
                                                                                                                                                            style={
                                                                                                                                                                mentionsInputStyle
                                                                                                                                                            }
                                                                                                                                                            a11ySuggestionsListLabel={
                                                                                                                                                                "Suggested mentions"
                                                                                                                                                            }
                                                                                                                                                            onPaste={
                                                                                                                                                                handlePaste
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            <Mention
                                                                                                                                                                style={
                                                                                                                                                                    mentionStyle
                                                                                                                                                                }
                                                                                                                                                                className="mention-popup-class"
                                                                                                                                                                data={
                                                                                                                                                                    getMentionUsers
                                                                                                                                                                }
                                                                                                                                                                appendSpaceOnAdd={
                                                                                                                                                                    true
                                                                                                                                                                }
                                                                                                                                                                onAdd={() =>
                                                                                                                                                                    setIsSelectingSuggestion(
                                                                                                                                                                        true
                                                                                                                                                                    )
                                                                                                                                                                } // Set the flag when a suggestion is selected
                                                                                                                                                                renderSuggestion={(
                                                                                                                                                                    suggestion,
                                                                                                                                                                    search,
                                                                                                                                                                    highlightedDisplay,
                                                                                                                                                                    index,
                                                                                                                                                                    focused
                                                                                                                                                                ) => (
                                                                                                                                                                    <div
                                                                                                                                                                        className="list-class"
                                                                                                                                                                        key={
                                                                                                                                                                            index
                                                                                                                                                                        }
                                                                                                                                                                    >
                                                                                                                                                                        <div className="list-class-div-1">
                                                                                                                                                                            <img
                                                                                                                                                                                src={
                                                                                                                                                                                    suggestion.photo
                                                                                                                                                                                }
                                                                                                                                                                                alt={
                                                                                                                                                                                    suggestion.display
                                                                                                                                                                                }
                                                                                                                                                                                className="list-class-div-1-img"
                                                                                                                                                                            />
                                                                                                                                                                            <div className="list-class-div-2">
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontWeight:
                                                                                                                                                                                            "bold",
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    {
                                                                                                                                                                                        suggestion?.full_name
                                                                                                                                                                                    }
                                                                                                                                                                                </span>
                                                                                                                                                                                <br />
                                                                                                                                                                                <span>
                                                                                                                                                                                    {
                                                                                                                                                                                        suggestion?.job_title
                                                                                                                                                                                    }
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                )}
                                                                                                                                                            />
                                                                                                                                                        </MentionsInput>
                                                                                                                                                        <Button
                                                                                                                                                            className="search-btn-dash-3"
                                                                                                                                                            style={{
                                                                                                                                                                height: "100%",
                                                                                                                                                            }}
                                                                                                                                                            onClick={() =>
                                                                                                                                                                handleCommentsReplyAPI(
                                                                                                                                                                    post?.id,
                                                                                                                                                                    "no",
                                                                                                                                                                    comment?.id
                                                                                                                                                                )
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            <FontAwesomeIcon
                                                                                                                                                                icon={
                                                                                                                                                                    faAdd
                                                                                                                                                                }
                                                                                                                                                            />
                                                                                                                                                        </Button>
                                                                                                                                                    </InputGroup>
                                                                                                                                                </Box>
                                                                                                                                            </Box>
                                                                                                                                        </Box>
                                                                                                                                    )}
                                                                                                                                {comment
                                                                                                                                    ?.replies
                                                                                                                                    ?.length >
                                                                                                                                    0 ? (
                                                                                                                                    <>
                                                                                                                                        {comment?.replies?.map(
                                                                                                                                            (
                                                                                                                                                reply
                                                                                                                                            ) => (
                                                                                                                                                <Box
                                                                                                                                                    className="w-100 ms-0"
                                                                                                                                                    sx={{
                                                                                                                                                        paddingLeft:
                                                                                                                                                            "62px",
                                                                                                                                                    }}
                                                                                                                                                    onMouseEnter={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                    onMouseLeave={() => {
                                                                                                                                                        handlePopoverClose();
                                                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <Box className="w-100 mt-2">
                                                                                                                                                        <Box className="d-flex justify-content-start align-items-center gap-2">
                                                                                                                                                            <Avatar
                                                                                                                                                                alt={
                                                                                                                                                                    reply
                                                                                                                                                                        ?.user
                                                                                                                                                                        ?.name
                                                                                                                                                                }
                                                                                                                                                                className="author-avtar-creator-2 mb-auto"
                                                                                                                                                                src={
                                                                                                                                                                    reply
                                                                                                                                                                        ?.user
                                                                                                                                                                        ?.thumbnail
                                                                                                                                                                }
                                                                                                                                                            >
                                                                                                                                                                {reply?.user?.name?.charAt(
                                                                                                                                                                    0
                                                                                                                                                                )}
                                                                                                                                                            </Avatar>
                                                                                                                                                            <Box
                                                                                                                                                                sx={{
                                                                                                                                                                    padding:
                                                                                                                                                                        "10px",
                                                                                                                                                                }}
                                                                                                                                                                className="comment-box w-100"
                                                                                                                                                            >
                                                                                                                                                                <Typography className="fs-12-700">
                                                                                                                                                                    {
                                                                                                                                                                        reply
                                                                                                                                                                            ?.user
                                                                                                                                                                            ?.name
                                                                                                                                                                    }
                                                                                                                                                                </Typography>
                                                                                                                                                                <Typography className="fs-12 mt-1">
                                                                                                                                                                    {renderCommentText(
                                                                                                                                                                        reply?.comment
                                                                                                                                                                    )}
                                                                                                                                                                </Typography>
                                                                                                                                                            </Box>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                </Box>
                                                                                                                                            )
                                                                                                                                        )}
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    ""
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                </>
                                                                                                            )
                                                                                                        )}
                                                                                                        {post
                                                                                                            ?.comments
                                                                                                            ?.length <
                                                                                                            post?.parent_comment_count && (
                                                                                                                <Box
                                                                                                                    className="w-100 ms-0 d-flex justify-content-center"
                                                                                                                    onMouseEnter={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                    onMouseLeave={() => {
                                                                                                                        handlePopoverClose();
                                                                                                                        handlePopoverCloseForReplyLike();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Button
                                                                                                                        className="w-50 mt-4 btn-load-comments"
                                                                                                                        onClick={() => {
                                                                                                                            setFetchAll(
                                                                                                                                true
                                                                                                                            );
                                                                                                                            fetchLoadMoreCommentsAPI(
                                                                                                                                post
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <FontAwesomeIcon
                                                                                                                            icon={
                                                                                                                                faComments
                                                                                                                            }
                                                                                                                        />
                                                                                                                        Load
                                                                                                                        More
                                                                                                                        Comments
                                                                                                                    </Button>
                                                                                                                </Box>
                                                                                                            )}
                                                                                                    </>
                                                                                                )}
                                                                                        </CardActions>
                                                                                    )}
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Card>
                                                                </>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </Paper>
                                    {getAllPost?.length < 1 && (
                                        <div className="no-data mt-5">
                                            <div>
                                                <Image
                                                    src={NoDataFoundImg}
                                                    className="text-center mx-auto no-data-img mt-4"
                                                />
                                            </div>
                                            <Typography
                                                component="h5"
                                                variant="h5"
                                            >
                                                No data found
                                            </Typography>
                                        </div>
                                    )}
                                </CustomTabPanel>
                            </Box>
                        </Col>
                        {/* <Col lg="4" md="5">
                            <Card className="bg-white card-wrapper w-100">
                                <Card.Header className="bg-white fw-700 primary-text">
                                    Page Content
                                </Card.Header>
                                <Card.Body className="card-type-post-div">
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid
                                            container
                                            columns={{ xs: 4, sm: 8, md: 12 }}
                                            className="main-grid"
                                        >
                                            <Grid
                                                item
                                                xs={2}
                                                sm={4}
                                                md={4}
                                                className="main-grid-item"
                                            >
                                                <Box className="main-box-class d-flex justify-content-center flex-column align-items-center gap-2">
                                                    <FontAwesomeIcon
                                                        icon={faTwitter}
                                                    />
                                                    <Typography className="fs-14">
                                                        Twitter
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                sm={4}
                                                md={4}
                                                className="main-grid-item"
                                            >
                                                <Box className="main-box-class justify-content-center d-flex flex-column align-items-center gap-2">
                                                    <FontAwesomeIcon
                                                        icon={faFacebook}
                                                    />
                                                    <Typography className="fs-14">
                                                        Facebook
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                sm={4}
                                                md={4}
                                                className="main-grid-item"
                                            >
                                                <Box className="main-box-class d-flex justify-content-center flex-column align-items-center gap-2">
                                                    <FontAwesomeIcon
                                                        icon={faGoogle}
                                                    />
                                                    <Typography className="fs-14">
                                                        Drive
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                sm={4}
                                                md={4}
                                                className="main-grid-item"
                                            >
                                                <Box className="main-box-class d-flex justify-content-center flex-column align-items-center gap-2">
                                                    <FontAwesomeIcon
                                                        icon={faWindows}
                                                    />
                                                    <Typography className="fs-14">
                                                        Cloud
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                sm={4}
                                                md={4}
                                                className="main-grid-item"
                                            >
                                                <Box className="main-box-class d-flex justify-content-center flex-column align-items-center gap-2">
                                                    <FontAwesomeIcon
                                                        icon={faSlack}
                                                    />
                                                    <Typography className="fs-14">
                                                        Slack
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                sm={4}
                                                md={4}
                                                className="main-grid-item"
                                            >
                                                <Box className="main-box-class d-flex justify-content-center flex-column align-items-center gap-2">
                                                    <FontAwesomeIcon
                                                        icon={faRocketchat}
                                                    />
                                                    <Typography className="fs-14">
                                                        RocketChat
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>
                </Container>
            </div>
            <Popper
                open={openForReaction}
                anchorEl={anchorElForReaction}
                placement="top"
                disablePortal
            >
                <Box
                    className="reaction-options"
                    sx={{
                        display: "flex",
                        gap: "12px",
                        padding: "10px",
                        background: "white",
                        border: "1px solid #EEEEEE",
                        borderRadius: "20px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <Box
                        onClick={() =>
                            toggleReaction(currentPostId, "like", true)
                        }
                    >
                        <FontAwesomeIcon
                            icon={faThumbsUp}
                            className="reaction-icon fa-like-btn"
                        />
                    </Box>
                    <Box
                        onClick={() =>
                            toggleReaction(currentPostId, "love", true)
                        }
                    >
                        <FontAwesomeIcon
                            icon={faHeart}
                            className="reaction-icon fa-love-btn"
                        />
                    </Box>
                    <Box
                        onClick={() =>
                            toggleReaction(currentPostId, "smile", true)
                        }
                    >
                        <FontAwesomeIcon
                            icon={faSmile}
                            className="reaction-icon fa-smile-btn"
                        />
                    </Box>
                    <Box
                        onClick={() =>
                            toggleReaction(currentPostId, "angry", true)
                        }
                    >
                        <FontAwesomeIcon
                            icon={faAngry}
                            className="reaction-icon fa-angry-btn"
                        />
                    </Box>
                </Box>
            </Popper>
            <Popper
                open={openForReactionReply}
                anchorEl={anchorElForReplyReaction}
                placement="top"
                disablePortal
            >
                <Box
                    className="reaction-options"
                    sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "7px",
                        background: "white",
                        border: "1px solid #EEEEEE",
                        borderRadius: "20px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <Box
                        onClick={() => {
                            toggleLikeForCommentsReaction(
                                currentPostId,
                                "like",
                                currentPostIdForReply,
                                true
                            );
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faThumbsUp}
                            className="reaction-icon-2 fa-like-btn"
                        />
                    </Box>
                    <Box
                        onClick={() =>
                            toggleLikeForCommentsReaction(
                                currentPostId,
                                "love",
                                currentPostIdForReply,
                                true
                            )
                        }
                    >
                        <FontAwesomeIcon
                            icon={faHeart}
                            className="reaction-icon-2 fa-love-btn"
                        />
                    </Box>
                    <Box
                        onClick={() =>
                            toggleLikeForCommentsReaction(
                                currentPostId,
                                "smile",
                                currentPostIdForReply,
                                true
                            )
                        }
                    >
                        <FontAwesomeIcon
                            icon={faSmile}
                            className="reaction-icon-2 fa-smile-btn"
                        />
                    </Box>
                    <Box
                        onClick={() =>
                            toggleLikeForCommentsReaction(
                                currentPostId,
                                "angry",
                                currentPostIdForReply,
                                true
                            )
                        }
                    >
                        <FontAwesomeIcon
                            icon={faAngry}
                            className="reaction-icon-2 fa-angry-btn"
                        />
                    </Box>
                </Box>
            </Popper>
            {isPopupUserExist == true && (
                <Popover
                    open={openForPopupUser}
                    anchorEl={anchorElForPopupUser}
                    onClose={handlePopoverCloseForMentionUser}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                    }}
                    id={idPopupUser}
                >
                    <Card
                        className="people-card-wrapper-3"
                        sx={{ maxWidth: 420 }}
                    >
                        <CardContent>
                            <Stack
                                direction="vertical"
                                className="gap-10 h-100 align-items-center"
                            >
                                <Box className="main-content-data-box">
                                    <Stack
                                        direction="horizontal"
                                        className="d-flex flex-wrap gap-3 w-100 align-items-center justify-content-center"
                                    >
                                        <Box className="w-100 d-flex align-items-center justify-content-center">
                                            {popupUserDetails?.image ? (
                                                <Image
                                                    src={
                                                        popupUserDetails?.image
                                                    }
                                                    className="user-img text-center cursor-pointer"
                                                    alt={popupUserDetails?.name}
                                                    onClick={() => {
                                                        if (
                                                            loggedUser?.user_type ===
                                                            "user"
                                                        ) {
                                                            localStorage.setItem(
                                                                "last-redirect-page-user",
                                                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                                                            );
                                                            navigate(
                                                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                                                            );
                                                        } else if (
                                                            loggedUser?.user_type ===
                                                            "admin" &&
                                                            adminAccessible ==
                                                            true
                                                        ) {
                                                            localStorage.setItem(
                                                                "last-redirect-page-admin-user",
                                                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                                                            );
                                                            navigate(
                                                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                                                            );
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                <Image
                                                    src={DummyUser}
                                                    className="user-img text-center cursor-pointer"
                                                    alt={popupUserDetails?.name}
                                                    onClick={() => {
                                                        if (
                                                            loggedUser?.user_type ===
                                                            "user"
                                                        ) {
                                                            localStorage.setItem(
                                                                "last-redirect-page-user",
                                                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                                                            );
                                                            navigate(
                                                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                                                            );
                                                        } else if (
                                                            loggedUser?.user_type ===
                                                            "admin" &&
                                                            adminAccessible ==
                                                            true
                                                        ) {
                                                            localStorage.setItem(
                                                                "last-redirect-page-admin-user",
                                                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                                                            );
                                                            navigate(
                                                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                                                            );
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Stack>
                                    <Stack
                                        direction="horizontal"
                                        className="d-flex flex-wrap gap-3 align-items-center justify-content-center"
                                    >
                                        <h4
                                            className="news-title dark-text fw-400 cursor-pointer text-break"
                                            style={{ height: "28px" }}
                                            onClick={() => {
                                                if (
                                                    loggedUser?.user_type ===
                                                    "user"
                                                ) {
                                                    localStorage.setItem(
                                                        "last-redirect-page-user",
                                                        `/user-type/people/profile/view/${popupUserDetails?.id}`
                                                    );
                                                    navigate(
                                                        `/user-type/people/profile/view/${popupUserDetails?.id}`
                                                    );
                                                } else if (
                                                    loggedUser?.user_type ===
                                                    "admin" &&
                                                    adminAccessible == true
                                                ) {
                                                    localStorage.setItem(
                                                        "last-redirect-page-admin-user",
                                                        `/user-type/people/profile/view/${popupUserDetails?.id}`
                                                    );
                                                    navigate(
                                                        `/user-type/people/profile/view/${popupUserDetails?.id}`
                                                    );
                                                }
                                            }}
                                        >
                                            {" "}
                                            {popupUserDetails?.name}
                                        </h4>
                                    </Stack>

                                    <Stack
                                        direction="horizontal"
                                        className="d-flex flex-wrap gap-3 mt-2 align-items-center justify-content-center"
                                    >
                                        <Box className="d-flex gap-4 justify-content-center align-items-center">
                                            <FontAwesomeIcon
                                                icon={faPhone}
                                                className="fs-12-660C60"
                                            />
                                            <Typography className="fs-14 opacity-50">
                                                {popupUserDetails?.phone
                                                    ? popupUserDetails?.phone
                                                    : "--"}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                    <Stack
                                        direction="horizontal"
                                        className="d-flex flex-wrap gap-3 mt-1 align-items-center justify-content-center"
                                    >
                                        <Box className="d-flex gap-4 justify-content-center align-items-center">
                                            <FontAwesomeIcon
                                                icon={faEnvelopeOpen}
                                                className="fs-12-660C60"
                                            />
                                            <Typography className="fs-14 opacity-50 text-break">
                                                {popupUserDetails?.email
                                                    ? popupUserDetails?.email
                                                    : "--"}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                    <Stack
                                        direction="horizontal"
                                        className="d-flex flex-wrap gap-3 mt-1 align-items-center justify-content-center"
                                    >
                                        <Button
                                            className="bg-white btn-view-article d-flex gap-3"
                                            variant="outlined"
                                            size="large"
                                            target="_blank"
                                            onClick={() => {
                                                handlePopoverCloseForMentionUser();
                                                window.open(
                                                    `/user-type/people/profile/view/${popupUserDetails?.id}`,
                                                    "_blank"
                                                );
                                            }}
                                        >
                                            View Profile
                                            <FontAwesomeIcon
                                                icon={faArrowRight}
                                            />
                                        </Button>
                                    </Stack>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                </Popover>
            )}

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openPostPopup}
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {/* Modal title
                     */}
                    <div className="d-flex align-items-center gap-2">
                        <Avatar
                            alt={loggedUser?.name}
                            className="author-avtar-creator"
                            src={loggedUser?.thumbnail}
                        >
                            {loggedUser?.name?.charAt(0)}
                        </Avatar>
                        <span className="fs-14-700">{loggedUser?.name}</span>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    className="alert-close-btn"
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className="no-border">
                    <TextField
                        className="input-field"
                        fullWidth
                        id="outlined-basic short summary"
                        variant="outlined"
                        multiline
                        maxRows={2}
                        placeholder={`What are you thinking about?`}
                        value={content}
                        onChange={(event) => setContent(event.target.value)}
                    />
                    {showBelowError == true && content === "" && (
                        <p
                            style={{
                                color: "#d32f2f",
                            }}
                            className="error-msg"
                        >
                            {`Content is required.`}
                        </p>
                    )}

                    <Stack gap={4} className="mt-5 mb-1">
                        {postImage && (
                            <div className="upload-img-wrapper">
                                <Image src={URL.createObjectURL(postImage)} />
                            </div>
                        )}

                        <Button
                            className="btn ternary-btn w-50"
                            onClick={handleButtonClick}
                        >
                            <FontAwesomeIcon icon={faImage} />
                            {postImage ? "Change Image" : "Add Image"}
                        </Button>
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                        <Divider>
                            <Button
                                onClick={() =>
                                    setIsCollapsed((prevState) => !prevState)
                                }
                                className="bg-white hide-option-btn"
                            >
                                {isCollapsed == false
                                    ? "Show Advanced Options"
                                    : "Hide Advanced Options"}

                                <FontAwesomeIcon
                                    icon={
                                        isCollapsed == false
                                            ? faChevronUp
                                            : faChevronDown
                                    }
                                    className="hide-option-btn-svg"
                                />
                            </Button>
                        </Divider>
                        {isCollapsed == true && (
                            <Box>
                                <Row>
                                    {attributesData?.map((attdata, index) => (
                                        <Col lg="12" className="att-col-12">
                                            {attdata?.fieldtype ===
                                                "checkbox_single_choice" && (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    attdata?.value ==
                                                                    true
                                                                }
                                                                name={
                                                                    attdata?.nameValue
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    handleChangeForFormData(
                                                                        attdata?.nameValue,
                                                                        event
                                                                            ?.target
                                                                            ?.checked,
                                                                        attdata?.section_id
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                        label={attdata?.label}
                                                        className="font-inter"
                                                    />
                                                )}
                                        </Col>
                                    ))}
                                </Row>
                            </Box>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions className="p-3">
                    <Button
                        className="btn primary-btn w-100"
                        type="submit"
                        onClick={() => {
                            sumbitPost();
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faCheck}
                            className="d-md-block d-sm-none d-none"
                        />
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className="d-md-none d-sm-block"
                        />
                        Post Update
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <BootstrapDialog
                onClose={handleCloseForAckQue}
                aria-labelledby="customized-dialog-title"
                open={openAckQuePopup}
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {/* Modal title
                     */}
                    <div className="d-flex align-items-center gap-2">
                        <Avatar
                            alt={loggedUser?.name}
                            className="author-avtar-creator"
                            src={loggedUser?.thumbnail}
                        >
                            {loggedUser?.name?.charAt(0)}
                        </Avatar>
                        <span className="fs-14-700">{loggedUser?.name}</span>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseForAckQue}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    className="alert-close-btn"
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className="no-border">
                    <Box>
                        <Typography className="fs-20 mt-2 text-center">
                            Acknowledgement Questions
                        </Typography>
                    </Box>
                    {ackQuestions?.map((que, index) => (
                        <>
                            <Box>
                                <Typography className="fs-14 mt-2">
                                    {que?.question}
                                </Typography>
                                <TextField
                                    className="input-field mt-2"
                                    fullWidth
                                    id="outlined-basic short summary"
                                    variant="outlined"
                                    multiline
                                    maxRows={2}
                                    placeholder={`write answer for ${que?.question}`}
                                    value={que?.ans}
                                    onChange={(event) =>
                                        handleInputChangeForAckQuestions(
                                            event,
                                            index
                                        )
                                    }
                                />
                            </Box>
                        </>
                    ))}
                </DialogContent>
                <DialogActions className="p-3">
                    <Button
                        className="btn primary-btn w-100"
                        type="submit"
                        onClick={() => {
                            // sumbitPost();
                            handleAckQuestionsAPI();
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faCheck}
                            className="d-md-block d-sm-none d-none"
                        />
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className="d-md-none d-sm-block"
                        />
                        Submit
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <BootstrapDialog
                onClose={handleCloseForAckQueForce}
                aria-labelledby="customized-dialog-title"
                open={openAckQuePopupForce}
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {/* Modal title
                     */}
                    <div className="d-flex align-items-center gap-2">
                        <Avatar
                            alt={loggedUser?.name}
                            className="author-avtar-creator"
                            src={loggedUser?.thumbnail}
                        >
                            {loggedUser?.name?.charAt(0)}
                        </Avatar>
                        <span className="fs-14-700">{loggedUser?.name}</span>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseForAckQueForce}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    className="alert-close-btn"
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className="no-border">
                    <Box>
                        <Typography className="fs-20 mt-2 text-center">
                            Acknowledgement Questions
                        </Typography>
                    </Box>
                    {ackQuestions?.map((que, index) => (
                        <>
                            <Box>
                                <Typography className="fs-14 mt-2">
                                    {que?.value}
                                </Typography>
                                <TextField
                                    className="input-field mt-2"
                                    fullWidth
                                    id="outlined-basic short summary"
                                    variant="outlined"
                                    multiline
                                    maxRows={2}
                                    placeholder={`write answer for ${que?.value}`}
                                    value={que?.ans}
                                    onChange={(event) =>
                                        handleInputChangeForAckQuestions(
                                            event,
                                            index
                                        )
                                    }
                                />
                            </Box>
                        </>
                    ))}
                </DialogContent>
                <DialogActions className="p-3">
                    <Button
                        className="btn primary-btn w-100"
                        type="submit"
                        onClick={() => {
                            handleAckQuestionsForceAPI();
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faCheck}
                            className="d-md-block d-sm-none d-none"
                        />
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className="d-md-none d-sm-block"
                        />
                        Submit
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            {isPopupOpen && currentPostIndex !== null && (
                <CustomPopup
                    post={modelPosts[currentPostIndex]}
                    onClose={() => closePopup(modelPosts[currentPostIndex]?.id)}
                />
            )}

            {isPopupOpenForAck && currentPostIndexForAck !== null && (
                <CustomPopupForAck
                    post={modelPostsForAck[currentPostIndexForAck]}
                    onClose={() =>
                        closePopupForAck(
                            modelPostsForAck[currentPostIndexForAck]
                        )
                    }
                />
            )}

            {openEngagementModal && enagagementModalData[0] && (
                <ModalEngagement
                    data={enagagementModalData[0]}
                    onClose={handleEngagementModalClose}
                />
            )}

            <Loader isLoading={loading} />
            <Toaster />
        </>
    );
};

export default UserDashboard;
