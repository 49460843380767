import React, { useEffect, useState, useRef, useCallback } from "react";
import { Dialog, styled, Typography } from "@mui/material";
import {
  Box,
  Tabs,
  Tab,
  Avatar,
  IconButton,
  Divider,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFaceAngry, faFaceSmile, faHeart as faHeartRegular, faThumbsUp as faThumbsUpRegular } from "@fortawesome/free-regular-svg-icons";

import NoDataFoundImg from "../../../assets/images/NoDataFound.png";

import "../modal/ModalEngagement.scss";



import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box">{children}</Box>}
    </div>
  );
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    minHeight: "70vh",
    maxHeight: "70vh"
  }
}));


function ModalEngagement({ data, onClose }) {
  console.log("inide modal engagement");

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  var tabs = []

  if (data?.tabs) {
    tabs = Object.keys(data?.tabs).map((key) => {
      return {
        key: key,
        label: key.charAt(0).toUpperCase() + key.slice(1),
        count: data?.tabs[key]
      };
    });
  } else {
    tabs = [
      { key: 'like', label: 'Like', count: data.engagement.like ? data.engagement.like.length : 0 },
      { key: 'love', label: 'Love', count: data.engagement.love ? data.engagement.love.length : 0 },
      { key: 'smile', label: 'Smile', count: data.engagement.smile ? data.engagement.smile.length : 0 },
      { key: 'angry', label: 'Angry', count: data.engagement.angry ? data.engagement.angry.length : 0 },
    ];
  }



  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className="highlight-engagement-model-title-div"
      >
        <Typography sx={{ fontSize: "1.25rem", lineHeight: "1.75rem", fontWeight: 400 }} className="text-center engagement-modal-title">
          Engagement
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
          className="alert-close-btn engagement-close-btn"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="enagagement-modal-dialog-content">
        <Box>
          {/* Tabs Header */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            className="engagement-modal-tabs"
            variant="scrollable"
            scrollButtons="auto"

            TabIndicatorProps={{
              style: {
                backgroundColor: "#660C60",
              },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                label={
                  <Box gap={2} className="engagement-modal-tab-label">
                    <FontAwesomeIcon
                      icon={
                        tab.key === "love"
                          ? faHeartRegular
                          : tab.key === "smile"
                            ? faFaceSmile
                            : tab.key === "angry"
                              ? faFaceAngry
                              : tab.key === "like"
                                ? faThumbsUpRegular
                                : ""
                      }
                      className={
                        tab.key === "love"
                          ? "fa-love-btn label-emoji"
                          : tab.key === "smile"
                            ? "fa-smile-btn label-emoji"
                            : tab.key === "angry"
                              ? "fa-angry-btn label-emoji"
                              : tab.key === "like"
                                ? "fa-like-btn label-emoji"
                                : ""
                      }
                    />
                    <span>
                      {`${tab?.label}`}
                    </span>
                    <Box className="label-counter">
                      <Typography sx={{ fontWeight: 500, fontSize: "0.7rem", color: "#333333" }}>
                        <b>
                          {`${tab?.count}`}
                        </b>
                      </Typography>
                    </Box>
                  </Box>
                }
                key={index}
              />
            ))}
          </Tabs>
          <Divider className="divider" />
          {tabs.map((tab, index) => (
            <CustomTabPanel key={index} value={activeTab} index={index}>
              {tab.count > 0 ? (
                data?.engagement[tab.key].map((engagement, idx) => (
                  <>
                    <Box key={idx} className="engagement-data" >
                      <Box gap={2} sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                        <Avatar
                          aria-label="recipe"
                          className="author-avtar-creator-engagement"
                          alt={engagement?.user?.full_name || engagement?.user?.name}
                          src={engagement?.user?.thumbnail}
                        />
                        <Typography sx={{ fontWeight: 400, fontSize: "0.9rem", color: "#333333" }}>
                          {engagement?.user?.full_name || engagement?.user?.name}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: "0.66rem" }} variant="caption">
                        {engagement?.format_created_at}
                      </Typography>
                    </Box>
                    <Divider className="divider engagement-divider" />
                  </>
                ))
              ) : (
                <Box className="engagement-data" >

                  <Typography variant="body2" sx={{ mt: 2 }}>
                    No engagement in this category.
                  </Typography>
                </Box>
              )}
            </CustomTabPanel>
          ))}
        </Box>
      </DialogContent>
    </BootstrapDialog >
  )
}

export default ModalEngagement