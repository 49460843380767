export const columns_attribute_select_fields_header = [
  {
    key: 1,
    disablePadding: true,
    id: "value",
    label: "Value",
    className: "tbl-cell-head-3",
  },
  {
    key: 2,
    disablePadding: true,
    id: "default",
    label: "Default",
    className: "tbl-cell-head-3",
  },
];
