import React, { useState, useEffect, useRef } from "react";
import { Stack, Image as BootstrapImage, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock, faSearch, faPlus, faGears, faUsers, faStar, faUser, faEye, faPen, faArrowRight, faAngleUp, faHeart,
  faSmile,
  faAngry,
  faThumbsUp,
  faClock,
  faGavel,
  faComment as faCommentSolid,
  faComments,
  faFolder,
  faFile
} from "@fortawesome/free-solid-svg-icons";
import SiteLogo from "../../assets/images/logo.png";
import logoutHelper from "../../helpers/logoutHelper";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import api from "../common/commonFunctionForApi";
import Loader from "../common/loader";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box, Popover, Typography, Grid, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Divider, Badge, Dialog, DialogContent, Tabs,
  Tab,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextareaAutosize
} from "@mui/material";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import menusvg from "../../assets/images/menu.svg";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Toaster } from "react-hot-toast";
import Pusher from "pusher-js";
import notificationSvg from "../../assets/images/notificationbell.svg";
import chatSvg from "../../assets/images/chaticon.svg"
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { faBell, faComment, faEdit } from "@fortawesome/free-regular-svg-icons";
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { headerAction } from "../../redux/slices/header/header";
import auth from "../../redux/slices/auth/auth";
import { Toast } from "../../utils/toast";
import { usePusher } from "../common/PusherContext";
import albertLogo from "../../assets/images/albert.png"
import notificationSound from "../../assets/sounds/notification_sound2.mp3"
import { conversationAction } from "../../redux/slices/conversation/conversation";
import { useLocation } from "react-router-dom";
import CryptoJS from 'crypto-js';
import noChatImg from "../../assets/images/noChatImg.png"
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import "../../../src/styles/header.scss"

import PropTypes from "prop-types";
import TableHeaderForSorting from "../common/TableHeaderForSorting";
import { columns_global_search_header } from "../../helpers/theaderColumns/globalSearch/globalSearch";
import NoDataFoundImg from "../../../src/assets/images/NoDataFound.png"

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box-2">{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const chatContainerRef = useRef(null);
  // const { pusherMessage, updatedPusherMessage, deletePusherMessage, reactionPusherMessage, startTyping, stopTyping, typingUsers, pusherInstanceData, setPusherMessage, setUpdatedPusherMessage, setDeletePusherMessage, setReactionPusherMessage, setTypingUsers } = usePusher()

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElForApps, setAnchorElForApps] = React.useState(null);
  const [anchorElForAvatar, setAnchorElForAvatar] = React.useState(null);
  const [anchorElForCreateContent, setAnchorElForCreateContent] = React.useState(null);
  const [anchorElForCreateContent2, setAnchorElForCreateContent2] = React.useState(null);
  const [anchorElForPlusContentIcon, setAnchorElForPlusContentIcon] = useState(null)
  const [anchorElForReaction, setAnchorElForReaction] = useState(null);
  const [anchorElForChatEdit, setAnchorElForChatEdit] = useState(null);
  const [anchorElForPopupUser, setAnchorElForPopupUser] = useState(null);
  const [anchorElForGlobalSearch, setanchorElForGlobalSearch] = useState(null)

  const [pusherMessage, setPusherMessage] = useState(null);
  const [updatedPusherMessage, setUpdatedPusherMessage] = useState(null)
  const [deletePusherMessage, setDeletePusherMessage] = useState(null)
  const [reactionPusherMessage, setReactionPusherMessage] = useState(null)
  const [startTyping, setStartTyping] = useState(null)
  const [stopTyping, setStopTyping] = useState(null)
  const [typingUsers, setTypingUsers] = useState([]);
  const [typindUsersData, setTypingUsersData] = useState([])
  const [pusherInstanceData, setPusherInstanceData] = useState()

  const [chatUserList, setChatUserList] = useState([])
  const [selectedChatRoom, setSelectedChatRoom] = useState()
  const [selectedChatRoomData, setSelectedChatRoomData] = useState()

  const [typedMessage, setTypedMessage] = useState("")
  const [isTyping, setIsTyping] = useState(false)
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(selectedChatRoomData?.links?.next !== null);
  const [isLoadingMorePressed, setIsLoadingMorePressed] = useState(false)
  const [isEditConversation, setIsEditConversation] = useState(false)
  const [selectedChatRoomDataOnly, setSelectedChatRoomDataOnly] = useState([])

  const [searchResults, setSearchResults] = useState([]);
  const [navigatedChatId, setNavigatedChatId] = useState(null);
  const [isSelectedEdit, setIsSelectedEdit] = useState(false)
  const [isEditableChat, setIsEditableChat] = useState(false)
  const [editChatMsg, setEditChatMsg] = useState()
  const [currentChatID, setCurrentChatID] = useState()
  const [isReacting, setIsReacting] = useState(false); // To track if a reaction is being selected
  const [reactionUsersList, setReactionUsersList] = useState([])
  const [globalSearch, setGlobalSearch] = useState("")
  const [globalSearchData, setGlobalSearchData] = useState()
  const [globalSearchDataTabs, setGlobalSearchDataTabs] = useState([])
  const [globalSearchDataOnly, setGlobalSearchDataOnly] = useState([])
  const [globalSearchFilterData, setGlobalSearchFilterData] = useState([])
  const [openForGlobalSearch, setOpenforGobalSearch] = useState(false)
  const [value, setValue] = useState("all");
  const [dataOrder, setDataOrder] = useState("asc");
  const [notificationTabCount, setNotificationTabCount] = useState({ "all": 0, "must action": 0, "mention": 0 })
  const [borderOnSendMessage, setBorderOnSendMessage] = useState(false)

  //states for notifications
  const [selectedTab, setSelectedTab] = useState("must action");


  const loggedInUserId = 5;

  const [openForPlusContentIcon, setOpenForPlusContentIcon] = React.useState(false);

  const { anchorElForChat, anchorElForNotification, notificationFetched, notificationData, pusherInitialized } = useSelector((state) => state?.header);
  const { unReadMsgCount } = useSelector((state) => state?.conversation);

  const [getAllTypes, setGetAllTypes] = useState([]);

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  let allMessages = [];
  const debounceTimeout = 500;
  let debounceTimer;

  const descriptionElementRef = useRef(null);

  const openMenu = Boolean(anchorEl);
  const openMenuForApps = Boolean(anchorElForApps);
  const openMenuForAvatar = Boolean(anchorElForAvatar);
  const openMenuForChat = Boolean(anchorElForChat);
  const openForReaction = Boolean(anchorElForReaction);
  const openMenuForChatEdit = Boolean(anchorElForChatEdit);
  const openMenuForNotification = Boolean(anchorElForNotification);
  const openMenuforGlobalSearch = Boolean(anchorElForGlobalSearch)


  const openMenuForCreateContent = Boolean(anchorElForCreateContent)
  const openMenuForCreateContent2 = Boolean(anchorElForCreateContent2)

  const openMenuForPlusContentIcon = Boolean(anchorElForPlusContentIcon)

  const openForPopupUser = Boolean(anchorElForPopupUser);

  const idPopupUser = openForPopupUser ? "simple-popover" : undefined;

  const idForReaction = openForReaction ? "simple-popover" : undefined;
  const idForChatEdit = openMenuForChatEdit ? "simple-popover" : undefined;

  const idForGlobalSearch = openMenuforGlobalSearch ? "simple-popover" : undefined

  const { open, drawerVariant, menuFor,
    adminAccessible } = useSelector((state) => state?.drawerMenu);

  const userToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  const typingTimeoutRef = useRef(null);

  const jsonPusherData = localStorage.getItem("pusher")
  const pusherData = JSON.parse(jsonPusherData)

  const jsonTenantData = localStorage.getItem("tenant")
  const tenantData = JSON.parse(jsonTenantData)

  const selectedChatRoomID = localStorage.getItem("selected-chat-room-id")

  const devMode = process.env.REACT_APP_MODE
  let apiURL;

  if (devMode !== "DEV") {
    apiURL = `${window?.location?.protocol}//${window?.location?.hostname}/api`;
  } else {
    apiURL = `${window?.location?.protocol}//${window?.location?.hostname}:8000/api`;
  }

  const [selectedView, setSelectedView] = React.useState("")

  const actions = [
    { icon: <FontAwesomeIcon icon={faBell} />, name: 'Notification' },
    { icon: <FontAwesomeIcon icon={faComment} />, name: 'Chat' },
    { icon: <FontAwesomeIcon icon={faPlus} />, name: 'Create Content' },
  ];

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const chunkedTypes = chunkArray(getAllTypes, 2);

  const idNotification = openMenuForNotification ? "simple-popover" : undefined;
  const id = openMenu ? "simple-popover" : undefined;
  const idApps = openMenuForApps ? "simple-popover" : undefined;
  const idAvatar = openMenuForAvatar ? "simple-popover" : undefined;
  const idChat = openMenuForChat ? "simple-popover" : undefined;

  const idCreateContent = openMenuForCreateContent ? "simple-popover" : undefined
  const idCreateContent2 = openMenuForCreateContent2 ? "simple-popover" : undefined

  const idPlusContentIcon = openMenuForPlusContentIcon ? "simple-popover" : undefined

  const onLogout = async () => {
    setLoading(true);
    localStorage.removeItem("selected-folder-item")
    localStorage.removeItem("selected-folder-type")

    const result = await logoutHelper(dispatch, navigate, userToken);
    if (result == true) {
      setLoading(false);
    }
  };

  const anotherCurrentTarget = () => {
    return (
      <button type="button" class="create-content-btn btn btn-primary" aria-controls="account-create-content" aria-expanded="true" aria-describedby="simple-popover">Create Content<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path></svg></button>
    )
  }

  const handleClickForDetails = () => {
    if (loggedUser?.user_type == "admin") {
      localStorage.setItem("last-redirect-page", `/`)
      navigate("/");
    } else {
      localStorage.setItem("last-redirect-page-user", `/dashboard`)
      navigate("/dashboard");
    }
  };

  const handleClick = (event) => {
    if (anchorEl == null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    async function updateNotification() {
      await updateNotificationSeenStatus();
      await fetchAllNotification();
    }
    //update the notification when there is unseen count
    if (anchorElForNotification !== null && notificationTabCount[selectedTab] > 0) {
      updateNotification();
    }
  }, [selectedTab])

  const updateNotificationSeenStatus = async () => {
    try {
      const response = await api.post(
        "user/notification/update-status",
        { "tab": selectedTab },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response?.success) {
        console.log("error while updating seen status::", response?.error);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
    }
  }

  const handleClickForNotification = async (event) => {
    handleCloseForAvatar();
    handleCloseForCreateContent();
    handleCloseForCreateContent2();
    handleCloseForPlusContentIcon();
    handleCloseForApps();

    if (anchorElForNotification == null) {
      dispatch(
        headerAction.setAnchorElForNotification(event.currentTarget)
      );

      if (notificationTabCount[selectedTab] > 0) {
        await updateNotificationSeenStatus();
      }
      await fetchAllNotification();

    } else {
      dispatch(headerAction.setAnchorElForNotification(null));
    }
  };

  const handleCloseForNotification = () => {
    dispatch(headerAction.setAnchorElForNotification(null));
  };

  const handleClickForApps = (event) => {
    handleCloseForAvatar();
    handleCloseForCreateContent();
    handleCloseForChat()
    if (anchorElForApps == null) {
      setAnchorElForApps(event.currentTarget);
    } else {
      setAnchorElForApps(null);
    }
  };



  const handleClickForChat = (event) => {
    handleCloseForAvatar();
    handleCloseForCreateContent();
    handleCloseForCreateContent2();
    handleCloseForPlusContentIcon()
    handleCloseForApps()

    if (anchorElForChat == null) {
      dispatch(headerAction.setAnchorElForChat(event.currentTarget));

    } else {
      dispatch(headerAction.setAnchorElForChat(null));

    }
  }

  const handleCloseForApps = () => {
    setAnchorElForApps(null);
  };

  const handleCloseForChat = () => {
    dispatch(headerAction.setAnchorElForChat(null));
  };

  const formatTypingMessage = () => {
    if (typingUsers.length === 0) return "";

    // Filter out the logged-in user from the typing users list
    const otherUsersTyping = typingUsers.filter(name => name !== loggedUser?.name);

    let typingMessage = "";

    if (otherUsersTyping.length === 0) {
      typingMessage = "";
    }
    else if (otherUsersTyping.length === 1) {
      typingMessage = loggedUser && otherUsersTyping.length === typingUsers.length
        ? `${otherUsersTyping[0]} is typing...`
        : `You and ${otherUsersTyping[0]} are typing...`;
    } else if (otherUsersTyping.length === 2) {
      typingMessage = loggedUser && otherUsersTyping.length === typingUsers.length
        ? `${otherUsersTyping[0]} and ${otherUsersTyping[1]} are typing...`
        : `You, ${otherUsersTyping[0]}, and ${otherUsersTyping[1]} are typing...`;
    } else {
      typingMessage = `You, ${otherUsersTyping.slice(0, -1).join(', ')}, and ${otherUsersTyping[otherUsersTyping.length - 1]} are typing...`;
    }

    return typingMessage;
  };

  const userTypingMessageData = formatTypingMessage();

  const handleClickForCreateContent = (event) => {
    handleCloseForPlusContentIcon()
    handleCloseForAvatar();
    handleCloseForApps()
    handleCloseForChat()
    if (anchorElForCreateContent == null) {
      setAnchorElForCreateContent(event.currentTarget);
    } else {
      setAnchorElForCreateContent(null);
    }
  };

  const handleClickForCreateContent2 = (event) => {
    handleCloseForPlusContentIcon()
    handleCloseForAvatar();
    handleCloseForApps()
    handleCloseForCreateContent()
    handleCloseForChat()
    if (anchorElForCreateContent2 == null) {
      setAnchorElForCreateContent2(event.currentTarget);
    } else {
      setAnchorElForCreateContent2(null);
    }
  };

  const handleCloseForCreateContent = () => {
    setAnchorElForCreateContent(null);
  };

  const handleCloseForCreateContent2 = () => {
    setAnchorElForCreateContent2(null);
  };

  const handleClickForPlusContentIcon = (event) => {
    handleCloseForAvatar();
    handleCloseForApps()
    handleCloseForCreateContent()
    handleCloseForCreateContent2()
    handleCloseForChat()
    if (anchorElForPlusContentIcon == null) {
      setAnchorElForPlusContentIcon(event.currentTarget);
    } else {
      setAnchorElForPlusContentIcon(null);
    }
  };

  const handleCloseForPlusContentIcon = () => {
    setAnchorElForPlusContentIcon(null);
  };

  const handleCloseForGlobalSearch = () => {
    setOpenforGobalSearch(false)
    setanchorElForGlobalSearch(null)
    setValue("all")
    setGlobalSearch("")
    setGlobalSearchData()
    setGlobalSearchDataOnly([])
    setGlobalSearchDataTabs([])
    setGlobalSearchFilterData([])
  }

  const handleChangeDataOrdr = () => {
    let order = dataOrder === "asc" ? "desc" : "asc";
    setDataOrder(dataOrder === "asc" ? "desc" : "asc");

    fetchGlobalSearchData(value, false, order);
  };

  const handleClickForAvatar = (event) => {
    handleCloseForApps();
    handleCloseForCreateContent();
    handleCloseForChat()
    if (anchorElForAvatar == null) {
      setAnchorElForAvatar(event.currentTarget);
    } else {
      setAnchorElForAvatar(null);
    }
  };

  const handleCloseForAvatar = () => {
    setAnchorElForAvatar(null);
  };

  const handleOpenDrawer = () => {
    if (drawerVariant === "permanent") {
      if (window.innerWidth <= 768) {
        if (open == true) {
          dispatch(drawerMenuAction.handleDrawerVariant("persistent"));
          dispatch(drawerMenuAction.handleDrawer(false));
        }
      }
      dispatch(drawerMenuAction.handleDrawer(open == true ? false : true));
    } else if (drawerVariant === "persistent") {
      dispatch(drawerMenuAction.handleDrawerVariant("permanent"));
      dispatch(drawerMenuAction.handleDrawer(true));
    }
  };

  const handleChange = (data) => {
    if (data == true) {
      localStorage.setItem("admin-access-view", true)
      setSelectedView("view")
      dispatch(drawerMenuAction.handleSelectMenu("user"))
      dispatch(drawerMenuAction.handleAdminAccessible(true))
      navigate(
        localStorage?.getItem("last-redirect-page-admin-user")
          ? localStorage?.getItem("last-redirect-page-admin-user")
          : "/dashboard"
      );
    } else {
      localStorage.setItem("admin-access-view", false)
      setSelectedView("edit")
      dispatch(drawerMenuAction.handleSelectMenu("admin"))
      dispatch(drawerMenuAction.handleAdminAccessible(false))
      navigate(
        localStorage?.getItem("last-redirect-page")
          ? localStorage?.getItem("last-redirect-page")
          : "/"
      );
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert UNIX timestamp to milliseconds

    // Format the date as "30 Aug"
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    // Format the time as "12:45 pm"
    const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toLowerCase();

    return `${day} ${month}, ${time}`;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert UNIX timestamp to milliseconds
    return date.toLocaleDateString(); // Display date in a readable format
  };

  // Filter notifications based on the selected tab
  const filteredNotifications = notificationData?.filter((notification) => {
    if (selectedTab === "all") return true;
    return notification?.tab === selectedTab;
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the default behavior of Enter (adding a new line)
      if (typedMessage.trim()) {
        sendMessage();
      }
    } else if (e.key === 'Enter' && e.shiftKey) {
      setBorderOnSendMessage(true)
      // Allow default behavior to add a new line when Shift+Enter is pressed
    }
  }

  function linkifyMessage(message) {
    // Extended regular expression to match URLs including those that start with www.
    const urlRegex = /((https?:\/\/|www\.)[^\s]+)/g;

    return message.replace(urlRegex, (url, index) => {
      // Add 'http://' if the URL starts with 'www.'
      const href = url.startsWith('www.') ? `http://${url}` : url;

      return `<a href="${href}" class="url-link" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  }

  const decryptData = (encData) => {
    // The APP_KEY in .env file. Note that it is base64 encoded binary
    var key = process.env.REACT_APP_DECRYPTION_KEY;

    // Laravel creates a JSON to store iv, value and a mac and base64 encodes it.
    // So let's base64 decode the string to get them.
    let decodedData = atob(encData);

    // Parse the decoded data as JSON
    let encrypted = JSON.parse(decodedData);

    // console.log("Laravel encryption result", encrypted);
    const iv = CryptoJS?.enc?.Base64?.parse(encrypted?.iv);
    const value = encrypted?.value;

    key = CryptoJS?.enc?.Base64?.parse(key);

    var decrypted = CryptoJS?.AES?.decrypt(value, key, {
      iv: iv
    });

    // CryptoJS returns a word array which can be
    // converted to string like this
    decrypted = decrypted?.toString(CryptoJS?.enc?.Utf8);

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  const handlePopoverOpen = (event, chatID, editable, message) => {

    setIsEditableChat(editable)
    if (editable == true) {
      setEditChatMsg(message)
    }
    setAnchorElForReaction(event.currentTarget);
    setCurrentChatID(chatID);


  };

  const groupMessagesByRelativeDate = (messages) => {
    const groupedMessages = {};
    console.log("messages for 653::", messages)
    messages?.forEach((message) => {
      const timestamp = message?.message_time;
      console.log("Timestamp:", timestamp);

      const messageDate = new Date(timestamp * 1000);
      console.log("messageDate:", messageDate);

      if (isNaN(messageDate.getTime())) {
        console.error("Invalid Date for message_time:", timestamp);
        return; // Skip this message if the date is invalid
      }

      const today = new Date();
      const yesterday = new Date();
      yesterday?.setDate(today?.getDate() - 1);

      const startOfWeek = new Date(today);
      startOfWeek?.setDate(today?.getDate() - today?.getDay());

      const endOfWeek = new Date(today);
      endOfWeek?.setDate(today?.getDate() + (6 - today?.getDay()));

      const formatDate = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long' };
        return new Intl.DateTimeFormat('en-US', options)?.format(date);
      };

      const formatDateWithYear = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options)?.format(date);
      };

      let dateLabel;
      if (messageDate?.toDateString() === today?.toDateString()) {
        dateLabel = 'Today';
      } else if (messageDate?.toDateString() === yesterday?.toDateString()) {
        dateLabel = 'Yesterday';
      } else if (messageDate >= startOfWeek && messageDate <= endOfWeek) {
        dateLabel = formatDate(messageDate);
      } else if (messageDate?.getFullYear() === today?.getFullYear()) {
        dateLabel = formatDate(messageDate);
      } else {
        dateLabel = formatDateWithYear(messageDate);
      }

      const timeLabel = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      if (!groupedMessages[dateLabel]) {
        groupedMessages[dateLabel] = {};
      }

      if (!groupedMessages[dateLabel][timeLabel]) {
        groupedMessages[dateLabel][timeLabel] = [];
      }

      groupedMessages[dateLabel][timeLabel].push(message);
    });
    console.log("groupedMessages::", groupedMessages)
    return groupedMessages;
  };

  const formatMessageTime = (timestamp) => {
    const messageDate = new Date(timestamp * 1000);
    const today = new Date();

    const isSameDay = (date1, date2) =>
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();

    if (isSameDay(messageDate, today)) {
      return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return messageDate.toLocaleDateString();
    }
  };

  const replaceMessageById = (messages, newMessage) => {
    return messages.map((msg) => (msg.id === newMessage.id ? newMessage : msg));
  };

  const loadMoreMessages = async () => {
    if (!hasMoreMessages || isLoadingMore) return;

    setIsLoadingMore(true);
    setIsLoadingMorePressed(true)

    try {
      const response = await api.get(selectedChatRoomData?.links?.next, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success == true) {
        let newMessages = response?.response?.data;
        newMessages = newMessages.flat(Infinity);
        // console.log("newMessages:::", newMessages)

        // const allMessages = [...newMessages?.slice()?.reverse(), ...Object.values(selectedChatRoomData?.data).flat(Infinity)];
        // // console.log("allMessages:::", allMessages)
        // const groupedMessages = groupMessagesByRelativeDate(allMessages);

        const flattenedMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat(Infinity))

        // Add the new message to the array
        const updatedMessages = [...newMessages?.slice()?.reverse(), ...flattenedMessages];

        // Re-group messages
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

        // Update your state or data structure here
        // setSelectedChatRoomData({
        //     ...selectedChatRoomData,
        //     data: regroupedMessages
        // });
        // console.log("groupedMessages:::", groupedMessages)

        setSelectedChatRoomData((prevState) => ({
          ...prevState,
          data: regroupedMessages,
          links: response?.response?.links,
        }));

        // setSelectedChatRoomDataOnly((prev))

        setHasMoreMessages(response?.response?.links?.next !== null);
      } else if (response?.success == false) {
        // Toast.error(response?.data?.message);
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.error("Error loading more messages:", err);
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Function to generate a favicon with a red dot
  const generateRedDotFavicon = (baseIcon) => {
    const canvas = document.createElement('canvas');
    const img = new window.Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      // Draw a glowing red dot
      ctx.beginPath();
      ctx.arc(canvas.width - 10, 10, 6, 0, 2 * Math.PI, false);
      ctx.fillStyle = 'red';
      ctx.shadowColor = 'red';
      ctx.shadowBlur = 10;
      ctx.fill();

      // Update the favicon
      const favicon = document.querySelector('link[rel="icon"]');
      favicon.href = canvas.toDataURL('image/png');
    };

    img.onerror = (error) => {
      console.error("Failed to load image:", error);
    };

    img.src = baseIcon;

    // Return a placeholder or the original favicon until the image is loaded
    return img.src;
  };

  // Function to manage flashy notifications
  const handleTabInactiveNotification = () => {
    const originalTitle = document.title;
    const originalFavicon = document.querySelector("link[rel~='icon']").href;

    if (document.visibilityState !== 'visible') {
      // Change the document title
      if (pusherMessage?.type == "private") {
        document.title = `${pusherMessage?.sender_detail?.full_name} - Platform Chat`;
      } else if (pusherMessage?.type == "group") {
        document.title = `${pusherMessage?.group_name} - Platform Chat`
      }

      // Change the favicon to a glowing red dot favicon
      const faviconElement = document.querySelector("link[rel~='icon']");
      const redDotFavicon = generateRedDotFavicon(albertLogo); // Function to generate red dot favicon
      faviconElement.href = redDotFavicon;

      // Optionally, play a sound
      // const audio = new Audio(notificationSound);
      // audio.play();

      // Show a desktop notification (optional)
      if (Notification.permission === 'granted') {
        new Notification(`New message from ${pusherMessage?.sender_detail?.full_name}`, {
          body: pusherMessage?.message,
          icon: albertLogo
        });
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            new Notification(`New message from ${pusherMessage?.sender_detail?.full_name}`, {
              body: pusherMessage?.message,
              icon: albertLogo
            });
          }
        });
      }
    }

    const revertChanges = () => {
      if (document.visibilityState === 'visible') {
        // Revert the title and favicon
        document.title = originalTitle;
        const faviconElement = document.querySelector("link[rel~='icon']");
        faviconElement.href = originalFavicon;

        // Remove the event listener since it's no longer needed
        document.removeEventListener('visibilitychange', revertChanges);
      }
    };

    // Add an event listener to detect when the tab becomes active again
    document.addEventListener('visibilitychange', revertChanges);
  };

  //This function is used to handle web notification if user will get any notification
  const handleTabInactiveForNotification = (data) => {
    const originalTitle = document.title;
    const originalFavicon = document.querySelector("link[rel~='icon']").href;

    if (document.visibilityState !== 'visible') {

      document.title = 'New notification - Platform';

      // Change the favicon to a glowing red dot favicon
      const faviconElement = document.querySelector("link[rel~='icon']");
      const redDotFavicon = generateRedDotFavicon(albertLogo); // Function to generate red dot favicon
      faviconElement.href = redDotFavicon;

      // Show a desktop notification (optional)
      if (Notification.permission === 'granted') {
        new Notification(`New notification received`, {
          body: (data?.title).replace(/<\/?[^>]+(>|$)/g, ""),
          icon: albertLogo
        });
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            new Notification(`New notification received`, {
              body: (data?.title).replace(/<\/?[^>]+(>|$)/g, ""),
              icon: albertLogo
            });
          }
        });
      }
    }

    const revertChanges = () => {
      if (document.visibilityState === 'visible') {
        // Revert the title and favicon
        document.title = originalTitle;
        const faviconElement = document.querySelector("link[rel~='icon']");
        faviconElement.href = originalFavicon;

        // Remove the event listener since it's no longer needed
        document.removeEventListener('visibilitychange', revertChanges);
      }
    };

    // Add an event listener to detect when the tab becomes active again
    document.addEventListener('visibilitychange', revertChanges);
  }

  const startTypingData = async () => {
    let data = {
      chat_room: selectedChatRoom
    }
    try {
      setLoading(false);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/user-typing/`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setLoading(false);
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in start typing::", err);
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  }

  const stopTypingData = async () => {
    let data = {
      chat_room: selectedChatRoom
    }
    try {
      setLoading(false);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/stopped-typing/`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setLoading(false);
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in start typing::", err);
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  }

  const manageChatData = () => {
    let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id == pusherMessage?.chat_room_id)

    if (openMenuForChat == false) {
      if (anchorElForChat == null &&
        (location.pathname != "/user-conversation" && location.pathname != "/admin-conversation")) {
        dispatch(conversationAction?.setUnReadMessageCount(unReadMsgCount + 1))
      }
    } else {
      // console.log("isChatRoomExist::", isChatRoomExist)
      if (selectedChatRoom == pusherMessage?.chat_room_id && isChatRoomExist) {
        console.log("686 header")
        const newMessage = pusherMessage;
        console.log("newMessage header::", newMessage)
        const flattenedMessages = Object?.values(selectedChatRoomData?.data)
          ?.flatMap(group => Object?.values(group).flat());

        // Add the new message to the array
        const updatedMessages = [...flattenedMessages, newMessage];

        // Re-group messages
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

        // Update your state or data structure here
        setSelectedChatRoomData({
          ...selectedChatRoomData,
          data: regroupedMessages
        });

        setChatUserList(prevState => {
          const updatedList = prevState.map(chatUser => {
            if (chatUser?.chat_room_id == selectedChatRoom) {
              return {
                ...chatUser,
                last_message_sender: pusherMessage?.sender_detail?.full_name,
                last_message: pusherMessage?.message,
                message_time: pusherMessage?.message_time,
                last_message_id: pusherMessage?.id,
              };
            }
            return chatUser;
          });

          // Move the updated chat room to the top
          const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id === pusherMessage?.chat_room_id);
          if (chatUserIndex > -1) {
            const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
            updatedList.unshift(updatedChatUser);
          }

          return updatedList;
        });
        if (
          anchorElForChat == null &&
          (location.pathname != "/user-conversation" && location.pathname != "/admin-conversation")
        ) {
          dispatch(conversationAction?.setUnReadMessageCount(unReadMsgCount + 1));
        }
      }

      else if (selectedChatRoom != pusherMessage?.chat_room_id && isChatRoomExist) {
        console.log("694 header")
        const newMessage = pusherMessage;
        console.log("newMessage header::", newMessage)

        setChatUserList(prevState => {
          const updatedList = prevState.map(chatUser => {
            if (chatUser?.chat_room_id == pusherMessage?.chat_room_id) {
              return {
                ...chatUser,
                last_message_sender: pusherMessage?.sender_detail?.full_name,
                last_message: pusherMessage?.message,
                unread_count: chatUser?.chat_room_id == selectedChatRoom ? 0 : chatUser?.unread_count + 1,
                message_time: pusherMessage?.message_time,
                last_message_id: pusherMessage?.id
              };
            }
            return chatUser;
          });

          // Move the updated chat room to the top
          const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id == pusherMessage?.chat_room_id);
          if (chatUserIndex > -1) {
            const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
            updatedList.unshift(updatedChatUser);
          }

          return updatedList;
        });

        if (anchorElForChat == null &&
          (location.pathname != "/user-conversation" && location.pathname != "/admin-conversation")) {
          dispatch(conversationAction?.setUnReadMessageCount(unReadMsgCount + 1))

        }

      } else if (!isChatRoomExist && pusherMessage?.message != null) {
        console.log("701 header")
        fetchAllChatRoomList()
        if (anchorElForChat == null &&
          (location.pathname != "/user-conversation" && location.pathname != "/admin-conversation")) {
          dispatch(conversationAction?.setUnReadMessageCount(unReadMsgCount + 1))
        }
      }
    }

  }

  const ManageChatRoomData = async (chatRoom) => {
    try {
      // setLoading(true);
      const response = await api.get(
        `user/message-list/${chatRoom}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        const chatRoomData = {
          data: groupMessagesByRelativeDate(response?.response?.data?.slice()?.reverse()),
          links: response?.response?.links,
          meta: response?.response?.meta,
          room_details: response?.response?.room_details
        }
        // Toast.success(response?.message);
        setLoading(false);
        setSelectedChatRoomData(chatRoomData)
        setHasMoreMessages(response?.response?.links?.next !== null);
        fetchUnreadMsgCount()
        setIsLoadingMorePressed(false)

      } else if (response?.success == false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  }

  const manageUpdatedChatData = () => {
    let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id == updatedPusherMessage?.chat_room_id)
    // console.log("isChatRoomExist::", isChatRoomExist)
    if (selectedChatRoom == updatedPusherMessage?.chat_room_id && isChatRoomExist) {
      console.log("686")
      const updatedMessages = replaceMessageById(Object.values(selectedChatRoomData?.data)
        .flatMap(group => Object.values(group).flat()), updatedPusherMessage);

      const groupedMessages = groupMessagesByRelativeDate(updatedMessages);

      // const flattenedMessages = Object.values(selectedChatRoomData?.data)
      //     .flatMap(group => Object.values(group).flat());

      // // Add the new message to the array
      // const updatedMessages = [...flattenedMessages, updatedPusherMessage];

      // // Re-group messages
      // const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

      // Update your state or data structure here
      // setSelectedChatRoomData({
      //     ...selectedChatRoomData,
      //     data: regroupedMessages
      // });

      setSelectedChatRoomData((prevState) => ({
        ...prevState,
        data: groupedMessages,
      }));

      // If you also store messages separately in `selectedChatRoomDataOnly`
      setSelectedChatRoomDataOnly((prevData) =>
        replaceMessageById(prevData, updatedPusherMessage)
      );

      setChatUserList(prevState => {
        const updatedList = prevState.map(chatUser => {
          if (chatUser?.chat_room_id == selectedChatRoom && chatUser?.last_message_id == updatedPusherMessage?.id) {
            return {
              ...chatUser,
              last_message_sender: updatedPusherMessage?.sender_detail?.full_name,
              last_message: updatedPusherMessage?.message,
              message_time: updatedPusherMessage?.message_time,
            };
          }
          return chatUser;
        });

        return updatedList;
      });
    }
    else if (selectedChatRoom != pusherMessage?.chat_room_id && isChatRoomExist) {
      console.log("694")
      const newMessage = pusherMessage;
      console.log("newMessage::", newMessage)

      setChatUserList(prevState => {
        const updatedList = prevState.map(chatUser => {
          if (chatUser?.chat_room_id == pusherMessage?.chat_room_id && chatUser?.last_message_id == updatedPusherMessage?.id) {
            return {
              ...chatUser,
              last_message_sender: updatedPusherMessage?.sender_detail?.full_name,

              last_message: updatedPusherMessage?.message,
              message_time: updatedPusherMessage?.message_time,
            };
          }
          return chatUser;
        });

        return updatedList;
      });

    }
  }

  const manageRemoveChat = () => {
    let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id == deletePusherMessage?.chat_room_id)
    // console.log("isChatRoomExist::", isChatRoomExist)
    if (selectedChatRoom == deletePusherMessage?.chat_room_id && isChatRoomExist) {
      console.log("686")

      const filteredMessages = Object.values(selectedChatRoomData?.data)
        .flatMap(group => Object.values(group).flat())
        .filter((msg) => msg?.id !== deletePusherMessage?.id);

      // Regroup the messages by relative date after filtering
      const groupedMessages = groupMessagesByRelativeDate(filteredMessages);

      // Update the state with the filtered messages
      setSelectedChatRoomData((prevState) => ({
        ...prevState,
        data: groupedMessages,
      }));

      // Update the separate list if needed
      setSelectedChatRoomDataOnly((prevData) =>
        prevData.filter((msg) => msg?.id !== deletePusherMessage?.id)
      );

      // Check if the deleted message was the last message
      const isLastMessage = isChatRoomExist?.last_message_id == deletePusherMessage?.id;
      if (isLastMessage) {
        const lastMessage = filteredMessages.length > 0 ? filteredMessages[filteredMessages.length - 1] : null;

        // Update the chat user list with the previous message as the last message
        setChatUserList((prevChatUserList) =>
          prevChatUserList.map((chatUser) => {
            if (chatUser?.chat_room_id == deletePusherMessage?.chat_room_id) {
              return {
                ...chatUser,
                last_message: lastMessage?.message || '',
                last_message_id: lastMessage?.id || '',
                last_message_sender: lastMessage?.sender_detail?.full_name || '',
                message_time: lastMessage?.message_time || '',
              };
            }
            return chatUser;
          })
        );
      }
      ManageChatRoomData(selectedChatRoom)

    }
    else if (selectedChatRoom != deletePusherMessage?.chat_room_id && isChatRoomExist) {
      console.log("694")
      const newMessage = deletePusherMessage;
      console.log("newMessage::", newMessage)
    }
  }

  const manageReactionData = () => {
    console.log("1114")
    let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id == reactionPusherMessage?.chat_room_id)
    console.log("isChatRoomExist::", isChatRoomExist)
    if (selectedChatRoom == reactionPusherMessage?.chat_room_id && isChatRoomExist) {
      console.log("1117")
      // Update the selectedChatRoomData
      setSelectedChatRoomData((prevData) => {
        // Create a copy of the previous data
        let updatedData = { ...prevData };

        // Iterate over the date groups
        for (let date in updatedData.data) {
          // Iterate over the time groups within the current date
          for (let time in updatedData.data[date]) {
            // Iterate over each message in the current time group
            updatedData.data[date][time] = updatedData.data[date][time].map((message) => {
              if (message.id == reactionPusherMessage?.id) {
                // Update the message with the new reaction data
                return {
                  ...message,
                  total_reaction: reactionPusherMessage?.total_reaction,
                  user_self_reaction: reactionPusherMessage?.user_self_reaction,
                  updated_time: reactionPusherMessage?.updated_time || message.updated_time,
                };
              }
              return message;
            });
          }
        }

        return updatedData;
      });
    }
  }

  const sendMessage = async () => {
    if (typedMessage === "") {
      return Toast.error("Please enter message!")
    }

    const tempId = `temp_${Math.random().toString(36).substring(2, 15)}`;
    const tempMessageTime = Math.floor(Date.now() / 1000);
    console.log("tempMessageTime::", tempMessageTime)
    const tempMessage = {
      id: tempId,
      sender_id: loggedUser?.id, // Assuming `user` is the logged-in user info
      receiver_id: null,
      message: typedMessage,
      chat_room_id: selectedChatRoomData?.room_details?.chat_room_id,
      message_time: tempMessageTime,
      sender_detail: {
        id: loggedUser?.id,
        full_name: loggedUser?.name || "You", // Assuming `user` has `full_name`
        name: loggedUser?.name,
        last_name: loggedUser?.last_name,
        username: loggedUser?.username,
        thumbnail: loggedUser?.thumbnail || "" // Assuming `user` has `thumbnail`
      },
      receiver_detail: null,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      isSent: false, // Mark as unsent initially
    };

    let data = {
      chat_room_id: selectedChatRoomData?.room_details?.chat_room_id,
      receiver_id: selectedChatRoomData?.room_details?.user_id,
      message: typedMessage,
      type: selectedChatRoomData?.room_details?.type,
      temp_id: tempId
    }
    // console.log("tempMessage:::", tempMessage)

    // Flatten existing grouped messages
    const flattenedMessages = Object.values(selectedChatRoomData?.data)
      .flatMap(group => Object.values(group).flat());

    // Add the new message to the array
    const updatedMessages = [...flattenedMessages, tempMessage];

    // Re-group messages
    const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

    // Update your state or data structure here
    setSelectedChatRoomData({
      ...selectedChatRoomData,
      data: regroupedMessages
    });
    setSelectedChatRoomDataOnly((prevData) => [...prevData, tempMessage]);
    setTypedMessage("");
    setIsTyping(false)
    try {
      setIsLoadingMorePressed(false)
      const response = await api.post(
        `user/send-message`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log("response for send api::", response)

      if (response?.success == true) {
        // Toast.success(response?.message);
        setLoading(false);
        const actualMessage = {
          ...response?.response,
          isSent: true, // Mark as sent
        };

        const updatedMessagesWithActual = Object.values(selectedChatRoomData?.data).flat().map((msg) =>
          msg?.id == tempId ? actualMessage : msg
        );
        // console.log("updatedMessagesWithActual::", updatedMessagesWithActual)

        const flattenedMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat());

        // Add the new message to the array
        const updatedMessages = [...flattenedMessages, actualMessage];

        // Re-group messages
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

        // Update your state or data structure here
        setSelectedChatRoomData({
          ...selectedChatRoomData,
          data: regroupedMessages
        });


        setTypedMessage("")
        setIsTyping(false)
        stopTypingData()
        setHasStartedTyping(false);

        setChatUserList(prevState => {
          const updatedList = prevState.map(chatUser => {
            if (chatUser?.chat_room_id == selectedChatRoom) {
              return {
                ...chatUser,
                last_message_sender: loggedUser?.name,
                last_message: typedMessage,
                message_time: actualMessage?.message_time,
                last_message_id: actualMessage?.id
              };
            }
            return chatUser;
          });

          // Move the updated chat room to the top
          const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id == selectedChatRoom);
          if (chatUserIndex > -1) {
            const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
            updatedList.unshift(updatedChatUser);
          }

          return updatedList;
        });

        setIsSelectedEdit(false)
        setCurrentChatID()
        setIsEditableChat(false)


      } else if (response?.success == false) {
        setLoading(false);
        if (response?.response?.member_exist == false) {
          Toast.error("you are not member of this conversation!")
          setSelectedChatRoom(undefined)
          setSelectedChatRoomData()
          setSelectedChatRoomDataOnly([])
          fetchAllChatRoomList()
        } else {
          Toast.error(response?.message);

        }
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  }

  const fetchAllChatRoomList = async () => {
    try {
      // setLoading(true)
      const response = await api.post(`user/chat-user-list`, {}, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setLoading(false)
        setChatUserList(response?.response?.data)
        if (selectedChatRoom == undefined) {
          setSelectedChatRoom(response?.response?.data[0]?.chat_room_id)
          ManageChatRoomData(response?.response?.data[0]?.chat_room_id)
          setIsLoadingMorePressed(false)

          setChatUserList(prevState => prevState.map(chatUser => {
            if (chatUser?.chat_room_id == selectedChatRoom) {
              return {
                ...chatUser,
                unread_count: 0
              };
            }
            return chatUser;
          }));


        }
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  }

  const fetchUnreadMsgCount = async () => {
    try {
      // setLoading(true)
      const response = await api.get(`user/unread-message-count`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setLoading(false)
        dispatch(conversationAction?.setUnReadMessageCount(response?.response?.unread_message_count))

      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  }

  const fetchAllTypes = async () => {
    try {
      const response = await api.get(`user/type/list/menu`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setGetAllTypes(response?.data);
    } catch (err) {
      console.log("err`or in fetch news api::", err);

    }
  };

  const renderChatMessages = (chatData) => {
    const chatContainer = document.getElementById('user-chats-div');
    chatContainer.innerHTML = ''; // Clear any existing messages

    chatData.response.data.forEach((chat) => {
      // Determine if the message is sent by the logged-in user
      const isSentByLoggedInUser = chat.sender_id === loggedInUserId;

      // Create chat message container
      const chatMessage = document.createElement('div');
      chatMessage.className = `chat-message ${isSentByLoggedInUser ? 'right' : 'left'}`;

      // Add sender's thumbnail
      const thumbnail = document.createElement('img');
      thumbnail.src = chat.sender_detail.thumbnail;
      chatMessage.appendChild(thumbnail);

      // Add message content
      const messageContent = document.createElement('div');
      messageContent.className = 'message-content';
      messageContent.textContent = chat.message;
      chatMessage.appendChild(messageContent);

      // Append chat message to the container
      chatContainer.appendChild(chatMessage);
    });
  };

  const isMessageHighlighted = (chatId) => {
    return searchResults.includes(chatId);
  };

  const isMessageNavigated = (chatId) => {
    return navigatedChatId === chatId;
  };

  const handlePopoverCloseForDataOnly = () => {
    if (isSelectedEdit == false) {
      setIsEditableChat(false)
      setEditChatMsg("")
      setCurrentChatID()
      setIsReacting(false);
    }

  };

  const handlePopoverClose = () => {
    setAnchorElForReaction(null);
    setIsEditableChat(false)
    setEditChatMsg("")
    setIsReacting(false);
  };

  const toggleReaction = (id, reaction) => {
    handleReactionAPI(id, reaction)
  }

  const handlePopoverOpenForUserReactionList = (e, reactions) => {
    console.log("target:::", e.currentTarget)
    console.log("reactions::", reactions)
    setReactionUsersList(reactions)
    setAnchorElForPopupUser(e?.currentTarget);

  }

  const handlePopoverCloseForUserReactionList = () => {
    setAnchorElForPopupUser(null);
    setReactionUsersList([])
    // setPopupUserDetails();
  };

  const handleReactionAPI = async (id, reaction) => {
    let data = {
      reaction: reaction
    };
    try {
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/message-reaction/${id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success === true) {
        const updatedMessage = response?.response;

        setSelectedChatRoomData((prevData) => {
          // Create a copy of the previous data
          let updatedData = { ...prevData };

          // Iterate over the date groups
          for (let date in updatedData.data) {
            // Iterate over the time groups within the current date
            for (let time in updatedData.data[date]) {
              // Iterate over each message in the current time group
              updatedData.data[date][time] = updatedData.data[date][time].map((message) => {
                if (message.id === id) {
                  // Update the message with the new reaction data
                  return {
                    ...message,
                    total_reaction: updatedMessage?.total_reaction,
                    user_self_reaction: updatedMessage?.user_self_reaction,
                    updated_time: updatedMessage?.updated_time || message.updated_time,
                  };
                }
                return message;
              });
            }
          }

          return updatedData;
        });

        setIsLoadingMorePressed(true);
        setLoading(false);
        handlePopoverClose();
      }
      else {
        handlePopoverClose();
        setLoading(false);
        Toast.error(response?.response);
      }
    } catch (err) {
      console.log("error in handleReactionAPI::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      } else if (err?.response?.status === 422) {
        handlePopoverClose();
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  };

  const handleChangeForGlobalSearchTab = (newValue) => {
    setValue(newValue);
    if (newValue == "all") {
      fetchGlobalSearchData(newValue, false, dataOrder);
    } else {
      fetchGlobalSearchData(newValue, false, dataOrder);
    }
  }

  const fetchGlobalSearchData = async (type, loading, order) => {
    let data = {
      search: globalSearch,
      type_id: type,
      order: order
    };
    try {
      setLoading(loading)
      const response = await api.post(`global-search`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      console.log("response for global search::", response)
      if (response?.success == true) {
        setGlobalSearchData(response?.response)
        setGlobalSearchDataTabs(response?.response?.Tabs)
        setGlobalSearchDataOnly(response?.response?.post?.data)
        setGlobalSearchFilterData(response?.response?.post?.data)
        setLoading(false)
        setOpenforGobalSearch(true)
      } else {
        setLoading(false)
      }
    } catch (err) {
      console.log("error in handleReactionAPI::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      } else if (err?.response?.status === 422) {
        handlePopoverClose();
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  }

  const fetchAllNotification = async () => {
    try {
      const response = await api.get("user/notification?page=1", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (response?.response?.data?.length > 0) {
          // Extract new notifications
          const newNotifications = response?.response?.data;
          console.log("Notification dispatch", [...newNotifications]);
          dispatch(headerAction.setNotificationData([...newNotifications]));
        } else {
          dispatch(headerAction.setNotificationData([]));
        }
      } else {
        Toast.error(response?.message)
      }
    } catch (err) {
      console.log("Error in fetch notification API:", err);
    } finally {
      setLoading(false);
    }
  };


  const handleUpdateNotificationActionApi = async (notification_id, type) => {
    try {
      const response = await api.post(
        "user/notification/update-action",
        {
          "type": type,
          "id": notification_id
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        console.log("notification action updated");
        const notificationIndex = notificationData.findIndex(notification => notification.id === notification_id);
        console.log("notification action notificationIndex", notificationIndex);
        if (notificationIndex !== -1) {
          const strippedTitle = (notificationData[notificationIndex].title).replace(/<\/?[^>]+(>|$)/g, "")
          const updatedNotification = {
            ...notificationData[notificationIndex],
            title: strippedTitle,
            action: true
          }
          const updatedNotificationData = [
            ...notificationData.slice(0, notificationIndex),
            updatedNotification,
            ...notificationData.slice(notificationIndex + 1),
          ];
          dispatch(headerAction.setNotificationData(updatedNotificationData));
        }
      } else {
        console.log("error while updating seen action::", response?.error);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err updating seen action api::", err);
    }
  }

  const handleNotificationClick = (data) => {
    console.log("notification click data", data);

    //Managed for notification type post
    if (['post', 'post_created', 'acknowledge_post'].includes(data?.type)) {
      const type = data?.post_data?.type_name || 'News'
      handleCloseForNotification();
      navigate(`/user-type/view/${type}/${data?.type_id}`);
    } else if (['mention'].includes(data?.type)) {
      const type = data?.post_data?.type_name || 'News';
      const comment_id = data?.type_id;
      handleCloseForNotification();

      //If not action yet then call action update api
      if (!data?.action) {
        handleUpdateNotificationActionApi(data?.id, 'mention');
      }

      if (comment_id) {
        navigate(`/user-type/view/${type}/${data?.post_data?.post_id}?comment=${comment_id}`);
      } else {
        navigate(`/user-type/view/${type}/${data?.post_data?.post_id}`);
      }
    }
  }

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className: value == index ? `selected-tab-attribute` : `non-selected-tab-attribute`,
    };
  }

  useEffect(() => {
    console.log("notification header useEffect pusherInitialized value is ", pusherInitialized);

    if (pusherInitialized == false) {
      const decPusherAppCluster = decryptData(pusherData?.app_cluster)
      const decPusherApKey = decryptData(pusherData?.app_key)
      const pusherInstance = new Pusher(decPusherApKey, {
        cluster: decPusherAppCluster,
        auth: {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Accept': 'application/json',
          }
        },
        authEndpoint: `${apiURL}/broadcasting/auth`,
        channelAuthorization: {
          endpoint: `${apiURL}/broadcasting/auth`,
          transport: "ajax",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      });

      const channel = pusherInstance.subscribe(`private-chat-${tenantData?.tenant_id}-${loggedUser?.id}`);

      const channelUpdateMsg = pusherInstance.subscribe(`private-update-chat-${tenantData?.tenant_id}-${loggedUser?.id}`)

      const channelDeleteMsg = pusherInstance.subscribe(`private-delete-chat-${tenantData?.tenant_id}-${loggedUser?.id}`)

      const channelReaction = pusherInstance.subscribe(`private-message-reaction-${tenantData?.tenant_id}-${loggedUser?.id}`)

      const channelIsStartTyping = pusherInstance?.subscribe(`private-user-typing-${tenantData?.tenant_id}-${selectedChatRoomID}`)

      channel.bind('pusher:subscription_succeeded', () => {
        console.log('Subscription succeeded');
      });
      channel.bind('pusher:subscription_failed', (data) => {
        console.error('Subscription failed:', data);
      });
      channel.bind('MessageSent', (data) => {
        const message = data?.data;
        console.log("msgs data::", message);
        setPusherMessage(message);
      });
      channel.bind('pusher:subscription_error', (status) => {
        console.error('Subscription error:', status);
      });

      channelUpdateMsg.bind("MessageUpdate", (data) => {
        console.log("data for update msg::", data)
        setUpdatedPusherMessage(data?.data)
      })

      channelDeleteMsg.bind("MessageDelete", (data) => {
        console.log("data for delete msg::", data)
        setDeletePusherMessage(data?.data)
      })

      channelReaction.bind("MessageReaction", (data) => {
        console.log("data for reaction msg::", data)
        setReactionPusherMessage(data?.data)
      })

      channelIsStartTyping?.bind("UserTyping", (data) => {
        console.log("data for typing msg::", data)

        const user = data?.data;
        setTypingUsersData(user)

        setTypingUsers(prev => [...new Set([...prev, user.user_name])]); // Add the user to typingUsers if not already present
      });

      const channelIsStopTyping = pusherInstance?.subscribe(`private-stopped-typing-${tenantData?.tenant_id}-${selectedChatRoomID}`)

      channelIsStopTyping?.bind("UserStoppedTyping", (data) => {
        const user = data?.data;
        setTypingUsersData(user)

        setTypingUsers(prev => prev.filter(name => name !== user.user_name)); // Remove the user from typingUsers
      });


      //Channels for notifications
      const channelNotification = pusherInstance.subscribe(`private-notification-${tenantData?.tenant_id}-${loggedUser?.id}`);

      const channelAllNotification = pusherInstance.subscribe(`private-notification-vendor-${tenantData?.tenant_id}-${loggedUser?.vendor_id}`);

      channelNotification.bind("pusher:subscription_succeeded", function () {
        console.log("notification Subscription succeeded");
      });

      channelNotification.bind("pusher:subscription_failed", function (data) {
        console.error("notification Subscription failed:", data);
      });

      channelNotification.bind("SendNotification", function (data) {

        console.log("personal Notification data recieved::", data?.data);
        dispatch(headerAction.setAppendNotificationData([
          data?.data,
        ]));
        handleTabInactiveForNotification(data?.data);
      });

      channelNotification.bind("pusher:subscription_error", function (status) {
        console.error("notification Subscription error:", status);
      });

      channelAllNotification.bind(
        "pusher:subscription_error",
        function (status) {
          console.error("all notification Subscription error:", status);
        }
      );

      channelAllNotification.bind(
        "pusher:subscription_succeeded",
        function () {
          console.log("all notification Subscription succeeded");
        }
      );

      channelAllNotification.bind(
        "pusher:subscription_failed",
        function (data) {
          console.error("all notifcation Subscription failed:", data);
        }
      );

      channelAllNotification.bind(
        "sendNotificationToAllUsers",
        function (data) {
          console.log("All notification data recived::", data?.data);
          dispatch(headerAction.setAppendNotificationData([
            data?.data,
          ]));
          handleTabInactiveForNotification(data?.data);
        }
      );

      channelAllNotification.bind(
        "pusher:subscription_error",
        function (status) {
          console.error("all notification Subscription error:", status);
        }
      );

      dispatch(headerAction?.setPusherInitialized(true))

      return () => {
        pusherInstance.unsubscribe(`private-chat-${tenantData?.tenant_id}-${loggedUser?.id}`);
        pusherInstance.unsubscribe(`private-update-chat-${tenantData?.tenant_id}-${loggedUser?.id}`)
        pusherInstance.unsubscribe(`private-delete-chat-${tenantData?.tenant_id}-${loggedUser?.id}`)
        pusherInstance.unsubscribe(`private-message-reaction-${tenantData?.tenant_id}-${loggedUser?.id}`)
        pusherInstance?.unsubscribe(`private-user-typing-${tenantData?.tenant_id}-${selectedChatRoomID}`)
        pusherInstance?.unsubscribe(`private-stopped-typing-${tenantData?.tenant_id}-${selectedChatRoomID}`)
        pusherInstance.unsubscribe(`private-notification-${tenantData?.tenant_id}-${loggedUser?.id}`);
        pusherInstance.unsubscribe(`private-notification-vendor-${tenantData?.tenant_id}-${loggedUser?.vendor_id}`);

        pusherInstance.disconnect();
      };
    }
  }, [])

  useEffect(() => {
    console.log("notification data in state::", notificationData);

    //filter out the count on the basis of seen status
    const newNotifications = notificationData?.filter((notification) => notification?.seen === false) || [];

    //Here all is used to display the total count near to the bell icon in header
    setNotificationTabCount({
      "all": newNotifications?.length || 0,
      "must action": newNotifications?.filter((notification) => notification?.tab === "must action").length || 0,
      "mention": newNotifications?.filter((notification) => notification?.tab === "mention").length || 0,
    })
  }, [notificationData]);


  useEffect(() => {
    if (openMenuForChat == true) {
      setLoading(true)
      fetchAllChatRoomList()
      // dispatch(headerAction?.setPusherInitialized(false))
      if (selectedChatRoom !== undefined) {
        ManageChatRoomData(selectedChatRoom)
        setChatUserList(prevState => prevState.map(chatUser => {
          if (chatUser?.chat_room_id == selectedChatRoom) {
            return {
              ...chatUser,
              unread_count: 0
            };
          }
          return chatUser;
        }));
      }
    }
  }, [openMenuForChat])


  useEffect(() => {

    fetchAllTypes();
    fetchUnreadMsgCount();

    console.log("notification global state::", notificationFetched);
    if (!notificationFetched) {
      fetchAllNotification();
    }

    const adminAccessView = localStorage.getItem("admin-access-view")

    if (adminAccessView === "true") {
      setSelectedView("view");
      dispatch(drawerMenuAction.handleSelectMenu("user"))
      dispatch(drawerMenuAction.handleAdminAccessible(true))
    } else {
      setSelectedView("edit");
      dispatch(drawerMenuAction.handleSelectMenu("admin"))
      dispatch(drawerMenuAction.handleAdminAccessible(false))
    }

  }, []);

  useEffect(() => {
    if (selectedChatRoom !== undefined && openMenuForChat == true) {
      setLoading(true)
      // localStorage.setItem("pusher-initialized", "false")
      const decPusherAppCluster = decryptData(pusherData?.app_cluster)
      const decPusherApKey = decryptData(pusherData?.app_key)

      const pusherInstance = new Pusher(decPusherApKey, {
        cluster: decPusherAppCluster,
        auth: {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Accept': 'application/json',
          }
        },
        authEndpoint: `${apiURL}/broadcasting/auth`,
        channelAuthorization: {
          endpoint: `${apiURL}/broadcasting/auth`,
          transport: "ajax",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      });

      const channel = pusherInstance.subscribe(`private-chat-${tenantData?.tenant_id}-${loggedUser?.id}`);

      const channelUpdateMsg = pusherInstance.subscribe(`private-update-chat-${tenantData?.tenant_id}-${loggedUser?.id}`)

      const channelDeleteMsg = pusherInstance.subscribe(`private-delete-chat-${tenantData?.tenant_id}-${loggedUser?.id}`)

      const channelReaction = pusherInstance.subscribe(`private-message-reaction-${tenantData?.tenant_id}-${loggedUser?.id}`)

      const channelIsStartTyping = pusherInstance?.subscribe(`private-user-typing-${tenantData?.tenant_id}-${selectedChatRoomID}`)

      channel.bind('pusher:subscription_succeeded', () => {
        console.log('Subscription succeeded');
      });
      channel.bind('pusher:subscription_failed', (data) => {
        console.error('Subscription failed:', data);
      });
      channel.bind('MessageSent', (data) => {
        const message = data?.data;
        console.log("msgs data::", message);
        setPusherMessage(message);
      });
      channel.bind('pusher:subscription_error', (status) => {
        console.error('Subscription error:', status);
      });

      channelUpdateMsg.bind("MessageUpdate", (data) => {
        console.log("data for update msg::", data)
        setUpdatedPusherMessage(data?.data)
      })

      channelDeleteMsg.bind("MessageDelete", (data) => {
        console.log("data for delete msg::", data)
        setDeletePusherMessage(data?.data)
      })

      channelReaction.bind("MessageReaction", (data) => {
        console.log("data for reaction msg::", data)
        setReactionPusherMessage(data?.data)
      })

      channelIsStartTyping?.bind("UserTyping", (data) => {
        console.log("data for typing msg::", data)

        const user = data?.data;
        setTypingUsersData(user)

        setTypingUsers(prev => [...new Set([...prev, user.user_name])]); // Add the user to typingUsers if not already present
      });

      const channelIsStopTyping = pusherInstance?.subscribe(`private-stopped-typing-${tenantData?.tenant_id}-${selectedChatRoomID}`)

      channelIsStopTyping?.bind("UserStoppedTyping", (data) => {
        const user = data?.data;
        setTypingUsersData(user)

        setTypingUsers(prev => prev.filter(name => name !== user.user_name)); // Remove the user from typingUsers
      });


      dispatch(headerAction?.setPusherInitialized(true))

      setTypedMessage("")
      setIsTyping(false)

      setIsLoadingMorePressed(false)
      setLoading(true)
      ManageChatRoomData(selectedChatRoom)
      fetchUnreadMsgCount()
      setChatUserList(prevState => prevState.map(chatUser => {
        if (chatUser?.chat_room_id == selectedChatRoom) {
          return {
            ...chatUser,
            unread_count: 0
          };
        }
        return chatUser;
      }));
      setLoading(false)
      return () => {
        pusherInstance.unsubscribe(`private-chat-${tenantData?.tenant_id}-${loggedUser?.id}`);
        pusherInstance.unsubscribe(`private-update-chat-${tenantData?.tenant_id}-${loggedUser?.id}`)
        pusherInstance.unsubscribe(`private-delete-chat-${tenantData?.tenant_id}-${loggedUser?.id}`)
        pusherInstance.unsubscribe(`private-message-reaction-${tenantData?.tenant_id}-${loggedUser?.id}`)
        pusherInstance?.unsubscribe(`private-user-typing-${tenantData?.tenant_id}-${selectedChatRoomID}`)
        pusherInstance?.unsubscribe(`private-stopped-typing-${tenantData?.tenant_id}-${selectedChatRoomID}`)

        pusherInstance.disconnect();
      };
    }
  }, [selectedChatRoom])

  useEffect(() => {
    if (isLoadingMorePressed == false) {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }
  }, [selectedChatRoomData]);

  useEffect(() => {
    console.log("pusher msg:::", pusherMessage)
    if (pusherMessage !== null) {
      manageChatData()
      handleTabInactiveNotification();
    }
  }, [pusherMessage])

  useEffect(() => {
    const handleStopTyping = () => {
      stopTypingData(); // Call stop typing API
      setHasStartedTyping(false); // Reset the start typing flag
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };

    if (typedMessage && isTyping && !hasStartedTyping) {
      startTypingData(); // Call start typing API
      setHasStartedTyping(true); // Set the start typing flag
    }

    if (isTyping) {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      // Set a new timeout to trigger stop typing after 10 seconds of inactivity
      typingTimeoutRef.current = setTimeout(handleStopTyping, 10000);
    }
  }, [typedMessage, isTyping]);

  useEffect(() => {
    if (updatedPusherMessage !== undefined) {
      console.log('New Updated Pusher message:', updatedPusherMessage);
      manageUpdatedChatData()
    }
  }, [updatedPusherMessage])

  useEffect(() => {
    if (deletePusherMessage !== undefined) {
      console.log('New Updated Pusher message:', updatedPusherMessage);
      manageRemoveChat()
    }
  }, [deletePusherMessage])

  useEffect(() => {
    if (reactionPusherMessage !== undefined) {
      console.log('New Updated reaction Pusher message:', reactionPusherMessage);
      manageReactionData()
    }
  }, [reactionPusherMessage])

  useEffect(() => {
    if (openMenuForChat == true) {
      const handleScroll = () => {
        console.log("line 1628 header")
        if (chatContainerRef?.current) {
          const { scrollTop, scrollHeight, clientHeight } = chatContainerRef?.current;
          const scrollPosition = scrollTop / (scrollHeight - clientHeight);

          // Load more messages if scrolled within the top 30%
          if (scrollPosition <= 0.3 && !isLoadingMorePressed) {
            loadMoreMessages();
          }
        }
      };

      const chatContainer = chatContainerRef?.current;
      chatContainer?.addEventListener('scroll', handleScroll);

      return () => {
        chatContainer?.removeEventListener('scroll', handleScroll);
      };
    }

  }, [loadMoreMessages, isLoadingMorePressed, openMenuForChat]);


  useEffect(() => {
    if (globalSearch !== "") {
      if (globalSearch.length >= 3) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {

          fetchGlobalSearchData("all", true, dataOrder);
        }, debounceTimeout);
      } else if (globalSearch === "") {

      }
      else {
        clearTimeout(debounceTimer);
      }

      return () => clearTimeout(debounceTimer);
    }
  }, [globalSearch])

  useEffect(() => {
    if (openForGlobalSearch) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openForGlobalSearch]);

  return (
    <>
      {/* START :: HEADER */}
      <div className="position-fixed w-100 bg-white header-wrapper">
        <Stack
          direction="horizontal"
          className="mx-auto justify-content-between header-content gap-2"
        >
          <Box className="drawer-div d-flex justify-content-center align-items-center gap-4">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleOpenDrawer}
              edge="start"
              sx={{
                marginRight: 2,
                // ...(open && { display: "none" }),
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              }}
              className="sidebar-dreawer-btn"
            >
              <MenuIcon className="drawer-menu-icon" />
            </IconButton>
            <BootstrapImage
              src={SiteLogo}
              width={135}
              onClick={() => {
                if (loggedUser?.user_type === "admin" && adminAccessible == false) {
                  localStorage.setItem("last-redirect-page", `/`);
                } else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                  localStorage.setItem("last-redirect-page-admin-user", `/`);
                } else {
                  localStorage.setItem("last-redirect-page-user", `/`);
                }
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            />

            {(loggedUser?.user_type === "admin") && (
              <Box className="main-switch-div">
                <Box className="switch-div-2">
                  <Box className={`${selectedView === "edit" ? "icon-div-selected cursor-pointer" : "icon-div-2 cursor-pointer"} "cusror-pointer"`} onClick={() => handleChange(false)}>
                    <FontAwesomeIcon icon={faPen} className="icon-switch" />
                  </Box>
                  <Box className={`${selectedView === "view" ? "icon-div-selected cursor-pointer" : "icon-div-2 cursor-pointer"} "cursor-pointer"`} onClick={() => handleChange(true)}>
                    <FontAwesomeIcon icon={faEye} className="icon-switch" />
                  </Box>
                </Box>
              </Box>
            )}

          </Box>
          <Box className="serach-box">
            <InputGroup className="">
              <Form.Control
                placeholder="Search Platform..."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className="search-input"
                value={globalSearch}
                onChange={(e) => {
                  setGlobalSearch(e.target.value)
                  setanchorElForGlobalSearch(e.currentTarget)
                  console.log("e.target::", e.currentTarget)
                }}
              />
              <Button className="search-btn">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
          </Box>
          <Stack direction="horizontal" className="last-stack">
            <Button
              className="create-content-btn"
              aria-controls={openMenuForCreateContent ? "account-create-content" : undefined}
              aria-expanded={openMenuForCreateContent ? "true" : undefined}
              aria-describedby={idCreateContent}
              onClick={handleClickForCreateContent}
            >
              Create Content
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            {notificationTabCount?.all > 0 ? (
              <IconButton
                onClick={handleClickForNotification}
                aria-controls={openMenuForNotification ? "account-menus" : undefined}
                aria-haspopup="true"
                className="notification-icon-btn"
                aria-expanded={openMenuForNotification ? "true" : undefined}
                aria-describedby={idNotification}
              >
                <Badge badgeContent={notificationTabCount?.all} color="error" anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                  className="chat-badge"
                >
                  <BootstrapImage
                    src={notificationSvg}
                    style={{ cursor: "pointer" }}
                    className="notification-icon-btn"
                  />
                </Badge>
              </IconButton>
            ) : (
              <IconButton
                onClick={handleClickForNotification}
                aria-controls={openMenuForNotification ? "account-menus" : undefined}
                aria-haspopup="true"
                className="notification-icon-btn"
                aria-expanded={openMenuForNotification ? "true" : undefined}
                aria-describedby={idNotification}
              >

                <BootstrapImage
                  src={notificationSvg}
                  style={{ cursor: "pointer" }}
                  className="notification-icon-btn"
                />
              </IconButton>
            )}

            {unReadMsgCount > 0 ? (
              <IconButton
                onClick={handleClickForChat}
                aria-controls={openMenuForChat ? "account-menus" : undefined}
                aria-haspopup="true"
                className="chat-icon-btn"
                aria-expanded={openMenuForChat ? "true" : undefined}
                aria-describedby={idChat}
              >
                <Badge badgeContent={unReadMsgCount} color="error" anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                  className="chat-badge"
                >
                  <BootstrapImage
                    src={chatSvg}
                    style={{ cursor: "pointer" }}
                    className="chat-icon-btn"
                  />
                </Badge>
              </IconButton>
            ) : (
              <IconButton
                onClick={handleClickForChat}
                aria-controls={openMenuForChat ? "account-menus" : undefined}
                aria-haspopup="true"
                className="chat-icon-btn"
                aria-expanded={openMenuForChat ? "true" : undefined}
                aria-describedby={idChat}
              >
                <BootstrapImage
                  src={chatSvg}
                  style={{ cursor: "pointer" }}
                  className="chat-icon-btn"
                />
              </IconButton>
            )}

            <IconButton
              onClick={handleClickForPlusContentIcon}
              aria-controls={openMenuForPlusContentIcon ? "account-menus" : undefined}
              aria-haspopup="true"
              className="plus-icon-btn"
              aria-expanded={openMenuForPlusContentIcon ? "true" : undefined}
              aria-describedby={idPlusContentIcon}
            >

              <FontAwesomeIcon icon={faPlus} style={{ cursor: "pointer" }} className="plus-icon-btn" />
            </IconButton>


            <IconButton
              onClick={handleClickForApps}
              aria-controls={openMenuForApps ? "account-menus" : undefined}
              aria-haspopup="true"
              className="avatar-menu-icon-btn"
              aria-expanded={openMenuForApps ? "true" : undefined}
              aria-describedby={idApps}
            >
              <BootstrapImage
                src={menusvg}
                style={{ cursor: "pointer" }}
                className="avatar-menu-icon-btn"
              />
            </IconButton>

            <IconButton
              onClick={handleClickForAvatar}
              aria-controls={openMenuForAvatar ? "account-menu" : undefined}
              aria-haspopup="true"
              className="avatar-menu-icon-btn"
              aria-expanded={openMenuForAvatar ? "true" : undefined}
              aria-describedby={idAvatar}
            >
              <Avatar
                aria-label="recipe"
                className="avatar"
                src={loggedUser?.thumbnail}
              >
                {loggedUser?.name?.charAt(0)}
              </Avatar>
            </IconButton>
          </Stack>
        </Stack>
      </div>

      <Popover
        id={idApps}
        anchorReference="anchorPosition"
        open={openMenuForApps}
        anchorEl={anchorElForApps}
        onClose={handleCloseForApps}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorPosition={{ top: 100, left: 1500 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box className="p-2 main-app-icon-div">
          <Box className="serach-box w-100" sx={{ padding: "15px", borderBottom: "1px solid #EEEEEE" }}>
            <InputGroup className="">
              <Form.Control
                placeholder="Search Apps.."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className="search-input w-100"
              />
              <Button className="search-btn-dash-5">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="main-grid"
            >
              {chunkedTypes?.map((typeData, index) => (
                <>
                  {typeData?.map((type, index) => (
                    <>
                      <Grid
                        item
                        xs={2}
                        sm={4}
                        md={4}
                        className="main-grid-item"
                      >
                        <Box className="main-box-class-2 cursor-pointer d-flex justify-content-center flex-column align-items-center gap-2"
                          sx={{
                            "&:hover": {
                              backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                            },
                          }}
                          onClick={() => {
                            localStorage.removeItem("selected-folder-item")

                            dispatch(drawerMenuAction.clickDrawerItem(""));
                            dispatch(
                              drawerMenuAction.clickDrawerItem("user-type")
                            );
                            dispatch(
                              drawerMenuAction.handleNameCollapsable(false)
                            );
                            dispatch(drawerMenuAction.handleActiveType(true));
                            dispatch(
                              drawerMenuAction.handleTypesCollapsable(true)
                            );
                            dispatch(
                              drawerMenuAction.handleUsersCollapsable(false)
                            );

                            if (loggedUser?.user_type == "admin" && adminAccessible == false) {
                              localStorage.setItem(
                                "type-selected-name",
                                type?.name
                              );
                              localStorage.setItem(
                                "type-selected-id",
                                type?.id
                              );
                              localStorage.setItem(
                                "type-selected-slug",
                                type?.slug
                              );
                              handleCloseForApps();
                              handleCloseForAvatar();
                              handleCloseForCreateContent();
                              localStorage.setItem("last-redirect-page", `/type/${type?.slug}`)
                              navigate(`/type/${type?.slug}`);
                            }
                            else if (loggedUser?.user_type == "admin" && adminAccessible == true) {

                              localStorage.setItem(
                                "type-selected-name-user",
                                type?.name
                              );
                              localStorage.setItem(
                                "type-selected-id-user",
                                type?.id
                              );
                              localStorage.setItem(
                                "type-selected-slug-user",
                                type?.slug
                              );
                              handleCloseForApps();
                              handleCloseForAvatar();
                              handleCloseForCreateContent();
                              localStorage.setItem("last-redirect-page-admin-user", `/user-type/${type?.slug}`)
                              navigate(`/user-type/${type?.slug}`);
                            }
                            else {
                              localStorage.setItem(
                                "type-selected-name-user",
                                type?.name
                              );
                              localStorage.setItem(
                                "type-selected-id-user",
                                type?.id
                              );
                              localStorage.setItem(
                                "type-selected-slug-user",
                                type?.slug
                              );
                              handleCloseForApps();
                              handleCloseForAvatar();
                              handleCloseForCreateContent();
                              localStorage.setItem("last-redirect-page-user", `/user-type/${type?.slug}`)
                              navigate(`/user-type/${type?.slug}`);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={type?.icon} className="fs-20-660C60" sx={{
                            "&:hover": {
                              color: "#660C60", // Change this to your desired hover background color
                            },
                          }} />
                          <Typography className="fs-14-op-50" sx={{
                            "&:hover": {
                              color: "#333333", // Change this to your desired hover background color
                            },
                          }}>{type?.name}</Typography>
                        </Box>
                      </Grid>

                    </>
                  ))}
                </>

              ))}
              {(loggedUser?.user_type === "user" || adminAccessible == true) && (
                <Grid
                  item
                  xs={2}
                  sm={4}
                  md={4}
                  className="main-grid-item"
                >
                  <Box className="main-box-class-2 cursor-pointer d-flex justify-content-center flex-column align-items-center gap-2"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                      },
                    }}
                    onClick={() => {
                      localStorage.removeItem("selected-folder-item")

                      dispatch(drawerMenuAction.clickDrawerItem(""));
                      dispatch(
                        drawerMenuAction.clickDrawerItem("user-type")
                      );
                      dispatch(
                        drawerMenuAction.handleNameCollapsable(false)
                      );
                      dispatch(drawerMenuAction.handleActiveType(true));
                      dispatch(
                        drawerMenuAction.handleTypesCollapsable(true)
                      );
                      dispatch(
                        drawerMenuAction.handleUsersCollapsable(false)
                      );
                      handleCloseForApps();
                      handleCloseForAvatar();
                      handleCloseForCreateContent();
                      if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                        localStorage.setItem(
                          "type-selected-name-user",
                          "People"
                        );
                        localStorage.setItem(
                          "type-selected-id-user",
                          "people"
                        );
                        localStorage.setItem("last-redirect-page-admin-user", `/user-type/people`)
                      }

                      else {
                        localStorage.setItem(
                          "type-selected-name-user",
                          "People"
                        );
                        localStorage.setItem(
                          "type-selected-id-user",
                          "people"
                        );
                        localStorage.setItem(
                          "type-selected-slug-user",
                          "people"
                        );
                        localStorage.setItem("last-redirect-page-user", `/user-type/people`)
                      }
                      navigate(`/user-type/people`);
                    }}
                  >
                    <FontAwesomeIcon icon={faUsers} className="fs-20-660C60" />
                    <Typography className="fs-14-op-50">People</Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>

        </Box>
      </Popover>

      <Popover
        id={idCreateContent}
        open={openMenuForCreateContent}
        anchorEl={anchorElForCreateContent}
        onClose={handleCloseForCreateContent}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="create-content-popup"
      // sx={{ top: "20px" }}
      >
        <Box className="p-2 main-app-icon-div-2">
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="main-grid"
            >
              <Stack direction="vertical" gap={1} className="w-100">
                <List className="list-item-menu-2" >
                  {getAllTypes.map((type, index) => (
                    <ListItem
                      key={type?.slug}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "initial",
                          px: 2.5,
                          "&:hover": {
                            backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                          },
                        }}
                        onClick={() => {
                          dispatch(drawerMenuAction.clickDrawerItem(type?.slug));
                          dispatch(drawerMenuAction.handleNameCollapsable(false));
                          dispatch(drawerMenuAction.handleActiveType(true));
                          dispatch(drawerMenuAction.handleTypesCollapsable(true));
                          dispatch(drawerMenuAction.handleUsersCollapsable(false));

                          if (loggedUser?.user_type == "admin" && adminAccessible == false) {
                            handleCloseForApps();
                            handleCloseForAvatar();
                            handleCloseForCreateContent();
                            localStorage.setItem("last-redirect-page", `/type/add/${type?.slug}`)
                            localStorage.setItem("type-selected-name", type?.name);
                            localStorage.setItem("type-selected-id", type?.id);
                            localStorage.setItem("type-selected-slug", type?.slug);
                            navigate(`/type/add/${type?.slug}`);
                          }
                          else if (loggedUser?.user_type == "admin" && adminAccessible == true) {

                            handleCloseForAvatar();
                            handleCloseForCreateContent();
                            handleCloseForApps();
                            localStorage.setItem("last-redirect-page-admin-user", `/create-content`)
                            localStorage.setItem("type-selected-name-user", type?.name);
                            localStorage.setItem("type-selected-id-user", type?.id);
                            localStorage.setItem("type-selected-slug-user", type?.slug);
                            navigate(`/create-content`);
                          }
                          else {
                            handleCloseForAvatar();
                            handleCloseForCreateContent();
                            handleCloseForApps();
                            localStorage.setItem("last-redirect-page-user", `/create-content`)
                            localStorage.setItem("type-selected-name-user", type?.name);
                            localStorage.setItem("type-selected-id-user", type?.id);
                            localStorage.setItem("type-selected-slug-user", type?.slug);
                            navigate(`/create-content`);
                          }
                        }}
                        className={"item-div"}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 2,
                            ml: 4,
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={type?.icon !== null ? type?.icon : faStar}
                            className="fs-12-660C60"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={type?.name}
                          className="fs-14-muted"
                          sx={{
                            color: type?.colour,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Grid>
          </Box>
        </Box>
      </Popover>

      <Popover
        id={idChat}
        anchorReference="anchorPosition"
        open={openMenuForChat}
        anchorEl={anchorElForChat}
        onClose={handleCloseForChat}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorPosition={{ top: 100, left: 1500 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="chat-popup-popover"
      >
        <Box className="main-app-icon-div-3 d-flex flex-wrap">
          <Box className="users-chat-list-div">
            <Box sx={{ padding: "16px 16px 0 16px" }}>
              <Button
                className="btn primary-btn"
                type="submit"
                onClick={() => {
                  handleCloseForChat()
                  dispatch(drawerMenuAction.clickDrawerItem("user-chat"))
                  if (adminAccessible == true) {
                    localStorage.setItem("last-redirect-page-admin-user", `/user-conversation`)
                  } else {
                    localStorage.setItem("last-redirect-page-user", `/user-conversation`)
                  }
                  navigate("/user-conversation")

                }}
              >
                <FontAwesomeIcon
                  icon={faEye}
                  className=""
                />
                See All Chats
              </Button>
            </Box>
            <Box className="user-list-box">
              <List className="user-list" >
                {chatUserList?.map((chatUser) => (
                  <>
                    <ListItem alignItems="flex-start" className={`user-list-items-con cursor-pointer ${selectedChatRoom == chatUser?.chat_room_id && "selected-chat-room-div"}`} onClick={() => {
                      setSelectedChatRoom(chatUser?.chat_room_id)
                      localStorage.setItem("selected-chat-room-id", chatUser?.chat_room_id)
                    }} sx={{ padding: "15px" }}>
                      <ListItemAvatar className="user-list-avatar-div">
                        <Avatar alt={chatUser?.name} src={chatUser?.profile} className="user-list-avatar text-capitalize">{chatUser?.name?.charAt(0)}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            <Box className="d-flex justify-content-between">
                              <Typography className="chat-usr-name">{chatUser?.name}</Typography>

                              <Typography className="chat-msg-time ">
                                {formatMessageTime(chatUser?.message_time)}
                              </Typography>
                            </Box>
                          </>
                        }
                        className="user-list-name chat-users-list"
                        secondary={
                          <>
                            <Box className="d-flex justify-content-between last-msg text-truncate">
                              {chatUser?.last_message_sender !== "" && (
                                <Typography sx={{ fontSize: "12px" }} className="last-chat-msg text-truncate">{`${chatUser?.last_message_sender}:  ${chatUser?.last_message}`}</Typography>
                              )}
                              {chatUser?.last_message !== "" && chatUser?.unread_count > 0 && (
                                <Typography className="chat-unread-count">
                                  {chatUser?.unread_count}
                                </Typography>
                              )}
                            </Box>
                          </>
                        }
                      />
                    </ListItem>

                    <Divider component="li" />
                  </>
                ))}

              </List>
            </Box>
          </Box>
          <Box className="user-chat-div">
            {selectedChatRoomData !== undefined && (
              <>
                <Box sx={{ padding: "15px" }} className="d-flex justify-content-between align-items-center user-info-div">
                  <Box className="d-flex gap-3 justify-content-center align-items-center">
                    <Avatar alt={selectedChatRoomData?.room_details?.name} src={selectedChatRoomData?.room_details?.profile} className="user-list-avatar" />
                    <Typography>{selectedChatRoomData?.room_details?.name}</Typography>
                  </Box>
                  <Box className="d-flex gap-3 justify-content-center align-items-center">
                    {selectedChatRoomData?.room_details?.type === "private" && (
                      <Box className="" onClick={() => {
                        if (loggedUser?.user_type === "user") {
                          handleCloseForChat()
                          localStorage.setItem("last-redirect-page-user", `/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`);
                          navigate(`/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`);
                        }
                        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                          handleCloseForChat()
                          localStorage.setItem("last-redirect-page-admin-user", `/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`)
                          navigate(`/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`);
                        } else if (loggedUser?.user_type === "admin" && adminAccessible == false) {
                          handleCloseForChat()
                          localStorage.setItem("last-redirect-page", `/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`)
                          navigate(`/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`);
                        }
                      }}>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box sx={{ padding: "15px", maxHeight: "404px", overflow: "auto", minHeight: "404px", borderBottom: "1px solid #EEEEEE" }} className="user-chats-div-con" ref={chatContainerRef}>
                  <Box className="load-more-btn-div">
                    {hasMoreMessages && (
                      <Button onClick={loadMoreMessages} disabled={isLoadingMore} className="load-more-btn text-capitalize" >
                        <FontAwesomeIcon icon={faAngleUp} />
                        {isLoadingMore ? "Loading..." : "Load More"}
                      </Button>

                    )}
                  </Box>
                  {Object?.keys(selectedChatRoomData?.data)?.length > 0 ? (
                    <Box className="full-chat-msg">
                      {Object?.keys(selectedChatRoomData?.data).map((date) => {
                        const messagesByTime = selectedChatRoomData?.data[date];
                        const timeGroups = selectedChatRoomData?.data[date];

                        return (
                          <div key={date} aria-controls={openForReaction ? "account-create-content" : undefined}
                            aria-expanded={openForReaction ? "true" : undefined}
                            aria-describedby={idForReaction} >
                            <Divider className="message-date text-center mb-2">
                              {date}
                            </Divider>
                            {Object.keys(timeGroups).map((time, index) => {
                              const messages = timeGroups[time];
                              // Step 1: Filter messages to have unique ids
                              const uniqueMessages = messages.reduce((acc, currentMessage) => {
                                // Check if the current message id is already in the accumulator
                                const isDuplicate = acc.find((message) => message.id === currentMessage.id);

                                // If it's not a duplicate, add it to the accumulator
                                if (!isDuplicate) {
                                  acc.push(currentMessage);
                                }

                                return acc;
                              }, []);
                              let lastUser = null;
                              console.log("messages:::", messages)
                              const lastMessageSentByUser = messages?.find(chat => chat?.sender_id === loggedUser?.id);
                              return (
                                <div>
                                  {uniqueMessages?.map((chat, index) => {
                                    const isSentByLoggedInUser = chat?.sender_id === loggedUser?.id;
                                    // const seenByAll = chat?.seen_by?.length >= selectedChatRoomData?.room_details?.total_user_count;
                                    const highlighted = isMessageHighlighted(chat?.id);
                                    const navigated = isMessageNavigated(chat?.id);
                                    const showTimeAndName = lastUser !== chat?.sender_id;
                                    lastUser = chat?.sender_id;

                                    return (
                                      <>
                                        <Box
                                        >
                                          <div key={chat?.id}
                                            id={`chat-${chat?.id}`}
                                            className={`chat-message-con ${isSentByLoggedInUser ? 'right' : 'left'} `}
                                            onMouseLeave={handlePopoverCloseForDataOnly}
                                          >
                                            <Box className="chat-data-con">
                                              {showTimeAndName && (
                                                <div className={`${!isSentByLoggedInUser ? "message-time-2 mb-1" : "mb-1"}`}>
                                                  <Typography variant="caption">
                                                    {!isSentByLoggedInUser && (
                                                      <>
                                                        <span className="message-sender message-sender-name">
                                                          {chat?.sender_detail?.name}
                                                        </span>
                                                        <span className="dot-separator">•</span>
                                                      </>
                                                    )}
                                                    <span className="dot-separator">
                                                      {formatTimestamp(chat?.message_time)}
                                                    </span>
                                                  </Typography>
                                                </div>
                                              )}


                                              <Box className="d-flex gap-1" onMouseLeave={() => {

                                              }}>
                                                {!isSentByLoggedInUser && (
                                                  <Avatar
                                                    aria-label="recipe"
                                                    className="avatar-chat text-capitalize"
                                                    src={chat?.sender_detail?.thumbnail}
                                                  >
                                                    {chat?.sender_detail?.name?.charAt(0)}
                                                  </Avatar>
                                                )}

                                                <Box className="msgs"

                                                >

                                                  <Box className="d-flex gap-2 justify-content-center align-items-center">

                                                    <div className={`message-content`} key={chat?.id}
                                                      onMouseEnter={(e) => handlePopoverOpen(e, chat.id, isSentByLoggedInUser == true ? true : false, chat?.message)}
                                                    >
                                                      <Typography
                                                        className={`${highlighted ? 'highlight' : ''} ${navigated ? 'navigated' : ''}`}
                                                        dangerouslySetInnerHTML={{ __html: linkifyMessage(chat?.message) }}
                                                      />
                                                      <Box className=" gap-1 reaction-popup-card" sx={{ display: 'flex', gap: '5px', background: 'white', border: '1px solid #EEEEEE', borderRadius: '6px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}
                                                        aria-controls={openMenuForChatEdit ? "account-create-content" : undefined}
                                                        aria-expanded={openMenuForChatEdit ? "true" : undefined}
                                                        aria-describedby={idForChatEdit}
                                                      >
                                                        <Box className="reaction-options" sx={{ padding: '5px', }}>

                                                          <Box onClick={() => {
                                                            toggleReaction(currentChatID, "love", true)
                                                          }}>
                                                            <FontAwesomeIcon icon={faHeart} className="reaction-icon fa-love-btn" />
                                                          </Box>
                                                          <Box onClick={() => {
                                                            toggleReaction(currentChatID, "smile", true)
                                                          }}>
                                                            <FontAwesomeIcon icon={faSmile} className="reaction-icon fa-smile-btn" />
                                                          </Box>
                                                          <Box onClick={() => {
                                                            toggleReaction(currentChatID, "angry", true)
                                                          }}>
                                                            <FontAwesomeIcon icon={faAngry} className="reaction-icon fa-angry-btn" />
                                                          </Box>
                                                          <Box className="" onClick={() => {
                                                            toggleReaction(currentChatID, "like", true)
                                                          }}>
                                                            <FontAwesomeIcon icon={faThumbsUp} className="reaction-icon fa-like-btn" />
                                                          </Box>
                                                        </Box>
                                                      </Box>

                                                    </div>
                                                    {chat?.sender_id === user?.id && !chat?.isSent && (
                                                      <FontAwesomeIcon icon={faClock} className="fa-clock-icon-send" />
                                                    )}
                                                  </Box>

                                                </Box>

                                              </Box>
                                              <Box className="d-flex gap-2 reaction-main-div">
                                                {Object.entries(chat?.total_reaction || {}).map(([reactionType, reactionsArray]) => (
                                                  <Box className="reaction" key={reactionType}
                                                    aria-owns={openForPopupUser ? 'mouse-over-popover' : undefined}
                                                    aria-haspopup="true"
                                                    onMouseEnter={(e) => handlePopoverOpenForUserReactionList(e, reactionsArray)}
                                                    onMouseLeave={handlePopoverCloseForUserReactionList}
                                                  >
                                                    <HtmlTooltip
                                                      title={
                                                        <React.Fragment>
                                                          <List>
                                                            {reactionsArray?.map(
                                                              (manager, index) => (
                                                                <>
                                                                  <ListItem>
                                                                    <ListItemAvatar>
                                                                      <Avatar
                                                                        alt={manager?.reaction_user?.name}
                                                                        src={manager?.reaction_user?.thumbnail}
                                                                        className="author-avtar-creator"
                                                                      />
                                                                    </ListItemAvatar>
                                                                    <ListItemText
                                                                      primary={manager?.reaction_user?.full_name}
                                                                    />
                                                                  </ListItem>
                                                                  {index !==
                                                                    reactionsArray?.length - 1 && (
                                                                      <Divider component="li" />
                                                                    )}
                                                                  {/* Conditionally render the divider */}
                                                                </>
                                                              )
                                                            )}
                                                          </List>
                                                        </React.Fragment>
                                                      }
                                                      placement="top"
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={
                                                          reactionType == "love" ? faHeart :
                                                            reactionType == "smile" ? faSmile :
                                                              reactionType == "angry" ? faAngry :
                                                                reactionType == "like" ? faThumbsUp :
                                                                  ""}
                                                        className={
                                                          reactionType == "love" ? "fa-love-btn-con" :
                                                            reactionType == "smile" ? "fa-smile-btn-con" :
                                                              reactionType == "angry" ? "fa-angry-btn-con" :
                                                                reactionType == "like" ? "fa-like-btn-con" :
                                                                  ""}
                                                      />
                                                      <span>{reactionsArray.length}</span>
                                                    </HtmlTooltip>
                                                  </Box>
                                                ))}
                                              </Box>
                                              {isSentByLoggedInUser && chat?.id === lastMessageSentByUser?.id && selectedChatRoomData?.room_details?.type === "group" && (
                                                <Box className="mt-0">
                                                  {chat?.seen_by?.length > 0 && (
                                                    <Typography className="seen-all-text">Seen by: {chat?.seen_by?.map(user => user?.name).join(", ")}</Typography>

                                                  )}
                                                </Box>
                                              )}
                                            </Box >
                                          </div>
                                        </Box>
                                      </>
                                    );
                                  })}
                                </div>
                              )

                            })}

                          </div>
                        );
                      })}

                    </Box>
                  ) : (
                    <div className="no-data mt-5">
                      <div>
                        <img src={noChatImg} className="text-center mx-auto no-chat-img mt-4" />
                      </div>
                    </div>
                  )}
                  {userTypingMessageData && typindUsersData?.chat_room_id == selectedChatRoom && (
                    <Box className="typing-indicator text-center">
                      <Typography className="typing-indicator-text">
                        {userTypingMessageData}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box sx={{ padding: "15px" }} className="user-chat-send-div">
                  <Box className="serach-box w-100">
                    <InputGroup className="input-group-2">
                      <TextareaAutosize maxRows={4}
                        placeholder="Quick Respond..."
                        aria-describedby="basic-addon2"
                        className={`search-input-2 w-100 ${borderOnSendMessage == true && "border-send-message-div"}`}
                        onChange={(e) => {
                          setIsTyping(true)
                          setTypedMessage(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}
                        value={typedMessage}
                        as="textarea"
                        style={{ height: "40px" }}
                      />
                      <Button className="search-btn-dash" onClick={sendMessage}>
                        <FontAwesomeIcon icon={faTelegram} />
                      </Button>
                    </InputGroup>
                  </Box>
                </Box>
              </>
            )}

          </Box>
        </Box>
      </Popover>

      <Popover
        id={idPlusContentIcon}
        open={openMenuForPlusContentIcon}
        anchorEl={anchorElForPlusContentIcon}
        onClose={handleCloseForPlusContentIcon}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ top: "20px" }}
      >
        <Box className="p-2 main-app-icon-div-2">
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="main-grid"
            >
              <Stack direction="vertical" gap={1} className="w-100">
                <List className="list-item-menu-2" >
                  <ListItem
                    key="create-content"
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "initial",
                        px: 2.5,
                        "&:hover": {
                          backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                        },
                      }}
                      aria-controls={openMenuForCreateContent2 ? "account-create-content" : undefined}
                      aria-expanded={openMenuForCreateContent2 ? "true" : undefined}
                      aria-describedby={idCreateContent2}
                      onClick={(e) => {
                        handleClickForCreateContent2(e)
                      }}
                      className={"item-div"}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 2,
                          // ml: 4,
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="fs-12-660C60"
                        />

                      </ListItemIcon>
                      <ListItemText
                        primary="Create Content"
                        className="fs-14-muted"
                      // sx={{
                      //   color: type?.colour,
                      // }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    key="notification"
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "initial",
                        px: 2.5,
                        "&:hover": {
                          backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                        },
                      }}
                      onClick={() => {
                        handleCloseForPlusContentIcon()
                      }}
                      className={"item-div"}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 2,
                          // ml: 4,
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBell}
                          className="fs-12-660C60"
                        />

                      </ListItemIcon>
                      <ListItemText
                        primary="Notification"
                        className="fs-14-muted"
                      // sx={{
                      //   color: type?.colour,
                      // }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    key="chat"
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "initial",
                        px: 2.5,
                        "&:hover": {
                          backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                        },
                      }}
                      onClick={() => {
                        handleCloseForPlusContentIcon()
                      }}
                      className={"item-div"}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 2,
                          // ml: 4,
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faComment}
                          className="fs-12-660C60"
                        />

                      </ListItemIcon>
                      <ListItemText
                        primary="Chat"
                        className="fs-14-muted"
                      // sx={{
                      //   color: type?.colour,
                      // }}
                      />
                    </ListItemButton>
                  </ListItem>

                </List>
              </Stack>
            </Grid>
          </Box>
        </Box>
      </Popover>

      <Popover
        id={idCreateContent2}
        open={openMenuForCreateContent2}
        anchorEl={anchorElForCreateContent2}
        onClose={handleCloseForCreateContent2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="another-create-content-popup"
      // sx={{ top: "10px" }}
      >
        <Box className="p-2 main-app-icon-div-2">
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="main-grid"
            >
              <Stack direction="vertical" gap={1} className="w-100">
                <List className="list-item-menu-2" >
                  {getAllTypes.map((type, index) => (
                    <ListItem
                      key={type?.slug}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "initial",
                          px: 2.5,
                          "&:hover": {
                            backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                          },
                        }}
                        onClick={() => {
                          dispatch(drawerMenuAction.clickDrawerItem(type?.slug));
                          dispatch(drawerMenuAction.handleNameCollapsable(false));
                          dispatch(drawerMenuAction.handleActiveType(true));
                          dispatch(drawerMenuAction.handleTypesCollapsable(true));
                          dispatch(drawerMenuAction.handleUsersCollapsable(false));

                          if (loggedUser?.user_type == "admin" && adminAccessible == false) {
                            handleCloseForApps();
                            handleCloseForAvatar();
                            handleCloseForCreateContent();
                            localStorage.setItem("last-redirect-page", `/type/add/${type?.slug}`)
                            localStorage.setItem("type-selected-name", type?.name);
                            localStorage.setItem("type-selected-id", type?.id);
                            localStorage.setItem("type-selected-slug", type?.slug);
                            navigate(`/type/add/${type?.slug}`);
                          }
                          else if (loggedUser?.user_type == "admin" && adminAccessible == true) {

                            handleCloseForAvatar();
                            handleCloseForCreateContent();
                            handleCloseForApps();
                            localStorage.setItem("last-redirect-page-admin-user", `/create-content`)
                            localStorage.setItem("type-selected-name-user", type?.name);
                            localStorage.setItem("type-selected-id-user", type?.id);
                            localStorage.setItem("type-selected-slug-user", type?.slug);
                            navigate(`/create-content`);
                          }
                          else {
                            handleCloseForAvatar();
                            handleCloseForCreateContent();
                            handleCloseForApps();
                            localStorage.setItem("last-redirect-page-user", `/create-content`)
                            localStorage.setItem("type-selected-name-user", type?.name);
                            localStorage.setItem("type-selected-id-user", type?.id);
                            localStorage.setItem("type-selected-slug-user", type?.slug);
                            navigate(`/create-content`);
                          }
                        }}
                        className={"item-div"}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 2,
                            ml: 4,
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={type?.icon !== null ? type?.icon : faStar}
                            className="fs-12-660C60"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={type?.name}
                          className="fs-14-muted"
                          sx={{
                            color: type?.colour,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Grid>
          </Box>
        </Box>
      </Popover>

      <Popover
        id={idNotification}
        anchorReference="anchorPosition"
        open={openMenuForNotification}
        anchorEl={anchorElForNotification}
        onClose={handleCloseForNotification}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorPosition={{ top: 100, left: 1500 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="chat-popup-popover"
      >
        <Box className="p-2 notification-popover d-flex flex-wrap ">
          <Box className="users-notification-list-div">
            <Box>
              <Tabs
                value={selectedTab}
                onChange={(event, newValue) =>
                  setSelectedTab(newValue)
                }
                className="notification-tabs"
                variant="scrollable"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#660C60",
                  },
                }}
              >
                {/* <Tab
                  icon={<FontAwesomeIcon icon={faBell} className="menu-icon" />}
                  iconPosition="start"
                  value="all"
                  label="All"
                /> */}
                <Tab
                  value="must action"
                  label={
                    <Box gap={2} className="notification-tab-label">
                      <FontAwesomeIcon icon={faGavel} className="menu-icon"
                        style={{ color: selectedTab === "must action" ? "#660C60" : "inherit" }}
                      />
                      <span>
                        Must Action
                      </span>
                      {notificationTabCount["must action"] > 0 && (
                        <Box className="label-counter">
                          <Typography sx={{ fontWeight: 500, fontSize: "0.6rem", color: "#FFFFFF" }}>
                            <b>
                              {notificationTabCount["must action"]}
                            </b>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  }
                />
                <Tab
                  value="mention"
                  label={
                    <Box gap={2} className="notification-tab-label">
                      <FontAwesomeIcon icon={faCommentSolid} className="menu-icon"
                        style={{ color: selectedTab === "mention" ? "#660C60" : "inherit" }}
                      />
                      <span>
                        Mentions
                      </span>
                      {notificationTabCount?.mention > 0 && (
                        <Box className="label-counter">
                          <Typography sx={{ fontWeight: 500, fontSize: "0.6rem", color: "#FFFFFF" }}>
                            <b>
                              {notificationTabCount?.mention}
                            </b>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  }
                />

              </Tabs>
              <Divider />
            </Box>
            <Box className="mt-0">
              <List className="user-list">
                {filteredNotifications.map(
                  (notification, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        alignItems="flex-start"
                        className="user-list-items notification-list-item"
                        onClick={() => { handleNotificationClick(notification) }}
                      >
                        <ListItemAvatar className="user-list-avatar-div">
                          {
                            ['post', 'acknowledge_post'].includes(notification?.type) ? (
                              <Avatar sx={{ bgcolor: '#660c60' }} className="user-list-avatar">
                                <FontAwesomeIcon icon={faFile} size="xs" />
                              </Avatar>
                            ) : (
                              <Avatar
                                alt={
                                  notification
                                    ?.sender_detail
                                    ?.full_name ||
                                  "Admin"
                                }
                                src={
                                  notification
                                    ?.sender_detail
                                    ?.thumbnail ||
                                  "/static/images/avatar/1.jpg"
                                }
                                className="user-list-avatar"
                              />
                            )
                          }


                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  (notification?.title?.length > 150
                                    ? `${notification.title.substring(0, 150)}...`
                                    : notification?.title) ||
                                  "Notification received",
                              }}
                              className={notification?.action ? "fs-14-muted" : ""}
                            />
                          }
                          className="user-list-name chat-users-list"
                        />
                      </ListItem>
                      <Divider component="li" />
                    </React.Fragment>
                  )
                )}
                {filteredNotifications.length <= 0 && (<ListItem
                  alignItems="flex-start"
                  className="user-list-items"
                >
                  <ListItemText
                    primary={
                      <span>No Notification </span>
                    }
                    className="user-list-name chat-users-list"
                  />
                </ListItem>)}
              </List>
            </Box>
          </Box>
        </Box>
      </Popover>

      <Menu
        anchorEl={anchorElForAvatar}
        id="account-menu-avatar"
        open={openMenuForAvatar}
        onClose={handleCloseForAvatar}
        onClick={handleCloseForAvatar}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 2.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClickForDetails} className="menu-items-user">
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faUser}
              className="fs-12-660C60"
            />
          </ListItemIcon>
          <ListItemText
            primary={"My Details"}
            className="menu-name fs-14-muted"

          />
        </MenuItem>
        <MenuItem onClick={handleClickForDetails} className="menu-items-user">
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faGears}
              className="fs-12-660C60"
            />
          </ListItemIcon>
          <ListItemText
            primary={"Top Drawer"}
            className="menu-name fs-14-muted"
          />
        </MenuItem>
        <MenuItem onClick={onLogout} className="menu-items-user">
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faLock}
              className="fs-12-660C60"
            />
          </ListItemIcon>
          <ListItemText
            primary={"Log Out"}
            className="menu-name fs-14-muted"
          />
        </MenuItem>
      </Menu>

      <Popover
        id={idForGlobalSearch}
        open={openForGlobalSearch}
        anchorEl={anchorElForGlobalSearch}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleCloseForGlobalSearch}
        className="global-search-popover"
      >
        <Box
          sx={{
            width: "100%",
          }}
          className="global-search-popover-box"
        >
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="box-attribute"
          >
            <Tabs
              value={value}
              onChange={(event, newValue) => {
                handleChangeForGlobalSearchTab(newValue)
              }}
              aria-label="basic tabs example"
              variant={"scrollable"}
              className={"tabs"}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#660C60",
                },
              }}
              scrollButtons="auto"
            >
              {globalSearchDataTabs?.map((tab, index) => (
                <Tab
                  label={tab?.name}
                  icon={<FontAwesomeIcon icon={tab?.icon} />}
                  iconPosition="start"
                  value={tab?.id}
                  {...a11yProps1(tab?.id)}
                />
              ))}

            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={value}>
            <Paper sx={{ width: "100%" }} className="type-list-paper">
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  {globalSearchFilterData?.length > 0 ? (
                    <>
                      <TableHeaderForSorting
                        columns={columns_global_search_header}
                        handleChangeDataOrdr={handleChangeDataOrdr}
                        dataOrder={dataOrder}
                      />
                      <TableBody>
                        {globalSearchFilterData?.map((row, index) => (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index + 1}
                            className="cursor-pointer"
                            onClick={() => {
                              if (row?.type !== "Folder") {
                                localStorage.setItem("type-selected-name-user", row?.post_type?.type_name)
                                window.open(`/user-type/view/${row?.type}/${row?.id}`, '_blank');
                              } else {
                                localStorage.setItem("type-selected-name-user", row?.post_type?.type_name)
                                localStorage.setItem("selected-folder-item", row?.folder_id)
                                window.open(`/user-type/${row?.post_type?.type_name}`, '_blank');
                              }
                            }}
                          >
                            <TableCell
                              align="left"
                              className={`tbl-body-cell`}
                            >
                              {row?.type === "Folder" ? (
                                <div
                                  className="d-flex flex-warp gap-3 align-items-center"
                                >
                                  <FontAwesomeIcon icon={faFolder} className="folder-tbl-cell" />
                                  <p
                                    className={`folder-tbl-cell-2 ${row?.status === "deleted" &&
                                      "text-muted fw-700"
                                      }`}
                                  >
                                    {row?.name}
                                  </p>
                                </div>
                              ) : (
                                <div
                                  className="d-flex flex-warp gap-3 align-items-center"
                                >
                                  <FontAwesomeIcon icon={faFile} className="folder-tbl-cell-3" />
                                  <p
                                    className={`folder-tbl-cell-4 ${row?.status === "deleted" &&
                                      "text-muted fw-700"
                                      }`}
                                  >
                                    {row?.name}
                                  </p>
                                </div>
                              )}

                            </TableCell>
                            <TableCell
                              align="left"
                              className="tbl-body-cell-3 "
                            >
                              {row?.type}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  ) : (
                    <div className="no-data mt-2">
                      <div>
                        <BootstrapImage
                          src={NoDataFoundImg}
                          className="text-center mx-auto no-data-img-2 mt-4"
                        />
                      </div>
                      <Typography component="h5" variant="h5">
                        No data found
                      </Typography>
                    </div>
                  )}
                </Table>
              </TableContainer>
            </Paper>
            {globalSearchData?.post?.data?.length > 0 && (
              <>
                {globalSearchData?.post?.links?.next !== null && (
                  <>
                    <Divider component="div" role="presentation" />
                    <Box className="p-3 d-flex justify-content-center">
                      <Button
                        className="view-search-result-btn w-50"

                        onClick={() => {
                          localStorage.setItem("globalSearchText", globalSearch)
                          localStorage.setItem("globalSearchTab", value)
                          handleCloseForGlobalSearch()
                          navigate("/search-result")
                        }}
                      >
                        <FontAwesomeIcon icon={faTelegram} />
                        View All Search Results
                      </Button>
                    </Box>
                  </>
                )}
              </>
            )}


          </CustomTabPanel>
        </Box>
      </Popover>

      <Loader isLoading={loading} />
      <Toaster />
      {/* END :: HEADER */}
    </>
  );
};

export default Header;
