import axios from "axios";

// const apiURL = `${window?.location?.protocol}//${window?.location?.hostname}/api`;
const devMode = process.env.REACT_APP_MODE
let apiURL;

if (devMode !== "DEV") {
    apiURL = `${window?.location?.protocol}//${window?.location?.hostname}/api`;
} else {
    apiURL = `${window?.location?.protocol}//${window?.location?.hostname}:8000/api`;
}

// const apiFull = axios.create({
//     baseURL: process.env.REREACT_APP_MODE !== "DEV" ? apiURL : process.env.REACT_APP_API_URL, // Your API base URL
// });

const apiFull = axios.create({
    baseURL: apiURL  // Your API base URL
});

// Request interceptor
apiFull.interceptors.request.use(
    async (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
apiFull.interceptors.response.use(
    (response) => {
        // Return the entire response object
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default apiFull;