import { lazy, Suspense } from "react";
import AuthGuard from "../utils/authGuard";
import UserDashboard from "../pages/user-dashboard/userDashboard";
import UserTypePage from "../pages/user-type-page/UserTypePage";
import UserTypeViewPage from "../pages/user-type-view/UserTypeViewPage";
import UserCreateContent from "../pages/user-create-content/userCreateContent";
import UserPeopleProfileView from "../pages/user-people-profile-view/UserPeopleProfileView";
import UserConversation from "../pages/user-conversation/userConversation";

const loggedUserRoute = [
  {
    path: "/dashboard",
    element: (
      <Suspense>
        <AuthGuard>
          <UserDashboard />
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/user-type/:type",
    element: (
      <Suspense>
        <AuthGuard>
          <UserTypePage />
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/user-type/view/:type/:id",
    element: (
      <Suspense>
        <AuthGuard>
          <UserTypeViewPage />
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/create-content",
    element: (
      <Suspense>
        <AuthGuard>
          <UserCreateContent />
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/user-type/create-content/edit/:id",
    element: (
      <Suspense>
        <AuthGuard>
          <UserCreateContent />
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/user-type/people/profile/view/:id",
    element: (
      <Suspense>
        <AuthGuard>
          <UserPeopleProfileView />
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/user-conversation",
    element: (
      <Suspense>
        <AuthGuard>
          <UserConversation />
        </AuthGuard>
      </Suspense>
    ),
  },
];

export default loggedUserRoute;
