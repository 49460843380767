import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

function CustomEditor(props) {
  const { setFieldValue, value, editorConfiguration, setFieldValueFor } = props;

  const addTargetBlankToAnchors = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const anchors = doc.querySelectorAll('a');

    anchors.forEach(anchor => {
      anchor.setAttribute('target', '_blank');
    });

    return doc.body.innerHTML;
  };

  return (
    <CKEditor
      editor={Editor}
      config={editorConfiguration}
      data={value ? value : ""}
      onChange={(event, editor) => {
        let data = editor.getData();
        data = addTargetBlankToAnchors(data);
        setFieldValue(setFieldValueFor, data);
      }}
    />
  );
}

export default CustomEditor;
