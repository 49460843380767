export const columns_users_contact_header = [
  {
    key: 1,
    disablePadding: true,
    id: "service",
    label: "Service",
    className: "tbl-cell-head-contact",
    minWidth: 50,
  },
  {
    key: 2,
    disablePadding: true,
    id: "value",
    label: "Value",
    className: "tbl-cell-head-contact",
    minWidth: 100,
  },
];
