/* eslint-disable import/no-anonymous-default-export */
export default {
    control: {
        backgroundColor: '#fff',
        fontSize: 14,
    },

    '&multiLine': {
        control: {
            minHeight: 10,
        },
        highlighter: {
            border: '1px solid transparent',

        },
        input: {
            padding: 8,
            border: '1px solid silver',
            top: 0
        },
    },

    '&singleLine': {

        highlighter: {
            border: '1px inset transparent',
        },
        input: {
            padding: 8,
            border: '1px inset',
        },
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 16,
        },
        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },
}