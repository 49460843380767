import React from "react";
import Header from "../../components/layout/header";
import DrawerMenu from "../../components/layout/drawer";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

const Forms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    open,
    selectedItem,
    nameCollapsable,
    drawerVariant,
    typeCollapsable,
    usersCollapsable,
  } = useSelector((state) => state?.drawerMenu);
  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          <Typography variant="h4">Forms</Typography>
        </Container>
      </div>
    </>
  );
};

export default Forms;
