export const columns_types_changelog_header = [
  {
    key: 1,
    disablePadding: true,
    id: "user",
    label: "User",
    minWidth: 150,
    className: "other-color tbl-cell-3",
  },
  {
    key: 2,
    id: "date",
    disablePadding: true,
    label: "Date",
    minWidth: 70,
    className: "other-color  tbl-cell-2",
  },
  {
    key: 3,
    id: "area",
    disablePadding: true,
    label: "Area",
    minWidth: 70,
    className: "other-color  tbl-cell-2",
  },
  {
    key: 4,
    id: "details",
    disablePadding: true,
    label: "Details",
    minWidth: 50,
    className: "other-color tbl-cell-2",
  },
];
