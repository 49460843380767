import { createSlice } from "@reduxjs/toolkit";
const initialData = {
    selectedTypeTab: 0
};

const typeTab = createSlice({
    name: "typeTabDetails",
    initialState: initialData,
    reducers: {
        handleSelectedTab(state, action) {
            state.selectedTypeTab = action?.payload
        }
    }
})

export const typeTabAction = typeTab.actions;
export default typeTab.reducer;