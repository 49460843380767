import React from "react";
import { FileUploader } from "react-drag-drop-files";

function DragDrop(props) {
  let { handleFileChange, fileTypes } = props;
  return (
    <FileUploader
      handleChange={handleFileChange}
      name="file"
      types={fileTypes}
    />
  );
}

export default DragDrop;
