import * as Yup from "yup";

export const addNewNewsvalidationSchema = Yup.object().shape({
  title: Yup.string("Please enter your post title.").required(
    "Post title is required"
  ),
  content: Yup.string("Please enter your content.").required(
    "Post content is required"
  ),
  image: Yup.mixed().required("Post image is required"),
  file: Yup.mixed(),
  postType: Yup.string("Please select post type.").required(
    "Post type is required"
  ),

});
