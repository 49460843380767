import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container, Image } from "react-bootstrap";
import "../../styles/news.scss";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useParams } from "react-router-dom";
import Loader from "../../components/common/loader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import Header from "../../components/layout/header";
import { Toast } from "../../utils/toast";
import DrawerMenu from "../../components/layout/drawer";
import { Divider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";

const ViewNews = () => {
  let { type, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [getNewsData, setGetNewsData] = useState();
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    activeType,
    drawerVariant,
    usersCollapsable,
  } = useSelector((state) => state?.drawerMenu);

  const userToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  const processedHTML = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent || ""; // Ensure there's a default value if htmlContent is undefined
    return tempDiv;
  };

  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const fetchNewsDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`type/details/view/${parseInt(id)}}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setGetNewsData(response?.response);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/type/${type}`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  useEffect(() => {
    const apiCall = async () => {
      if (parseInt(id)) {
        await fetchNewsDetails();
      }
    };
    apiCall();
  }, [parseInt(id)]);

  return (
    <>

      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage == true && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage == true && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <Box className="mb-5">
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  underline="hover"
                  key="1"
                  color="inherit"
                  href="/"
                  component="h3"
                  onClick={() => {
                    localStorage.setItem("last-redirect-page", `/`);

                    navigate("/");
                  }}
                  className="cursor-pointer"
                >
                  {type}
                </Link>
                <Typography key="3" component="h3" color="text.primary">
                  {getNewsData?.title}
                </Typography>
              </Breadcrumbs>
            </Stack>
            <Divider className="mt-2" style={{ background: "#EEEEEE" }} />
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item lg={8} sm={12} md={12} xl={8} xs={12}>
              <div className="p-2">
                <div className="news-imag-div">
                  <Image src={getNewsData?.image} />
                </div>
                <div className="p-3" style={{ backgroundColor: "#FFFFFF" }}>
                  <div className="mt-4 mb-3 p-2">
                    <h3 className="view-news-short-desc">
                      {getNewsData?.classification_shortSummary}
                    </h3>
                  </div>
                  <div className="mt-2 mb-3 p-2">
                    <Typography
                      variant="body1"
                      component="p"
                      className="view-news-content"
                      dangerouslySetInnerHTML={{
                        __html: processedHTML(getNewsData?.content).innerHTML,
                      }}
                    ></Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={4} sm={12} md={12} xl={4} xs={12}></Grid>
          </Grid>
        </Container>
      </div>

      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default ViewNews;
