export const columns_user_groups_header = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "tbl-head-class-2",
    showInfoIcon: true,
  },
  {
    key: 2,
    disablePadding: true,
    id: "members",
    label: "Members",
    minWidth: 150,
  },
  {
    key: 3,
    disablePadding: true,
    id: "date-created",
    label: "Date Created",
    minWidth: 150,
  },
  {
    key: 4,
    id: "type",
    disablePadding: true,
    label: "Type",
    minWidth: 100,
    className: "other-color",
  },
  {
    key: 5,
    id: "manager",
    disablePadding: true,
    label: "Manager",
    minWidth: 200,
    className: "other-color",
  },
  {
    key: 6,
    id: "creator",
    disablePadding: true,
    label: "Creator",
    minWidth: 100,
    className: "other-color",
  },
  {
    key: 7,
    id: "status",
    disablePadding: true,
    label: "Status",
    minWidth: 100,
    className: "other-color",
  },
];
