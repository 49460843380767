export const columns_categories_header = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "tbl-head-class-2",
    showInfoIcon: true,
  },
  {
    key: 2,
    disablePadding: true,
    id: "type",
    label: "Category Type",
    minWidth: 200,
  },
  { key: 3, disablePadding: true, id: "used", label: "Used", minWidth: 200 },
  {
    key: 4,
    id: "author",
    disablePadding: true,
    label: "Creator",
    minWidth: 70,
    className: "other-color",
  },
  {
    key: 5,
    id: "status",
    disablePadding: true,
    label: "Status",
    minWidth: 50,
    className: "other-color",
  },
];
