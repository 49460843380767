import { lazy, Suspense } from "react";
import AuthGuard from "../utils/authGuard";
import AuthGuardForSuperAdmin from "../utils/authGuardForSuperAdmin";
import Categories from "../pages/categories";
import AddCategory from "../pages/categories/addCategory";

const categoryRoutes = [
  {
    path: "/categories",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <Categories />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/add/categories",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddCategory />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/categories/edit/:id",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddCategory />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
];

export default categoryRoutes;
