import { createSlice } from "@reduxjs/toolkit";
const initialData = {
  open: true,
  selectedItem: "",
  nameCollapsable: false,
  typeCollapsable: false,
  usersCollapsable: false,
  activeType: false,
  drawerVariant: "permanent",
  menuFor: "",
  adminAccessible: false,
  fetchNewType: false,
};

const drawerMenu = createSlice({
  name: "drawerMenuDetails",
  initialState: initialData,
  reducers: {
    handleDrawer(state, action) {
      state.open = action.payload;
    },
    clickDrawerItem(state, action) {
      state.selectedItem = action.payload;
    },
    handleDrawerForSmallScreen(state, action) {
      state.open = action?.payload;
    },
    handleNameCollapsable(state, action) {
      state.nameCollapsable = action?.payload;
    },
    handleTypesCollapsable(state, action) {
      state.typeCollapsable = action?.payload;
    },
    handleUsersCollapsable(state, action) {
      state.usersCollapsable = action?.payload;
    },
    handleActiveType(state, action) {
      state.activeType = action?.payload;
    },
    handleDrawerVariant(state, action) {
      state.drawerVariant = action?.payload;
    },
    handleSelectMenu(state, action) {
      state.menuFor = action?.payload;
    },
    handleAdminAccessible(state, action) {
      state.adminAccessible = action?.payload
    },
    handleFetchNewType(state, action) {
      state.fetchNewType = action?.payload
    }
  },
});

export const drawerMenuAction = drawerMenu.actions;
export default drawerMenu.reducer;
