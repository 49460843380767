import { createSlice } from "@reduxjs/toolkit";
const initialData = {
  selectedGroupTab: 0,
};

const groupTab = createSlice({
  name: "groupTabDetails",
  initialState: initialData,
  reducers: {
    handleSelectedTab(state, action) {
      state.selectedGroupTab = action?.payload;
    },
  },
});

export const groupTabAction = groupTab.actions;
export default groupTab.reducer;
