export const NotifyUsersOptions = [
  { value: "email", label: "Email" },
  { value: "internal", label: "Internal" },
  { value: "sms", label: "SMS" },
];

export const ExpiryActions = [
  { value: "disabled_acknowledge", label: "DISABLE Acknowledgement" },
  { value: "hide_news", label: "Hide Object (News)" },
  {
    value: "force",
    label:
      "On Home page show model with news item and forcefully asked to acknowledge",
  },
];

export const ReminderNotificationActions = [
  { value: "2 days", label: "2 Days" },
  { value: "7 days", label: "7 Days" },
];

export const ShowTopOfFeedOptionActions = [
  { value: "none", label: "None" },
  { value: "min", label: "1 Min" },
  { value: "hour", label: "Hour" },
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
];

export const AcknowledgementTypeOptions = [
  { value: "checkbox", label: "Checkbox" },
  { value: "questions", label: "Questions" },
];
