import * as React from "react";
import Alert from "@mui/material/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

export const WarningAlert = (props) => {
  let { message, onClose } = props;
  return (
    <>
      <Alert
        icon={<FontAwesomeIcon icon={faBell} className="warning-alert-icon" />}
        severity="success"
        className="mb-2 warning-alert"
        onClose={onClose}
      >
        {message}
      </Alert>
    </>
  );
};
