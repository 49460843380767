import { lazy, Suspense } from "react";
import AuthGuard from "../utils/authGuard";
import AuthGuardForSuperAdmin from "../utils/authGuardForSuperAdmin";
import Groups from "../pages/groups";
import AddGroups from "../pages/groups/addGroups";

const groupRoutes = [
  {
    path: "/groups",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <Groups />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/add/groups",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddGroups />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/group/edit/:id",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddGroups />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
];

export default groupRoutes;
