export const postTypeOptions = [
  { value: "news", label: "News" },
  { value: "document", label: "Document" },
  { value: "update", label: "Update" },
  { value: "intel", label: "Intel" },
  { value: "policy", label: "Policy" },
  { value: "page", label: "Page" },
  { value: "recognition", label: "Recognition" },
  { value: "poll", label: "Poll" },
  { value: "ink", label: "Link" },
  { value: "task", label: "Task" },
];
