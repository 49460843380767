import * as Yup from "yup";
export const addNewCategoryvalidationSchema = Yup.object().shape({
  categoryTitle: Yup.string("Please enter category title.").required(
    "Category title is required"
  ),
  categoryStatus: Yup.boolean(),
  categoryAppend: Yup.boolean(),

  users: Yup.array().when("categoryAppend", (categoryAppend, schema) => {
    const isUsers = categoryAppend.flat().includes(true);
    if (isUsers) {
      return Yup.array()
        .min(1, "At least one user is required")
        .label("At least one user is required")
        .required("At least one user is required");
    }
    return schema;
  }),
});
