import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/layout/header";
import DrawerMenu from "../../components/layout/drawer";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Avatar,
  Paper,
  Button,
  Stack,
  Chip,
  Menu,
  MenuItem,
  DialogContentText,
  Radio,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  OutlinedInput,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Image } from "react-bootstrap";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faBullhorn,
  faBurst,
  faCheck,
  faFloppyDisk,
  faFolder,
  faPhone,
  faPlus,
  faShield,
  faShieldHalved,
  faTrash,
  faUser,
  faXmark,
  faSearch,
  faCircleInfo,
  faFileCsv,
  faTimes,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import { Toast } from "../../utils/toast";
import Loader from "../../components/common/loader";
import { useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import InputGroup from "react-bootstrap/InputGroup";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import RadioGroup from "@mui/material/RadioGroup";
import CreatableSelect from "react-select/creatable";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  FastField,
  Field,
  FieldArray,
  Form,
  Formik,
} from "formik";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import deleteType from "../../assets/images/delete.png";
import Accordion from "react-bootstrap/Accordion";
import { columns_users_contact_header } from "../../helpers/theaderColumns/users/usersFields";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import { AntSwitch } from "../../components/common/switch/antSwitch";
import ImageUpload from "../../components/common/imageUpload";
import FileUploadModal from "../../components/common/fileUploadModal";
import { addNewUsersvalidationSchema } from "../../helpers/validations/users/addUsers";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "../../styles/users.scss";
import { EditNewUsersvalidationSchema } from "../../helpers/validations/users/editUsers";
import { CSVLink } from "react-csv";
import TablePagination from "@mui/material/TablePagination";

import { columns_attribute_changelog_header } from "../../helpers/theaderColumns/attributes/attributeChangelog";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";
import FileUploadModalAttribue from "../../components/common/fileUploadModalAttribute.js";
import { parseInt } from "lodash";
import { configForContentEditor, configForContentEditorForHTML } from "../../helpers/constants/common.js/index.js";
import { dateWithTime } from "../../helpers/DateTimeFormat/dateTimeFormat.js";
import { exportXlsx } from "../../helpers/Exports/FileExport.js";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="pl-3 py-2"
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  errorTab: PropTypes.number.isRequired,
};

const processedHTML = (htmlContent) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent || ""; // Ensure there's a default value if htmlContent is undefined
  return tempDiv;
};

const attributes = [
  {
    id: 1,
    section_id: 1,
    label: "Folder",
    is_mandatory: true,
    nameValue: "folder",
    fieldtype: "dropdodown_single_choice",
    value: "",
    options: [
      { value: "news", label: "News" },
      { value: "document", label: "Document" },
    ],
  },
  {
    id: 2,
    section_id: 1,
    label: "Short Summary",
    is_mandatory: true,
    nameValue: "short_summary",
    fieldtype: "long_text",
    value: "",
  },

  // {
  //   id: 3,
  //   section_id: 1,
  //   label: "Link For News",
  //   is_mandatory: true,
  //   nameValue: "link_news",
  //   fieldtype: "link_object",
  //   value: "",
  // },

  // {
  //   id: 3,
  //   section_id: 1,
  //   label: "Link For News",
  //   is_mandatory: true,
  //   nameValue: "link_news",
  //   fieldtype: "link_object",
  //   value: {
  //     "title": "test-news-title",
  //     "url": "https://mui.com/material-ui/react-pagination/"
  //   },
  // },

  {
    id: 5,
    section_id: 3,
    label: "Title",
    is_mandatory: false,
    nameValue: "title",
    // repeatable: true,
    fieldtype: "short_text",
    value: "",
  },
  {
    id: 6,
    section_id: 2,
    label: "Scheduled release",
    is_mandatory: false,
    nameValue: "schedule_release",
    fieldtype: "date_time",
    value: "",
  },
  {
    id: 7,
    section_id: 4,
    label: "Comments",
    is_mandatory: true,
    nameValue: "comments",
    fieldtype: "radio_single_choice",
    options: [
      { id: 1, name: "Yes" },
      { id: 2, name: "No" },
    ],
    parentID: 1,
    childAtts: [
      {
        id: 115,
        section_id: 3,
        label: "Title",
        is_mandatory: false,
        nameValue: "title_child",
        // repeatable: true,
        fieldtype: "short_text",
        value: "",
      },
      {
        id: 116,
        section_id: 2,
        label: "Scheduled release",
        is_mandatory: false,
        nameValue: "schedule_release_child",
        fieldtype: "date_time",
        value: "",
      },
      {
        id: 121,
        section_id: 1,
        label: "Folder",
        is_mandatory: true,
        nameValue: "folder_child",
        fieldtype: "dropdodown_single_choice",
        value: "",
        options: [
          { value: "news", label: "News" },
          { value: "document", label: "Document" },
        ],
      },
      ,
      {
        id: 1133,
        section_id: 2,
        label: "Folders",
        is_mandatory: true,
        nameValue: "folders_multi_child",
        fieldtype: "dropdodown_multiple_choice",
        value: "",
        options: [
          { value: "news", label: "News" },
          { value: "document", label: "Document" },
          { value: "draft", label: "Draft" },
          { value: "test", label: "Test" },
        ],
      },
    ],
    value: "",
  },
  {
    id: 1122,
    section_id: 4,
    label: "Assign responsibility",
    is_mandatory: true,
    is_repeatable: true,
    nameValue: "responsibility_child",
    fieldtype: "checkbox_multiple_choice",
    options: [
      { id: 1, name: "Gilad Gray", checked: true },
      { id: 2, name: "Jason Killian", checked: false },
      { id: 3, name: "Antoine Llorca", checked: false },
    ],
    value: [],
  },
  {
    id: 8,
    section_id: 4,
    label: "Url",
    is_mandatory: true,
    nameValue: "url",
    fieldtype: "link",
    value: "",
  },
  // {
  //   id: 9,
  //   section_id: 4,
  //   label: "Email",
  //   is_mandatory: true,
  //   nameValue: "email",
  //   fieldtype: "email",
  //   repeatable: true,

  //   value: "",
  // },
  // {
  //   id: 10,
  //   section_id: 4,
  //   label: "Count",
  //   is_mandatory: false,
  //   nameValue: "count",
  //   fieldtype: "number",
  //   value: "",
  // },
  // {
  //   id: 11,
  //   section_id: 1,
  //   label: "Does this Group/team have a leader/manager?",
  //   is_mandatory: false,
  //   nameValue: "lead/manager",
  //   fieldtype: "checkbox_single_choice",
  //   value: "",
  // },
  // {
  //   id: 12,
  //   section_id: 4,
  //   label: "Assign responsibility",
  //   is_mandatory: true,
  //   nameValue: "responsibility",
  //   fieldtype: "checkbox_multiple_choice",
  //   options: [
  //     { id: 1, name: "Gilad Gray", checked: true },
  //     { id: 2, name: "Jason Killian", checked: false },
  //     { id: 3, name: "Antoine Llorca", checked: false },
  //   ],
  //   value: [1],
  // },
  // {
  //   id: 13,
  //   section_id: 2,
  //   label: "Folders",
  //   is_mandatory: true,
  //   nameValue: "folders",
  //   fieldtype: "dropdodown_multiple_choice",
  //   value: "",
  //   options: [
  //     { value: "news", label: "News" },
  //     { value: "document", label: "Document" },
  //     { value: "draft", label: "Draft" },
  //     { value: "test", label: "Test" },
  //   ],
  // },
  // {
  //   id: 14,
  //   section_id: 2,
  //   label: "Scheduled release Time",
  //   is_mandatory: false,
  //   nameValue: "schedule_release_time",
  //   fieldtype: "time",
  //   value: "",
  // },
  // {
  //   id: 15,
  //   section_id: 2,
  //   label: "Scheduled release Date Time",
  //   is_mandatory: false,
  //   nameValue: "schedule_release_date_time",
  //   fieldtype: "date_time",
  //   value: "",
  // },
  // {
  //   id: 16,
  //   section_id: 4,
  //   label: "Scheduled release Range",
  //   is_mandatory: false,
  //   nameValue: "schedule_release_range",
  //   fieldtype: "date_range",
  //   value: "",
  // },
  {
    id: 17,
    section_id: 3,
    label: "Content",
    is_mandatory: false,
    nameValue: "content",
    fieldtype: "text_editor",
    value: "",
  },
  // {
  //   id: 18,
  //   section_id: 1,
  //   label: "Content HTML",
  //   is_mandatory: false,
  //   nameValue: "content_html",
  //   fieldtype: "html",
  //   value: "",
  // },
  // {
  //   id: 19,
  //   section_id: 5,
  //   label: "User Image",
  //   is_mandatory: true,
  //   nameValue: "image",
  //   fieldtype: "image",
  //   value: "",
  // },
  // {
  //   id: 20,
  //   section_id: 5,
  //   label: "User File",
  //   is_mandatory: true,
  //   nameValue: "file",
  //   fieldtype: "file",
  //   value: "",
  // },
];

const AddUsers = () => {
  let { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getAllChangeLogs, setGetAllChangeLogs] = useState();
  const [search, setSearch] = useState("");
  const [getChangelogCsvData, setGetChangelogCsvData] = useState([]);
  const [getCategoryData, setCategoryData] = useState();
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState([]);

  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [errorTab, setErrorTab] = useState(-1);
  const [isErrorOnClassificationTab, SetIsErrorOnClassificationTab] =
    useState(false);
  const [isErrorOnIRTab, setIsErrorOnIRTab] = useState(false);
  const [isErrorOnEngTab, SetIsErrorOnEngTab] = useState(false);
  const [isErrorOnProfileTab, setIsErrorOnProfileTab] = useState(false);
  const [isErrorOnContactTab, setIsErrorOnContactTab] = useState(false);
  const [isErrorOnAuthenticationTab, setIsErrorOnAuthenticationTab] =
    useState(false);
  const [isErroronOrganisationTab, setIsErrorOnOrganisationTab] =
    useState(false);
  const [contactData, setContactData] = useState([]);
  const [openFileUploadModel, setOpenFileUploadModel] = useState(false);
  const [contentForPost, setContentForPost] = useState("");
  const [isEdit, setIsEdit] = useState(id ? true : false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [showError, setShowError] = useState(false);


  const debounceTimeout = 500; // Adjust the debounce timeout as needed
  let debounceTimer;

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] =
    React.useState(false);
  const [getUserData, setGetUserData] = useState();
  const [istype, setIstype] = useState(false);
  const [userNameExist, setUserNameExist] = useState();
  const [emailExist, setEmailExist] = useState();

  const [attributesData, setAttributes] = useState([]);
  // const [attributesData, setAttributes] = useState(attributes);

  const [getAllCategories, setGetAllCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState({})

  const [attData, setAttData] = useState();
  const [additionalTextFields, setAdditionalTextFields] = useState([]);
  const [additionalTextFieldsForNestedChildAtt1, setAdditionalTextFieldsForNestedChildAtt1] = useState([]);

  const [showBelowError, setShowBelowError] = useState(false);
  const [formData, setFormData] = useState({});

  const [selectedFileForAtt, setSelectedFileForAtt] = useState(null);
  const [selectedImageFileForAtt, setSelectedImageFileForAtt] = useState(null);

  const [contentForPostForAtt, setContentForPostForAtt] = useState("");
  const [openFileUploadModelForAtt, setOpenFileUploadModelForAtt] =
    useState(false);

  const [value, setValue] = useState(0);

  const total_changelog = parseInt(getAllChangeLogs?.meta?.total);

  const usernameRegex = /^(?=.{3,20}$)(?!.*[_.]{2})[a-zA-Z0-9._]+$/; // Username regex for 4-8 characters with at least one special character

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  let emailExists;
  let usernameExist;

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmedPassword = () =>
    setShowConfirmedPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const servicesSections = [
    { label: "Email", value: "email" },
    { label: "Phone", value: "phone" },
    { label: "Twitter", value: "twitter" },
    { label: "Address", value: "address" },
    { label: "Linkdein", value: "linkdein" },
  ];

  const handleOpen = () => setOpenFileUploadModel(true);
  const handleClose = () => setOpenFileUploadModel(false);

  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    drawerVariant,
    usersCollapsable,
  } = useSelector((state) => state?.drawerMenu);

  let newWarningMessages = [];

  const userToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);
  const ITEM_HEIGHT = 48;

  const header_csv = [
    { label: "No", key: "no" },
    { label: "User", key: "user" },
    { label: "Date", key: "date" },
    { label: "Area", key: "area" },
    { label: "Details", key: "details" },
  ];

  const initialValues = {
    profileAccountType: id ? getUserData?.profileAccountType : "1",
    profileFirstName: id ? getUserData?.profileFirstName : "",
    profileLastName: id ? getUserData?.profileLastName : "",
    profileJobTitle: id ? getUserData?.profileJobTitle : "",
    profileBio: id ? getUserData?.profileBio : "",
    profileGroups: id ? getUserData?.profileGroups : [],
    contactDetails: id
      ? getUserData?.contactDetails
      : [
        { id: 1, service: "email", value: "" },
        { id: 2, service: "phone", value: "" },
      ],
    authenticationUserName: id ? getUserData?.authenticationUserName : "",
    authenticationPassword: "",
    authenticationConfirmPassword: "",
    authenticationNextLogin: id ? getUserData?.authenticationNextLogin : false,
    authenticationLoginDetails: id
      ? getUserData?.authenticationLoginDetails
      : false,
    organisationManagers: id ? getUserData?.organisationManagers : "",
    organisationDirectReports: id ? getUserData?.organisationDirectReports : "",
    userStatus: id ? getUserData?.userStatus : true,
    image: id ? getUserData?.image : "",
  };

  const imageFileTypes = ["JPG", "PNG", "GIF"];
  const fileTypes = ["docx", "pdf"];
  const handleOpenForAtt = () => setOpenFileUploadModelForAtt(true);
  const handleCloseForAtt = () => setOpenFileUploadModelForAtt(false);

  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const handleFileChange = (file, setFieldValue) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (contentForPost === "image") {
          setIsEdit(false);
          setSelectedImageFile(file);
        } else if (contentForPost === "file") {
          setSelectedFile(file);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCloseWarningMessage = (index) => {
    const updatedMessages = [...warningMessage];
    updatedMessages.splice(index, 1);
    setWarningMessage(updatedMessages);
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleChangeForTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    } else if (newPage < page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const transformData = (data) => {
    const result = {};

    data?.forEach(category => {
      const termIds = category?.term_value?.split(',')?.map(Number);
      result[category?.slug] = category?.categoryterm?.filter(term =>
        termIds?.includes(term?.id)).map(term => ({
          label: term?.name,
          value: term?.id,
          categoryId: term?.category_id
        }));
    });

    return result;
  };

  const isValidURL = (url) => {
    const pattern = new RegExp(
      /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?$/
    );
    return !!pattern.test(url);
  };

  const isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const transformCategoryData = (categories) => {
    return categories?.map(category => ({
      label: category?.title,
      slug: category?.slug,
      id: category?.id,
      options: category?.categoryterm?.map(term => ({
        label: term?.name,
        value: term?.id,
        categoryId: category?.id
      }))
    }));
  };

  const handleCategoryChange = (categoryLabel, selectedOption) => {
    setSelectedCategories(prevState => ({
      ...prevState,
      [categoryLabel]: selectedOption
    }));
  };

  const transformSelectedCategories = (selectedCategories, allCategories) => {
    const payload = {};

    allCategories?.forEach(category => {
      const categoryLabel = category?.slug?.toLowerCase();
      const terms = selectedCategories[categoryLabel] || [];
      const categoryId = category?.id;

      payload[categoryLabel] = {
        category_id: categoryId,
        term_values: terms?.length > 0 ? terms?.map(term => term.value)?.join(',') : ""
      };
    });

    return payload;
  };

  const handleAddTextField = (
    nameValue,
    label,
    section_id,
    fieldtype,
    options,
    is_mandatory,
    repeatableAtt,
    valueAtt,
  ) => {
    const lastId =
      additionalTextFields.length > 0
        ? additionalTextFields[additionalTextFields.length - 1].id
        : null;

    const newTextField = {
      id: lastId + 1, // Generate a unique ID for the new text field
      nameValue: nameValue,
      value: "",
      label: label,
      section_id: section_id, // Set the appropriate tab value
      fieldtype: fieldtype,
      is_mandatory: is_mandatory,
      ...(options && { options: options }), // Conditionally include options if available
    };
    setAdditionalTextFields([...additionalTextFields, newTextField]);
    if (repeatableAtt == true) {
      const updatedValue = Array.isArray(valueAtt)
        ? [...valueAtt, newTextField]
        : newTextField;
      handleChangeForFormData(nameValue, updatedValue, section_id, false, "", true);
    }
  };

  const handleChangeForFormData = (
    nameValue,
    value,
    section_id,
    isChildAtt,
    subField,
    isAppendDirectly
  ) => {
    setAttributes((prevAttributes) => {
      const updatedAttributes = prevAttributes.map((attribute) => {
        if (
          attribute.section_id === section_id &&
          attribute.nameValue === nameValue
        ) {
          // Update the value of the attribute
          let updatedAttribute = { ...attribute, value };

          if (attribute.fieldtype === "link_object" && isAppendDirectly == false) {
            const currentLinkObject = attribute.value || {};
            updatedAttribute.value = {
              ...currentLinkObject,
              [subField]: value
            };

          } else {
            updatedAttribute.value = value;

            // If the fieldtype is radio_single_choice and options are provided
            if (
              attribute.fieldtype === "radio_single_choice" &&
              attribute.options
            ) {
              const updatedOptions = attribute.options.map((option) => {
                // Set the checked property based on the selected value
                return { ...option, checked: option.id === parseInt(value) };
              });
              // Update the options of the attribute
              updatedAttribute = { ...updatedAttribute, options: updatedOptions };
            }

            if (attribute.fieldtype === "tags") {
              // Initialize options with current options or an empty array
              let options = attribute?.options || [];

              // Separate existing and new options
              const existingOptions = value.filter((option) => !option.__isNew__);
              const newOptions = value.filter((option) => option.__isNew__);

              // Add new options to the existing options list
              if (newOptions.length > 0) {
                options = [
                  ...options,
                  ...newOptions.map((option) => ({
                    value: option.value,
                    label: option.label,
                  })),
                ];
              }

              // Extract selected values from the value array
              const selectedValues = value.map((option) => option.value);

              // Update the attribute with new options and selected values
              updatedAttribute = {
                ...updatedAttribute,
                options: options,
                value: selectedValues,
              };
            }

            // If the fieldtype is checkbox_multiple_choice
            if (
              attribute.fieldtype === "checkbox_multiple_choice" &&
              attribute.options
            ) {
              const updatedOptions = attribute.options.map((option) => {
                // Set the checked property based on whether the option ID is included in the selected values array
                return { ...option, checked: value.includes(option.id) };
              });
              // Update the options of the attribute
              updatedAttribute = { ...updatedAttribute, options: updatedOptions };
            }

            if (isChildAtt == true) {
              updatedAttribute = { ...updatedAttribute, childAtts: value };
            }
          }

          return updatedAttribute;
        }
        return attribute;
      });

      return updatedAttributes;
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      [nameValue]: value,
    }));
  };

  const handleChangeForChildAtts = (
    nameValue,
    value,
    section_id,
    childAtts,
    attdata
  ) => {
    const findAtt = attributesData
      .filter((data) => data?.id === attdata?.id)
      .map((data) => {
        return {
          id: data?.id,
          section_id: data?.section_id,
          label: data?.label,
          is_mandatory: data?.is_mandatory,
          nameValue: data?.nameValue,
          fieldtype: data?.fieldtype,
          options: data?.options,
          parentID: data?.parentID,
          childAtts: data?.childAtts,
          value: data?.value,
        };
      });

    const updatedChildAtts = findAtt.flatMap((att) => {
      return att.childAtts.map((childAtt) => {
        if (
          childAtt.section_id === section_id &&
          childAtt.nameValue === nameValue
        ) {
          // If the fieldtype is checkbox_multiple_choice
          if (
            childAtt.fieldtype === "checkbox_multiple_choice" &&
            childAtt.options
          ) {
            const updatedOptions = childAtt.options.map((option) => {
              // Set the checked property based on whether the option ID is included in the selected values array
              return { ...option, checked: value.includes(option.id) };
            });

            // Update the options of the child attribute
            return { ...childAtt, options: updatedOptions, value };
          } else {
            // If the fieldtype is not checkbox_multiple_choice, simply update the value
            return { ...childAtt, value };
          }
        }
        return childAtt;
      });
    });

    const updatedAttData = attributesData.map((att) => {
      if (att.id === attdata.id) {
        return { ...att, childAtts: updatedChildAtts };
      }
      return att;
    });
    setAttributes(updatedAttData);
  };

  const handleChangeForAdditionalTextField = (
    id,
    value,
    nameValue,
    section_id,
    fieldtype,
    subField
  ) => {
    let updatedTextFields;
    if (fieldtype === "link_object") {
      updatedTextFields = additionalTextFields.map((field) =>
        field.id === id && field?.nameValue === nameValue
          ? { ...field, value: { ...field.value, [subField]: value } }
          : field
      );
    } else {
      updatedTextFields = additionalTextFields.map((field) =>
        field.id === id && field?.nameValue === nameValue
          ? { ...field, value: value }
          : field
      );
    }

    setAdditionalTextFields(updatedTextFields);

    const finalData = updatedTextFields?.filter(
      (data) => data?.nameValue == nameValue
    );

    handleChangeForFormData(nameValue, finalData, section_id, false, "", true);
  };

  const removeExtraFields = (id, nameValue, section_id) => {
    const updatedTextFields = additionalTextFields?.filter(
      (field) => !(field?.id === id && field?.nameValue === nameValue)
    );
    setAdditionalTextFields(updatedTextFields);
    const anotherupdatedTextFields = updatedTextFields?.filter((field) => field?.nameValue === nameValue)
    handleChangeForFormData(nameValue, anotherupdatedTextFields, section_id, false, "", true);
  };

  const handleImageFileValue = (setFieldValue) => {
    if (
      selectedImageFile instanceof Blob ||
      selectedImageFile instanceof File ||
      selectedFile instanceof Blob ||
      selectedFile instanceof File
    ) {
      if (contentForPost === "image") {
        setFieldValue("image", URL.createObjectURL(selectedImageFile));
        setOpenFileUploadModel(false);
      } else {
        setFieldValue("file", selectedFile);
        setOpenFileUploadModel(false);
      }
    } else {
      console.error("Selected file is not a valid Blob or File object");
    }
  };

  const handleClickForAtt = (content, attdatas) => {
    setContentForPostForAtt(content);
    setAttData(attdatas);
    setOpenFileUploadModelForAtt(true);
    if (
      selectedImageFileForAtt instanceof Blob ||
      selectedImageFileForAtt instanceof File ||
      selectedFileForAtt instanceof Blob ||
      selectedFileForAtt instanceof File
    ) {
      setIsEdit(false)
    } else {
      setIsEdit(true)
    }
    if (attdatas?.value !== "") {
      if (content == "image") {
        setSelectedImageFileForAtt(selectedImageFileForAtt);
      }
      if (content == "file") {
        setSelectedFileForAtt(selectedFileForAtt);
      }
    }
  };

  const handleFileChangeForAtt = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (contentForPostForAtt === "image") {
          setIsEdit(false);
          setSelectedImageFileForAtt(file);
        } else if (contentForPostForAtt === "file") {
          setSelectedFileForAtt(file);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageFileValueForAtt = () => {
    if (
      selectedImageFileForAtt instanceof Blob ||
      selectedImageFileForAtt instanceof File ||
      selectedFileForAtt instanceof Blob ||
      selectedFileForAtt instanceof File
    ) {
      if (contentForPostForAtt == "image") {
        handleChangeForFormData(
          attData?.nameValue,
          URL.createObjectURL(selectedImageFileForAtt),
          attData?.section_id
        );
        setOpenFileUploadModelForAtt(false);
      } else {
        handleChangeForFormData(
          attData?.nameValue,
          selectedFileForAtt,
          attData?.section_id
        );

        setOpenFileUploadModelForAtt(false);

      }
    } else {
      console.error("Selected file is not a valid Blob or File object");
    }
  };

  const validateAttributes = () => {
    let isValid = true;
    let errorMessages = [];

    const validateField = (attribute, labelPrefix = "") => {
      if (attribute?.is_mandatory == true) {
        if (!attribute?.is_repeatable) {
          if (
            attribute?.value == null ||
            attribute?.value === "" ||
            attribute?.value == NaN && attribute?.fieldtype !== "link_object"
          ) {
            isValid = false;
            // isValid = true;

            Toast.error(
              `The field "${labelPrefix}${attribute.label}" is required.`
            );

            errorMessages.push(
              `The field1 "${labelPrefix}${attribute.label}" is required.`
            );
          }
          // Email validation for non-repeatable fields
          if (attribute?.fieldtype === "email" && attribute?.value) {
            if (!isValidEmail(attribute.value)) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" must be a valid email.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" must be a valid email.`
              );
            }
          }
          if (attribute?.fieldtype === "url" && attribute?.value) {
            if (!isValidURL(attribute.value)) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" must be a valid url.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" must be a valid url.`
              );
            }
          }
          if (
            attribute?.fieldtype === "checkbox_multiple_choice" &&
            attribute?.value
          ) {
            if (attribute?.value?.length < 1) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
            }
          }
          if (
            attribute?.fieldtype === "dropdodown_multiple_choice" &&
            attribute?.value
          ) {
            if (attribute?.value?.length < 1) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
            }
          }
          if (attribute?.fieldtype === "tags" && attribute?.value) {
            if (attribute?.value?.length < 1) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
            }
          }
          if (attribute.fieldtype === "link_object" && attribute?.value) {
            if (!attribute.value?.title || attribute.value.title === "") {
              isValid = false;
              Toast.error(`The field "${labelPrefix}${attribute.label}  Title" is required.`);
              errorMessages.push(`The field "${labelPrefix}${attribute.label}  Title" is required.`);
            }
            if (attribute.value?.url && !isValidURL(attribute.value.url)) {
              isValid = false;
              Toast.error(`The field "${labelPrefix}${attribute.label}  URL" must be a valid URL.`);
              errorMessages.push(`The field "${labelPrefix}${attribute.label} URL" must be a valid URL.`);
            }
          }
        } else {
          if (
            !Array.isArray(attribute?.value) ||
            attribute?.value.length === 0
          ) {
            isValid = false;
            // isValid = true;

            Toast.error(
              `At least one "${labelPrefix}${attribute.label}" is required.`
            );
            errorMessages.push(
              `At least one "${labelPrefix}${attribute.label}" is required.`
            );
          }

          else {
            attribute.value.forEach((item, index) => {
              if (
                item?.is_mandatory &&
                (!item.value || item.value === "" || item.value === NaN)
              ) {
                isValid = false;
                // isValid = true;

                Toast.error(
                  `The field "${labelPrefix}${attribute.label} #${index + 1
                  }" is required.`
                );
                errorMessages.push(
                  `The field2 "${labelPrefix}${attribute.label} #${index + 1
                  }" is required.`
                );
              }

              if (attribute.fieldtype === "link_object") {
                if (!item.value?.title || item.value.title === "") {
                  isValid = false;
                  Toast.error(`The field "${labelPrefix}${attribute.label} #${index + 1} Title" is required.`);
                  errorMessages.push(`The field "${labelPrefix}${attribute.label} #${index + 1} Title" is required.`);
                }
                if (item.value?.url && !isValidURL(item.value.url)) {
                  isValid = false;
                  Toast.error(`The field "${labelPrefix}${attribute.label} #${index + 1} URL" must be a valid URL.`);
                  errorMessages.push(`The field "${labelPrefix}${attribute.label} #${index + 1} URL" must be a valid URL.`);
                }
              }
            });
          }
        }
      }

      if (attribute?.nameValue === "checkbox_single_choice_acknowledgement" && attribute?.value == true) {

        if (attribute?.childAtts[0]?.value?.length < 1) {
          isValid = false;
          Toast.error("Please add atleast one user for acknowledge")
        } if (attribute?.childAtts[1]?.value == "") {
          isValid = false;
          Toast.error("Please select due by date for acknowledge")
        } if (attribute?.childAtts[2]?.value == "") {
          isValid = false;
          Toast.error("Please select due by expiry action for acknowledge")
        } if (attribute?.childAtts[3]?.value == "") {
          isValid = false;
          Toast.error("Please select reminder notification for acknowledge")
        } if (attribute?.childAtts[4]?.value == 1) {
          if (attribute?.childAtts[4]?.childAtts[0]?.value === "") {
            isValid = false;
            Toast.error("Please enter acknowledgement texts")
          }
        }
        if (attribute?.childAtts[4]?.value == 2) {
          if (attribute?.childAtts[4]?.childAtts[1]?.value?.length < 1) {
            isValid = false;
            Toast.error("Please add atleast one acknowledgement question")
          } else if (attribute?.childAtts[4]?.childAtts[1]?.value?.length > 0) {
            attribute?.childAtts[4]?.childAtts[1]?.value?.forEach((childAtt, index) => {
              if (childAtt?.value === "") {
                isValid = false;
                Toast.error(
                  `Please add acknowledgement question for ${index + 1} is required.`
                );
              }
            })
          }
        }
      }
      // Check child attributes if they exist
      if (
        (attribute?.value === true ||
          attribute?.value === 1 ||
          attribute?.value === NaN) &&
        attribute?.childAtts?.length > 0
      ) {
        attribute?.childAtts?.forEach((childAtt) =>
          validateField(childAtt, `${labelPrefix}${attribute?.label} - `)
        );
      }
    };

    attributesData?.forEach((attribute) => validateField(attribute));

    return { isValid, errorMessages };
  };

  const handleClick = (content) => {
    setContentForPost(content);
    setOpenFileUploadModel(true);
  };

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        tabValue == index
          ? `selected-tab-attribute ${isErrorOnProfileTab == true && `error-on-class`
          }`
          : `non-selected-tab-attribute ${isErrorOnProfileTab == true && `error-on-class`
          }`,
    };
  }
  function a11yProps2(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        tabValue == index
          ? `selected-tab-attribute ${isErrorOnContactTab == true && `error-on-ir`
          }`
          : `non-selected-tab-attribute ${isErrorOnContactTab == true && `error-on-ir`
          }`,
    };
  }
  function a11yProps3(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        tabValue == index
          ? `selected-tab-attribute ${isErrorOnAuthenticationTab == true && `error-on-eng`
          }`
          : `non-selected-tab-attribute ${isErrorOnAuthenticationTab == true && `error-on-eng`
          }`,
    };
  }

  function a11yProps4(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        tabValue == index
          ? `selected-tab-attribute ${isErroronOrganisationTab == true && `error-on-eng`
          }`
          : `non-selected-tab-attribute ${isErroronOrganisationTab == true && `error-on-eng`
          }`,
    };
  }

  function a11yProps5(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        tabValue == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  const onSubmit = async (values) => {
    const validate = await validateAttributes();

    if (validate?.isValid == false) {
      setShowBelowError(true);
    } else {
      setShowBelowError(false);
      console.log("values?.profileGroups::", values)

      const formData = new FormData();

      let groupsString = "";
      let managersString;
      let directReportsString;

      if (values?.organisationManagers !== "") {
        const managers = values?.organisationManagers?.map((user) => user);
        managersString = managers?.join(",");
        formData.append("user_manager", managersString);
      }

      if (values?.organisationDirectReports !== "") {
        const directReports = values?.organisationDirectReports?.map(
          (user) => user
        );
        directReportsString = directReports?.join(",");
        formData.append("user_report", directReportsString);
      }

      const contactDetails = JSON.stringify(values?.contactDetails);
      const contactEmail = values?.contactDetails?.filter(
        (data) => data?.service === "email"
      );

      const fileAttribute = attributesData?.find(
        (data) => data?.fieldtype == "file"
      );
      const imageAttribute = attributesData?.find(
        (data) => data?.fieldtype == "image"
      );
      formData.append("account_type_id", values?.profileAccountType);
      formData.append("first_name", values?.profileFirstName);
      formData.append("last_name", values?.profileLastName);
      formData.append("job_title", values?.profileJobTitle);
      formData.append("bio", values?.profileBio);
      formData.append(
        "status",
        values?.userStatus == true ? "enable" : "disable"
      );
      if (values?.profileGroups?.length > 0) {
        groupsString = values?.profileGroups?.join(",");
      } else {
        groupsString = " ";
      }

      formData.append("groups", groupsString);
      formData.append("contact_details", contactDetails);

      if (
        selectedImageFile instanceof Blob ||
        selectedImageFile instanceof File
      ) {
        formData.append("thumbnail", selectedImageFile);
      }
      formData.append("email", contactEmail[0]?.value);

      if (values?.profileAccountType == "1") {
        formData.append("username", values?.authenticationUserName);
        formData.append("password", values?.authenticationPassword);
        formData.append(
          "force_pwd_changed",
          values?.authenticationNextLogin == true ? "enable" : "disable"
        );
        formData.append(
          "email_login_detail",
          values?.authenticationLoginDetails == true ? "enable" : "disable"
        );
      }

      function extractFileName(url) {
        const baseUrl =
          "https://staging-intranet.s3.ap-southeast-2.amazonaws.com/images/";
        return url.replace(baseUrl, "");
      }

      const transformedAttributesData = attributesData?.reduce(
        (acc, attribute) => {
          let attributeValue = attribute?.value;
          let attributeIsChild =
            attribute?.childAtts?.length > 0 ? "yes" : "no";
          let attributeChildAtts = [];

          // If the fieldtype is checkbox_multiple_choice or dropdodown_multiple_choice, convert the value to a comma-separated string
          if (
            attribute?.fieldtype === "checkbox_multiple_choice" ||
            attribute?.fieldtype === "dropdodown_multiple_choice" ||
            attribute?.fieldtype === "users_list" ||
            attribute?.fieldtype === "group_list" ||
            attribute?.fieldtype === "tags"
          ) {
            attributeValue = Array.isArray(attribute?.value)
              ? attribute?.value.join(",")
              : attribute?.value;
          }

          if (attribute?.fieldtype === "checkbox_single_choice") {
            attributeValue = String(attribute?.value);
          }

          // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

          if (attribute?.fieldtype === "date" && attribute?.value !== "") {
            const date = dayjs(attribute?.value)?.format("YYYY-MM-DD");
            attributeValue = date;
          }
          if (attribute?.fieldtype === "time" && attribute?.value !== "") {
            const time = dayjs(attribute?.value, "HH:mm A")?.format("HH:mm A");
            attributeValue = time;
          }
          if (attribute?.fieldtype === "date_time" && attribute?.value !== "") {
            const dateTime = dayjs(attribute?.value)?.format(
              "YYYY-MM-DDTHH:mm:ss.SSSZ"
            );
            attributeValue = dateTime;
          }
          if (attribute?.fieldtype === "date_range") {
            const startDate = moment(attribute.value.startDate);
            const endDate = moment(attribute.value.endDate);
            const formattedStartDate = startDate?.format("YYYY-MM-DD");
            const formattedEndDate = endDate?.format("YYYY-MM-DD");
            // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
            attributeValue = `${formattedStartDate} - ${formattedEndDate}`;
          }

          if (attribute?.fieldtype === "image") {
            if (
              selectedImageFileForAtt instanceof Blob ||
              selectedImageFileForAtt instanceof File
            ) {
              attributeValue = "";
            } else {
              attributeValue = extractFileName(attribute.value);
            }
          }

          if (attribute?.fieldtype === "file") {
            if (
              selectedFileForAtt instanceof Blob ||
              selectedFileForAtt instanceof File
            ) {
              attributeValue = "";
            } else {
              attributeValue = extractFileName(attribute.value);
            }
          }

          if (attribute?.is_repeatable == true) {
            if (Array.isArray(attribute?.value)) {
              attributeValue = attribute?.value?.map(item => {
                console.log("item all::", item)
                if (item?.fieldtype == "time") {
                  console.log("tine is present")
                  return {
                    ...item,
                    value: dayjs(item?.value, "HH:mm A")?.format("HH:mm A")
                  };
                }
                // if (item?.fieldtype == "dropdodown_multiple_choice"
                //   || item?.fieldtype == "users_list" || item?.fieldtype == "group_list" || item?.fieldtype == "tags") {
                //   const value = Array.isArray(item?.value)
                //     ? item?.value.join(",")
                //     : item?.value
                //   return {
                //     ...item,
                //     value: value
                //   };
                // }
                // if (item?.fieldtype == "checkbox_single_choice") {
                //   return {
                //     ...item,
                //     value: String(attribute?.value)
                //   };
                // }
                // if (item?.fieldtype == "date") {
                //   return {
                //     ...item,
                //     value: dayjs(attribute?.value)?.format("YYYY-MM-DD")
                //   };
                // }
                // if (item?.fieldtype == "date_range") {
                //   const startDate = moment(attribute.value.startDate);
                //   const endDate = moment(attribute.value.endDate);
                //   const formattedStartDate = startDate?.format("YYYY-MM-DD");
                //   const formattedEndDate = endDate?.format("YYYY-MM-DD");
                //   return {
                //     ...item,
                //     value: `${formattedStartDate} - ${formattedEndDate}`
                //   };
                // }
                // if (item?.fieldtype == "date_time") {
                //   return {
                //     ...item,
                //     value: dayjs(attribute?.value)?.format(
                //       "YYYY-MM-DDTHH:mm:ss.SSSZ"
                //     )
                //   };
                // }

                // console.log("item for time::", item)
                return item;
              });
            }
            attributeValue = JSON?.stringify(attributeValue);
          }

          if (attribute?.childAtts?.length > 0) {
            if (attribute.value == false) {
              attributeChildAtts = attribute?.childAtts?.reduce(
                (acc, childAtt) => {
                  let attributeValueForChild = childAtt?.value;

                  // If the fieldtype is checkbox_multiple_choice or dropdodown_multiple_choice, convert the value to a comma-separated string
                  if (
                    childAtt?.fieldtype === "checkbox_multiple_choice" ||
                    childAtt?.fieldtype === "dropdodown_multiple_choice" ||
                    childAtt?.fieldtype === "users_list" ||
                    childAtt?.fieldtype === "group_list" ||
                    childAtt?.fieldtype === "tags"
                  ) {
                    attributeValueForChild = Array.isArray(childAtt?.value)
                      ? childAtt?.value.join(",")
                      : childAtt?.value;
                  }

                  if (childAtt?.fieldtype === "checkbox_single_choice") {
                    attributeValueForChild = String(childAtt?.value);
                  }

                  // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

                  if (childAtt?.fieldtype === "date" && childAtt?.value !== "") {
                    const date = dayjs(attribute?.value)?.format("YYYY-MM-DD");
                    attributeValueForChild = date;
                  }
                  if (childAtt?.fieldtype === "time" && childAtt?.value !== "") {
                    const time = dayjs(attribute?.value, "HH:mm A")?.format("HH:mm A");
                    attributeValueForChild = time;
                  }
                  if (childAtt?.fieldtype === "date_time" && childAtt?.value !== "") {
                    const dateTime = dayjs(attribute?.value)?.format(
                      "YYYY-MM-DDTHH:mm:ss.SSSZ"
                    );
                    attributeValueForChild = dateTime;
                  }
                  if (childAtt?.fieldtype === "date_range") {
                    const startDate = moment(childAtt.value.startDate);
                    const endDate = moment(childAtt.value.endDate);
                    const formattedStartDate = startDate?.format("YYYY-MM-DD");
                    const formattedEndDate = endDate?.format("YYYY-MM-DD");
                    // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
                    attributeValueForChild = `${formattedStartDate} - ${formattedEndDate}`;
                  }

                  if (childAtt?.fieldtype === "image") {
                    if (
                      selectedImageFileForAtt instanceof Blob ||
                      selectedImageFileForAtt instanceof File
                    ) {
                      attributeValueForChild = "";
                    } else {
                      attributeValueForChild = extractFileName(childAtt.value);
                    }
                  }

                  if (childAtt?.fieldtype === "file") {
                    if (
                      selectedFileForAtt instanceof Blob ||
                      selectedFileForAtt instanceof File
                    ) {
                      attributeValueForChild = "";
                    } else {
                      attributeValueForChild = extractFileName(childAtt.value);
                    }
                  }

                  if (childAtt?.is_repeatable == true) {
                    attributeValueForChild = JSON?.stringify(childAtt?.value);
                  }

                  acc[childAtt?.nameValue] = {
                    parentAttId: attribute?.id,
                    attribute_id: childAtt?.attribute_id,
                    attribute_core_name: childAtt?.nameValue,
                    attribute_data_type:
                      childAtt?.fieldtype === "checkbox_multiple_choice" ||
                        childAtt?.fieldtype === "dropdodown_multiple_choice"
                        ? "array"
                        : childAtt?.fieldtype === "file"
                          ? "file"
                          : childAtt?.fieldtype === "image"
                            ? "image"
                            : "string",
                    attribute_value: attributeValueForChild,
                    is_child: "no",
                  };

                  return acc;
                },
                {}
              );
            } else if (attribute.value != 1) {
              attributeChildAtts = attribute?.childAtts?.reduce(
                (acc, childAtt) => {
                  let attributeValueForChild = childAtt?.value;

                  // If the fieldtype is checkbox_multiple_choice or dropdodown_multiple_choice, convert the value to a comma-separated string
                  if (
                    childAtt?.fieldtype === "checkbox_multiple_choice" ||
                    childAtt?.fieldtype === "dropdodown_multiple_choice" ||
                    childAtt?.fieldtype === "users_list" ||
                    childAtt?.fieldtype === "group_list" ||
                    attribute?.fieldtype === "tags"
                  ) {
                    attributeValueForChild = Array.isArray(childAtt?.value)
                      ? childAtt?.value.join(",")
                      : childAtt?.value;;
                  }

                  if (childAtt?.fieldtype === "checkbox_single_choice") {
                    attributeValueForChild = String(childAtt?.value);
                  }

                  // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

                  if (childAtt?.fieldtype === "date" && childAtt?.value !== "") {
                    const date = dayjs(attribute?.value)?.format("YYYY-MM-DD");
                    attributeValueForChild = date;
                  }
                  if (childAtt?.fieldtype === "time" && childAtt?.value !== "") {
                    const time = dayjs(attribute?.value, "HH:mm A")?.format("HH:mm A");
                    attributeValueForChild = time;
                  }
                  if (childAtt?.fieldtype === "date_time" && childAtt?.value !== "") {
                    const dateTime = dayjs(attribute?.value)?.format(
                      "YYYY-MM-DDTHH:mm:ss.SSSZ"
                    );
                    attributeValueForChild = dateTime;
                  }
                  if (childAtt?.fieldtype === "date_range") {
                    const startDate = moment(childAtt.value.startDate);
                    const endDate = moment(childAtt.value.endDate);
                    const formattedStartDate = startDate?.format("YYYY-MM-DD");
                    const formattedEndDate = endDate?.format("YYYY-MM-DD");
                    // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
                    attributeValueForChild = `${formattedStartDate} - ${formattedEndDate}`;
                  }

                  if (childAtt?.fieldtype === "image") {
                    if (
                      selectedImageFileForAtt instanceof Blob ||
                      selectedImageFileForAtt instanceof File
                    ) {
                      attributeValueForChild = "";
                    } else {
                      attributeValueForChild = extractFileName(childAtt.value);
                    }
                  }

                  if (childAtt?.fieldtype === "file") {
                    if (
                      selectedFileForAtt instanceof Blob ||
                      selectedFileForAtt instanceof File
                    ) {
                      attributeValueForChild = "";
                    } else {
                      attributeValueForChild = extractFileName(childAtt.value);
                    }
                  }

                  if (childAtt?.is_repeatable == true) {
                    attributeValueForChild = JSON?.stringify(childAtt?.value);
                  }

                  acc[childAtt?.nameValue] = {
                    parentAttId: attribute?.id,
                    attribute_id: childAtt?.attribute_id,
                    attribute_core_name: childAtt?.nameValue,
                    attribute_data_type:
                      childAtt?.fieldtype === "checkbox_multiple_choice" ||
                        childAtt?.fieldtype === "dropdodown_multiple_choice"
                        ? "array"
                        : childAtt?.fieldtype === "file"
                          ? "file"
                          : childAtt?.fieldtype === "image"
                            ? "image"
                            : "string",
                    attribute_value: attributeValueForChild,
                    is_child: "no",
                  };

                  return acc;
                },
                {}
              );
            } else {
              attributeChildAtts = attribute?.childAtts?.reduce(
                (acc, childAtt) => {
                  let attributeValueForChild = childAtt?.value;

                  // If the fieldtype is checkbox_multiple_choice or dropdodown_multiple_choice, convert the value to a comma-separated string
                  if (
                    childAtt?.fieldtype === "checkbox_multiple_choice" ||
                    childAtt?.fieldtype === "dropdodown_multiple_choice" ||
                    childAtt?.fieldtype === "users_list" ||
                    childAtt?.fieldtype === "group_list"
                  ) {
                    attributeValueForChild = Array?.isArray(childAtt?.value)
                      ? childAtt?.value.join(",")
                      : childAtt?.value;
                  }

                  if (childAtt?.fieldtype === "checkbox_single_choice") {
                    attributeValueForChild = String(attribute?.value);
                  }

                  // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

                  if (childAtt?.fieldtype === "date" && childAtt?.value !== "") {
                    const date = dayjs(childAtt?.value)?.format("YYYY-MM-DD");
                    attributeValueForChild = date;
                  }
                  if (childAtt?.fieldtype === "time" && childAtt?.value !== "") {
                    const time = dayjs(childAtt?.value, "HH:mm A")?.format(
                      "HH:mm A"
                    );
                    attributeValueForChild = time;
                  }
                  if (childAtt?.fieldtype === "date_time" && childAtt?.value !== "") {
                    const dateTime = dayjs(childAtt?.value)?.format(
                      "YYYY-MM-DDTHH:mm:ss.SSSZ"
                    );
                    attributeValueForChild = dateTime;
                  }
                  if (childAtt?.fieldtype === "date_range") {
                    const startDate = moment(childAtt?.value?.startDate);
                    const endDate = moment(childAtt?.value?.endDate);
                    const formattedStartDate = startDate?.format("YYYY-MM-DD");
                    const formattedEndDate = endDate?.format("YYYY-MM-DD");
                    // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
                    attributeValueForChild = `${formattedStartDate} - ${formattedEndDate}`;
                  }

                  if (childAtt?.fieldtype === "image") {
                    if (
                      selectedImageFileForAtt instanceof Blob ||
                      selectedImageFileForAtt instanceof File
                    ) {
                      attributeValueForChild = "";
                    } else {
                      attributeValueForChild = extractFileName(childAtt.value);
                    }
                  }

                  if (childAtt?.fieldtype === "file") {
                    if (
                      selectedFileForAtt instanceof Blob ||
                      selectedFileForAtt instanceof File
                    ) {
                      attributeValueForChild = "";
                    } else {
                      attributeValueForChild = extractFileName(childAtt.value);
                    }
                  }

                  if (childAtt?.is_repeatable == true) {
                    attributeValueForChild = JSON?.stringify(childAtt?.value);
                  }

                  acc[childAtt?.nameValue] = {
                    parentAttId: attribute?.id,
                    attribute_id: childAtt?.attribute_id,
                    attribute_core_name: childAtt?.nameValue,
                    attribute_data_type:
                      childAtt?.fieldtype === "checkbox_multiple_choice" ||
                        childAtt?.fieldtype === "dropdodown_multiple_choice"
                        ? "array"
                        : childAtt?.fieldtype === "file"
                          ? "file"
                          : childAtt?.fieldtype === "image"
                            ? "image"
                            : "string",
                    attribute_value: attributeValueForChild,
                    is_child: "no",
                  };

                  return acc;
                },
                {}
              );
            }
          }

          acc[attribute?.nameValue] = {
            attribute_id: attribute?.attribute_id,
            attribute_core_name: attribute?.nameValue,
            attribute_data_type:
              attribute?.fieldtype === "checkbox_multiple_choice" ||
                attribute?.fieldtype === "dropdodown_multiple_choice" ||
                attribute?.fieldtype === "link_object"
                ? "array"
                : attribute?.fieldtype === "file"
                  ? "file"
                  : attribute?.fieldtype === "image"
                    ? "image"
                    : "string",
            attribute_value: attributeValue,
            is_child: attributeIsChild,
            childAtts: attributeChildAtts,
          };

          return acc;
        },
        {}
      );

      const categoryData = transformSelectedCategories(selectedCategories, getAllCategories);
      formData.append("attribute_data", JSON.stringify([transformedAttributesData]));

      formData.append("category_data", JSON.stringify(categoryData))

      if (id) {
        formData.append("id", id);
      }

      if (
        (fileAttribute?.value != "" && selectedFileForAtt instanceof Blob) ||
        selectedFileForAtt instanceof File
      ) {
        formData.append(`${fileAttribute?.nameValue}`, selectedFileForAtt);
      }

      if (
        (imageAttribute?.value != "" &&
          selectedImageFileForAtt instanceof Blob) ||
        selectedImageFileForAtt instanceof File
      ) {
        formData.append(
          `${imageAttribute?.nameValue}`,
          selectedImageFileForAtt
        );
      }

      if (id) {
        try {
          setLoading(true);
          const response = await api.post(`admin/user/update/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userToken}`,
            },
          });
          if (response?.success == true) {
            setLoading(false);
            setIsSuccessMessage(true);
            setSuccessMessage(response?.message);
            Toast.success(response?.message);
            localStorage.setItem("last-redirect-page", `/users`);
            navigate("/users");
          } else if (response?.success == false) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage([...warningMessage, response?.message]);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);
          if (err?.response?.status == 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status == 422) {
            setLoading(false);
            setIsWarningMessage(true);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));

          }
          if (err?.response?.status == 429) {
            Toast.error("Too many attemps wait for 2 min.")
          }
        }
      } else {
        try {
          setLoading(true);
          const response = await api.post("admin/user/store", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userToken}`,
            },
          });
          if (response?.success == true) {
            setLoading(false);
            setIsSuccessMessage(true);
            setSuccessMessage(response?.message);
            Toast.success(response?.message);
            localStorage.setItem("last-redirect-page", `/users`);
            navigate("/users");
          } else if (response?.success == false) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage([...warningMessage, response?.message]);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);
          if (err?.response?.status == 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status == 422) {
            setLoading(false);
            setIsWarningMessage(true);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));

          }
          if (err?.response?.status == 429) {
            Toast.error("Too many attemps wait for 2 min.")
          }
        }
      }
    }
  };

  const fetchAccountTypes = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/account-type-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setAccountTypeList(response?.response);
      setLoading(false);
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchGroupList = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/group/enable`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let groups = response?.response?.map((data) => {
          return {
            value: data?.id,
            label: data?.title,
          };
        });
        setGroupList(groups);
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchAllUser = async () => {
    setLoading(true);

    try {
      const response = await api.get(`users-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let users = response?.response?.map((user) => ({
          value: user?.id,
          label: user?.name,
        }));

        setGetAllUsers(users);
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchGroupDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/user/show/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let childAttData;

        const contactDetailsData = JSON.parse(
          response?.response?.contact_details
        );
        const categories = response?.response?.category

        const contentData = await Promise?.all(
          response?.response?.attributes?.map(async (data) => {
            let contentValue = data?.value;

            if (data?.fieldtype == "date" && data?.value !== "" && data?.is_repeatable == false) {
              contentValue = dayjs(data?.value);
            }

            if (data?.fieldtype == "date" && data?.value == "" && data?.is_repeatable == false) {
              contentValue = "";
            }

            if (data?.fieldtype == "date_time" && data?.value !== "" && data?.is_repeatable == false) {
              contentValue = dayjs(data?.value);
            }

            if (data?.fieldtype == "date_time" && data?.value == "" && data?.is_repeatable == false) {
              contentValue = "";
            }

            if (data?.fieldtype == "time" && data?.value !== "" && data?.is_repeatable == false) {
              contentValue = dayjs(data?.value, "HH:mm A");
            }

            if (data?.fieldtype == "time" && data?.value == "" && data?.is_repeatable == false) {
              contentValue = "";
            }

            if (data?.fieldtype === "image" && data?.is_repeatable == false) {
              setSelectedImageFileForAtt(data?.value);
            }

            if (data?.fieldtype === "file" && data?.is_repeatable == false) {
              // Fetch the file from the URL and create a File object
              setSelectedFileForAtt(data?.value);
            }

            if (data?.fieldtype === "dropdodown_single_choice" && data?.is_repeatable == false) {
              contentValue = parseInt(data?.value);
            }

            if (data?.fieldtype === "checkbox_single_choice" && data?.is_repeatable == false) {
              contentValue = data?.value == "true" ? true : false;
            }

            if (data?.fieldtype === "checkbox_multiple_choice" && data?.is_repeatable == false) {
              contentValue = data?.value?.map((value) => {
                return value; // Using unary plus operator to convert string to number
              });
            }

            if (data?.fieldtype === "dropdodown_multiple_choice" && data?.is_repeatable == false) {
              contentValue = data?.value?.map((value) => {
                return value; // Using unary plus operator to convert string to number
              });
            }

            if (data?.fieldtype === "users_list" && data?.is_repeatable == false) {
              const transformedValue = data.value.map((item) => {
                const parsedItem = parseInt(item, 10);
                return isNaN(parsedItem) ? item : parsedItem;
              });
              contentValue = transformedValue;
            }


            if (data?.is_repeatable == true) {
              if (data?.fieldtype === "link_object") {
                if (typeof data?.value === "string" && data?.value !== "") {
                  const jsonString = data?.value;
                  console.log("jsonString:::", jsonString);
                  const cleanString = jsonString?.replace(/value\s*:\s*/g, "");
                  console.log("cleanString:::", cleanString);

                  // Parse the cleaned string into an array
                  const resultArray = JSON?.parse(cleanString);
                  console.log("resultArray1::", resultArray);

                  contentValue = resultArray;
                  if (resultArray?.length > 0) {
                    setAdditionalTextFields((prevFields) => [
                      ...prevFields,
                      ...resultArray,
                    ]);
                  }
                }
              } else if (data?.fieldtype === "time") {
                if (Array.isArray(data?.value) && data?.value.length > 0) {
                  const timeValues = data.value.map((item) => ({
                    ...item,
                    value: dayjs(item?.value, "HH:mm A"),
                  }));
                  console.log("timeValues::", timeValues);
                  setAdditionalTextFields((prevFields) => [
                    ...prevFields,
                    ...timeValues,
                  ]);
                  contentValue = timeValues;
                }
              } else {
                // Remove unwanted characters
                if (typeof data?.value === "string" && data?.value !== "") {
                  const jsonString = data?.value;
                  console.log("jsonString:::", jsonString);
                  const cleanString = jsonString?.replace(/value\s*:\s*/g, "");
                  console.log("cleanString:::", cleanString);

                  // Parse the cleaned string into an array
                  const resultArray = JSON?.parse(cleanString);

                  contentValue = resultArray;

                  if (resultArray?.length > 0) {
                    setAdditionalTextFields((prevFields) => [
                      ...prevFields,
                      ...resultArray,
                    ]);
                  }
                }

              }
            }

            if (data?.fieldtype === "date_range" && data?.is_repeatable == false) {
              const dateString = data?.value;
              const [startDateString, endDateString] = dateString.split(" - ");

              const startDate = moment(startDateString, "YYYY-MM-DD");
              const endDate = moment(endDateString, "YYYY-MM-DD");

              contentValue = {
                startDate: startDate.isValid() ? startDate : null,
                endDate: endDate.isValid() ? endDate : null,
              };
            }
            if (data?.childAtts?.length > 0) {
              const childData = await Promise.all(
                data?.childAtts?.map(async (data) => {
                  let contentValue = data?.value;

                  if (
                    data?.fieldtype == "date" && data?.value !== "" && data?.is_repeatable == false
                  ) {
                    contentValue = dayjs(data?.value);
                  }

                  if (data?.fieldtype == "date" && data?.value == "" && data?.is_repeatable == false) {
                    contentValue = "";
                  }

                  if (
                    data?.fieldtype == "date_time" && data?.value !== "" && data?.is_repeatable == false
                  ) {
                    contentValue = dayjs(data?.value);
                  }

                  if (data?.fieldtype == "date_time" && data?.value == "" && data?.is_repeatable == false) {
                    contentValue = "";
                  }

                  if (data?.fieldtype == "time" && data?.value !== "" && data?.is_repeatable == false) {
                    contentValue = dayjs(data?.value, "HH:mm A");
                  }
                  if (data?.fieldtype == "time" && data?.value == "" && data?.is_repeatable == false) {
                    contentValue = "";
                  }


                  if (data?.fieldtype === "image" && data?.is_repeatable == false) {
                    setSelectedImageFileForAtt(data?.value);
                  }

                  if (data?.fieldtype === "file" && data?.is_repeatable == false) {
                    // Fetch the file from the URL and create a File object
                    setSelectedFileForAtt(data?.value);
                  }

                  if (data?.fieldtype === "dropdodown_single_choice" && data?.is_repeatable == false) {
                    contentValue = parseInt(data?.value) || data?.value;
                  }

                  if (data?.fieldtype === "checkbox_single_choice" && data?.is_repeatable == false) {
                    contentValue = data?.value == "true" ? true : false;
                  }

                  if (data?.fieldtype === "checkbox_multiple_choice" && data?.is_repeatable == false) {
                    contentValue = data?.value?.map((value) => {
                      return value; // Using unary plus operator to convert string to number
                    });
                  }

                  if (data?.fieldtype === "users_list" && data?.is_repeatable == false) {
                    if (data?.value?.length > 0) {
                      const transformedValue = data.value.map((item) => {
                        const parsedItem = parseInt(item, 10);
                        return isNaN(parsedItem) ? item : parsedItem;
                      });
                      contentValue = transformedValue;
                    } else {
                      contentValue = "";
                    }
                  }

                  if (data?.fieldtype === "dropdodown_multiple_choice" && data?.is_repeatable == false) {
                    if (data?.value?.length > 0) {
                      contentValue = data?.value?.map((value) => {
                        return value; // Using unary plus operator to convert string to number
                      });
                    } else {
                      contentValue = "";
                    }
                  }

                  if (data?.is_repeatable == true) {
                    // Remove unwanted characters
                    const jsonString = data?.value;
                    const cleanString = jsonString?.replace(/value\s*:\s*/g, "");

                    // Parse the cleaned string into an array
                    const resultArray = JSON?.parse(cleanString);

                    contentValue = resultArray;

                    if (resultArray?.length > 0) {
                      setAdditionalTextFields((prevFields) => [
                        ...prevFields,
                        ...resultArray,
                      ]);
                    }
                  }


                  if (data?.fieldtype === "date_range" && data?.is_repeatable == false) {
                    const dateString = data?.value;
                    const [startDateString, endDateString] =
                      dateString.split(" - ");

                    const startDate = moment(startDateString, "YYYY-MM-DD");
                    const endDate = moment(endDateString, "YYYY-MM-DD");

                    contentValue = {
                      startDate: startDate.isValid() ? startDate : null,
                      endDate: endDate.isValid() ? endDate : null,
                    };
                  }

                  if (data?.childAtts?.length > 0) {
                    const childData = await Promise?.all(
                      data?.childAtts?.map(async (data) => {
                        if (data?.is_repeatable === true) {
                          try {
                            // Remove unwanted characters
                            const jsonString = data?.value;
                            let properJsonString = jsonString;

                            // Ensure the string starts and ends with quotes
                            if (properJsonString?.startsWith('"') && properJsonString?.endsWith('"')) {
                              properJsonString = properJsonString?.slice(1, -1);
                            }

                            // Replace escaped quotes
                            properJsonString = properJsonString?.replace(/\\"/g, '"');

                            // Parse the cleaned string into an array
                            const resultArray = JSON?.parse(properJsonString);

                            // Check if the resultArray is actually an array
                            if (Array.isArray(resultArray) && resultArray.length > 0) {
                              setAdditionalTextFieldsForNestedChildAtt1((prevFields) => [
                                ...prevFields,
                                ...resultArray,
                              ]);
                            } else {
                              console.error("Parsed JSON is not an array or is empty:", resultArray);
                            }
                          } catch (error) {
                            console.error("Error parsing JSON:", error, data?.value);
                          }
                        }
                      })
                    );
                  }

                  return {
                    attribute_id: data?.attribute_id,
                    fieldtype: data?.fieldtype,
                    id: data?.id,
                    is_mandatory: data?.is_mandatory,
                    is_repeatable: data?.is_repeatable,
                    label: data?.label,
                    nameValue: data?.nameValue,
                    options: data?.options,
                    section_id: data?.section_id,
                    value: contentValue,
                    childAtts: data?.childAtts,
                  };
                })
              );
              childAttData = childData;
            }

            return {
              attribute_id: data?.attribute_id,
              fieldtype: data?.fieldtype,
              id: data?.id,
              is_mandatory: data?.is_mandatory,
              is_repeatable: data?.is_repeatable,
              label: data?.label,
              nameValue: data?.nameValue,
              options: data?.options,
              section_id: data?.section_id,
              value: contentValue,
              childAtts: childAttData,
            };
          })
        );

        const allCategories = transformCategoryData(categories);
        setGetAllCategories(allCategories);

        const transformedData = transformData(response?.response?.category);
        setSelectedCategories(transformedData)

        let userData = {
          profileAccountType: response?.response?.account_type_id,
          profileFirstName: response?.response?.name,
          profileLastName: response?.response?.last_name,
          profileJobTitle: response?.response?.job_title,
          profileBio: response?.response?.bio,
          profileGroups: response?.response?.group_member?.map(
            (data) => data?.group_id
          ),
          contactDetails:
            contactDetailsData?.length > 0
              ? contactDetailsData
              : [
                { id: 1, service: "email", value: "" },
                { id: 2, service: "phone", value: "" },
              ],
          userStatus: response?.response?.status === "enable" ? true : false,
          image: response?.response?.thumbnail,
          authenticationUserName: response?.response?.username,
          authenticationNextLogin:
            response?.response?.force_pwd_changed === "enable" ? true : false,
          authenticationLoginDetails:
            response?.response?.email_login_detail === "enable" ? true : false,
          organisationManagers: response?.response?.user_managers?.map(
            (data) => data?.manager_user_id
          ),
          organisationDirectReports:
            response?.response?.direct_report_user?.map(
              (data) => data?.user_id
            ),
        };
        setGetUserData(userData);
        setSelectedImageFile(userData?.image);
        setAttributes(contentData);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/users`);

        navigate("/users");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);

        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);

        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        setWarningMessage(err?.response?.data?.message);
        Toast.warning(err?.response?.data?.message);
        localStorage.setItem("last-redirect-page", `/users`);

        navigate("/users");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const fetchAllChangeLogs = async (page) => {
    try {
      setLoading(true);
      const response = await api.get(
        `admin/user/changelog/${id}?per_page=${rowsPerPage}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        response.response.data = response?.response?.data.map((log) => ({
          ...log,
          changed_at: dateWithTime(log.changed_at, true, true, true) || log.changed_at,
        }));
        setGetAllChangeLogs(response?.response);

        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const handleChangeLogCsvDownload = async () => {
    try {
      setLoading(true);

      const result = await exportXlsx(`admin/user/changelog/${id}?search=${search}&pagination=no`, 'user_change_log');
      if (!result) {
        Toast.error("Failed to download file");
      }

    } catch (error) {
      console.error("Error while downloading csv changelog::", error);
      Toast.error("Failed to download file");
    } finally {
      setLoading(false);
    }
  }

  const [checkExistData, setcheckExistData] = useState()

  const handleCheckExistApi = async (data) => {
    try {
      const response = await api.post("/admin/user/check-exist", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (data?.column === "username") {
        setUserNameExist(response);
      } else if (data?.column === "email") {
        setEmailExist(response);
      }
      return response;
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      if (checkExistData && checkExistData?.search.length > 3) {
        handleCheckExistApi(checkExistData)
      }
    }, 1000)

    return () => clearTimeout(getData)

  }, [checkExistData])

  const checkExist = async (search, column) => {
    let data = {
      search: search,
      column: column,
    };
    setcheckExistData(data);
  };

  const fetchElementList = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/user/attribute-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let acknowledgementQuestionsArray = [];

        const categories = response?.response?.category

        setAttributes(response?.response?.attributes);

        const allCategories = transformCategoryData(categories);

        setGetAllCategories(allCategories)
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  useEffect(() => {
    const apiCall = async () => {
      if (id) {
        await fetchAccountTypes();
        await fetchGroupList();
        await fetchAllUser();
        await fetchGroupDetails();
        await fetchAllChangeLogs(page + 1);
        setTabValue(0);
      } else {
        await fetchAccountTypes();
        await fetchGroupList();
        await fetchAllUser();
        await fetchElementList()
        setTabValue(0);
      }
    };
    apiCall();
  }, [id]);

  useEffect(() => {
    setWarningMessage(newWarningMessages);
  }, [newWarningMessages]);

  useEffect(() => {
    if (search.length >= 3 && istype == true) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        fetchAllChangeLogs(page + 1);
      }, debounceTimeout);
    }
    else if (search === "" && istype == true) {
      fetchAllChangeLogs(page + 1);
    }
    else {
      clearTimeout(debounceTimer);
    }

    return () => clearTimeout(debounceTimer);
  }, [search]);

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage == true && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage == true && (
            <>
              {warningMessage?.map((message, index) => (
                <>
                  <WarningAlert
                    message={message}
                    onClose={() => handleCloseWarningMessage(index)}
                  />
                </>
              ))}
            </>
          )}
          <Box className="">
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  underline="hover"
                  key="1"
                  color="inherit"
                  href="/"
                  component="h3"
                >
                  Admin
                </Link>
                <Typography
                  key="3"
                  component="h3"
                  color="inherit"
                  className="cursor-pointer"
                  onClick={() => {
                    localStorage.setItem("last-redirect-page", `/users`);

                    navigate("/users");
                  }}
                >
                  Users
                </Typography>
                <Typography key="3" component="h3" color="text.primary">
                  {id ? "Edit" : "Add"} User
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Box>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={initialValues}
            validationSchema={
              id ? EditNewUsersvalidationSchema : addNewUsersvalidationSchema
            }
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({
              values,
              setFieldValue,
              touched,
              errors,
              handleSubmit,
              handleBlur,
              isValid,
              dirty,
              handleChange,
            }) => {
              let profileDetailsErrors = {};
              let contactDetailsErrors = {};
              let authenticationErrors = {};
              let organisationErrors = {};
              let messages = [];

              if (Object.keys(errors).length > 0) {
                for (let key in errors) {
                  if (key.startsWith("profile")) {
                    profileDetailsErrors[key] = errors[key];
                  } else if (key.startsWith("contact")) {
                    contactDetailsErrors[key] = errors[key];
                  } else if (key.startsWith("authentication")) {
                    authenticationErrors[key] = errors[key];
                  } else if (key.startsWith("organisation")) {
                    organisationErrors[key] = errors[key];
                  }
                }
              }

              const errorTypes = [
                {
                  errors: profileDetailsErrors,
                  errorMessage:
                    "Please enter required field for Profile Details",
                  setErrorFlag: setIsErrorOnProfileTab,
                },
                {
                  errors: contactDetailsErrors,
                  errorMessage:
                    "Please enter required field for Contact Details",
                  setErrorFlag: setIsErrorOnContactTab,
                },
                {
                  errors: authenticationErrors,
                  errorMessage:
                    "Please enter required field for Authentication",
                  setErrorFlag: setIsErrorOnAuthenticationTab,
                },
                {
                  errors: organisationErrors,
                  errorMessage: "Please enter required field for Organisation",
                  setErrorFlag: setIsErrorOnOrganisationTab,
                },
              ];

              errorTypes.forEach(({ errors, errorMessage, setErrorFlag }) => {
                if (Object.keys(errors).length > 0) {
                  if (showError == true) {
                    setIsWarningMessage(true);
                    messages.push(errorMessage);
                    setErrorFlag(true);
                  }
                } else {
                  setErrorFlag(false);
                }
              });

              newWarningMessages = messages;

              const handleAddOption = () => {
                const newId = values?.contactDetails?.length + 1;
                const newRow = {
                  id: newId,
                  service: "",
                  value: "",
                };
                setContactData([...contactData, newRow]);
                setFieldValue("contactDetails", [
                  ...values?.contactDetails,
                  newRow,
                ]);
              };

              const handleSelection = (id, selectedOption) => {
                let updatedRowsData;

                updatedRowsData = values?.contactDetails.map((details) => {
                  if (details.id === id) {
                    return {
                      ...details,
                      service: selectedOption,
                    };
                  }
                  return details;
                });

                setFieldValue("contactDetails", updatedRowsData);
              };

              const handleSelectionForValue = (id, selectedOption) => {
                let updatedRowsData;

                updatedRowsData = values?.contactDetails.map((details) => {
                  if (details.id === id) {
                    return {
                      ...details,
                      value: selectedOption,
                    };
                  }
                  return details;
                });

                setFieldValue("contactDetails", updatedRowsData);
              };

              return (
                <Form>
                  <Row className="mt-1">
                    <Col lg="9" md="7">
                      <Stack direction="row" className="gap-30">
                        <Accordion
                          defaultActiveKey={["0", "1", "2", "3"]}
                          alwaysOpen
                          className="w-100"
                        >
                          {" "}
                          <Accordion.Item
                            eventKey="0"
                            className="bg-white card-wrapper w-100"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text">
                              Content
                            </Accordion.Header>
                            <Accordion.Body className="add-user-content-p-0">
                              <Box
                                sx={{
                                  borderBottom: 1,
                                  borderColor: "divider",
                                }}
                                className="box-attribute"
                              >
                                <Tabs
                                  value={tabValue}
                                  onChange={handleChangeForTab}
                                  aria-label="basic tabs example"
                                  // variant={isTablet && "scrollable"}
                                  variant="scrollable"
                                  className={
                                    isErrorOnProfileTab === true ||
                                      isErrorOnContactTab === true ||
                                      isErrorOnAuthenticationTab === true ||
                                      isErroronOrganisationTab
                                      ? "tabs"
                                      : ""
                                  }
                                  TabIndicatorProps={{
                                    style: {
                                      backgroundColor:
                                        (isErrorOnProfileTab === true &&
                                          tabValue == 0) ||
                                          (isErrorOnContactTab === true &&
                                            tabValue == 1) ||
                                          (isErrorOnAuthenticationTab === true &&
                                            tabValue == 2) ||
                                          (isErroronOrganisationTab === true &&
                                            tabValue == 3)
                                          ? "red"
                                          : "#1976d2",
                                    },
                                  }}
                                >
                                  <Tab
                                    icon={<FontAwesomeIcon icon={faUser} />}
                                    label="Profile Details"
                                    iconPosition="start"
                                    {...a11yProps1(0)}
                                  />

                                  <Tab
                                    label="Contact Details"
                                    icon={<FontAwesomeIcon icon={faPhone} />}
                                    iconPosition="start"
                                    {...a11yProps2(1)}
                                  />
                                  {values?.profileAccountType == "1" && (
                                    <Tab
                                      label="Authentication"
                                      {...a11yProps3(2)}
                                      icon={
                                        <FontAwesomeIcon
                                          icon={faShieldHalved}
                                        />
                                      }
                                      iconPosition="start"
                                    />
                                  )}

                                  <Tab
                                    label="Organisation"
                                    {...a11yProps4(values?.profileAccountType == "1" ? 3 : 2)}
                                    icon={<FontAwesomeIcon icon={faBuilding} />}
                                    iconPosition="start"
                                  />
                                  <Tab
                                    label="Classification"
                                    icon={<FontAwesomeIcon icon={faFolder} />}
                                    iconPosition="start"
                                    {...a11yProps5(values?.profileAccountType == "1" ? 4 : 3)}
                                  />
                                </Tabs>
                              </Box>
                              <CustomTabPanel
                                value={tabValue}
                                index={0}
                                errorTab={errorTab}
                              >
                                <Row>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text font-inter">
                                      Account Type
                                      <span className="required">*</span>
                                    </Typography>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="profileAccountType"
                                      className="mt-1"
                                      value={values?.profileAccountType}
                                      onChange={handleChange}
                                    >
                                      {accountTypeList?.map((account) => (
                                        <FormControlLabel
                                          key={account?.id}
                                          value={account?.id}
                                          control={
                                            <Radio
                                              size="small"
                                              checked={
                                                values?.profileAccountType ==
                                                account?.id
                                              }
                                              value={account?.id}
                                            />
                                          }
                                          label={account?.name}
                                          className="font-inter"
                                        />
                                      ))}
                                    </RadioGroup>
                                    {errors?.profileAccountType &&
                                      touched.profileAccountType && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.profileAccountType}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="6">
                                    <Typography className="fw-400 dark-text">
                                      First Name
                                      <span className="required">*</span>
                                    </Typography>
                                    <TextField
                                      className="input-field mt-2"
                                      fullWidth
                                      id="outlined-basic username"
                                      variant="outlined"
                                      size="small"
                                      name="profileFirstName"
                                      placeholder="Enter first name..."
                                      onChange={(event) => {
                                        setFieldValue(
                                          "profileFirstName",
                                          event.target.value
                                        );
                                      }}
                                      value={values?.profileFirstName}
                                      helperText={
                                        touched.profileFirstName &&
                                        errors.profileFirstName
                                      }
                                      error={
                                        touched.profileFirstName &&
                                        Boolean(errors.profileFirstName)
                                      }
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <Typography className="fw-400 dark-text">
                                      Last Name
                                      <span className="required">*</span>
                                    </Typography>
                                    <TextField
                                      className="input-field mt-2"
                                      fullWidth
                                      id="outlined-basic username"
                                      variant="outlined"
                                      size="small"
                                      name="profileLastName"
                                      placeholder="Enter last name..."
                                      onChange={(event) => {
                                        setFieldValue(
                                          "profileLastName",
                                          event.target.value
                                        );
                                      }}
                                      value={values?.profileLastName}
                                      helperText={
                                        touched.profileLastName &&
                                        errors.profileLastName
                                      }
                                      error={
                                        touched.profileLastName &&
                                        Boolean(errors.profileLastName)
                                      }
                                    />
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Job Title
                                      <span className="required">*</span>
                                    </Typography>
                                    <TextField
                                      className="input-field mt-2"
                                      fullWidth
                                      id="outlined-basic username"
                                      variant="outlined"
                                      size="small"
                                      name="profileJobTitle"
                                      placeholder="Enter job title..."
                                      onChange={(event) => {
                                        setFieldValue(
                                          "profileJobTitle",
                                          event.target.value
                                        );
                                      }}
                                      value={values?.profileJobTitle}
                                      helperText={
                                        touched.profileJobTitle &&
                                        errors.profileJobTitle
                                      }
                                      error={
                                        touched.profileJobTitle &&
                                        Boolean(errors.profileJobTitle)
                                      }
                                    />
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Bio
                                      <span className="required">*</span>
                                    </Typography>
                                    <TextField
                                      className="input-field mt-2"
                                      fullWidth
                                      id="outlined-basic short summary"
                                      variant="outlined"
                                      multiline
                                      maxRows={4}
                                      name="profileBio"
                                      placeholder="Enter bio here..."
                                      value={values?.profileBio}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "profileBio",
                                          event.target.value
                                        );
                                      }}
                                      helperText={
                                        touched.profileBio && errors.profileBio
                                      }
                                      error={
                                        touched.profileBio &&
                                        Boolean(errors.profileBio)
                                      }
                                    />
                                  </Col>
                                </Row>
                              </CustomTabPanel>
                              <CustomTabPanel
                                value={tabValue}
                                index={1}
                                errorTab={errorTab}
                              >
                                <Row>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Contact Details
                                      <span className="required">*</span>
                                    </Typography>
                                    <TableContainer className="z-0">
                                      <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        stickyHeader
                                      >
                                        <TableHeaderForSorting
                                          columns={columns_users_contact_header}
                                        />
                                        <TableBody
                                          style={{
                                            width: "100%",
                                            top: 0,
                                            left: 0,
                                          }}
                                        >
                                          <FieldArray
                                            name="contactDetails"
                                            render={({ push, remove }) => (
                                              <>
                                                {values?.contactDetails?.map(
                                                  (row, index) => (
                                                    <>
                                                      <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                        className="cursor-pointer tbl-row-attribute-row"
                                                        sx={{
                                                          verticalAlign: "top",
                                                        }}
                                                      >
                                                        <TableCell
                                                          align="left"
                                                          className={`tbl-cell-attribute`}
                                                          style={{
                                                            width: "50%",
                                                            top: 0,
                                                            left: 0,
                                                          }}
                                                        >
                                                          <Field
                                                            name={`contactDetails[${index}].service`}
                                                            render={({
                                                              field,
                                                            }) => (
                                                              <>
                                                                <Select
                                                                  closeMenuOnSelect={
                                                                    true
                                                                  }
                                                                  options={servicesSections.filter(
                                                                    (option) =>
                                                                      !values?.contactDetails.some(
                                                                        (
                                                                          data
                                                                        ) =>
                                                                          data.service ===
                                                                          option.value
                                                                      )
                                                                  )}
                                                                  menuPlacement="top"
                                                                  name="users"
                                                                  placeholder="Select service..."
                                                                  menuPosition="fixed"
                                                                  className="select-service z-2"
                                                                  onChange={(
                                                                    selectedOption
                                                                  ) =>
                                                                    handleSelection(
                                                                      row?.id,
                                                                      selectedOption?.value
                                                                    )
                                                                  }
                                                                  isDisabled={
                                                                    row?.service ===
                                                                    "email" ||
                                                                    row?.service ===
                                                                    "phone"
                                                                  }
                                                                  value={servicesSections.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                      row?.service
                                                                  )}
                                                                  classNamePrefix="select"
                                                                  styles={{
                                                                    option: (
                                                                      provided,
                                                                      state
                                                                    ) => ({
                                                                      ...provided,
                                                                      backgroundColor:
                                                                        state.isSelected
                                                                          ? "#660c605c"
                                                                          : provided.backgroundColor,
                                                                      color:
                                                                        state.isSelected
                                                                          ? "var(--dark-color)"
                                                                          : provided.color,
                                                                    }),
                                                                  }}
                                                                />
                                                                <div>
                                                                  <ErrorMessage
                                                                    name={`contactDetails[${index}].service`}
                                                                    component="p"
                                                                    className="error-msg"
                                                                  />
                                                                </div>
                                                              </>
                                                            )}
                                                          />
                                                        </TableCell>
                                                        <TableCell
                                                          align="left"
                                                          className={` tbl-cell-attribute`}
                                                          style={{
                                                            width: "50%",
                                                            top: 0,
                                                            left: 0,
                                                          }}
                                                        >
                                                          <Field
                                                            name={`contactDetails[${index}].value`}
                                                            render={({
                                                              field,
                                                              form,
                                                            }) => (
                                                              <>
                                                                <TextField
                                                                  className="input-field bg-white"
                                                                  fullWidth
                                                                  variant="outlined"
                                                                  size="small"
                                                                  placeholder="Enter value here..."
                                                                  value={
                                                                    row?.value
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    e.preventDefault();

                                                                    if (
                                                                      row?.service ==
                                                                      "email" &&
                                                                      emailRegex.test(
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    ) {
                                                                      emailExists =
                                                                        checkExist(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          "email"
                                                                        );
                                                                    }

                                                                    handleSelectionForValue(
                                                                      row?.id,
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                                {emailExist
                                                                  ?.response
                                                                  ?.is_exists ==
                                                                  true &&
                                                                  emailRegex.test(
                                                                    row?.value
                                                                  ) &&
                                                                  getUserData
                                                                    ?.contactDetails[
                                                                    index
                                                                  ].value !==
                                                                  row?.value && (
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          "#d32f2f",
                                                                      }}
                                                                      className="error-msg"
                                                                    >
                                                                      Email
                                                                      alreday
                                                                      Exist!
                                                                    </p>
                                                                  )}

                                                                <div>
                                                                  <ErrorMessage
                                                                    name={`contactDetails[${index}].value`}
                                                                    component="p"
                                                                    className="error-msg"
                                                                  />
                                                                </div>
                                                              </>
                                                            )}
                                                          />
                                                        </TableCell>
                                                      </TableRow>
                                                      <TableRow className="extra-row"></TableRow>
                                                    </>
                                                  )
                                                )}
                                              </>
                                            )}
                                          />
                                        </TableBody>
                                      </Table>
                                    </TableContainer>

                                    {values?.contactDetails?.length <
                                      servicesSections?.length && (
                                        <div className="mt-3 d-flex flex-wrap justify-content-end">
                                          <Button
                                            variant="outlined"
                                            startIcon={<AddIcon />}
                                            className="add-option-btn"
                                            onClick={handleAddOption}
                                          >
                                            Add Option
                                          </Button>
                                        </div>
                                      )}
                                  </Col>
                                </Row>
                              </CustomTabPanel>

                              {values?.profileAccountType == "1" && (
                                <CustomTabPanel
                                  value={tabValue}
                                  index={2}
                                  errorTab={errorTab}
                                >
                                  <Row>
                                    <Col lg="12">
                                      <Typography className="fw-400 dark-text">
                                        Username
                                        <span className="required">*</span>
                                      </Typography>
                                      <TextField
                                        className="input-field mt-2"
                                        fullWidth
                                        id="outlined-basic username"
                                        variant="outlined"
                                        size="small"
                                        name="authenticationUserName"
                                        placeholder="Enter username here..."
                                        onChange={async (event) => {
                                          setFieldValue(
                                            "authenticationUserName",
                                            event.target.value
                                          );
                                          if (event?.target?.value !== "") {
                                            usernameExist = await checkExist(
                                              event.target.value,
                                              "username"
                                            );
                                          }
                                        }}
                                        value={values?.authenticationUserName}
                                      />
                                      {userNameExist?.response?.is_exists ==
                                        true &&
                                        usernameRegex.test(
                                          values.authenticationUserName
                                        ) &&
                                        getUserData?.authenticationUserName !==
                                        values.authenticationUserName && (
                                          <p
                                            style={{
                                              color: "#d32f2f",
                                            }}
                                            className="error-msg"
                                          >
                                            Username is already exist!
                                          </p>
                                        )}

                                      {errors?.authenticationUserName &&
                                        touched.authenticationUserName && (
                                          <p
                                            style={{
                                              color: "#d32f2f",
                                            }}
                                            className="error-msg"
                                          >
                                            {errors?.authenticationUserName}
                                          </p>
                                        )}
                                    </Col>
                                    <Col lg="6">
                                      <Typography className="fw-400 dark-text">
                                        Password
                                        <span className="required">*</span>
                                      </Typography>
                                      <OutlinedInput
                                        className="input-field mt-2"
                                        fullWidth
                                        id="outlined-basic username"
                                        variant="outlined"
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        size="small"
                                        name="authenticationPassword"
                                        placeholder="Enter password here..."
                                        onChange={(event) => {
                                          setFieldValue(
                                            "authenticationPassword",
                                            event.target.value
                                          );
                                        }}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              onMouseDown={
                                                handleMouseDownPassword
                                              }
                                              edge="end"
                                            >
                                              {showPassword ? (
                                                <VisibilityOff />
                                              ) : (
                                                <Visibility />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        value={values?.authenticationPassword}
                                      />
                                      {errors?.authenticationPassword &&
                                        touched.authenticationPassword && (
                                          <p
                                            style={{
                                              color: "#d32f2f",
                                            }}
                                            className="error-msg"
                                          >
                                            {errors?.authenticationPassword}
                                          </p>
                                        )}
                                    </Col>
                                    <Col lg="6">
                                      <Typography className="fw-400 dark-text">
                                        Confirm Password
                                        <span className="required">*</span>
                                      </Typography>
                                      <OutlinedInput
                                        className="input-field mt-2"
                                        fullWidth
                                        id="outlined-basic username"
                                        variant="outlined"
                                        size="small"
                                        type={
                                          showConfirmedPassword
                                            ? "text"
                                            : "password"
                                        }
                                        name="authenticationConfirmPassword"
                                        placeholder="Enter confirm password..."
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={
                                                handleClickShowConfirmedPassword
                                              }
                                              onMouseDown={
                                                handleMouseDownPassword
                                              }
                                              edge="end"
                                            >
                                              {showConfirmedPassword ? (
                                                <VisibilityOff />
                                              ) : (
                                                <Visibility />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        onChange={(event) => {
                                          setFieldValue(
                                            "authenticationConfirmPassword",
                                            event.target.value
                                          );
                                        }}
                                        value={
                                          values?.authenticationConfirmPassword
                                        }
                                      />
                                      {errors?.authenticationConfirmPassword &&
                                        touched.authenticationConfirmPassword && (
                                          <p
                                            style={{
                                              color: "#d32f2f",
                                            }}
                                            className="error-msg"
                                          >
                                            {
                                              errors?.authenticationConfirmPassword
                                            }
                                          </p>
                                        )}
                                    </Col>
                                    <Col lg="12" className="repeatable-col">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values?.authenticationNextLogin ==
                                              true
                                            }
                                            name="authenticationNextLogin"
                                            onChange={handleChange}
                                          />
                                        }
                                        label="User must change password on next login"
                                        className="font-inter"
                                      />
                                    </Col>
                                    <Col lg="12" className="repeatable-col">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values?.authenticationLoginDetails ==
                                              true
                                            }
                                            name="authenticationLoginDetails"
                                            onChange={handleChange}
                                          />
                                        }
                                        label="Email User their login details"
                                        className="font-inter"
                                      />
                                    </Col>
                                  </Row>
                                </CustomTabPanel>
                              )}

                              <CustomTabPanel
                                value={tabValue}
                                index={
                                  values?.profileAccountType == "1" ? 3 : 2
                                }
                                errorTab={errorTab}
                              >
                                <Row>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Manager(s)
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={false}
                                      options={getAllUsers}
                                      isMulti
                                      menuPlacement="bottom"
                                      name="organisationManagers"
                                      placeholder="Select managers..."
                                      menuPosition="fixed"
                                      onChange={(selectedOption) => {
                                        const users = selectedOption.map(
                                          (option) => option.value
                                        );
                                        setFieldValue(
                                          "organisationManagers",
                                          users
                                        );
                                      }}
                                      value={getAllUsers?.filter((option) =>
                                        values?.organisationManagers?.includes(
                                          option?.value
                                        )
                                      )}
                                      className="mt-2"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: state.isSelected
                                            ? "#660c605c"
                                            : provided.backgroundColor,
                                          color: state.isSelected
                                            ? "var(--dark-color)"
                                            : provided.color,
                                        }),
                                        multiValueLabel: (provided, state) => {
                                          return {
                                            ...provided,
                                            color:
                                              state.data?.is_system === 1
                                                ? "rgb(0, 135, 90)"
                                                : "rgb(0, 82, 204)",
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.organisationManagers &&
                                      touched.organisationManagers && (
                                        <p
                                          style={{
                                            color: "#d32f2f",
                                          }}
                                          className="error-msg"
                                        >
                                          {errors?.organisationManagers}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Direct Reports
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={false}
                                      options={getAllUsers}
                                      isMulti
                                      menuPlacement="bottom"
                                      name="organisationDirectReports"
                                      placeholder="Select direct reports..."
                                      menuPosition="fixed"
                                      onChange={(selectedOption) => {
                                        const users = selectedOption.map(
                                          (option) => option.value
                                        );
                                        setFieldValue(
                                          "organisationDirectReports",
                                          users
                                        );
                                      }}
                                      value={getAllUsers?.filter((option) =>
                                        values?.organisationDirectReports?.includes(
                                          option?.value
                                        )
                                      )}
                                      className="mt-2 "
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: state.isSelected
                                            ? "#660c605c"
                                            : provided.backgroundColor,
                                          color: state.isSelected
                                            ? "var(--dark-color)"
                                            : provided.color,
                                        }),
                                        multiValueLabel: (provided, state) => {
                                          return {
                                            ...provided,
                                            color:
                                              state.data?.is_system === 1
                                                ? "rgb(0, 135, 90)"
                                                : "rgb(0, 82, 204)",
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.organisationDirectReports &&
                                      touched.organisationDirectReports && (
                                        <p
                                          style={{
                                            color: "#d32f2f",
                                          }}
                                          className="error-msg"
                                        >
                                          {errors?.organisationDirectReports}
                                        </p>
                                      )}
                                  </Col>
                                </Row>
                              </CustomTabPanel>
                              <CustomTabPanel
                                value={tabValue}
                                index={
                                  values?.profileAccountType == "1" ? 4 : 3
                                }
                              >
                                <Row>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Groups
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={false}
                                      options={groupList}
                                      isMulti
                                      menuPlacement="bottom"
                                      name="profileGroups"
                                      placeholder="Select groups..."
                                      menuPosition="fixed"
                                      onChange={(selectedOption) => {
                                        const users = selectedOption.map(
                                          (option) => option.value
                                        );
                                        setFieldValue("profileGroups", users);
                                      }}
                                      value={groupList?.filter((option) =>
                                        values?.profileGroups?.includes(
                                          option?.value
                                        )
                                      )}
                                      className="mt-2"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: state.isSelected
                                            ? "#660c605c"
                                            : provided.backgroundColor,
                                          color: state.isSelected
                                            ? "var(--dark-color)"
                                            : provided.color,
                                        }),
                                        multiValueLabel: (provided, state) => {
                                          return {
                                            ...provided,
                                            color:
                                              state.data?.is_system === 1
                                                ? "rgb(0, 135, 90)"
                                                : "rgb(0, 82, 204)",
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.profileGroups &&
                                      touched.profileGroups && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.profileGroups}
                                        </p>
                                      )}
                                  </Col>

                                  {getAllCategories?.length > 0 && (
                                    <>
                                      {getAllCategories?.map((category) => (
                                        <>
                                          <Col lg="12" className="">
                                            <Typography className="fw-400 fs-14 dark-text">
                                              {category?.label}
                                            </Typography><Select
                                              closeMenuOnSelect={false}
                                              options={category?.options}
                                              menuPlacement="top"
                                              name="groupManagers"
                                              value={selectedCategories[category.slug] || []}
                                              placeholder={`Select Terms For ${category?.label}...`}
                                              menuPosition="fixed"
                                              isMulti
                                              onChange={(selectedOption) => handleCategoryChange(category.slug, selectedOption)}

                                              className={`muilt-select-field mt-2 `}
                                              classNamePrefix="select"
                                              styles={{
                                                option: (provided, state) => {
                                                  return {
                                                    ...provided,
                                                    backgroundColor: state.isSelected
                                                      ? "#660c605c"
                                                      : provided.backgroundColor,
                                                    color: state.isSelected
                                                      ? "var(--dark-color)"
                                                      : provided.color,
                                                  };
                                                },
                                              }} />
                                          </Col>
                                        </>
                                      ))}
                                    </>
                                  )}

                                  {attributesData?.map((attdata, index) => (
                                    <>

                                      {attdata?.is_repeatable ==
                                        true ? (
                                        <Col lg="12">
                                          {/* {attdata?.fieldtype !==
                                            "checkbox_single_choice" && ( */}
                                          <>
                                            <div className="extra-links-div">
                                              <Typography
                                                className={`${attdata?.fieldtype ===
                                                  "text_editor" ||
                                                  attdata?.fieldtype ===
                                                  "html" ||
                                                  attdata?.fieldtype ===
                                                  "image" ||
                                                  attdata?.fieldtype ===
                                                  "file"
                                                  ? "mb-2 fs-14"
                                                  : "fs-14"
                                                  }`}
                                              >
                                                {attdata?.label}{" "}
                                                {attdata?.is_mandatory ==
                                                  true && (
                                                    <span className="required">
                                                      *
                                                    </span>
                                                  )}
                                              </Typography>
                                              <Button
                                                className="btn primary-btn"
                                                onClick={() =>
                                                  handleAddTextField(
                                                    attdata?.nameValue,
                                                    attdata?.label,
                                                    attdata?.section_id,
                                                    attdata?.fieldtype,
                                                    attdata?.options,
                                                    attdata?.is_mandatory,
                                                    attdata?.is_repeatable,
                                                    attdata?.value,
                                                    attdata?.id
                                                  )
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={faPlus}
                                                />
                                                {`Add ${attdata?.label}`}
                                              </Button>
                                            </div>
                                            <div>
                                              {additionalTextFields.map(
                                                (
                                                  textField,
                                                  index
                                                ) => (
                                                  <div key={index}>
                                                    {textField?.nameValue ==
                                                      attdata?.nameValue && (
                                                        <div className={`${attdata?.fieldtype === "link_object" ? 'add-title-div-2' : 'add-title-div'}`}>
                                                          {textField?.fieldtype ===
                                                            "dropdodown_single_choice" ? (
                                                            <>
                                                              <div className="w-100">
                                                                <Select
                                                                  closeMenuOnSelect={
                                                                    true
                                                                  }
                                                                  options={
                                                                    textField?.options
                                                                  }
                                                                  menuPlacement="auto"
                                                                  name={
                                                                    attdata?.nameValue
                                                                  }
                                                                  placeholder={`Select ${textField?.label}...`}
                                                                  className="muilt-select-field mt-2 w-100"
                                                                  classNamePrefix="select"
                                                                  value={textField?.options.find(
                                                                    (
                                                                      option
                                                                    ) =>
                                                                      option?.value ==
                                                                      textField?.value
                                                                  )}
                                                                  onChange={(
                                                                    selectedOption
                                                                  ) => {
                                                                    handleChangeForAdditionalTextField(
                                                                      textField?.id,
                                                                      selectedOption?.value,
                                                                      attdata?.nameValue,
                                                                      attdata?.section_id,
                                                                      textField?.fieldtype
                                                                    );
                                                                  }}
                                                                  styles={{
                                                                    option:
                                                                      (
                                                                        provided,
                                                                        state
                                                                      ) => {
                                                                        return {
                                                                          ...provided,
                                                                          backgroundColor:
                                                                            state.isSelected
                                                                              ? "#660c605c"
                                                                              : provided.backgroundColor,
                                                                          color:
                                                                            state.isSelected
                                                                              ? "var(--dark-color)"
                                                                              : provided.color,
                                                                        };
                                                                      },
                                                                  }}
                                                                />
                                                                {textField?.is_mandatory ==
                                                                  true &&
                                                                  showBelowError ==
                                                                  true &&
                                                                  textField?.value ===
                                                                  "" && (
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          "#d32f2f",
                                                                      }}
                                                                      className="error-msg"
                                                                    >
                                                                      {`${textField?.label
                                                                        } ${index +
                                                                        1
                                                                        } is required.`}
                                                                    </p>
                                                                  )}
                                                              </div>
                                                            </>
                                                          ) : textField?.fieldtype ===
                                                            "long_text" ? (
                                                            <>
                                                              <div className="w-100">
                                                                <TextField
                                                                  className="input-field mt-2"
                                                                  fullWidth
                                                                  id="outlined-basic short summary"
                                                                  variant="outlined"
                                                                  multiline
                                                                  maxRows={
                                                                    2
                                                                  }
                                                                  name={
                                                                    textField?.nameValue
                                                                  }
                                                                  placeholder={`Enter ${textField?.label}...`}
                                                                  value={
                                                                    textField?.value
                                                                  }
                                                                  onChange={(
                                                                    event
                                                                  ) =>
                                                                    handleChangeForAdditionalTextField(
                                                                      textField?.id,
                                                                      event
                                                                        ?.target
                                                                        ?.value,
                                                                      attdata?.nameValue,
                                                                      attdata?.section_id,
                                                                      textField?.fieldtype

                                                                    )
                                                                  }
                                                                />
                                                                {textField?.is_mandatory ==
                                                                  true &&
                                                                  showBelowError ==
                                                                  true &&
                                                                  textField?.value ===
                                                                  "" && (
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          "#d32f2f",
                                                                      }}
                                                                      className="error-msg"
                                                                    >
                                                                      {`${textField?.label
                                                                        } ${index +
                                                                        1
                                                                        } is required.`}
                                                                    </p>
                                                                  )}
                                                              </div>
                                                            </>
                                                          ) : textField?.fieldtype ===
                                                            "short_text" ? (
                                                            <>
                                                              <div className="w-100">
                                                                <TextField
                                                                  className="input-field mt-2"
                                                                  fullWidth
                                                                  id="outlined-basic short summary"
                                                                  variant="outlined"
                                                                  name={
                                                                    textField?.nameValue
                                                                  }
                                                                  placeholder={`Enter ${textField?.label}...`}
                                                                  value={
                                                                    textField?.value
                                                                  }
                                                                  onChange={(
                                                                    event
                                                                  ) =>
                                                                    handleChangeForAdditionalTextField(
                                                                      textField?.id,
                                                                      event
                                                                        ?.target
                                                                        ?.value,
                                                                      attdata?.nameValue,
                                                                      attdata?.section_id,
                                                                      textField?.fieldtype

                                                                    )
                                                                  }
                                                                />
                                                                {textField?.is_mandatory ==
                                                                  true &&
                                                                  showBelowError ==
                                                                  true &&
                                                                  textField?.value ===
                                                                  "" && (
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          "#d32f2f",
                                                                      }}
                                                                      className="error-msg"
                                                                    >
                                                                      {`${textField?.label
                                                                        } ${index +
                                                                        1
                                                                        } is required.`}
                                                                    </p>
                                                                  )}
                                                              </div>
                                                            </>
                                                          ) : textField?.fieldtype ===
                                                            "date" ? (
                                                            <div className="w-100">
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DemoContainer
                                                                  components={[
                                                                    "DatePicker",
                                                                  ]}
                                                                  className="p-0 date"
                                                                >
                                                                  <DatePicker
                                                                    name={
                                                                      textField?.nameValue
                                                                    }
                                                                    value={dayjs(
                                                                      textField?.value
                                                                    )}
                                                                    className="w-100 pt-0"
                                                                    onChange={(
                                                                      newValue
                                                                    ) => {
                                                                      handleChangeForAdditionalTextField(
                                                                        textField?.id,
                                                                        newValue,
                                                                        attdata?.nameValue,
                                                                        attdata?.section_id,
                                                                        textField?.fieldtype

                                                                      );
                                                                    }}
                                                                    views={[
                                                                      "year",
                                                                      "month",
                                                                      "day",
                                                                    ]}
                                                                    slotProps={{
                                                                      field:
                                                                      {
                                                                        clearable: true,
                                                                      },
                                                                    }}
                                                                  />
                                                                </DemoContainer>
                                                              </LocalizationProvider>
                                                              {textField?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                textField?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${textField?.label
                                                                      } ${index +
                                                                      1
                                                                      } is required.`}
                                                                  </p>
                                                                )}
                                                            </div>
                                                          ) : textField?.fieldtype ===
                                                            "radio_single_choice" ? (
                                                            <div className="w-100">
                                                              <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name={
                                                                  textField?.nameValue
                                                                }
                                                                className="mt-1"
                                                                value={
                                                                  textField?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  handleChangeForAdditionalTextField(
                                                                    textField?.id,
                                                                    event
                                                                      ?.target
                                                                      ?.value,
                                                                    attdata?.nameValue,
                                                                    attdata?.section_id,
                                                                    textField?.fieldtype

                                                                  );
                                                                }}
                                                              >
                                                                {textField?.options?.map(
                                                                  (
                                                                    attribute
                                                                  ) => (
                                                                    <FormControlLabel
                                                                      key={
                                                                        attribute?.id
                                                                      }
                                                                      value={
                                                                        attribute?.id
                                                                      }
                                                                      control={
                                                                        <Radio
                                                                          size="small"
                                                                          checked={
                                                                            textField?.value ==
                                                                            attribute?.id
                                                                          }
                                                                          value={
                                                                            attribute?.id
                                                                          }
                                                                        />
                                                                      }
                                                                      label={
                                                                        attribute?.name
                                                                      }
                                                                      className="font-inter"
                                                                    />
                                                                  )
                                                                )}
                                                              </RadioGroup>
                                                              {textField?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                textField?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${textField?.label
                                                                      } ${index +
                                                                      1
                                                                      } is required.`}
                                                                  </p>
                                                                )}
                                                            </div>
                                                          ) : textField?.fieldtype ===
                                                            "link" ? (
                                                            <div className="w-100">
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  textField?.nameValue
                                                                }
                                                                placeholder={`Enter ${textField?.label}...`}
                                                                value={
                                                                  textField?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) =>
                                                                  handleChangeForAdditionalTextField(
                                                                    textField?.id,
                                                                    event
                                                                      ?.target
                                                                      ?.value,
                                                                    attdata?.nameValue,
                                                                    attdata?.section_id,
                                                                    textField?.fieldtype

                                                                  )
                                                                }
                                                                type="text"
                                                              />
                                                              {textField?.is_mandatory ===
                                                                true && (
                                                                  <>
                                                                    {textField?.value ===
                                                                      "" && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${textField?.label} ${index} is required.`}
                                                                        </p>
                                                                      )}
                                                                    {textField?.value !==
                                                                      "" &&
                                                                      !isValidURL(
                                                                        textField?.value
                                                                      ) && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${textField?.label} ${index} must be a valid URL..`}
                                                                        </p>
                                                                      )}
                                                                  </>
                                                                )}
                                                            </div>
                                                          ) : textField?.fieldtype ===
                                                            "email" ? (
                                                            <div className="w-100">
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  textField?.nameValue
                                                                }
                                                                placeholder={`Enter ${textField?.label}...`}
                                                                value={
                                                                  textField?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) =>
                                                                  handleChangeForAdditionalTextField(
                                                                    textField?.id,
                                                                    event
                                                                      ?.target
                                                                      ?.value,
                                                                    attdata?.nameValue,
                                                                    attdata?.section_id,
                                                                    textField?.fieldtype

                                                                  )
                                                                }
                                                                type="text"
                                                              />
                                                              {textField?.is_mandatory ===
                                                                true && (
                                                                  <>
                                                                    {textField?.value ===
                                                                      "" && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${textField?.label} ${index} is required.`}
                                                                        </p>
                                                                      )}
                                                                    {textField?.value !==
                                                                      "" &&
                                                                      !isValidEmail(
                                                                        textField?.value
                                                                      ) && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${textField?.label} ${index} must be a valid email address.`}
                                                                        </p>
                                                                      )}
                                                                  </>
                                                                )}
                                                            </div>
                                                          ) : textField?.fieldtype ===
                                                            "number" ? (
                                                            <div className="w-100">
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  textField?.nameValue
                                                                }
                                                                placeholder={`Enter ${textField?.label}...`}
                                                                value={
                                                                  textField?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) =>
                                                                  handleChangeForAdditionalTextField(
                                                                    textField?.id,
                                                                    event
                                                                      ?.target
                                                                      ?.value,
                                                                    attdata?.nameValue,
                                                                    attdata?.section_id,
                                                                    textField?.fieldtype

                                                                  )
                                                                }
                                                                type="number"
                                                              />
                                                              {textField?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                textField?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${textField?.label
                                                                      } ${index +
                                                                      1
                                                                      } is required.`}
                                                                  </p>
                                                                )}
                                                            </div>
                                                          ) : textField?.fieldtype ===
                                                            "checkbox_single_choice" ? (
                                                            <div className="w-100">
                                                              <FormControlLabel
                                                                control={
                                                                  <Checkbox
                                                                    checked={
                                                                      textField?.value ==
                                                                      true
                                                                    }
                                                                    name={
                                                                      textField?.nameValue
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) => {
                                                                      handleChangeForAdditionalTextField(
                                                                        textField?.id,
                                                                        event
                                                                          ?.target
                                                                          ?.checked,
                                                                        attdata?.nameValue,
                                                                        attdata?.section_id,
                                                                        textField?.fieldtype

                                                                      );
                                                                    }}
                                                                  />
                                                                }
                                                                label={
                                                                  textField?.label
                                                                }
                                                                className="font-inter"
                                                              />
                                                              {textField?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                textField?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${textField?.label
                                                                      } ${index +
                                                                      1
                                                                      } is required.`}
                                                                  </p>
                                                                )}
                                                            </div>
                                                          ) : textField?.fieldtype ===
                                                            "checkbox_multiple_choice" ? (
                                                            <div className="w-100">
                                                              <>
                                                                {textField?.options?.map(
                                                                  (
                                                                    multiData,
                                                                    index
                                                                  ) => (
                                                                    <FormControlLabel
                                                                      control={
                                                                        <Checkbox
                                                                          checked={
                                                                            attributesData
                                                                              ?.find(attr => attr.nameValue === textField.nameValue && attr.section_id === textField.section_id)
                                                                              ?.value?.find(val => typeof val === 'object' && val.id === textField.id)
                                                                              ?.value?.includes(multiData.id) || false
                                                                          }
                                                                          name={
                                                                            multiData?.id
                                                                              ?.toString()
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const checked =
                                                                              event
                                                                                ?.target
                                                                                ?.checked;
                                                                            const optionId =
                                                                              multiData?.id;
                                                                            const attributeIndex = attributesData?.findIndex(
                                                                              attr => attr.nameValue === textField.nameValue && attr.section_id === textField.section_id
                                                                            );
                                                                            let updatedValues;


                                                                            if (attributeIndex !== -1) {
                                                                              const updatedAttributes = [...attributesData];
                                                                              const attribute = updatedAttributes[attributeIndex];
                                                                              const repeatableIndex = attribute?.value?.findIndex(val => typeof val === 'object' && val.id === textField.id);

                                                                              if (repeatableIndex !== -1) {
                                                                                const repeatable = { ...attribute.value[repeatableIndex] };

                                                                                if (checked) {
                                                                                  updatedValues = [...(repeatable.value || []), optionId];
                                                                                } else {
                                                                                  updatedValues = (repeatable.value || [])?.filter(val => val !== optionId);
                                                                                }

                                                                                repeatable.value = updatedValues;
                                                                                updatedAttributes[attributeIndex].value[repeatableIndex] = repeatable;

                                                                                // setAttributesData(updatedAttributes);

                                                                                console.log("updatedValues::", updatedValues);
                                                                              }
                                                                            }

                                                                            handleChangeForAdditionalTextField(
                                                                              textField?.id,
                                                                              updatedValues,
                                                                              attdata?.nameValue,
                                                                              attdata?.section_id,
                                                                              textField?.fieldtype

                                                                            );
                                                                          }}
                                                                        />
                                                                      }
                                                                      label={
                                                                        multiData?.name
                                                                      }
                                                                      className="font-inter"
                                                                    />
                                                                  )
                                                                )}
                                                                {textField?.is_mandatory ==
                                                                  true &&
                                                                  showBelowError ==
                                                                  true &&
                                                                  textField
                                                                    ?.value
                                                                    ?.length <
                                                                  1 && (
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          "#d32f2f",
                                                                      }}
                                                                      className="error-msg"
                                                                    >
                                                                      {`${textField?.label
                                                                        } ${index +
                                                                        1
                                                                        } is required at least one selection.`}
                                                                    </p>
                                                                  )}
                                                              </>
                                                            </div>
                                                          ) : textField?.fieldtype ===
                                                            "dropdodown_multiple_choice" ? (
                                                            <div className="w-100">
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                                options={
                                                                  textField?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  textField?.nameValue
                                                                }
                                                                placeholder={`Select ${textField?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                isMulti
                                                                value={textField?.options?.filter(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    textField?.value?.includes(
                                                                      option?.value
                                                                    )
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  const selectedValues =
                                                                    selectedOption.map(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option?.value
                                                                    );

                                                                  handleChangeForAdditionalTextField(
                                                                    textField?.id,
                                                                    selectedValues,
                                                                    attdata?.nameValue,
                                                                    attdata?.section_id,
                                                                    textField?.fieldtype

                                                                  );
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {textField?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                textField
                                                                  ?.value
                                                                  ?.length <
                                                                1 && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${textField?.label
                                                                      } ${index +
                                                                      1
                                                                      } is required at least one selection.`}
                                                                  </p>
                                                                )}
                                                            </div>
                                                          ) : textField?.fieldtype ===
                                                            "time" ? (
                                                            <div className="w-100">
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DemoContainer
                                                                  components={[
                                                                    "TimePicker",
                                                                  ]}
                                                                  className="p-0 date"
                                                                >
                                                                  <TimePicker
                                                                    name={
                                                                      textField?.nameValue
                                                                    }
                                                                    value={dayjs(
                                                                      textField?.value,
                                                                      "HH:mm:ss"
                                                                    )}
                                                                    className="w-100 pt-0"
                                                                    onChange={(
                                                                      newValue
                                                                    ) => {
                                                                      handleChangeForAdditionalTextField(
                                                                        textField?.id,
                                                                        newValue,
                                                                        attdata?.nameValue,
                                                                        attdata?.section_id,
                                                                        textField?.fieldtype

                                                                      );
                                                                    }}
                                                                    slotProps={{
                                                                      field:
                                                                      {
                                                                        clearable: true,
                                                                      },
                                                                    }}
                                                                  />
                                                                </DemoContainer>
                                                              </LocalizationProvider>
                                                              {textField?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                textField?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${textField?.label
                                                                      } ${index +
                                                                      1
                                                                      } is required.`}
                                                                  </p>
                                                                )}
                                                            </div>
                                                          ) : textField?.fieldtype ===
                                                            "date_time" ? (
                                                            <div className="w-100">
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DemoContainer
                                                                  components={[
                                                                    "DateTimePicker",
                                                                  ]}
                                                                  className="p-0 date"
                                                                >
                                                                  <DateTimePicker
                                                                    name={
                                                                      textField?.nameValue
                                                                    }
                                                                    value={dayjs(
                                                                      textField?.value
                                                                    )}
                                                                    className="w-100 pt-0"
                                                                    onChange={(
                                                                      newValue
                                                                    ) => {
                                                                      handleChangeForAdditionalTextField(
                                                                        textField?.id,
                                                                        newValue,
                                                                        attdata?.nameValue,
                                                                        attdata?.section_id,
                                                                        textField?.fieldtype

                                                                      );
                                                                    }}
                                                                    slotProps={{
                                                                      field:
                                                                      {
                                                                        clearable: true,
                                                                      },
                                                                    }}
                                                                  />
                                                                </DemoContainer>
                                                              </LocalizationProvider>
                                                              {textField?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                textField?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${textField?.label
                                                                      } ${index +
                                                                      1
                                                                      } is required.`}
                                                                  </p>
                                                                )}
                                                            </div>
                                                          ) : textField?.fieldtype ===
                                                            "date_range" ? (
                                                            <div className="w-100">
                                                              <>
                                                                <DateRangePicker
                                                                  // ref={keyRef}
                                                                  initialSettings={{
                                                                    startDate:
                                                                      textField
                                                                        ?.value
                                                                        ?.startDate,
                                                                    endDate:
                                                                      textField
                                                                        ?.value
                                                                        ?.endDate,
                                                                    autoUpdateInput: false,
                                                                  }}
                                                                  onApply={(
                                                                    _,
                                                                    range
                                                                  ) => {
                                                                    const startDate =
                                                                      range?.startDate;
                                                                    const endDate =
                                                                      range?.endDate;
                                                                    let data =
                                                                    {
                                                                      startDate:
                                                                        startDate,
                                                                      endDate:
                                                                        endDate,
                                                                    };

                                                                    handleChangeForAdditionalTextField(
                                                                      textField?.id,
                                                                      data,
                                                                      attdata?.nameValue,
                                                                      attdata?.section_id,
                                                                      textField?.fieldtype

                                                                    );
                                                                  }}
                                                                >
                                                                  <TextField
                                                                    className="input-field mt-2"
                                                                    fullWidth
                                                                    id="outlined-basic short summary"
                                                                    variant="outlined"
                                                                    placeholder={`Enter ${textField?.label}...`}
                                                                    value={
                                                                      textField?.value
                                                                        ? `${moment(
                                                                          textField
                                                                            ?.value
                                                                            ?.startDate
                                                                        ).format(
                                                                          "DD/MM/YYYY"
                                                                        )} - ${moment(
                                                                          textField
                                                                            ?.value
                                                                            ?.endDate
                                                                        ).format(
                                                                          "DD/MM/YYYY"
                                                                        )}`
                                                                        : ""
                                                                    }
                                                                    type="text"
                                                                  />
                                                                </DateRangePicker>
                                                              </>
                                                              {textField?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                textField?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${textField?.label
                                                                      } ${index +
                                                                      1
                                                                      } is required.`}
                                                                  </p>
                                                                )}
                                                            </div>
                                                          ) : textField?.fieldtype ===
                                                            "text_editor" ? (
                                                            <div className="w-100">
                                                              <CKEditor
                                                                editor={
                                                                  Editor
                                                                }
                                                                className="mt-2"
                                                                config={
                                                                  configForContentEditor
                                                                }
                                                                data={
                                                                  textField?.value
                                                                }
                                                                onChange={(
                                                                  event,
                                                                  editor
                                                                ) => {
                                                                  const data =
                                                                    editor.getData();

                                                                  handleChangeForAdditionalTextField(
                                                                    textField?.id,
                                                                    data,
                                                                    attdata?.nameValue,
                                                                    attdata?.section_id,
                                                                    textField?.fieldtype

                                                                  );
                                                                }}
                                                              />
                                                              {/* ) :
                                                              textField?.fieldtype
                                                              === "html"
                                                              ? (
                                                              <CKEditor
                                                                editor={
                                                                  Editor
                                                                }
                                                                className="mt-2"
                                                                config={
                                                                  configForContentEditorForHTML
                                                                }
                                                                data={
                                                                  textField?.value
                                                                }
                                                                onChange={(
                                                                  event,
                                                                  editor
                                                                ) => {
                                                                  const data =
                                                                    editor.getData();

                                                                  handleChangeForAdditionalTextField(
                                                                    textField?.id,
                                                                    data,
                                                                    attdata?.nameValue,
                                                                    attdata?.section_id,
                                                                    textField?.fieldtype

                                                                  );
                                                                }}
                                                              /> */}
                                                              {textField?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                textField?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${textField?.label
                                                                      } ${index +
                                                                      1
                                                                      } is required.`}
                                                                  </p>
                                                                )}
                                                            </div>
                                                          ) : textField?.fieldtype ===
                                                            "html" ? (
                                                            <div className="w-100">
                                                              <CKEditor
                                                                editor={
                                                                  Editor
                                                                }
                                                                className="mt-2 w-100"
                                                                config={
                                                                  configForContentEditorForHTML
                                                                }
                                                                data={
                                                                  textField?.value
                                                                }
                                                                onChange={(
                                                                  event,
                                                                  editor
                                                                ) => {
                                                                  const data =
                                                                    editor.getData();

                                                                  handleChangeForAdditionalTextField(
                                                                    textField?.id,
                                                                    data,
                                                                    attdata?.nameValue,
                                                                    attdata?.section_id,
                                                                    textField?.fieldtype

                                                                  );
                                                                }}
                                                              />

                                                              {textField?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                textField?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${textField?.label
                                                                      } ${index +
                                                                      1
                                                                      } is required.`}
                                                                  </p>
                                                                )}
                                                            </div>
                                                          )
                                                            : textField?.fieldtype ===
                                                              "image" ? (
                                                              <div className="w-100">
                                                                <Stack
                                                                  gap={4}
                                                                >
                                                                  {textField?.value && (
                                                                    <div className="upload-img-wrapper">
                                                                      <Image
                                                                        src={
                                                                          textField?.value
                                                                        }
                                                                      />
                                                                    </div>
                                                                  )}

                                                                  <Button
                                                                    className="btn ternary-btn w-100"
                                                                    onClick={() =>
                                                                      handleClickForAtt(
                                                                        "image",
                                                                        textField
                                                                      )
                                                                    }
                                                                  >
                                                                    <FontAwesomeIcon
                                                                      icon={
                                                                        faUpload
                                                                      }
                                                                    />
                                                                    {value
                                                                      ? "Change Image"
                                                                      : "Upload Image"}
                                                                  </Button>
                                                                </Stack>
                                                                {textField?.is_mandatory ==
                                                                  true &&
                                                                  showBelowError ==
                                                                  true &&
                                                                  textField?.value ===
                                                                  "" && (
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          "#d32f2f",
                                                                      }}
                                                                      className="error-msg"
                                                                    >
                                                                      {`${textField?.label
                                                                        } ${index +
                                                                        1
                                                                        } is required.`}
                                                                    </p>
                                                                  )}
                                                              </div>
                                                            ) : textField?.fieldtype ===
                                                              "file" ? (
                                                              <div className="w-100">
                                                                <Stack
                                                                  gap={3}
                                                                >
                                                                  {textField?.value && (
                                                                    <div className="upload-img-wrapper mt-2">
                                                                      {textField?.value !==
                                                                        "" &&
                                                                        `File name: ${textField
                                                                          ?.value
                                                                          ?.name ||
                                                                        textField?.value
                                                                        }`}
                                                                    </div>
                                                                  )}

                                                                  <Button
                                                                    className="btn ternary-btn w-100 mt-3"
                                                                    onClick={() =>
                                                                      handleClickForAtt(
                                                                        "file",
                                                                        textField
                                                                      )
                                                                    }
                                                                  >
                                                                    <FontAwesomeIcon
                                                                      icon={
                                                                        faUpload
                                                                      }
                                                                    />
                                                                    {attdata?.value
                                                                      ? "Change File"
                                                                      : "Upload File"}
                                                                  </Button>
                                                                </Stack>
                                                                {textField?.is_mandatory ==
                                                                  true &&
                                                                  showBelowError ==
                                                                  true &&
                                                                  textField?.value ===
                                                                  "" && (
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          "#d32f2f",
                                                                      }}
                                                                      className="error-msg"
                                                                    >
                                                                      {`${textField?.label
                                                                        } ${index +
                                                                        1
                                                                        } is required.`}
                                                                    </p>
                                                                  )}
                                                              </div>
                                                            ) : textField?.fieldtype ===
                                                              "users_list" ? (
                                                              <div className="w-100">
                                                                <Select
                                                                  closeMenuOnSelect={
                                                                    false
                                                                  }
                                                                  options={
                                                                    textField?.options
                                                                  }
                                                                  menuPlacement="auto"
                                                                  name={
                                                                    textField?.nameValue
                                                                  }
                                                                  placeholder={`Select ${textField?.label}...`}
                                                                  className="muilt-select-field mt-2"
                                                                  classNamePrefix="select"
                                                                  isMulti

                                                                  value={textField?.options.filter(
                                                                    (
                                                                      option
                                                                    ) =>
                                                                      textField?.value?.includes(
                                                                        option?.value
                                                                      )
                                                                  )}
                                                                  onChange={(
                                                                    selectedOption
                                                                  ) => {
                                                                    const selectedValues =
                                                                      selectedOption.map(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option?.value
                                                                      );

                                                                    handleChangeForAdditionalTextField(
                                                                      textField?.id,
                                                                      selectedValues,
                                                                      attdata?.nameValue,
                                                                      attdata?.section_id,
                                                                      textField?.fieldtype

                                                                    );
                                                                  }}
                                                                  styles={{
                                                                    option:
                                                                      (
                                                                        provided,
                                                                        state
                                                                      ) => {
                                                                        return {
                                                                          ...provided,
                                                                          backgroundColor:
                                                                            state.isSelected
                                                                              ? "#660c605c"
                                                                              : provided.backgroundColor,
                                                                          color:
                                                                            state.isSelected
                                                                              ? "var(--dark-color)"
                                                                              : provided.color,
                                                                        };
                                                                      },
                                                                  }}
                                                                />
                                                                {textField?.is_mandatory ==
                                                                  true &&
                                                                  showBelowError ==
                                                                  true &&
                                                                  textField?.value ===
                                                                  "" && (
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          "#d32f2f",
                                                                      }}
                                                                      className="error-msg"
                                                                    >
                                                                      {`${textField?.label
                                                                        } ${index +
                                                                        1
                                                                        } is required.`}
                                                                    </p>
                                                                  )}
                                                              </div>
                                                            ) : textField?.fieldtype ===
                                                              "group_list" ? (
                                                              <div className="w-100">
                                                                <Select
                                                                  closeMenuOnSelect={
                                                                    false
                                                                  }
                                                                  options={
                                                                    textField?.options
                                                                  }
                                                                  menuPlacement="auto"
                                                                  name={
                                                                    textField?.nameValue
                                                                  }
                                                                  placeholder={`Select ${textField?.label}...`}
                                                                  className="muilt-select-field mt-2"
                                                                  classNamePrefix="select"
                                                                  isMulti
                                                                  value={textField?.options.filter(
                                                                    (
                                                                      option
                                                                    ) =>
                                                                      textField?.value?.includes(
                                                                        option?.value
                                                                      )
                                                                  )}
                                                                  onChange={(
                                                                    selectedOption
                                                                  ) => {
                                                                    const selectedValues =
                                                                      selectedOption.map(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option?.value
                                                                      );

                                                                    handleChangeForAdditionalTextField(
                                                                      textField?.id,
                                                                      selectedValues,
                                                                      attdata?.nameValue,
                                                                      attdata?.section_id,
                                                                      textField?.fieldtype

                                                                    );
                                                                  }}
                                                                  styles={{
                                                                    option:
                                                                      (
                                                                        provided,
                                                                        state
                                                                      ) => {
                                                                        return {
                                                                          ...provided,
                                                                          backgroundColor:
                                                                            state.isSelected
                                                                              ? "#660c605c"
                                                                              : provided.backgroundColor,
                                                                          color:
                                                                            state.isSelected
                                                                              ? "var(--dark-color)"
                                                                              : provided.color,
                                                                        };
                                                                      },
                                                                  }}
                                                                />
                                                                {textField?.is_mandatory ==
                                                                  true &&
                                                                  showBelowError ==
                                                                  true &&
                                                                  textField?.value ===
                                                                  "" && (
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          "#d32f2f",
                                                                      }}
                                                                      className="error-msg"
                                                                    >
                                                                      {`${textField?.label
                                                                        } ${index +
                                                                        1
                                                                        } is required.`}
                                                                    </p>
                                                                  )}
                                                              </div>
                                                            ) : textField?.fieldtype ===
                                                              "link_object" ? (
                                                              <>
                                                                <div className="w-100">
                                                                  <TextField
                                                                    className="input-field mt-2"
                                                                    fullWidth
                                                                    id="outlined-basic short summary"
                                                                    variant="outlined"
                                                                    name={
                                                                      textField?.nameValue
                                                                    }
                                                                    placeholder={`Enter ${textField?.label} Title...`}
                                                                    value={
                                                                      textField?.value?.title || ""
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleChangeForAdditionalTextField(
                                                                        textField?.id,
                                                                        event
                                                                          ?.target
                                                                          ?.value,
                                                                        attdata?.nameValue,
                                                                        attdata?.section_id,
                                                                        textField?.fieldtype,
                                                                        "title"
                                                                      )
                                                                    }
                                                                  />
                                                                  {textField?.is_mandatory ==
                                                                    true &&
                                                                    showBelowError ==
                                                                    true &&
                                                                    textField?.value ===
                                                                    "" && (
                                                                      <p
                                                                        style={{
                                                                          color:
                                                                            "#d32f2f",
                                                                        }}
                                                                        className="error-msg"
                                                                      >
                                                                        {`${textField?.label
                                                                          } ${index +
                                                                          1
                                                                          } is required.`}
                                                                      </p>
                                                                    )}
                                                                  <TextField
                                                                    className="input-field mt-2"
                                                                    fullWidth
                                                                    id="outlined-basic short summary"
                                                                    variant="outlined"
                                                                    name={
                                                                      textField?.nameValue
                                                                    }
                                                                    placeholder={`Enter ${textField?.label} URL...`}
                                                                    value={
                                                                      textField?.value?.url || ""
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleChangeForAdditionalTextField(
                                                                        textField?.id,
                                                                        event
                                                                          ?.target
                                                                          ?.value,
                                                                        attdata?.nameValue,
                                                                        attdata?.section_id,
                                                                        textField?.fieldtype,
                                                                        "url"
                                                                      )
                                                                    }
                                                                    type="text"
                                                                  />


                                                                  {textField?.value?.url &&
                                                                    !isValidURL(
                                                                      textField?.value?.url
                                                                    ) && (
                                                                      <p
                                                                        style={{
                                                                          color:
                                                                            "#d32f2f",
                                                                        }}
                                                                        className="error-msg"
                                                                      >
                                                                        {`${textField?.label} ${index} must be a valid URL..`}
                                                                      </p>
                                                                    )}

                                                                </div>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                          <div className={`${attdata?.fieldtype === "link_object" ? "add-title-div-btn-2" : "add-title-div-btn"}`}>
                                                            <Button
                                                              className="btn btn-danger"
                                                              // color="error"
                                                              onClick={() =>
                                                                removeExtraFields(
                                                                  textField.id,
                                                                  attdata?.nameValue,
                                                                  attdata?.section_id
                                                                )
                                                              }
                                                            // startIcon={<FontAwesomeIcon
                                                            //   icon={
                                                            //     faTimes
                                                            //   }
                                                            // />}
                                                            >
                                                              <FontAwesomeIcon
                                                                icon={
                                                                  faTimes
                                                                }
                                                              />
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      )}
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </>
                                          {/* )} */}
                                        </Col>
                                      ) : (
                                        <Col lg="12">
                                          {attdata?.fieldtype !==
                                            "checkbox_single_choice" && (
                                              <Typography
                                                className={`${attdata?.fieldtype ===
                                                  "text_editor" ||
                                                  attdata?.fieldtype ===
                                                  "html" ||
                                                  attdata?.fieldtype ===
                                                  "image" ||
                                                  attdata?.fieldtype ===
                                                  "file"
                                                  ? "mb-2 fs-14"
                                                  : "fs-14"
                                                  }`}
                                              >
                                                {attdata?.label}{" "}
                                                {attdata?.is_mandatory ==
                                                  true && (
                                                    <span className="required">
                                                      *
                                                    </span>
                                                  )}
                                              </Typography>
                                            )}

                                          {attdata?.fieldtype ===
                                            "dropdodown_single_choice" ? (
                                            <>
                                              <Select
                                                closeMenuOnSelect={true}
                                                options={
                                                  attdata?.options
                                                }
                                                menuPlacement="auto"
                                                name={
                                                  attdata?.nameValue
                                                }
                                                placeholder={`Select ${attdata?.label}...`}
                                                className="muilt-select-field mt-2"
                                                classNamePrefix="select"
                                                value={attdata?.options.find(
                                                  (option) =>
                                                    option?.value ==
                                                    attdata?.value
                                                )}
                                                onChange={(
                                                  selectedOption
                                                ) => {
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    selectedOption?.value,
                                                    attdata?.section_id
                                                  );
                                                }}
                                                styles={{
                                                  option: (
                                                    provided,
                                                    state
                                                  ) => {
                                                    return {
                                                      ...provided,
                                                      backgroundColor:
                                                        state.isSelected
                                                          ? "#660c605c"
                                                          : provided.backgroundColor,
                                                      color:
                                                        state.isSelected
                                                          ? "var(--dark-color)"
                                                          : provided.color,
                                                    };
                                                  },
                                                }}
                                              />
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                              {attdata?.childAtts
                                                ?.length > 0 && (
                                                  <>
                                                    {attdata?.childAtts?.map(
                                                      (
                                                        childAtt,
                                                        index
                                                      ) => (
                                                        <Col lg="12 mt-3 test-12">
                                                          {childAtt?.fieldtype !==
                                                            "checkbox_single_choice" && (
                                                              <Typography
                                                                className={`${childAtt?.fieldtype ===
                                                                  "text_editor" ||
                                                                  childAtt?.fieldtype ===
                                                                  "html" ||
                                                                  childAtt?.fieldtype ===
                                                                  "image" ||
                                                                  childAtt?.fieldtype ===
                                                                  "file"
                                                                  ? "mb-2 fs-14"
                                                                  : "fs-14"
                                                                  }`}
                                                              >
                                                                {
                                                                  childAtt?.label
                                                                }{" "}
                                                                {childAtt?.is_mandatory ==
                                                                  true && (
                                                                    <span className="required">
                                                                      *
                                                                    </span>
                                                                  )}
                                                              </Typography>
                                                            )}
                                                          {childAtt?.fieldtype ===
                                                            "dropdodown_single_choice" ? (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  true
                                                                }
                                                                options={
                                                                  childAtt?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Select ${childAtt?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                value={childAtt?.options.find(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    option?.value ==
                                                                    childAtt?.value
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    selectedOption?.value,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  // Update the value in the childAtt directly
                                                                  childAtt.value =
                                                                    selectedOption?.value;
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "long_text" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                multiline
                                                                maxRows={
                                                                  2
                                                                }
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enter ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    event
                                                                      .target
                                                                      .value,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "short_text" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enters ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    newValue,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  // Update the value in the childAtt directly
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "date" ? (
                                                            <>
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DemoContainer
                                                                  components={[
                                                                    "DatePicker",
                                                                    "DatePicker",
                                                                  ]}
                                                                  className="p-0 date"
                                                                >
                                                                  <DatePicker
                                                                    name={
                                                                      childAtt?.nameValue
                                                                    }
                                                                    value={
                                                                      childAtt?.value
                                                                    }
                                                                    className="w-100 pt-0"
                                                                    onChange={(
                                                                      newValue
                                                                    ) => {
                                                                      handleChangeForChildAtts(
                                                                        childAtt?.nameValue,
                                                                        newValue,
                                                                        childAtt?.section_id,
                                                                        childAtt,
                                                                        attdata
                                                                      );
                                                                      childAtt.value =
                                                                        newValue;
                                                                    }}
                                                                    views={[
                                                                      "year",
                                                                      "month",
                                                                      "day",
                                                                    ]}
                                                                    slotProps={{
                                                                      field:
                                                                      {
                                                                        clearable: true,
                                                                      },
                                                                    }}
                                                                  />
                                                                </DemoContainer>
                                                              </LocalizationProvider>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "link" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enter ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    newValue,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                                type="text"
                                                              />
                                                              {childAtt?.is_mandatory ===
                                                                true && (
                                                                  <>
                                                                    {childAtt?.value ===
                                                                      "" && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${childAtt?.label} is required.`}
                                                                        </p>
                                                                      )}
                                                                    {childAtt?.value !==
                                                                      "" &&
                                                                      !isValidURL(
                                                                        childAtt?.value
                                                                      ) && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${childAtt?.label} must be a valid URL.`}
                                                                        </p>
                                                                      )}
                                                                  </>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "email" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enter ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    newValue,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                                type="text"
                                                              />
                                                              {childAtt?.is_mandatory ===
                                                                true && (
                                                                  <>
                                                                    {childAtt?.value ===
                                                                      "" && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${childAtt?.label} is required.`}
                                                                        </p>
                                                                      )}
                                                                    {childAtt?.value !==
                                                                      "" &&
                                                                      !isValidEmail(
                                                                        childAtt?.value
                                                                      ) && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${childAtt?.label} must be a valid email address.`}
                                                                        </p>
                                                                      )}
                                                                  </>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "number" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enter ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    newValue,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                                type="number"
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "checkbox_single_choice" ? (
                                                            <>
                                                              <FormControlLabel
                                                                control={
                                                                  <Checkbox
                                                                    checked={
                                                                      childAtt?.value ==
                                                                      true
                                                                    }
                                                                    name={
                                                                      childAtt?.nameValue
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) => {
                                                                      const newValue =
                                                                        event
                                                                          .target
                                                                          .checked;

                                                                      handleChangeForChildAtts(
                                                                        childAtt?.nameValue,
                                                                        newValue,
                                                                        childAtt?.section_id,
                                                                        childAtt,
                                                                        attdata
                                                                      );
                                                                      childAtt.value =
                                                                        newValue;
                                                                    }}
                                                                  />
                                                                }
                                                                label={
                                                                  childAtt?.label
                                                                }
                                                                className="font-inter"
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "radio_single_choice" ? (
                                                            <>
                                                              <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                className="mt-1"
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    event
                                                                      ?.target
                                                                      ?.value,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                }}
                                                              >
                                                                {childAtt?.options?.map(
                                                                  (
                                                                    attribute
                                                                  ) => (
                                                                    <FormControlLabel
                                                                      key={
                                                                        attribute?.id
                                                                      }
                                                                      value={
                                                                        attribute?.id
                                                                      }
                                                                      control={
                                                                        <Radio
                                                                          size="small"
                                                                          checked={
                                                                            childAtt?.value ==
                                                                            attribute?.id
                                                                          }
                                                                          value={
                                                                            attribute?.id
                                                                          }
                                                                        />
                                                                      }
                                                                      label={
                                                                        attribute?.name
                                                                      }
                                                                      className="font-inter"
                                                                    />
                                                                  )
                                                                )}
                                                              </RadioGroup>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "checkbox_multiple_choice" ? (
                                                            <>
                                                              {childAtt?.options?.map(
                                                                (
                                                                  multiData,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <FormControlLabel
                                                                      control={
                                                                        <Checkbox
                                                                          checked={
                                                                            attdata?.childAtts
                                                                              .find(
                                                                                (
                                                                                  attr
                                                                                ) =>
                                                                                  attr?.id ==
                                                                                  childAtt?.id &&
                                                                                  attr?.section_id ==
                                                                                  childAtt?.section_id
                                                                              )
                                                                              ?.value?.includes(
                                                                                multiData?.id
                                                                              ) ||
                                                                            false
                                                                          }
                                                                          name={
                                                                            multiData?.id
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const checked =
                                                                              event
                                                                                ?.target
                                                                                ?.checked;
                                                                            const optionId =
                                                                              multiData?.id;

                                                                            let updatedValues;
                                                                            if (
                                                                              checked
                                                                            ) {
                                                                              // Add the optionId to the array if checked
                                                                              updatedValues =
                                                                                [
                                                                                  ...(attdata?.childAtts.find(
                                                                                    (
                                                                                      attr
                                                                                    ) =>
                                                                                      attr?.id ==
                                                                                      childAtt?.id &&
                                                                                      attr?.section_id ===
                                                                                      childAtt?.section_id
                                                                                  )
                                                                                    ?.value ||
                                                                                    []),
                                                                                  optionId,
                                                                                ];
                                                                            } else {
                                                                              // Remove the optionId from the array if unchecked

                                                                              updatedValues =
                                                                                (
                                                                                  attdata?.childAtts.find(
                                                                                    (
                                                                                      attr
                                                                                    ) =>
                                                                                      attr?.id ==
                                                                                      childAtt?.id &&
                                                                                      attr?.section_id ===
                                                                                      childAtt?.section_id
                                                                                  )
                                                                                    ?.value ||
                                                                                  []
                                                                                ).filter(
                                                                                  (
                                                                                    val
                                                                                  ) =>
                                                                                    val !==
                                                                                    optionId
                                                                                );
                                                                            }
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              updatedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                          }}
                                                                        />
                                                                      }
                                                                      label={
                                                                        multiData?.name
                                                                      }
                                                                      className="font-inter"
                                                                    />
                                                                  );
                                                                }
                                                              )}
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt
                                                                  ?.value
                                                                  ?.length <
                                                                1 && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required at least one selection.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "dropdodown_multiple_choice" ? (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                                options={
                                                                  childAtt?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Select ${childAtt?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                isMulti
                                                                value={childAtt?.options?.filter(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    childAtt?.value?.includes(
                                                                      option?.value
                                                                    )
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  const selectedValues =
                                                                    selectedOption.map(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option?.value
                                                                    );

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    selectedValues,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    selectedValues;
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt
                                                                  ?.value
                                                                  ?.length <
                                                                1 && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required at least one selection.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "time" ? (
                                                            <>
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DemoContainer
                                                                  components={[
                                                                    "TimePicker",
                                                                    "TimePicker",
                                                                  ]}
                                                                  className="p-0 date"
                                                                >
                                                                  <TimePicker
                                                                    name={
                                                                      childAtt?.nameValue
                                                                    }
                                                                    value={dayjs(
                                                                      childAtt?.value,
                                                                      "HH:mm A"
                                                                    )}
                                                                    className="w-100 pt-0"
                                                                    onChange={(
                                                                      newValue
                                                                    ) => {
                                                                      handleChangeForChildAtts(
                                                                        childAtt?.nameValue,
                                                                        newValue,
                                                                        childAtt?.section_id,
                                                                        childAtt,
                                                                        attdata
                                                                      );
                                                                      childAtt.value =
                                                                        newValue;
                                                                    }}
                                                                    slotProps={{
                                                                      field:
                                                                      {
                                                                        clearable: true,
                                                                      },
                                                                    }}
                                                                  />
                                                                </DemoContainer>
                                                              </LocalizationProvider>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "date_time" ? (
                                                            <>
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DemoContainer
                                                                  components={[
                                                                    "DateTimePicker",
                                                                  ]}
                                                                  className="p-0 date"
                                                                >
                                                                  <DateTimePicker
                                                                    name={
                                                                      childAtt?.nameValue
                                                                    }
                                                                    value={dayjs(
                                                                      childAtt?.value
                                                                    )}
                                                                    className="w-100 pt-0"
                                                                    onChange={(
                                                                      newValue
                                                                    ) => {
                                                                      handleChangeForChildAtts(
                                                                        childAtt?.nameValue,
                                                                        newValue,
                                                                        childAtt?.section_id,
                                                                        childAtt,
                                                                        attdata
                                                                      );
                                                                      childAtt.value =
                                                                        newValue;
                                                                    }}
                                                                    slotProps={{
                                                                      field:
                                                                      {
                                                                        clearable: true,
                                                                      },
                                                                    }}
                                                                  />
                                                                </DemoContainer>
                                                              </LocalizationProvider>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "date_range" ? (
                                                            <>
                                                              <DateRangePicker
                                                                // ref={keyRef}
                                                                initialSettings={{
                                                                  startDate:
                                                                    childAtt
                                                                      ?.value
                                                                      ?.startDate,
                                                                  endDate:
                                                                    childAtt
                                                                      ?.value
                                                                      ?.endDate,
                                                                  autoUpdateInput: false,
                                                                }}
                                                                onApply={(
                                                                  _,
                                                                  range
                                                                ) => {
                                                                  const startDate =
                                                                    range?.startDate;
                                                                  const endDate =
                                                                    range?.endDate;
                                                                  let data =
                                                                  {
                                                                    startDate:
                                                                      startDate,
                                                                    endDate:
                                                                      endDate,
                                                                  };

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    data,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    data;
                                                                }}
                                                              >
                                                                <TextField
                                                                  className="input-field mt-2"
                                                                  fullWidth
                                                                  id="outlined-basic short summary"
                                                                  variant="outlined"
                                                                  placeholder={`Enter ${childAtt?.label}...`}
                                                                  value={
                                                                    childAtt?.value
                                                                      ? `${moment(
                                                                        childAtt
                                                                          ?.value
                                                                          ?.startDate
                                                                      ).format(
                                                                        "DD/MM/YYYY"
                                                                      )} - ${moment(
                                                                        childAtt
                                                                          ?.value
                                                                          ?.endDate
                                                                      ).format(
                                                                        "DD/MM/YYYY"
                                                                      )}`
                                                                      : ""
                                                                  }
                                                                  type="text"
                                                                />
                                                              </DateRangePicker>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "text_editor" ? (
                                                            <>
                                                              <CKEditor
                                                                editor={
                                                                  Editor
                                                                }
                                                                className="mt-2"
                                                                config={
                                                                  configForContentEditor
                                                                }
                                                                data={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event,
                                                                  editor
                                                                ) => {
                                                                  const data =
                                                                    editor?.getData();

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    data,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    data;
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "html" ? (
                                                            <>
                                                              <CKEditor
                                                                editor={
                                                                  Editor
                                                                }
                                                                className="mt-2"
                                                                config={
                                                                  configForContentEditorForHTML
                                                                }
                                                                data={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event,
                                                                  editor
                                                                ) => {
                                                                  const data =
                                                                    editor?.getData();

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    data,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    data;
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "image" ? (
                                                            <>
                                                              <Stack
                                                                gap={4}
                                                              >
                                                                {childAtt?.value && (
                                                                  <div className="upload-img-wrapper">
                                                                    <Image
                                                                      src={
                                                                        childAtt?.value
                                                                      }
                                                                    />
                                                                  </div>
                                                                )}

                                                                <Button
                                                                  className="btn ternary-btn w-100"
                                                                  onClick={() =>
                                                                    handleClickForAtt(
                                                                      "image",
                                                                      attdata
                                                                    )
                                                                  }
                                                                >
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faUpload
                                                                    }
                                                                  />
                                                                  {attdata?.value
                                                                    ? "Change Image"
                                                                    : "Upload Image"}
                                                                </Button>
                                                              </Stack>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "file" ? (
                                                            <>
                                                              <Stack
                                                                gap={3}
                                                              >
                                                                {childAtt?.value && (
                                                                  <div className="upload-img-wrapper mt-2">
                                                                    {childAtt?.value !==
                                                                      "" &&
                                                                      `File name: ${childAtt
                                                                        ?.value
                                                                        ?.name ||
                                                                      childAtt?.value
                                                                      }`}
                                                                  </div>
                                                                )}

                                                                <Button
                                                                  className="btn ternary-btn w-100 mt-3"
                                                                  onClick={() =>
                                                                    handleClickForAtt(
                                                                      "file",
                                                                      attdata
                                                                    )
                                                                  }
                                                                >
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faUpload
                                                                    }
                                                                  />
                                                                  {attdata?.value
                                                                    ? "Change File"
                                                                    : "Upload File"}
                                                                </Button>
                                                              </Stack>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "users_list" ? (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                                options={
                                                                  childAtt?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Select ${childAtt?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                isMulti
                                                                value={childAtt?.options.filter(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    childAtt?.value?.includes(
                                                                      option?.value
                                                                    )
                                                                )}

                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  const selectedValues =
                                                                    selectedOption.map(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option?.value
                                                                    );

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    selectedValues,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    selectedValues;
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "group_list" ? (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                                options={
                                                                  childAtt?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Select ${childAtt?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                isMulti
                                                                value={childAtt?.options.find(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    option?.value ===
                                                                    childAtt?.value
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  const selectedValues =
                                                                    selectedOption.map(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option?.value
                                                                    );

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    selectedValues,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    selectedValues;
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </Col>
                                                      )
                                                    )}
                                                  </>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "long_text" ? (
                                            <>
                                              <TextField
                                                className="input-field mt-2"
                                                fullWidth
                                                id="outlined-basic short summary"
                                                variant="outlined"
                                                multiline
                                                maxRows={2}
                                                name={
                                                  attdata?.nameValue
                                                }
                                                placeholder={`Enter ${attdata?.label}...`}
                                                value={attdata?.value}
                                                onChange={(event) =>
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    event?.target
                                                      ?.value,
                                                    attdata?.section_id
                                                  )
                                                }
                                              />
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "short_text" ? (
                                            <>
                                              <TextField
                                                className="input-field mt-2"
                                                fullWidth
                                                id="outlined-basic short summary"
                                                variant="outlined"
                                                name={
                                                  attdata?.nameValue
                                                }
                                                placeholder={`Enter ${attdata?.label}...`}
                                                value={attdata?.value}
                                                onChange={(event) =>
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    event?.target
                                                      ?.value,
                                                    attdata?.section_id
                                                  )
                                                }
                                              />
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "date" ? (
                                            <>
                                              <LocalizationProvider
                                                dateAdapter={
                                                  AdapterDayjs
                                                }
                                              >
                                                <DemoContainer
                                                  components={[
                                                    "DatePicker",
                                                    "DatePicker",
                                                  ]}
                                                  className="p-0 date"
                                                >
                                                  <DatePicker
                                                    name={
                                                      attdata?.nameValue
                                                    }
                                                    value={dayjs(
                                                      attdata?.value
                                                    )}
                                                    className="w-100 pt-0"
                                                    onChange={(
                                                      newValue
                                                    ) => {
                                                      if (
                                                        (attdata?.nameValue === "date_birthday" || attdata?.nameValue === "date_start_date") &&
                                                        newValue.isAfter(dayjs())
                                                      ) {
                                                        handleChangeForFormData(attdata?.nameValue, dayjs(), attdata?.section_id);
                                                      } else {
                                                        handleChangeForFormData(attdata?.nameValue, newValue, attdata?.section_id);
                                                      }
                                                    }}
                                                    views={[
                                                      "year",
                                                      "month",
                                                      "day",
                                                    ]}
                                                    slotProps={{
                                                      field: {
                                                        clearable: true,
                                                      },
                                                    }}
                                                    maxDate={
                                                      attdata?.nameValue === "date_birthday" || attdata?.nameValue === "date_start_date"
                                                        ? dayjs()
                                                        : undefined
                                                    }
                                                  />
                                                </DemoContainer>
                                              </LocalizationProvider>
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "radio_single_choice" ? (
                                            <>
                                              <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name={
                                                  attdata?.nameValue
                                                }
                                                className="mt-1"
                                                value={attdata?.value}
                                                onChange={(event) => {
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    event?.target
                                                      ?.value,
                                                    attdata?.section_id
                                                  );
                                                }}
                                              >
                                                {attdata?.options?.map(
                                                  (attribute) => (
                                                    <FormControlLabel
                                                      key={
                                                        attribute?.id
                                                      }
                                                      value={
                                                        attribute?.id
                                                      }
                                                      control={
                                                        <Radio
                                                          size="small"
                                                          checked={
                                                            attdata?.value ==
                                                            attribute?.id
                                                          }
                                                          value={
                                                            attribute?.id
                                                          }
                                                        />
                                                      }
                                                      label={
                                                        attribute?.name
                                                      }
                                                      className="font-inter"
                                                    />
                                                  )
                                                )}
                                              </RadioGroup>
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                              {attdata?.childAtts
                                                ?.length > 0 &&
                                                attdata?.value == 1 && (
                                                  <>
                                                    {attdata?.childAtts?.map(
                                                      (
                                                        childAtt,
                                                        index
                                                      ) => (
                                                        <Col lg="12 mt-3">
                                                          {childAtt?.fieldtype !==
                                                            "checkbox_single_choice" && (
                                                              <Typography
                                                                className={`${childAtt?.fieldtype ===
                                                                  "text_editor" ||
                                                                  childAtt?.fieldtype ===
                                                                  "html" ||
                                                                  childAtt?.fieldtype ===
                                                                  "image" ||
                                                                  childAtt?.fieldtype ===
                                                                  "file"
                                                                  ? "mb-2 fs-14"
                                                                  : "fs-14"
                                                                  }`}
                                                              >
                                                                {
                                                                  childAtt?.label
                                                                }{" "}
                                                                {childAtt?.is_mandatory ==
                                                                  true && (
                                                                    <span className="required">
                                                                      *
                                                                    </span>
                                                                  )}
                                                              </Typography>
                                                            )}
                                                          {childAtt?.fieldtype ===
                                                            "dropdodown_single_choice" ? (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  true
                                                                }
                                                                options={
                                                                  childAtt?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Select ${childAtt?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                value={childAtt?.options.find(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    option?.value ==
                                                                    childAtt?.value
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    selectedOption?.value,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  // Update the value in the childAtt directly
                                                                  childAtt.value =
                                                                    selectedOption?.value;
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "long_text" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                multiline
                                                                maxRows={
                                                                  2
                                                                }
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enter ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    event
                                                                      .target
                                                                      .value,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "short_text" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enters ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    newValue,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  // Update the value in the childAtt directly
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "date" ? (
                                                            <>
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DemoContainer
                                                                  components={[
                                                                    "DatePicker",
                                                                    "DatePicker",
                                                                  ]}
                                                                  className="p-0 date"
                                                                >
                                                                  <DatePicker
                                                                    name={
                                                                      childAtt?.nameValue
                                                                    }
                                                                    value={dayjs(
                                                                      childAtt?.value
                                                                    )}
                                                                    className="w-100 pt-0"
                                                                    onChange={(
                                                                      newValue
                                                                    ) => {
                                                                      handleChangeForChildAtts(
                                                                        childAtt?.nameValue,
                                                                        newValue,
                                                                        childAtt?.section_id,
                                                                        childAtt,
                                                                        attdata
                                                                      );
                                                                      childAtt.value =
                                                                        newValue;
                                                                    }}
                                                                    views={[
                                                                      "year",
                                                                      "month",
                                                                      "day",
                                                                    ]}
                                                                    slotProps={{
                                                                      field:
                                                                      {
                                                                        clearable: true,
                                                                      },
                                                                    }}
                                                                  />
                                                                </DemoContainer>
                                                              </LocalizationProvider>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "link" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enter ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    newValue,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                                type="text"
                                                              />
                                                              {childAtt?.is_mandatory ===
                                                                true && (
                                                                  <>
                                                                    {childAtt?.value ===
                                                                      "" && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${childAtt?.label} is required.`}
                                                                        </p>
                                                                      )}
                                                                    {childAtt?.value !==
                                                                      "" &&
                                                                      !isValidURL(
                                                                        childAtt?.value
                                                                      ) && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${childAtt?.label} must be a valid URL.`}
                                                                        </p>
                                                                      )}
                                                                  </>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "email" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enter ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    newValue,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                                type="text"
                                                              />
                                                              {childAtt?.is_mandatory ===
                                                                true && (
                                                                  <>
                                                                    {childAtt?.value ===
                                                                      "" && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${childAtt?.label} is required.`}
                                                                        </p>
                                                                      )}
                                                                    {childAtt?.value !==
                                                                      "" &&
                                                                      !isValidEmail(
                                                                        childAtt?.value
                                                                      ) && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${childAtt?.label} must be a valid email address.`}
                                                                        </p>
                                                                      )}
                                                                  </>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "number" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enter ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    newValue,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                                type="number"
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "checkbox_single_choice" ? (
                                                            <>
                                                              <FormControlLabel
                                                                control={
                                                                  <Checkbox
                                                                    checked={
                                                                      childAtt?.value ==
                                                                      true
                                                                    }
                                                                    name={
                                                                      childAtt?.nameValue
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) => {
                                                                      const newValue =
                                                                        event
                                                                          .target
                                                                          .checked;

                                                                      handleChangeForChildAtts(
                                                                        childAtt?.nameValue,
                                                                        newValue,
                                                                        childAtt?.section_id,
                                                                        childAtt,
                                                                        attdata
                                                                      );
                                                                      childAtt.value =
                                                                        newValue;
                                                                    }}
                                                                  />
                                                                }
                                                                label={
                                                                  childAtt?.label
                                                                }
                                                                className="font-inter"
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "radio_single_choice" ? (
                                                            <>
                                                              <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                className="mt-1"
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    event
                                                                      ?.target
                                                                      ?.value,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                }}
                                                              >
                                                                {childAtt?.options?.map(
                                                                  (
                                                                    attribute
                                                                  ) => (
                                                                    <FormControlLabel
                                                                      key={
                                                                        attribute?.id
                                                                      }
                                                                      value={
                                                                        attribute?.id
                                                                      }
                                                                      control={
                                                                        <Radio
                                                                          size="small"
                                                                          checked={
                                                                            attdata?.value ==
                                                                            attribute?.id
                                                                          }
                                                                          value={
                                                                            attribute?.id
                                                                          }
                                                                        />
                                                                      }
                                                                      label={
                                                                        attribute?.name
                                                                      }
                                                                      className="font-inter"
                                                                    />
                                                                  )
                                                                )}
                                                              </RadioGroup>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "checkbox_multiple_choice" ? (
                                                            <>
                                                              {childAtt?.options?.map(
                                                                (
                                                                  multiData,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <FormControlLabel
                                                                      control={
                                                                        <Checkbox
                                                                          checked={
                                                                            attdata?.childAtts
                                                                              .find(
                                                                                (
                                                                                  attr
                                                                                ) =>
                                                                                  attr?.id ==
                                                                                  childAtt?.id &&
                                                                                  attr?.section_id ==
                                                                                  childAtt?.section_id
                                                                              )
                                                                              ?.value?.includes(
                                                                                multiData?.id
                                                                              ) ||
                                                                            false
                                                                          }
                                                                          name={
                                                                            multiData?.id
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const checked =
                                                                              event
                                                                                ?.target
                                                                                ?.checked;
                                                                            const optionId =
                                                                              multiData?.id;

                                                                            let updatedValues;
                                                                            if (
                                                                              checked
                                                                            ) {
                                                                              // Add the optionId to the array if checked
                                                                              updatedValues =
                                                                                [
                                                                                  ...(attdata?.childAtts.find(
                                                                                    (
                                                                                      attr
                                                                                    ) =>
                                                                                      attr?.id ==
                                                                                      childAtt?.id &&
                                                                                      attr?.section_id ===
                                                                                      childAtt?.section_id
                                                                                  )
                                                                                    ?.value ||
                                                                                    []),
                                                                                  optionId,
                                                                                ];
                                                                            } else {
                                                                              // Remove the optionId from the array if unchecked

                                                                              updatedValues =
                                                                                (
                                                                                  attdata?.childAtts.find(
                                                                                    (
                                                                                      attr
                                                                                    ) =>
                                                                                      attr?.id ==
                                                                                      childAtt?.id &&
                                                                                      attr?.section_id ===
                                                                                      childAtt?.section_id
                                                                                  )
                                                                                    ?.value ||
                                                                                  []
                                                                                ).filter(
                                                                                  (
                                                                                    val
                                                                                  ) =>
                                                                                    val !==
                                                                                    optionId
                                                                                );
                                                                            }

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              updatedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                          }}
                                                                        />
                                                                      }
                                                                      label={
                                                                        multiData?.name
                                                                      }
                                                                      className="font-inter"
                                                                    />
                                                                  );
                                                                }
                                                              )}
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt
                                                                  ?.value
                                                                  ?.length <
                                                                1 && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required at least one selection.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "dropdodown_multiple_choice" ? (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                                options={
                                                                  childAtt?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Select ${childAtt?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                isMulti
                                                                value={childAtt?.options?.filter(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    childAtt?.value?.includes(
                                                                      option?.value
                                                                    )
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  const selectedValues =
                                                                    selectedOption.map(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option?.value
                                                                    );

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    selectedValues,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    selectedValues;
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt
                                                                  ?.value
                                                                  ?.length <
                                                                1 && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required at least one selection.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "time" ? (
                                                            <>
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DemoContainer
                                                                  components={[
                                                                    "TimePicker",
                                                                    "TimePicker",
                                                                  ]}
                                                                  className="p-0 date"
                                                                >
                                                                  <TimePicker
                                                                    name={
                                                                      childAtt?.nameValue
                                                                    }
                                                                    value={dayjs(
                                                                      childAtt?.value,
                                                                      "HH:mm A"
                                                                    )}
                                                                    className="w-100 pt-0"
                                                                    onChange={(
                                                                      newValue
                                                                    ) => {
                                                                      handleChangeForChildAtts(
                                                                        childAtt?.nameValue,
                                                                        newValue,
                                                                        childAtt?.section_id,
                                                                        childAtt,
                                                                        attdata
                                                                      );
                                                                      childAtt.value =
                                                                        newValue;
                                                                    }}
                                                                    slotProps={{
                                                                      field:
                                                                      {
                                                                        clearable: true,
                                                                      },
                                                                    }}
                                                                  />
                                                                </DemoContainer>
                                                              </LocalizationProvider>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "date_time" ? (
                                                            <>
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DemoContainer
                                                                  components={[
                                                                    "DateTimePicker",
                                                                  ]}
                                                                  className="p-0 date"
                                                                >
                                                                  <DateTimePicker
                                                                    name={
                                                                      childAtt?.nameValue
                                                                    }
                                                                    value={dayjs(
                                                                      childAtt?.value
                                                                    )}
                                                                    className="w-100 pt-0"
                                                                    onChange={(
                                                                      newValue
                                                                    ) => {
                                                                      handleChangeForChildAtts(
                                                                        childAtt?.nameValue,
                                                                        newValue,
                                                                        childAtt?.section_id,
                                                                        childAtt,
                                                                        attdata
                                                                      );
                                                                      childAtt.value =
                                                                        newValue;
                                                                    }}
                                                                    slotProps={{
                                                                      field:
                                                                      {
                                                                        clearable: true,
                                                                      },
                                                                    }}
                                                                  />
                                                                </DemoContainer>
                                                              </LocalizationProvider>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "date_range" ? (
                                                            <>
                                                              <DateRangePicker
                                                                // ref={keyRef}
                                                                initialSettings={{
                                                                  startDate:
                                                                    childAtt
                                                                      ?.value
                                                                      ?.startDate,
                                                                  endDate:
                                                                    childAtt
                                                                      ?.value
                                                                      ?.endDate,
                                                                  autoUpdateInput: false,
                                                                }}
                                                                onApply={(
                                                                  _,
                                                                  range
                                                                ) => {
                                                                  const startDate =
                                                                    range?.startDate;
                                                                  const endDate =
                                                                    range?.endDate;
                                                                  let data =
                                                                  {
                                                                    startDate:
                                                                      startDate,
                                                                    endDate:
                                                                      endDate,
                                                                  };

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    data,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    data;
                                                                }}
                                                              >
                                                                <TextField
                                                                  className="input-field mt-2"
                                                                  fullWidth
                                                                  id="outlined-basic short summary"
                                                                  variant="outlined"
                                                                  placeholder={`Enter ${childAtt?.label}...`}
                                                                  value={
                                                                    childAtt?.value
                                                                      ? `${moment(
                                                                        childAtt
                                                                          ?.value
                                                                          ?.startDate
                                                                      ).format(
                                                                        "DD/MM/YYYY"
                                                                      )} - ${moment(
                                                                        childAtt
                                                                          ?.value
                                                                          ?.endDate
                                                                      ).format(
                                                                        "DD/MM/YYYY"
                                                                      )}`
                                                                      : ""
                                                                  }
                                                                  type="text"
                                                                />
                                                              </DateRangePicker>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "text_editor" ? (
                                                            <>
                                                              <CKEditor
                                                                editor={
                                                                  Editor
                                                                }
                                                                className="mt-2"
                                                                config={
                                                                  configForContentEditor
                                                                }
                                                                data={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event,
                                                                  editor
                                                                ) => {
                                                                  const data =
                                                                    editor?.getData();

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    data,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    data;
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "html" ? (
                                                            <>
                                                              <CKEditor
                                                                editor={
                                                                  Editor
                                                                }
                                                                className="mt-2"
                                                                config={
                                                                  configForContentEditorForHTML
                                                                }
                                                                data={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event,
                                                                  editor
                                                                ) => {
                                                                  const data =
                                                                    editor?.getData();

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    data,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    data;
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "image" ? (
                                                            <>
                                                              <Stack
                                                                gap={4}
                                                              >
                                                                {childAtt?.value && (
                                                                  <div className="upload-img-wrapper">
                                                                    <Image
                                                                      src={
                                                                        childAtt?.value
                                                                      }
                                                                    />
                                                                  </div>
                                                                )}

                                                                <Button
                                                                  className="btn ternary-btn w-100"
                                                                  onClick={() =>
                                                                    handleClickForAtt(
                                                                      "image",
                                                                      attdata
                                                                    )
                                                                  }
                                                                >
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faUpload
                                                                    }
                                                                  />
                                                                  {value
                                                                    ? "Change Image"
                                                                    : "Upload Image"}
                                                                </Button>
                                                              </Stack>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "file" ? (
                                                            <>
                                                              <Stack
                                                                gap={3}
                                                              >
                                                                {childAtt?.value && (
                                                                  <div className="upload-img-wrapper mt-2">
                                                                    {childAtt?.value !==
                                                                      "" &&
                                                                      `File name: ${childAtt
                                                                        ?.value
                                                                        ?.name ||
                                                                      childAtt?.value
                                                                      }`}
                                                                  </div>
                                                                )}

                                                                <Button
                                                                  className="btn ternary-btn w-100 mt-3"
                                                                  onClick={() =>
                                                                    handleClickForAtt(
                                                                      "file",
                                                                      attdata
                                                                    )
                                                                  }
                                                                >
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faUpload
                                                                    }
                                                                  />
                                                                  {attdata?.value
                                                                    ? "Change File"
                                                                    : "Upload File"}
                                                                </Button>
                                                              </Stack>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "users_list" ? (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                                options={
                                                                  childAtt?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Select ${childAtt?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                isMulti
                                                                value={childAtt?.options.filter(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    childAtt?.value?.includes(
                                                                      option?.value
                                                                    )
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  const selectedValues =
                                                                    selectedOption.map(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option?.value
                                                                    );

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    selectedValues,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    selectedValues;
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "group_list" ? (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                                options={
                                                                  childAtt?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Select ${childAtt?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                isMulti
                                                                value={childAtt?.options.find(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    option?.value ===
                                                                    childAtt?.value
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  const selectedValues =
                                                                    selectedOption.map(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option?.value
                                                                    );

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    selectedValues,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    selectedValues;
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </Col>
                                                      )
                                                    )}
                                                  </>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "link" ? (
                                            <>
                                              <TextField
                                                className="input-field mt-2"
                                                fullWidth
                                                id="outlined-basic short summary"
                                                variant="outlined"
                                                name={
                                                  attdata?.nameValue
                                                }
                                                placeholder={`Enter ${attdata?.label}...`}
                                                value={attdata?.value}
                                                onChange={(event) =>
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    event?.target
                                                      ?.value,
                                                    attdata?.section_id
                                                  )
                                                }
                                                type="text"
                                              />
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true && (
                                                  <>
                                                    {attdata?.value ===
                                                      "" && (
                                                        <p
                                                          style={{
                                                            color:
                                                              "#d32f2f",
                                                          }}
                                                          className="error-msg"
                                                        >
                                                          {`${attdata?.label} is required.`}
                                                        </p>
                                                      )}
                                                    {attdata?.value !==
                                                      "" &&
                                                      !isValidURL(
                                                        attdata?.value
                                                      ) && (
                                                        <p
                                                          style={{
                                                            color:
                                                              "#d32f2f",
                                                          }}
                                                          className="error-msg"
                                                        >
                                                          {`${attdata?.label} must be a valid URL.`}
                                                        </p>
                                                      )}
                                                  </>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "email" ? (
                                            <>
                                              <TextField
                                                className="input-field mt-2"
                                                fullWidth
                                                id="outlined-basic short summary"
                                                variant="outlined"
                                                name={
                                                  attdata?.nameValue
                                                }
                                                placeholder={`Enter ${attdata?.label}...`}
                                                value={attdata?.value}
                                                onChange={(event) =>
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    event?.target
                                                      ?.value,
                                                    attdata?.section_id
                                                  )
                                                }
                                                type="text"
                                              />
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true && (
                                                  <>
                                                    {attdata?.value ===
                                                      "" && (
                                                        <p
                                                          style={{
                                                            color:
                                                              "#d32f2f",
                                                          }}
                                                          className="error-msg"
                                                        >
                                                          {`${attdata?.label} is required.`}
                                                        </p>
                                                      )}
                                                    {attdata?.value !==
                                                      "" &&
                                                      !isValidEmail(
                                                        attdata?.value
                                                      ) && (
                                                        <p
                                                          style={{
                                                            color:
                                                              "#d32f2f",
                                                          }}
                                                          className="error-msg"
                                                        >
                                                          {`${attdata?.label} must be a valid email address.`}
                                                        </p>
                                                      )}
                                                  </>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "number" ? (
                                            <>
                                              <TextField
                                                className="input-field mt-2"
                                                fullWidth
                                                id="outlined-basic short summary"
                                                variant="outlined"
                                                name={
                                                  attdata?.nameValue
                                                }
                                                placeholder={`Enter ${attdata?.label}...`}
                                                value={attdata?.value}
                                                onChange={(event) =>
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    event?.target
                                                      ?.value,
                                                    attdata?.section_id
                                                  )
                                                }
                                                type="number"
                                              />
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "checkbox_single_choice" ? (
                                            <>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={
                                                      attdata?.value ==
                                                      true
                                                    }
                                                    name={
                                                      attdata?.nameValue
                                                    }
                                                    onChange={(
                                                      event
                                                    ) => {
                                                      handleChangeForFormData(
                                                        attdata?.nameValue,
                                                        event?.target
                                                          ?.checked,
                                                        attdata?.section_id
                                                      );
                                                    }}
                                                  />
                                                }
                                                sx={{ marginRight: "0" }}
                                                label={attdata?.label}
                                                className="font-inter ack-5"
                                              />
                                              {attdata?.is_mandatory ==
                                                true && (
                                                  <span className="required">
                                                    *
                                                  </span>
                                                )}
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                              {attdata?.childAtts
                                                ?.length > 0 &&
                                                attdata?.value ==
                                                true && (
                                                  <>
                                                    {attdata?.childAtts?.map(
                                                      (
                                                        childAtt,
                                                        index
                                                      ) => (
                                                        <Col lg="12 mt-3">
                                                          {childAtt?.fieldtype !==
                                                            "checkbox_single_choice" && (
                                                              <Typography
                                                                className={`${childAtt?.fieldtype ===
                                                                  "text_editor" ||
                                                                  childAtt?.fieldtype ===
                                                                  "html" ||
                                                                  childAtt?.fieldtype ===
                                                                  "image" ||
                                                                  childAtt?.fieldtype ===
                                                                  "file"
                                                                  ? "mb-2 fs-14"
                                                                  : "fs-14"
                                                                  }`}
                                                              >
                                                                {
                                                                  childAtt?.label
                                                                }{" "}
                                                                {childAtt?.is_mandatory ==
                                                                  true && (
                                                                    <span className="required">
                                                                      *
                                                                    </span>
                                                                  )}
                                                              </Typography>
                                                            )}
                                                          {childAtt?.fieldtype ===
                                                            "dropdodown_single_choice" ? (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  true
                                                                }
                                                                options={
                                                                  childAtt?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Select ${childAtt?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                value={childAtt?.options.find(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    option?.value ==
                                                                    childAtt?.value
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    selectedOption?.value,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  // Update the value in the childAtt directly
                                                                  childAtt.value =
                                                                    selectedOption?.value;
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "long_text" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                multiline
                                                                maxRows={
                                                                  2
                                                                }
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enter ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    event
                                                                      .target
                                                                      .value,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "short_text" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enters ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    newValue,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  // Update the value in the childAtt directly
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "date" ? (
                                                            <>
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DemoContainer
                                                                  components={[
                                                                    "DatePicker",
                                                                    "DatePicker",
                                                                  ]}
                                                                  className="p-0 date"
                                                                >
                                                                  <DatePicker
                                                                    name={
                                                                      childAtt?.nameValue
                                                                    }
                                                                    value={
                                                                      childAtt?.value
                                                                    }
                                                                    className="w-100 pt-0"
                                                                    onChange={(
                                                                      newValue
                                                                    ) => {
                                                                      handleChangeForChildAtts(
                                                                        childAtt?.nameValue,
                                                                        newValue,
                                                                        childAtt?.section_id,
                                                                        childAtt,
                                                                        attdata
                                                                      );
                                                                      childAtt.value =
                                                                        newValue;
                                                                    }}
                                                                    views={[
                                                                      "year",
                                                                      "month",
                                                                      "day",
                                                                    ]}
                                                                    slotProps={{
                                                                      field:
                                                                      {
                                                                        clearable: true,
                                                                      },
                                                                    }}
                                                                  />
                                                                </DemoContainer>
                                                              </LocalizationProvider>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "link" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enter ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    newValue,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                                type="text"
                                                              />
                                                              {childAtt?.is_mandatory ===
                                                                true && (
                                                                  <>
                                                                    {childAtt?.value ===
                                                                      "" && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${childAtt?.label} is required.`}
                                                                        </p>
                                                                      )}
                                                                    {childAtt?.value !==
                                                                      "" &&
                                                                      !isValidURL(
                                                                        childAtt?.value
                                                                      ) && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${childAtt?.label} must be a valid URL.`}
                                                                        </p>
                                                                      )}
                                                                  </>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "email" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enter ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    newValue,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                                type="email"
                                                              />
                                                              {attdata?.is_mandatory ===
                                                                true && (
                                                                  <>
                                                                    {attdata?.value ===
                                                                      "" && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${attdata?.label} is required.`}
                                                                        </p>
                                                                      )}
                                                                    {attdata?.value !==
                                                                      "" &&
                                                                      !isValidEmail(
                                                                        attdata?.value
                                                                      ) && (
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#d32f2f",
                                                                          }}
                                                                          className="error-msg"
                                                                        >
                                                                          {`${attdata?.label} must be a valid email address.`}
                                                                        </p>
                                                                      )}
                                                                  </>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "number" ? (
                                                            <>
                                                              <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic short summary"
                                                                variant="outlined"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Enter ${childAtt?.label}...`}
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  const newValue =
                                                                    event
                                                                      .target
                                                                      .value;

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    newValue,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    newValue;
                                                                }}
                                                                type="number"
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "checkbox_single_choice" ? (
                                                            <>
                                                              <FormControlLabel
                                                                control={
                                                                  <Checkbox
                                                                    checked={
                                                                      childAtt?.value ==
                                                                      true
                                                                    }
                                                                    name={
                                                                      childAtt?.nameValue
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) => {
                                                                      const newValue =
                                                                        event
                                                                          .target
                                                                          .checked;

                                                                      handleChangeForChildAtts(
                                                                        childAtt?.nameValue,
                                                                        newValue,
                                                                        childAtt?.section_id,
                                                                        childAtt,
                                                                        attdata
                                                                      );
                                                                      childAtt.value =
                                                                        newValue;
                                                                    }}
                                                                  />
                                                                }
                                                                label={
                                                                  childAtt?.label
                                                                }
                                                                className="font-inter"
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "radio_single_choice" ? (
                                                            <>
                                                              <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                className="mt-1"
                                                                value={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    event
                                                                      ?.target
                                                                      ?.value,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                }}
                                                              >
                                                                {childAtt?.options?.map(
                                                                  (
                                                                    attribute
                                                                  ) => (
                                                                    <FormControlLabel
                                                                      key={
                                                                        attribute?.id
                                                                      }
                                                                      value={
                                                                        attribute?.id
                                                                      }
                                                                      control={
                                                                        <Radio
                                                                          size="small"
                                                                          checked={
                                                                            childAtt?.value ==
                                                                            attribute?.id
                                                                          }
                                                                          value={
                                                                            attribute?.id
                                                                          }
                                                                        />
                                                                      }
                                                                      label={
                                                                        attribute?.name
                                                                      }
                                                                      className="font-inter"
                                                                    />
                                                                  )
                                                                )}
                                                              </RadioGroup>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "checkbox_multiple_choice" ? (
                                                            <>
                                                              {childAtt?.options?.map(
                                                                (
                                                                  multiData,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <FormControlLabel
                                                                      control={
                                                                        <Checkbox
                                                                          checked={
                                                                            attdata?.childAtts
                                                                              .find(
                                                                                (
                                                                                  attr
                                                                                ) =>
                                                                                  attr?.id ==
                                                                                  childAtt?.id &&
                                                                                  attr?.section_id ==
                                                                                  childAtt?.section_id
                                                                              )
                                                                              ?.value?.includes(
                                                                                multiData?.id
                                                                              ) ||
                                                                            false
                                                                          }
                                                                          name={
                                                                            multiData?.id
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const checked =
                                                                              event
                                                                                ?.target
                                                                                ?.checked;
                                                                            const optionId =
                                                                              multiData?.id;

                                                                            let updatedValues;
                                                                            if (
                                                                              checked
                                                                            ) {
                                                                              // Add the optionId to the array if checked
                                                                              updatedValues =
                                                                                [
                                                                                  ...(attdata?.childAtts.find(
                                                                                    (
                                                                                      attr
                                                                                    ) =>
                                                                                      attr?.id ==
                                                                                      childAtt?.id &&
                                                                                      attr?.section_id ===
                                                                                      childAtt?.section_id
                                                                                  )
                                                                                    ?.value ||
                                                                                    []),
                                                                                  optionId,
                                                                                ];
                                                                            } else {
                                                                              // Remove the optionId from the array if unchecked

                                                                              updatedValues =
                                                                                (
                                                                                  attdata?.childAtts.find(
                                                                                    (
                                                                                      attr
                                                                                    ) =>
                                                                                      attr?.id ==
                                                                                      childAtt?.id &&
                                                                                      attr?.section_id ===
                                                                                      childAtt?.section_id
                                                                                  )
                                                                                    ?.value ||
                                                                                  []
                                                                                ).filter(
                                                                                  (
                                                                                    val
                                                                                  ) =>
                                                                                    val !==
                                                                                    optionId
                                                                                );
                                                                            }

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              updatedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                          }}
                                                                        />
                                                                      }
                                                                      label={
                                                                        multiData?.name
                                                                      }
                                                                      className="font-inter"
                                                                    />
                                                                  );
                                                                }
                                                              )}
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt
                                                                  ?.value
                                                                  ?.length <
                                                                1 && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required at least one selection.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "dropdodown_multiple_choice" ? (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                                options={
                                                                  childAtt?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Select ${childAtt?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                isMulti
                                                                value={childAtt?.options?.filter(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    childAtt?.value?.includes(
                                                                      option?.value
                                                                    )
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  const selectedValues =
                                                                    selectedOption.map(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option?.value
                                                                    );

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    selectedValues,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    selectedValues;
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt
                                                                  ?.value
                                                                  ?.length <
                                                                1 && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required at least one selection.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "time" ? (
                                                            <>
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DemoContainer
                                                                  components={[
                                                                    "TimePicker",
                                                                    "TimePicker",
                                                                  ]}
                                                                  className="p-0 date"
                                                                >
                                                                  <TimePicker
                                                                    name={
                                                                      childAtt?.nameValue
                                                                    }
                                                                    value={dayjs(
                                                                      childAtt?.value,
                                                                      "HH:mm A"
                                                                    )}
                                                                    className="w-100 pt-0"
                                                                    onChange={(
                                                                      newValue
                                                                    ) => {
                                                                      handleChangeForChildAtts(
                                                                        childAtt?.nameValue,
                                                                        newValue,
                                                                        childAtt?.section_id,
                                                                        childAtt,
                                                                        attdata
                                                                      );
                                                                      childAtt.value =
                                                                        newValue;
                                                                    }}
                                                                    slotProps={{
                                                                      field:
                                                                      {
                                                                        clearable: true,
                                                                      },
                                                                    }}
                                                                  />
                                                                </DemoContainer>
                                                              </LocalizationProvider>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "date_time" ? (
                                                            <>
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DemoContainer
                                                                  components={[
                                                                    "DateTimePicker",
                                                                  ]}
                                                                  className="p-0 date"
                                                                >
                                                                  <DateTimePicker
                                                                    name={
                                                                      childAtt?.nameValue
                                                                    }
                                                                    value={dayjs(
                                                                      childAtt?.value
                                                                    )}
                                                                    className="w-100 pt-0"
                                                                    onChange={(
                                                                      newValue
                                                                    ) => {
                                                                      handleChangeForChildAtts(
                                                                        childAtt?.nameValue,
                                                                        newValue,
                                                                        childAtt?.section_id,
                                                                        childAtt,
                                                                        attdata
                                                                      );
                                                                      childAtt.value =
                                                                        newValue;
                                                                    }}
                                                                    slotProps={{
                                                                      field:
                                                                      {
                                                                        clearable: true,
                                                                      },
                                                                    }}
                                                                  />
                                                                </DemoContainer>
                                                              </LocalizationProvider>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "date_range" ? (
                                                            <>
                                                              <DateRangePicker
                                                                // ref={keyRef}
                                                                initialSettings={{
                                                                  startDate:
                                                                    childAtt
                                                                      ?.value
                                                                      ?.startDate,
                                                                  endDate:
                                                                    childAtt
                                                                      ?.value
                                                                      ?.endDate,
                                                                  autoUpdateInput: false,
                                                                }}
                                                                onApply={(
                                                                  _,
                                                                  range
                                                                ) => {
                                                                  const startDate =
                                                                    range?.startDate;
                                                                  const endDate =
                                                                    range?.endDate;
                                                                  let data =
                                                                  {
                                                                    startDate:
                                                                      startDate,
                                                                    endDate:
                                                                      endDate,
                                                                  };

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    data,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    data;
                                                                }}
                                                              >
                                                                <TextField
                                                                  className="input-field mt-2"
                                                                  fullWidth
                                                                  id="outlined-basic short summary"
                                                                  variant="outlined"
                                                                  placeholder={`Enter ${childAtt?.label}...`}
                                                                  value={
                                                                    childAtt?.value
                                                                      ? `${moment(
                                                                        childAtt
                                                                          ?.value
                                                                          ?.startDate
                                                                      ).format(
                                                                        "DD/MM/YYYY"
                                                                      )} - ${moment(
                                                                        childAtt
                                                                          ?.value
                                                                          ?.endDate
                                                                      ).format(
                                                                        "DD/MM/YYYY"
                                                                      )}`
                                                                      : ""
                                                                  }
                                                                  type="text"
                                                                />
                                                              </DateRangePicker>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "text_editor" ? (
                                                            <>
                                                              <CKEditor
                                                                editor={
                                                                  Editor
                                                                }
                                                                className="mt-2"
                                                                config={
                                                                  configForContentEditor
                                                                }
                                                                data={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event,
                                                                  editor
                                                                ) => {
                                                                  const data =
                                                                    editor?.getData();

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    data,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    data;
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "html" ? (
                                                            <>
                                                              <CKEditor
                                                                editor={
                                                                  Editor
                                                                }
                                                                className="mt-2"
                                                                config={
                                                                  configForContentEditorForHTML
                                                                }
                                                                data={
                                                                  childAtt?.value
                                                                }
                                                                onChange={(
                                                                  event,
                                                                  editor
                                                                ) => {
                                                                  const data =
                                                                    editor?.getData();

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    data,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    data;
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "image" ? (
                                                            <>
                                                              <Stack
                                                                gap={4}
                                                              >
                                                                {childAtt?.value && (
                                                                  <div className="upload-img-wrapper">
                                                                    <Image
                                                                      src={
                                                                        childAtt?.value
                                                                      }
                                                                    />
                                                                  </div>
                                                                )}

                                                                <Button
                                                                  className="btn ternary-btn w-100"
                                                                  onClick={() =>
                                                                    handleClickForAtt(
                                                                      "image",
                                                                      attdata
                                                                    )
                                                                  }
                                                                >
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faUpload
                                                                    }
                                                                  />
                                                                  {attdata?.value
                                                                    ? "Change Image"
                                                                    : "Upload Image"}
                                                                </Button>
                                                              </Stack>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "file" ? (
                                                            <>
                                                              <Stack
                                                                gap={3}
                                                              >
                                                                {childAtt?.value && (
                                                                  <div className="upload-img-wrapper mt-2">
                                                                    {childAtt?.value !==
                                                                      "" &&
                                                                      `File name: ${childAtt
                                                                        ?.value
                                                                        ?.name ||
                                                                      childAtt?.value
                                                                      }`}
                                                                  </div>
                                                                )}

                                                                <Button
                                                                  className="btn ternary-btn w-100 mt-3"
                                                                  onClick={() =>
                                                                    handleClickForAtt(
                                                                      "file",
                                                                      attdata
                                                                    )
                                                                  }
                                                                >
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faUpload
                                                                    }
                                                                  />
                                                                  {attdata?.value
                                                                    ? "Change File"
                                                                    : "Upload File"}
                                                                </Button>
                                                              </Stack>
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "users_list" ? (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                                options={
                                                                  childAtt?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Select ${childAtt?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                isMulti
                                                                value={childAtt?.options.filter(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    childAtt?.value?.includes(
                                                                      option?.value
                                                                    )
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  const selectedValues =
                                                                    selectedOption.map(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option?.value
                                                                    );

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    selectedValues,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    selectedValues;
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : childAtt?.fieldtype ===
                                                            "group_list" ? (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                                options={
                                                                  childAtt?.options
                                                                }
                                                                menuPlacement="auto"
                                                                name={
                                                                  childAtt?.nameValue
                                                                }
                                                                placeholder={`Select ${childAtt?.label}...`}
                                                                className="muilt-select-field mt-2"
                                                                classNamePrefix="select"
                                                                isMulti
                                                                value={childAtt?.options.find(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    option?.value ===
                                                                    childAtt?.value
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  const selectedValues =
                                                                    selectedOption.map(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option?.value
                                                                    );

                                                                  handleChangeForChildAtts(
                                                                    childAtt?.nameValue,
                                                                    selectedValues,
                                                                    childAtt?.section_id,
                                                                    childAtt,
                                                                    attdata
                                                                  );
                                                                  childAtt.value =
                                                                    selectedValues;
                                                                }}
                                                                styles={{
                                                                  option:
                                                                    (
                                                                      provided,
                                                                      state
                                                                    ) => {
                                                                      return {
                                                                        ...provided,
                                                                        backgroundColor:
                                                                          state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color:
                                                                          state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                      };
                                                                    },
                                                                }}
                                                              />
                                                              {childAtt?.is_mandatory ==
                                                                true &&
                                                                showBelowError ==
                                                                true &&
                                                                childAtt?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${childAtt?.label} is required.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </Col>
                                                      )
                                                    )}
                                                  </>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "checkbox_multiple_choice" ? (
                                            <>
                                              {attdata?.options?.map(
                                                (multiData, index) => (
                                                  <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                        checked={
                                                          attributesData
                                                            .find(
                                                              (attr) =>
                                                                attr?.id ==
                                                                attdata?.id &&
                                                                attr?.section_id ==
                                                                attdata?.section_id
                                                            )
                                                            ?.value?.includes(
                                                              multiData?.id
                                                            ) || false
                                                        }
                                                        name={
                                                          multiData?.id
                                                        }
                                                        onChange={(
                                                          event
                                                        ) => {
                                                          const checked =
                                                            event
                                                              ?.target
                                                              ?.checked;
                                                          const optionId =
                                                            multiData?.id;

                                                          let updatedValues;
                                                          if (checked) {
                                                            // Add the optionId to the array if checked
                                                            updatedValues =
                                                              [
                                                                ...(attributesData.find(
                                                                  (
                                                                    attr
                                                                  ) =>
                                                                    attr?.id ==
                                                                    attdata?.id &&
                                                                    attr?.section_id ===
                                                                    attdata?.section_id
                                                                )
                                                                  ?.value ||
                                                                  []),
                                                                optionId,
                                                              ];
                                                          } else {
                                                            // Remove the optionId from the array if unchecked
                                                            updatedValues =
                                                              (
                                                                attributesData.find(
                                                                  (
                                                                    attr
                                                                  ) =>
                                                                    attr?.id ==
                                                                    attdata?.id &&
                                                                    attr?.section_id ===
                                                                    attdata?.section_id
                                                                )
                                                                  ?.value ||
                                                                []
                                                              ).filter(
                                                                (val) =>
                                                                  val !==
                                                                  optionId
                                                              );
                                                          }

                                                          handleChangeForFormData(
                                                            attdata?.nameValue,
                                                            updatedValues,
                                                            attdata?.section_id
                                                          );
                                                        }}
                                                      />
                                                    }
                                                    label={
                                                      multiData?.name
                                                    }
                                                    className="font-inter"
                                                  />
                                                )
                                              )}
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value?.length <
                                                1 && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required at least one selection.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "dropdodown_multiple_choice" ? (
                                            <>
                                              <Select
                                                closeMenuOnSelect={
                                                  false
                                                }
                                                options={
                                                  attdata?.options
                                                }
                                                menuPlacement="auto"
                                                name={
                                                  attdata?.nameValue
                                                }
                                                placeholder={`Select ${attdata?.label}...`}
                                                className="muilt-select-field mt-2"
                                                classNamePrefix="select"
                                                isMulti
                                                value={attdata?.options?.filter(
                                                  (option) =>
                                                    attdata?.value?.includes(
                                                      option?.value
                                                    )
                                                )}
                                                onChange={(
                                                  selectedOption
                                                ) => {
                                                  const selectedValues =
                                                    selectedOption.map(
                                                      (option) =>
                                                        option?.value
                                                    );
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    selectedValues,
                                                    attdata?.section_id
                                                  );
                                                }}
                                                styles={{
                                                  option: (
                                                    provided,
                                                    state
                                                  ) => {
                                                    return {
                                                      ...provided,
                                                      backgroundColor:
                                                        state.isSelected
                                                          ? "#660c605c"
                                                          : provided.backgroundColor,
                                                      color:
                                                        state.isSelected
                                                          ? "var(--dark-color)"
                                                          : provided.color,
                                                    };
                                                  },
                                                }}
                                              />
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value?.length <
                                                1 && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required at least one selection.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "tags" ? (
                                            <>
                                              <CreatableSelect
                                                closeMenuOnSelect={
                                                  false
                                                }
                                                isMulti
                                                options={
                                                  attdata?.options
                                                }
                                                className="muilt-select-field  mt-2 boder-radius-8"
                                                menuPlacement="auto"
                                                placeholder={`Select or enter ${attdata?.label}...`}
                                                name={
                                                  attdata?.nameValue
                                                }
                                                value={attdata?.options?.filter(
                                                  (option) =>
                                                    attdata?.value?.includes(
                                                      option?.value
                                                    )
                                                )}
                                                onChange={(
                                                  selectedOptions
                                                ) => {
                                                  const selectedValues =
                                                    selectedOptions.map(
                                                      (option) => option
                                                    );
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    selectedValues,
                                                    attdata?.section_id
                                                  );
                                                }}
                                                styles={{
                                                  option: (
                                                    provided,
                                                    state
                                                  ) => {
                                                    return {
                                                      ...provided,
                                                      backgroundColor:
                                                        state.isSelected
                                                          ? "#660c605c"
                                                          : provided.backgroundColor,
                                                      color:
                                                        state.isSelected
                                                          ? "var(--dark-color)"
                                                          : provided.color,
                                                    };
                                                  },
                                                }}
                                              />
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value?.length <
                                                1 && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required at least one selection.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "time" &&
                                            attdata?.value !== null ? (
                                            <>
                                              <LocalizationProvider
                                                dateAdapter={
                                                  AdapterDayjs
                                                }
                                              >
                                                <DemoContainer
                                                  components={[
                                                    "TimePicker",
                                                  ]}
                                                  className="p-0 date"
                                                >
                                                  <TimePicker
                                                    name={
                                                      attdata?.nameValue
                                                    }
                                                    value={dayjs(
                                                      attdata?.value,
                                                      "HH:mm A"
                                                    )}
                                                    className="w-100 pt-0"
                                                    onChange={(
                                                      newValue
                                                    ) => {
                                                      handleChangeForFormData(
                                                        attdata?.nameValue,
                                                        newValue,
                                                        attdata?.section_id
                                                      );
                                                    }}
                                                    slotProps={{
                                                      field: {
                                                        clearable: true,
                                                      },
                                                    }}
                                                  />
                                                </DemoContainer>
                                              </LocalizationProvider>
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "date_time" ? (
                                            <>
                                              <LocalizationProvider
                                                dateAdapter={
                                                  AdapterDayjs
                                                }
                                              >
                                                <DemoContainer
                                                  components={[
                                                    "DateTimePicker",
                                                  ]}
                                                  className="p-0 date"
                                                >
                                                  <DateTimePicker
                                                    name={
                                                      attdata?.nameValue
                                                    }
                                                    value={dayjs(
                                                      attdata?.value
                                                    )}
                                                    className="w-100 pt-0"
                                                    onChange={(
                                                      newValue
                                                    ) => {
                                                      handleChangeForFormData(
                                                        attdata?.nameValue,
                                                        newValue,
                                                        attdata?.section_id
                                                      );
                                                    }}
                                                    slotProps={{
                                                      field: {
                                                        clearable: true,
                                                      },
                                                    }}
                                                  />
                                                </DemoContainer>
                                              </LocalizationProvider>
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "date_range" ? (
                                            <>
                                              <DateRangePicker
                                                initialSettings={{
                                                  startDate:
                                                    attdata?.value
                                                      ?.startDate,
                                                  endDate:
                                                    attdata?.value
                                                      ?.endDate,
                                                  autoUpdateInput: false,
                                                }}
                                                onApply={(_, range) => {
                                                  const startDate =
                                                    range?.startDate;
                                                  const endDate =
                                                    range?.endDate;
                                                  let data = {
                                                    startDate:
                                                      startDate,
                                                    endDate: endDate,
                                                  };
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    data,
                                                    attdata?.section_id
                                                  );
                                                }}
                                              >
                                                <TextField
                                                  className="input-field mt-2"
                                                  fullWidth
                                                  id="outlined-basic short summary"
                                                  variant="outlined"
                                                  placeholder={`Enter ${attdata?.label}...`}
                                                  value={
                                                    attdata?.value
                                                      ? `${moment(
                                                        attdata?.value
                                                          ?.startDate
                                                      ).format(
                                                        "DD/MM/YYYY"
                                                      )} - ${moment(
                                                        attdata?.value
                                                          ?.endDate
                                                      ).format(
                                                        "DD/MM/YYYY"
                                                      )}`
                                                      : ""
                                                  }
                                                  type="text"
                                                />
                                              </DateRangePicker>
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "text_editor" ? (
                                            <>
                                              <CKEditor
                                                editor={Editor}
                                                className="mt-2"
                                                config={
                                                  configForContentEditor
                                                }
                                                data={attdata?.value}
                                                onChange={(
                                                  event,
                                                  editor
                                                ) => {
                                                  const data =
                                                    editor?.getData();
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    data,
                                                    attdata?.section_id
                                                  );
                                                }}
                                              />
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "html" ? (
                                            <>
                                              <CKEditor
                                                editor={Editor}
                                                className="mt-2"
                                                config={
                                                  configForContentEditorForHTML
                                                }
                                                data={attdata?.value}
                                                onChange={(
                                                  event,
                                                  editor
                                                ) => {
                                                  const data =
                                                    editor?.getData();
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    data,
                                                    attdata?.section_id
                                                  );
                                                }}
                                              />
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "image" ? (
                                            <>
                                              <Stack gap={4}>
                                                {attdata?.value && (
                                                  <div className="upload-img-wrapper">
                                                    <Image
                                                      src={
                                                        attdata.value
                                                      }
                                                    />
                                                  </div>
                                                )}

                                                <Button
                                                  className="btn ternary-btn w-100"
                                                  onClick={() =>
                                                    handleClickForAtt(
                                                      "image",
                                                      attdata
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faUpload}
                                                  />
                                                  {attdata?.value
                                                    ? "Change Image"
                                                    : "Upload Image"}
                                                </Button>
                                              </Stack>
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "file" ? (
                                            <>
                                              <Stack gap={3}>
                                                {attdata?.value && (
                                                  <div className="upload-img-wrapper mt-2">
                                                    {attdata?.value !==
                                                      "" &&
                                                      `File name: ${attdata?.value
                                                        ?.name ||
                                                      attdata?.value
                                                      }`}
                                                  </div>
                                                )}

                                                <Button
                                                  className="btn ternary-btn w-100 mt-3"
                                                  onClick={() =>
                                                    handleClickForAtt(
                                                      "file",
                                                      attdata
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faUpload}
                                                  />
                                                  {attdata?.value
                                                    ? "Change File"
                                                    : "Upload File"}
                                                </Button>
                                              </Stack>
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "users_list" ? (
                                            <>
                                              <Select
                                                closeMenuOnSelect={
                                                  false
                                                }
                                                options={
                                                  attdata?.options
                                                }
                                                menuPlacement="auto"
                                                name={
                                                  attdata?.nameValue
                                                }
                                                placeholder={`Select ${attdata?.label}...`}
                                                className="muilt-select-field mt-2"
                                                classNamePrefix="select"
                                                isMulti

                                                value={attdata?.options.filter(
                                                  (
                                                    option
                                                  ) =>
                                                    attdata?.value?.includes(
                                                      option?.value
                                                    )
                                                )}
                                                onChange={(
                                                  selectedOption
                                                ) => {
                                                  const selectedValues =
                                                    selectedOption.map(
                                                      (option) =>
                                                        option?.value
                                                    );
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    selectedValues,
                                                    attdata?.section_id
                                                  );
                                                }}
                                                styles={{
                                                  option: (
                                                    provided,
                                                    state
                                                  ) => {
                                                    return {
                                                      ...provided,
                                                      backgroundColor:
                                                        state.isSelected
                                                          ? "#660c605c"
                                                          : provided.backgroundColor,
                                                      color:
                                                        state.isSelected
                                                          ? "var(--dark-color)"
                                                          : provided.color,
                                                    };
                                                  },
                                                }}
                                              />
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required at least one selection.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "group_list" ? (
                                            <>
                                              <Select
                                                closeMenuOnSelect={
                                                  false
                                                }
                                                options={
                                                  attdata?.options
                                                }
                                                menuPlacement="auto"
                                                name={
                                                  attdata?.nameValue
                                                }
                                                placeholder={`Select ${attdata?.label}...`}
                                                className="muilt-select-field mt-2"
                                                classNamePrefix="select"
                                                isMulti
                                                value={attdata?.options.find(
                                                  (option) =>
                                                    option?.value ===
                                                    attdata?.value
                                                )}
                                                onChange={(
                                                  selectedOption
                                                ) => {
                                                  const selectedValues =
                                                    selectedOption.map(
                                                      (option) =>
                                                        option?.value
                                                    );
                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    selectedValues,
                                                    attdata?.section_id
                                                  );
                                                }}
                                                styles={{
                                                  option: (
                                                    provided,
                                                    state
                                                  ) => {
                                                    return {
                                                      ...provided,
                                                      backgroundColor:
                                                        state.isSelected
                                                          ? "#660c605c"
                                                          : provided.backgroundColor,
                                                      color:
                                                        state.isSelected
                                                          ? "var(--dark-color)"
                                                          : provided.color,
                                                    };
                                                  },
                                                }}
                                              />
                                              {attdata?.is_mandatory ==
                                                true &&
                                                showBelowError ==
                                                true &&
                                                attdata?.value ===
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {`${attdata?.label} is required at least one selection.`}
                                                  </p>
                                                )}
                                            </>
                                          ) : attdata?.fieldtype ===
                                            "link_object" ? (
                                            <>
                                              <TextField
                                                className="input-field mt-2"
                                                fullWidth
                                                id={`outlined-basic-title-${index}`}
                                                variant="outlined"
                                                name={
                                                  attdata?.nameValue
                                                }
                                                placeholder={`Enter ${attdata?.label} Title...`}
                                                value={attdata?.value?.title || ""}
                                                onChange={(event) =>

                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    event?.target?.value,
                                                    attdata?.section_id,
                                                    false,
                                                    "title", // Specify this is the title subfield
                                                    false
                                                  )
                                                }
                                              />

                                              {attdata?.is_mandatory && showBelowError && !attdata?.value?.title && (
                                                <p style={{ color: "#d32f2f" }} className="error-msg">
                                                  {`${attdata?.label} Title is required.`}
                                                </p>
                                              )}
                                              <TextField
                                                className="input-field mt-2"
                                                fullWidth
                                                id={`outlined-basic-url-${index}`}
                                                variant="outlined"
                                                name={
                                                  attdata?.nameValue
                                                }
                                                placeholder={`Enter ${attdata?.label} URL...`}
                                                value={attdata?.value?.url || ""}
                                                onChange={(event) =>

                                                  handleChangeForFormData(
                                                    attdata?.nameValue,
                                                    event?.target?.value,
                                                    attdata?.section_id,
                                                    false,
                                                    "url", // Specify this is the url subfield
                                                    false
                                                  )
                                                }
                                                type="text"
                                              />
                                              {attdata?.value?.url && !isValidURL(attdata?.value?.url) && (
                                                <p style={{ color: "#d32f2f" }} className="error-msg">
                                                  {`${attdata?.label} URL must be a valid URL.`}
                                                </p>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Col>
                                      )}

                                    </>
                                  ))}
                                </Row>
                              </CustomTabPanel>
                            </Accordion.Body>
                          </Accordion.Item>
                          {id && (
                            <Accordion.Item
                              eventKey="2"
                              className="bg-white card-wrapper mt-4"
                            >
                              <Accordion.Header className="bg-white fw-700 primary-text">
                                Changelog
                              </Accordion.Header>
                              <Accordion.Body className="p-0 changelog-body">
                                <Grid
                                  container
                                  spacing={1}
                                  className="changelog-div  "
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={9}
                                    xl={9}
                                  >
                                    <Box className="">
                                      <InputGroup className="">
                                        <TextField
                                          className="search-changelog-input"
                                          fullWidth
                                          id="outlined-basic username"
                                          variant="outlined"
                                          size="small"
                                          name="attributeTitle"
                                          value={search}
                                          placeholder="search changelog"
                                          onChange={(e) => {
                                            setIstype(true);
                                            setSearch(e.target.value);
                                            if (e.target.value === "") {
                                              fetchAllChangeLogs(page);
                                            }
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                              fetchAllChangeLogs(page);
                                            }
                                          }}
                                        />
                                        <Button
                                          className="search-changelog"
                                          onClick={() => {
                                            fetchAllChangeLogs(page);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faSearch}
                                            className="search-changelog-icon"
                                          />
                                        </Button>
                                      </InputGroup>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={3}
                                    xl={3}
                                  >
                                    <div className="w-100">

                                      <Button
                                        variant="outlined"
                                        startIcon={
                                          <FontAwesomeIcon icon={faFileCsv} />
                                        }
                                        className="export-csv-btn"
                                        onClick={handleChangeLogCsvDownload}
                                      >
                                        Export as CSV
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Paper
                                  sx={{ width: "100%" }}
                                  className="changelog-table-div mt-4"
                                >
                                  <TableContainer className="">
                                    <Table
                                      sx={{ minWidth: 750 }}
                                      aria-labelledby="tableTitle"
                                      stickyHeader
                                    >
                                      <TableHeaderForSorting
                                        columns={
                                          columns_attribute_changelog_header
                                        }
                                        className="z-0"
                                      />
                                      <TableBody>
                                        {getAllChangeLogs?.data?.map(
                                          (data, index) => (
                                            <>
                                              <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index + 1}
                                                className=" tbl-changelog mt-2"
                                              >
                                                <TableCell
                                                  align="left"
                                                  className="tbl-border-left tbl-cell-5"
                                                >
                                                  <Box>
                                                    <Stack
                                                      direction="row"
                                                      alignContent="center"
                                                      alignItems="center"
                                                      spacing={1}
                                                      className="z-n1"
                                                    >
                                                      <Avatar
                                                        alt={data?.changed_by}
                                                        src="/static/images/avatar/1.jpg"
                                                        className="author-avtar"
                                                      />
                                                      <Typography className="fw-400 fs-14 ">
                                                        {data?.changed_by
                                                          ? data?.changed_by
                                                          : "-"}
                                                      </Typography>
                                                    </Stack>
                                                  </Box>
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  className="text-nowrap"
                                                >
                                                  {data?.changed_at
                                                    ? data?.changed_at
                                                    : "-"}
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  className="text-nowrap"
                                                >
                                                  {data?.area
                                                    ? data?.area
                                                    : "-"}
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  className="tbl-border-right"
                                                >
                                                  <Typography
                                                    className="fw-400 fs-14"
                                                    dangerouslySetInnerHTML={{
                                                      __html: data?.description
                                                        ? processedHTML(
                                                          data?.description
                                                        ).innerHTML
                                                        : "-",
                                                    }}
                                                  ></Typography>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow className="extra-row"></TableRow>
                                            </>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Paper>
                                {total_changelog > rowsPerPage && (
                                  <TablePagination
                                    component="div"
                                    count={total_changelog}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPage
                                    }
                                    labelDisplayedRows={({
                                      from,
                                      to,
                                      count,
                                    }) => {
                                      const currentPage = page + 1;
                                      const start =
                                        currentPage * rowsPerPage -
                                        rowsPerPage +
                                        1;
                                      const end = Math.min(
                                        currentPage * rowsPerPage,
                                        count
                                      );
                                      return (
                                        <>
                                          {`Showing ${start} to ${end} of `}
                                          <span>{count}</span>
                                          {" records"}
                                        </>
                                      );
                                    }}
                                  />
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </Accordion>
                      </Stack>
                    </Col>
                    <Col lg="3" md="5">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        gap={2}
                        flexWrap="wrap"
                        className="publish-div"
                      >
                        <Card className="bg-white card-wrapper w-100">
                          <Card.Header className="bg-white fw-700 primary-text">
                            Publish
                          </Card.Header>
                          <Card.Body className="p-15 publish-div">
                            <Button
                              className="btn primary-btn w-100"
                              type="submit"
                              onClick={() => {
                                setShowError(true);
                              }}
                            >
                              <FontAwesomeIcon icon={faBullhorn} />
                              Publish
                            </Button>
                            <div className="mt-3 enable-div">
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                gap={2}
                                flexWrap="wrap"
                              >
                                <div className="d-flex align-items-center gap-3">
                                  <Box className="d-flex gap-3 flex-wrap justify-content-center">
                                    <Typography
                                      className="fw-400 dark-text font-inter "
                                      whiteSpace="nowrap"
                                    >
                                      Enable User
                                    </Typography>
                                    <BootstrapTooltip
                                      title="You can enable/disable user."
                                      placement="bottom"
                                    >
                                      <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        className="mandatory-icon fw-900"
                                        aria-haspopup="true"
                                      />
                                    </BootstrapTooltip>
                                  </Box>
                                </div>

                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  className="h-100"
                                >
                                  <AntSwitch
                                    name="userStatus"
                                    checked={values?.userStatus}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "userStatus",
                                        event.target.checked
                                      );
                                    }}
                                    inputProps={{
                                      "aria-label": "ant design",
                                    }}
                                  />
                                </Stack>
                              </Stack>
                            </div>
                          </Card.Body>
                        </Card>
                        <Card className="bg-white card-wrapper mt-4 w-100">
                          <Card.Header className="bg-white fw-700 primary-text">
                            Thumbnail <span className="required">*</span>
                          </Card.Header>
                          <Card.Body className="p-15">
                            <ImageUpload
                              value={values?.image}
                              handleClick={() => handleClick("image")}
                              handleFileChange={handleFileChange}
                              setFieldValue={setFieldValue}
                            />
                            {errors?.image && touched.image && (
                              <p
                                style={{ color: "#d32f2f" }}
                                className="error-msg"
                              >
                                {errors?.image}
                              </p>
                            )}
                          </Card.Body>
                        </Card>
                      </Stack>
                    </Col>
                  </Row>
                  <FileUploadModal
                    open={openFileUploadModel}
                    setOpen={setOpenFileUploadModel}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    title={contentForPost}
                    setFieldValue={setFieldValue}
                    fileTypes={
                      contentForPost === "image" ? imageFileTypes : fileTypes
                    }
                    handleFileChange={handleFileChange}
                    file={
                      contentForPost === "image"
                        ? selectedImageFile
                        : selectedFile
                    }
                    setFileValue={() => handleImageFileValue(setFieldValue)}
                    isImage={contentForPost === "image" ? true : false}
                    isImageEditable={isEdit}
                  />
                  <FileUploadModalAttribue
                    open={openFileUploadModelForAtt}
                    setOpen={setOpenFileUploadModelForAtt}
                    handleOpen={handleOpenForAtt}
                    handleClose={handleCloseForAtt}
                    title={contentForPostForAtt}
                    setFieldValue={setFieldValue}
                    fileTypes={
                      contentForPostForAtt === "image"
                        ? imageFileTypes
                        : fileTypes
                    }
                    handleFileChange={handleFileChangeForAtt}
                    file={
                      contentForPostForAtt === "image"
                        ? selectedImageFileForAtt
                        : selectedFileForAtt
                    }
                    setFileValue={handleImageFileValueForAtt}
                    isImage={contentForPostForAtt === "image" ? true : false}
                    isImageEditable={isEdit}
                  />
                </Form>
              );
            }}
          </Formik>
        </Container>
      </div>
      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default AddUsers;
