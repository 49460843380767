import { userSigInAction } from "../redux/slices/auth/auth";
import { drawerMenuAction } from "../redux/slices/drawer/drawer";
import api from "../components/common/commonFunctionForApi";
import { Toast } from "../utils/toast";
import { headerAction } from "../redux/slices/header/header";

const logoutHelper = async (dispatch, navigate, userToken) => {
  try {
    const response = await api.post(
      "/logout",
      {},
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response?.success == true) {
      console.log("response success");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.removeItem("loginTime")
      localStorage.removeItem("lastCallTime_minute")
      localStorage.removeItem("lastCallTime_hour")
      localStorage.removeItem("lastCallTime_day")
      localStorage.removeItem("lastCallTime_week")
      localStorage.removeItem("selected-chat-room-id")
      localStorage.removeItem("pusher")
      localStorage.removeItem("tenant")
      localStorage.removeItem("pusherTransportTLS")
      localStorage.setItem("pusher-initialized", "false")

      dispatch(userSigInAction.logout());
      dispatch(drawerMenuAction.clickDrawerItem(""));
      navigate("/sign-in");

      Toast.success(response?.message);
      return true;
    } else if (response?.success == false) {
      console.log("response error");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.removeItem("loginTime")
      localStorage.removeItem("lastCallTime_minute")
      localStorage.removeItem("lastCallTime_hour")
      localStorage.removeItem("lastCallTime_day")
      localStorage.removeItem("lastCallTime_week")
      localStorage.removeItem("selected-chat-room-id")
      localStorage.removeItem("pusher")
      localStorage.removeItem("tenant")
      localStorage.removeItem("pusherTransportTLS")
      localStorage.setItem("pusher-initialized", "false")

      dispatch(userSigInAction.logout());
      dispatch(headerAction.setNotificationData([]));
      dispatch(headerAction.setNotificationFetched(false));
      dispatch(headerAction.setPusherInitialized(false));
      navigate("/sign-in");
      Toast.success(response?.message);
      return true;
    }
  } catch (err) {
    console.log("err::", err);
    dispatch(userSigInAction.logout());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("loginTime")
    localStorage.removeItem("lastCallTime_minute")
    localStorage.removeItem("lastCallTime_hour")
    localStorage.removeItem("lastCallTime_day")
    localStorage.removeItem("lastCallTime_week")
    localStorage.removeItem("selected-chat-room-id")
    localStorage.removeItem("pusher")
    localStorage.removeItem("tenant")
    navigate("/sign-in");
    return false;
  }
};

export default logoutHelper;
