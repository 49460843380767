export const columns_global_search_header = [
    {
        key: 1,
        disablePadding: true,
        id: "name",
        label: "Name",
        minWidth: 250,
        className: "tbl-head-class-global",
        showInfoIcon: true,
    },
    {
        key: 2,
        disablePadding: true,
        id: "type",
        label: "Type",
        className: "tbl-head-class-global",
        minWidth: 200,
    },
];
