import { lazy, Suspense } from "react";
import AuthGuard from "../utils/authGuard";
import AuthGuardForSuperAdmin from "../utils/authGuardForSuperAdmin";
import Users from "../pages/users";
import AddUsers from "../pages/users/addUsers";

const userRoutes = [
  {
    path: "/users",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <Users />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/add/users",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddUsers />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/user/edit/:id",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddUsers />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
];

export default userRoutes;
