import { createSlice } from "@reduxjs/toolkit";
const initialData = {
  selectedCategoryTab: 0,
};

const categoryTab = createSlice({
  name: "categoryTabDetails",
  initialState: initialData,
  reducers: {
    handleSelectedTab(state, action) {
      state.selectedCategoryTab = action?.payload;
    },
  },
});

export const categoryTabAction = categoryTab.actions;
export default categoryTab.reducer;
