import { createSlice } from "@reduxjs/toolkit";
const initialData = {
    selectedChatRoom: null,
    unReadMsgCount: 0
};

const conversation = createSlice({
    name: "conversationDetails",
    initialState: initialData,
    reducers: {
        setSelectedChatRoom(state, action) {
            state.selectedChatRoom = action?.payload
        },
        setUnReadMessageCount(state, action) {
            state.unReadMsgCount = action?.payload
        }
    }
})

export const conversationAction = conversation.actions;
export default conversation.reducer;